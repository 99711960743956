import React, { PureComponent } from 'react'
import { hasTradeAccess } from '../../utilities/Functions'
import NotSignedInBanner from './NotSignedInBanner'
import { getInspectionDamage, getReportDownloadLink } from './VehicleDetailsHelper'

export default class DamageReport extends PureComponent {
    render() {
        const { vehicle, user, onOpenVehiclePhotoModal } = this.props

        if (!hasTradeAccess(user)) {
            return <NotSignedInBanner />
        }

        return (
            <>
                {getReportDownloadLink(vehicle, user.theme)}
                {getInspectionDamage(vehicle, onOpenVehiclePhotoModal, user.theme)}
            </>
        )
    }
}
