import {
    RESET_CRITERIA_BY_KEY,
    RESET_SEARCH,
    SEARCH_BY_KEYWORD,
    SET_ALL_CRITERIA,
    SET_BUY_NOW_CRITERIA,
    SET_CRITERIA,
    SET_EXCHANGE_CRITERIA,
    SET_RANGE_CRITERIA,
    UNSET_CRITERIA,
    UNSET_RANGE_CRITERIA,
    API_BASE,
    API_GET_HEADERS,
    CLEAR_CRITERIA,
} from '../constants'

import { dispatchCall } from '../utilities/Functions'

import { changePagination } from './pagination'

export const setCriteria = (payload) => ({
    type: SET_CRITERIA,
    payload,
})

export const setAllCriteriasBegin = (payload) => ({
    type: 'SET_CRITERIA_BEGIN',
})

export const setAllCriteriaFailure = (error) => ({
    type: 'SET_CRITERIA_ERROR',
    error,
})

export const resetSearchCriteria = () => ({
    type: RESET_SEARCH,
})

export const resetCriteriaByKey = (payload) => ({
    type: RESET_CRITERIA_BY_KEY,
    payload,
})

export const unSetCriteria = (payload) => ({
    type: UNSET_CRITERIA,
    payload,
})

export const unSetRangeCriteria = (payload) => ({
    type: UNSET_RANGE_CRITERIA,
    payload,
})

export const setRangeCriteria = (payload) => ({
    type: SET_RANGE_CRITERIA,
    payload,
})

export const setBuyNow = (payload) => ({
    type: SET_BUY_NOW_CRITERIA,
    payload,
})

export const setExchange = (payload) => ({
    type: SET_EXCHANGE_CRITERIA,
    payload,
})

export const searchByKeyword = (payload) => ({
    type: SEARCH_BY_KEYWORD,
    payload,
})

export const setAllCriteria = (payload) => ({
    type: SET_ALL_CRITERIA,
    payload,
})

export const clearCriteria = (payload) => ({
    type: CLEAR_CRITERIA,
    payload,
})

export function handleOnSetCriteria(value, key, set, inputType, offsetFinish) {
    const payload = {
        value,
        key,
        inputType,
    }
    return (dispatch) => {
        if (set) {
            if (key === 'makes') {
                const altPayload = {
                    key: 'models',
                    inputType,
                }
                const altPayload2 = {
                    key: 'modelVariants',
                    inputType,
                }
                dispatch(clearCriteria(altPayload))
                dispatch(clearCriteria(altPayload2))
            }
            if (key === 'models') {
                const altPayload = {
                    key: 'modelVariants',
                    inputType,
                }
                dispatch(clearCriteria(altPayload))
            }
            dispatch(
                changePagination({
                    currentPage: 1,
                    offsetStart: 0,
                    offsetFinish: offsetFinish,
                }),
            )
            return dispatch(setCriteria(payload))
        }
        if (key === 'makes') {
            const altPayload = {
                key: 'models',
                inputType,
            }
            const altPayload2 = {
                key: 'modelVariants',
                inputType,
            }
            dispatch(clearCriteria(altPayload))
            dispatch(clearCriteria(altPayload2))
        }
        if (key === 'models') {
            const altPayload = {
                key: 'modelVariants',
                inputType,
            }
            dispatch(clearCriteria(altPayload))
        }
        dispatch(
            changePagination({
                currentPage: 1,
                offsetStart: 0,
                offsetFinish: offsetFinish,
            }),
        )
        return dispatch(unSetCriteria(payload))
    }
}

export function handleOnLoadSearch(criteria, offsetFinish, disableExcludeList) {
    return (dispatch) => {
        dispatch(
            changePagination({
                currentPage: 1,
                offsetStart: 0,
                offsetFinish: offsetFinish,
            }),
        )
        return dispatch(setAllCriteria(criteria))
    }
}

export function loadMarketingSearch(id) {
    const url = `${API_BASE}api/search/public/${id}`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS(),
        }
        dispatchCall(dispatch, fetchDetails, setAllCriteriasBegin, setAllCriteria, setAllCriteriaFailure)
    }
}

export function handleOnSetRangeCriteria(value, key, set, offsetFinish) {
    const payload = {
        value,
        key,
    }
    return (dispatch) => {
        if (set) {
            dispatch(
                changePagination({
                    currentPage: 1,
                    offsetStart: 0,
                    offsetFinish: offsetFinish,
                }),
            )
            return dispatch(setRangeCriteria(payload))
        }
        dispatch(
            changePagination({
                currentPage: 1,
                offsetStart: 0,
                offsetFinish: offsetFinish,
            }),
        )
        return dispatch(unSetRangeCriteria(payload))
    }
}

export function resetSearch(offsetFinish) {
    return (dispatch) => {
        dispatch(
            changePagination({
                currentPage: 1,
                offsetStart: 0,
                offsetFinish: offsetFinish,
            }),
        )
        return dispatch(resetSearchCriteria())
    }
}

export function resetCriteriaFilter(key, offsetFinish) {
    return (dispatch) => {
        dispatch(
            changePagination({
                currentPage: 1,
                offsetStart: 0,
                offsetFinish: offsetFinish,
            }),
        )
        return dispatch(resetCriteriaByKey(key))
    }
}

export function onKeywordChange(value, offsetFinish) {
    return (dispatch) => {
        dispatch(
            changePagination({
                currentPage: 1,
                offsetStart: 0,
                offsetFinish: offsetFinish,
            }),
        )
        return dispatch(searchByKeyword(value))
    }
}

export function onClickBuyNowOnly(value, offsetFinish) {
    return (dispatch) => {
        dispatch(
            changePagination({
                currentPage: 1,
                offsetStart: 0,
                offsetFinish: offsetFinish,
            }),
        )
        return dispatch(setBuyNow(value))
    }
}

export function onClickExchangeOnly(value, offsetFinish) {
    return (dispatch) => {
        dispatch(
            changePagination({
                currentPage: 1,
                offsetStart: 0,
                offsetFinish: offsetFinish,
            }),
        )
        return dispatch(setExchange(value))
    }
}
