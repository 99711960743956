import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchProxyBidVehicles } from '../../../actions/vehicles'
import { setPageSize } from '../../../actions/pagination'
import { onChangeSortOrder } from '../../../actions/sorting'

import ProxyBidList from './ProxyBidList'

const mapStateToProps = (state) => ({
    user: state.user,
    vehicles: state.vehicles,
    pagination: state.pagination,
    sorting: state.sorting,
})

const mapDispatchToProps = {
    fetchProxyBidVehicles,
    setPageSize,
    onChangeSortOrder,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProxyBidList))
