import {
    FETCH_PROFILE_LANGUAGE_DICTIONARY,
    FETCH_PROFILE_LANGUAGE_DICTIONARY_SUCCESS,
    FETCH_PROFILE_LANGUAGE_DICTIONARY_ERROR,
} from '../constants'

const initialState = {
    loading: false,
    data: {},
    error: null,
}

const LanguageDictionaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROFILE_LANGUAGE_DICTIONARY:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case FETCH_PROFILE_LANGUAGE_DICTIONARY_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            }

        case FETCH_PROFILE_LANGUAGE_DICTIONARY_ERROR:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.error,
            }
        default:
            return state
    }
}

export default LanguageDictionaryReducer
