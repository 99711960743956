import React, { Component } from 'react'
import { FaMapMarkerAlt } from '@react-icons/all-files/fa/FaMapMarkerAlt'
import { FaAt } from '@react-icons/all-files/fa/FaAt'

export default class VehicleBanner extends Component {
    render() {
        const { vehicle } = this.props

        if (vehicle.collectionAddress && vehicle.collectionAddress.town) {
            return (
                <div
                    style={{
                        position: 'absolute',
                        zIndex: '1',
                        background: 'rgba(0, 0, 0, 0.5)',
                        width: '100%',
                        color: '#fff',
                        bottom: '0px',
                        left: '0px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                    }}
                >
                    <div>
                        <FaMapMarkerAlt color={'#F67419'} style={{ marginLeft: '5px' }} />
                        <FaAt color={'#fff'} style={{ marginLeft: '5px' }} />
                        {vehicle.collectionAddress.town}
                    </div>
                </div>
            )
        } else if (vehicle.fullAccountName) {
            return (
                <div
                    style={{
                        position: 'absolute',
                        zIndex: '1',
                        background: 'rgba(0, 0, 0, 0.5)',
                        width: '100%',
                        color: '#fff',
                        bottom: '0px',
                        left: '0px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                    }}
                >
                    <div>&nbsp;&nbsp;{vehicle.fullAccountName}</div>
                </div>
            )
        } else {
            return null
        }
    }
}
