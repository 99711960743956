import React, { Component } from 'react'
import Select from 'react-select'
import Input from './Input'
import InputTwo from './InputTwo'
import InputFromListOption from './InputFromListOption'
class Items extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null,
            criteria: [],
            disabled: false,
        }
    }

    componentDidMount() {
        const data = this.props.data
        const criteria = this.props.criteria
        this.setState({
            data,
            criteria,
            disabled: false,
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            data: nextProps.data,
            criteria: nextProps.criteria,
            disabled: false,
        })
    }

    render() {
        const blankValue = { value: '', label: 'any' }
        let minFieldOptions = []
        if (this.state.data && this.state.data.minField) {
            minFieldOptions = this.state.data.minField.options
        }
        if (!minFieldOptions.find((field) => field.label.includes('any'))) {
            minFieldOptions = [blankValue, ...minFieldOptions]
        }
        let maxFieldOptions = []
        if (this.state.data && this.state.data.maxField) {
            maxFieldOptions = this.state.data.maxField.options
        }
        if (!maxFieldOptions.find((field) => field.label.includes('any'))) {
            maxFieldOptions = [blankValue, ...maxFieldOptions]
        }
        if (this.state.data) {
            if (this.props.inputType === 'rangeSelect') {
                if (this.state.data.minField) {
                    return (
                        <div className="col-sm-12">
                            <div className="row">
                                <p>Select or type {this.props.defaultText.toLowerCase()}</p>
                            </div>
                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label>Min</label> <br />
                                    <Select
                                        value={this.state.criteria.min || blankValue}
                                        isDisabled={this.props.loading}
                                        onChange={(val) => this.props.onSetRangeCriteria(val, 'min')}
                                        options={minFieldOptions}
                                        isSearchable={true}
                                        classNamePrefix="ab-select"
                                        className="custom-select-input"
                                    />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label>Max</label> <br />
                                    <Select
                                        value={this.state.criteria.max || blankValue}
                                        isDisabled={this.props.loading}
                                        onChange={(val) => this.props.onSetRangeCriteria(val, 'max')}
                                        options={maxFieldOptions}
                                        isSearchable={true}
                                        classNamePrefix="ab-select"
                                        className="custom-select-input"
                                    />
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return null
                }
            } else {
                if (this.props.dataType === 'sales') {
                    return Object.keys(this.state.data).map((item, itemIndex) => {
                        return Object.keys(this.state.data[item]).map((subItem, subItemIndex) => {
                            return (
                                <InputTwo
                                    className="custom-checkbox"
                                    checked={this.state.criteria.indexOf(this.state.data[item][subItem].id) >= 0}
                                    inputType={this.props.inputType}
                                    dataType={this.props.dataType}
                                    title={subItem}
                                    value={this.state.data[item][subItem].id}
                                    id={this.props.dataType + '-' + subItem}
                                    description={this.state.data[item][subItem].vehicles}
                                    handleOnSetCriteria={this.props.handleOnSetCriteria}
                                    key={itemIndex + '-' + subItemIndex}
                                    offsetFinish={this.props.offsetFinish}
                                />
                            )
                        })
                    })
                }

                if (this.props.jsonType === 'option-list') {
                    if (this.props.inputType === 'checkbox' || this.props.inputType === 'radio') {
                        return this.state.data.map((item, index) => {
                            return (
                                <Input
                                    key={this.props.dataType + '-' + index + item.id}
                                    index={index}
                                    item={item}
                                    dataType={this.props.dataType}
                                    checked={this.state.criteria.indexOf(item.value) >= 0}
                                    handleOnSetCriteria={this.props.handleOnSetCriteria}
                                    inputType={this.props.inputType}
                                    offsetFinish={this.props.offsetFinish}
                                />
                            )
                        })
                    }
                    // This is broken, and using the above input for radio inputType works fine
                    // if (this.props.inputType === "radio") {
                    //   return this.state.data.map((item, index) => {
                    //     return (
                    //       <InputFromListOption
                    //         key={this.props.dataType + "-" + index}
                    //         className="custom-checkbox text-capitalize"
                    //         index={index}
                    //         item={item}
                    //         title={item.label}
                    //         value={item.value}
                    //         description={item.count}
                    //         checked={this.state.criteria.includes(item.value)}
                    //         type={this.state.inputType}
                    //         jsonType={this.props.jsonType}
                    //         dataType={this.props.dataType}
                    //         handleOnSetCriteria={this.props.handleOnSetCriteria}
                    //         inputType={this.props.inputType}
                    //         offsetFinish={this.props.offsetFinish}
                    //       />
                    //     );
                    //   });
                    // }
                }

                return Object.keys(this.state.data).map((key, index) => {
                    return (
                        <InputTwo
                            key={this.props.dataType + '-' + index + key}
                            className="custom-checkbox text-capitalize"
                            index={index}
                            title={key}
                            description={this.state.data[key]}
                            checked={this.state.criteria.indexOf(key) >= 0}
                            type={this.state.inputType ? this.state.inputType : 'checkbox'}
                            dataType={this.props.dataType}
                            handleOnSetCriteria={this.props.handleOnSetCriteria}
                            inputType={this.props.inputType}
                            offsetFinish={this.props.offsetFinish}
                        />
                    )
                })
            }
        } else {
            return null
        }
    }
}

export default Items
