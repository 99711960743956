import { CHANGE_SORT_ORDER, SET_PREV_NEXT_ORDER, SET_SORTING_ORDER, SORT_VEHICLES } from '../constants'

import { changePagination } from './pagination'

export const changeSortOrder = (value) => ({
    type: CHANGE_SORT_ORDER,
    payload: value,
})

export const sortVehicles = (value) => ({
    type: SORT_VEHICLES,
    payload: value,
})

export const setSortingOrder = (payload) => ({
    type: SET_SORTING_ORDER,
    payload,
})

export const setPrevNextOrder = (payload) => ({
    type: SET_PREV_NEXT_ORDER,
    payload,
})

export function setSortOrderProperty({ value, label, reverse = false }) {
    return (dispatch) => {
        dispatch(
            changeSortOrder({
                dataField: {
                    value,
                    label,
                },
                reverse,
                active: true,
            }),
        )
    }
}

export function setSortedVehicles(ids) {
    return (dispatch) => {
        dispatch(setSortingOrder(ids))
    }
}

export function setPrevAndNextOrder(ids) {
    return (dispatch) => {
        dispatch(setPrevNextOrder(ids))
    }
}

export function onChangeSortOrder(value, offsetFinish) {
    return (dispatch) => {
        dispatch(
            changePagination({
                currentPage: 1,
                offsetStart: 0,
                offsetFinish: offsetFinish,
            }),
        )
        dispatch(changeSortOrder(value.sorting))
    }
}
