import React, { Component } from 'react'
import { FaPlus } from '@react-icons/all-files/fa/FaPlus'
import { FaMinus } from '@react-icons/all-files/fa/FaMinus'

import { Button } from 'reactstrap'

import { renderButtonContent } from '../../utilities/Functions'

class MobileButton extends Component {
    render() {
        const { dataType, defaultText, disabled, isOpen, onOpenCriteria, onCloseCriteria } = this.props
        return (
            <div className="ab-results-filter-sm" id="headingOne">
                <h5 className="mb-0">
                    <Button
                        id={`${dataType}-button-accordion`}
                        className={`btn btn-primary btn-block position-relative ${!isOpen ? 'collapsed' : ''} ${
                            this.props.criteria.length ? 'ab-results-filter-with-selection' : ''
                        } `}
                        onClick={() => {
                            if (isOpen) {
                                return onCloseCriteria(this.props.dataType)
                            }
                            return onOpenCriteria(this.props.dataType)
                        }}
                        disabled={disabled}
                        type="button"
                    >
                        <p className="p-0 m-0">{defaultText}</p>
                        {isOpen ? <FaMinus className="position-absolute" /> : <FaPlus className="position-absolute" />}
                        {renderButtonContent(this.props)}
                    </Button>
                </h5>
            </div>
        )
    }
}

export default MobileButton
