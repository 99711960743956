import React from 'react'
import { NavLink, Nav } from 'reactstrap'

export default function Tabs(props) {
    if (props.dataType === 'modelVariants') {
        return (
            <Nav tabs className="card-header-tabs">
                {Object.keys(props.data).map((item, itemIndex, itemArray) => {
                    return Object.keys(props.data[item]).map((subItem, subItemIndex, subItemArray) => {
                        return (
                            <NavLink
                                className={`${
                                    props.activeTab === itemIndex + '-' + subItemIndex ? 'active' : ''
                                } ${subItem.indexOf('Digital') !== -1 ? 'e-hub' : ''}`}
                                onClick={() => {
                                    props.toggle(itemIndex + '-' + subItemIndex)
                                }}
                                key={itemIndex + '-' + subItemIndex}
                            >
                                {item} - {subItem}
                            </NavLink>
                        )
                    })
                })}
            </Nav>
        )
    }
    return (
        <Nav tabs className="card-header-tabs">
            {Object.keys(props.data).map((item, index, arr) => {
                return (
                    <NavLink
                        className={`${props.activeTab === index + '-' + index ? 'active' : ''} ${item.indexOf('Digital') >= 0 ? 'e-hub' : ''}`}
                        onClick={() => {
                            props.toggle(index + '-' + index)
                        }}
                        key={index + '-' + index}
                    >
                        {item}
                    </NavLink>
                )
            })}
        </Nav>
    )
}
