import React, { Component } from 'react'
import { Col, Container, Row } from 'reactstrap'
import MapContainer from './Sections/MapContainer'
import AuctionSchedule from './Sections/AuctionSchedule'
import FeaturedProducts from './Sections/FeaturedProducts'
import LatestNews from './Sections/LatestNews'
import FollowPosts from './Sections/FollowPosts'
import FiftyFifty from './Sections/FiftyFifty'
import RegisterNow from './Sections/RegisterNow'
import { FaChevronCircleRight } from '@react-icons/all-files/fa/FaChevronCircleRight'
import { FaChevronCircleLeft } from '@react-icons/all-files/fa/FaChevronCircleLeft'

class HomePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewFullSchedule: false,
        }
    }
    componentDidMount() {
        this.props.toggleHeaderTotalBar(true)
    }

    viewFullSchedule(value) {
        this.setState({ viewFullSchedule: value })
    }

    render() {
        return (
            <div className="section__wrapper">
                <section>
                    <Container fluid={true}>
                        <Container className="container-lg">
                            <Row>
                                <Col xs="12" lg={{ size: 5, offset: 1 }} className="pb-5">
                                    <RegisterNow />
                                </Col>
                                <Col
                                    xs="12"
                                    lg="6"
                                    className={`${this.state.viewFullSchedule ? '' : 'o-hidden '} ab-view-mob-height`}
                                >
                                    <div
                                        className={`ab-view-auction__expanded-first ${this.state.viewFullSchedule ? 'ab-view-auction__expanded-first--visible' : ''}`}
                                    >
                                        <h2>Todays Auctions</h2>
                                        <h3>Monday 6th May</h3>
                                        <MapContainer />
                                        <div className="m-0 ab-view-more" onClick={() => this.viewFullSchedule(true)}>
                                            <div>
                                                View Full Schedule
                                                <FaChevronCircleRight className="ml-3" />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`ab-view-auction__expanded ${this.state.viewFullSchedule ? 'ab-view-auction__expanded--visible' : ''}`}
                                    >
                                        <h2>Forthcoming Auction Schedule</h2>
                                        <h3>Week overview</h3>
                                        <AuctionSchedule />
                                        <div className="m-0 ab-view-more" onClick={() => this.viewFullSchedule(false)}>
                                            <div>
                                                Close Full Schedule
                                                <FaChevronCircleLeft className="ml-3" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </section>
                <section>
                    <Container fluid={true}>
                        <FeaturedProducts />
                    </Container>
                </section>
                <section>
                    <Container fluid={true}>
                        <Container>
                            <FiftyFifty />
                        </Container>
                    </Container>
                </section>
                <section className="ab__fluid-background text-white">
                    <Container fluid={true}>
                        <Container>
                            <LatestNews />
                            <FollowPosts />
                        </Container>
                    </Container>
                </section>
            </div>
        )
    }
}

export default HomePage
