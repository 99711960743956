import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import VehicleDetails from './CatalogueVehicleDetails'

import { getVehicleDetails, getVehicles, onClickSetProxyBid, onClickWatch } from '../../actions/vehicles'

import {
    onClickBiddingModal,
    onOpenBuyNowModal,
    onOpenProxyBidModal,
    onOpenVehiclePhotoModal,
} from '../../actions/modals'

const mapStateToProps = (state) => ({
    vehicles: state.vehicles,
    user: state.user,
    mode: state.mode,
    criteria: state.criteria,
    sortingOrder: state.sorting.sortingOrder,
    websocketConnected: state.websocket.connected,
    prevAndNextOrder: state.prevAndNext.order,
})

const mapDispatchToProps = {
    getVehicleDetails,
    onClickWatch,
    onOpenBuyNowModal,
    onOpenVehiclePhotoModal,
    onOpenProxyBidModal,
    onClickSetProxyBid,
    onClickBiddingModal,
    getVehicles,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VehicleDetails))
