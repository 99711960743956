import { connect } from 'react-redux'

import MockGui from './MockGui'
import { updateBiddingDetails } from '../../actions/vehicles'
const mapStateToProps = (state) => ({
    vehicles: state.vehicles,
})

const mapDispatchToProps = {
    updateBiddingDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(MockGui)
