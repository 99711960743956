import React, { Component } from 'react'
import { formatCurrency } from '../../utilities/Functions'
import { lang } from '../../themeConfig'
import { FaCircle } from '@react-icons/all-files/fa/FaCircle'

class ProgressBar extends Component {
    render() {
        const { options, theme } = this.props
        return (
            <div
                className={`e-hub__progress-bar ${options.fillPercent ? 'e-hub__progress-bar-alt' : ''} ${
                    options.enabled ? options.class : 'disabled'
                } ${!options.fillPercent || options.isTotal || options.unclickable ? 'no-link' : ''} ${options.isTotal ? 'total' : ''}`}
            >
                <span
                    className="e-hub__progress-bar-fill "
                    style={
                        options.fillPercent
                            ? {
                                  width: options.excludedFromTotalText ? '100%' : options.fillPercent,
                              }
                            : {}
                    }
                >
                    {options.fillPercent && <span className={`${options.class} e-hub__progress-bar--fill-up`} />}
                </span>
                {options.hasIcon && <FaCircle className={options.enabled ? options.class : 'disabled'} />}
                <span
                    className={`e-hub__progress-bar--text ${options.hasIcon ? 'e-hub__progress-bar--text-icon' : ''} ${options.isTotal ? 'e-hub__progress-bar--text-total' : ''}`}
                >
                    {options.name}{' '}
                    {options.excludedFromTotalText ? <small>{lang('LABEL_EXCLUDED_FROM_TOTAL', theme)}</small> : ''}
                </span>
                <span className="e-hub__progress-bar--count">
                    {options.format === 'currency' && formatCurrency(options.count, true)}
                    {options.format === 'number' && options.count}
                </span>
            </div>
        )
    }
}

export default ProgressBar
