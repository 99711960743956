import { combineReducers } from 'redux'
import user from './user'
import criteria from './criteria'
import criteriaData from './criteriaData'
import error from './error'
import mode from './mode'
import pagination from './pagination'
import navigation from './navigation'
import profileConfig from './profileConfig'
import sorting from './sorting'
import vehicles from './vehicles'
import notifications from './notifications'
import modals from './modals'
import ehub from './ehub'
import websocket from './websocket'
import funding from './funding'
import prevAndNext from './prevAndNext'
import languageDictionary from './languageDictionary'
import permissions from './permissions'

const AppReducers = combineReducers({
    navigation,
    profileConfig,
    vehicles,
    user,
    criteria,
    criteriaData,
    sorting,
    pagination,
    error,
    mode,
    notifications,
    modals,
    ehub,
    websocket,
    funding,
    prevAndNext,
    languageDictionary,
    permissions,
})

export default AppReducers
