import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FaChevronCircleRight } from '@react-icons/all-files/fa/FaChevronCircleRight'

class BuyerDashboard extends Component {
    render() {
        return (
            <React.Fragment>
                <h1 className="h1-s">Welcome to your Aston Barclay Account</h1>
                <h2 className="h2-s">Your Services</h2>
                <div className="ab__buyer-dash--wrapper">
                    <Link to="/my-account/e-xchange" className="ab__buyer-dash--item" alt="e-xchange">
                        <img src="/img/auction-exchange.png" alt="service" />
                        <h3>E-XCHANGE</h3>
                    </Link>
                    <Link to="/my-account/bid-buy-now" className="ab__buyer-dash--item">
                        <img src="/img/auction-green.png" alt="service" />
                        <h3>Bid & Buy Now list</h3>
                    </Link>
                    <Link to="/my-account/watchlist" className="ab__buyer-dash--item">
                        <img src="/img/logo-watch.png" alt="service" />
                        <h3>Wathclist</h3>
                    </Link>
                    <Link to="/my-account/proxy-bids" className="ab__buyer-dash--item">
                        <img src="/img/logo-watch.png" alt="service" />
                        <h3>Proxy Bid List</h3>
                    </Link>
                    <Link to="/my-account/damage-portal" className="ab__buyer-dash--item">
                        <img src="/img/logo-damage.png" alt="service" />
                        <h3>Damage Portal</h3>
                    </Link>
                    <Link to="/my-account/saved-searches" className="ab__buyer-dash--item">
                        <img src="/img/logo-search.png" alt="service" />
                        <h3>Saved Searches</h3>
                    </Link>
                    <Link to="/my-account/live" className="ab__buyer-dash--item">
                        <img src="/img/logo-live.png" alt="service" />
                        <h3>live</h3>
                    </Link>
                    <Link to="/my-account/ims-reporting" className="ab__buyer-dash--item">
                        <img src="/img/logo-ims.png" alt="service" />
                        <h3>IMS Reporting</h3>
                    </Link>
                    <Link to="/my-account/collection-request" className="ab__buyer-dash--item">
                        <img src="/img/logo-collection.png" alt="service" />
                        <h3>Collection request</h3>
                    </Link>
                </div>
                <div className="text-right">
                    <p className="mt-4 ab-forgot__password">
                        <a href="/">
                            View more services
                            <FaChevronCircleRight className="ml-3" />
                        </a>
                    </p>
                </div>
                <small>
                    If you require any assistance with any of our services, please email{' '}
                    <a href="/">websupport@astonbarclay.net</a>
                </small>
            </React.Fragment>
        )
    }
}

export default BuyerDashboard
