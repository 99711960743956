import React, { Component } from 'react'
import { Col } from 'reactstrap'

import { permissions } from '../../themeConfig'
import { FaChevronUp } from '@react-icons/all-files/fa/FaChevronUp'

export default class BrandedFooterLinks extends Component {
    render() {
        let links = []
        for (const k in this.props.data.footer) {
            links.push(
                <li className="footer_nav_items">
                    <a href={this.props.data.footer[k].url} key={k}>
                        {k}
                    </a>
                </li>,
            )
        }
        return (
            <React.Fragment>
                {this.props.data && this.props.data.socialMedia && (
                    <Col xs="4" className="order-2 order-md-4">
                        <div className="ab-footer-social">
                            <div
                                style={{ display: 'inline-flex', marginLeft: '20px' }}
                                onClick={() =>
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                    })
                                }
                            >
                                <FaChevronUp />
                            </div>
                        </div>
                    </Col>
                )}
                {permissions('CATALOGUE_TOC_VISIBLE') && (
                    <Col xs="6" md="2" className="ab-footer-menu-2 order-3 order-md-2">
                        <p>
                            <a
                                href={`${this.props.global.frontMediaHost}/terms`}
                                target={(this.props.theme !== 'default' && '_blank') || null}
                                rel={(this.props.theme !== 'default' && 'noreferrer noopener') || null}
                            >
                                Terms & Conditions
                            </a>
                        </p>
                    </Col>
                )}

                {permissions('CATALOGUE_PRIVACYPOLICY_VISIBLE') && (
                    <Col xs="6" md="2" className="ab-footer-menu-2 order-4 order-md-3">
                        <p>
                            <a
                                href={`${this.props.global.frontMediaHost}/privacy`}
                                target={(this.props.theme !== 'default' && '_blank') || null}
                                rel={(this.props.theme !== 'default' && 'noreferrer noopener') || null}
                            >
                                Privacy Policy
                            </a>
                            <br />
                        </p>
                    </Col>
                )}
                <ul className="footer_nav">{links}</ul>
            </React.Fragment>
        )
    }
}
