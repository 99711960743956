import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import VehicleByVrm from './VehicleByVrm'
import { fetchStats, onAllSearchVrm } from '../../../actions/ehub'

const mapStateToProps = (state) => ({
    ehub: state.ehub,
    loading: state.ehub.loading,
    user: state.user,
    appraisalSearches: state.ehub.appraisalSearch,
})

const mapDispatchToProps = {
    fetchStats,
    onAllSearchVrm,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VehicleByVrm))
