import {
    FETCH_PROFILE_PERMISSIONS,
    FETCH_PROFILE_PERMISSIONS_ERROR,
    FETCH_PROFILE_PERMISSIONS_SUCCESS,
} from '../constants'
import { addNotification } from './notifications'

export const fetchProfilePermissionsBegin = () => ({
    type: FETCH_PROFILE_PERMISSIONS,
})

export const fetchProfilePermissionsSuccess = (permissions) => ({
    type: FETCH_PROFILE_PERMISSIONS_SUCCESS,
    payload: permissions,
})

export const fetchProfilePermissionsFailure = (error) => ({
    type: FETCH_PROFILE_PERMISSIONS_ERROR,
    payload: { error },
})

export function fetchProfilePermissions(themeId) {
    return async (dispatch) => {
        dispatch(fetchProfilePermissionsBegin())

        try {
            const translationPath = `../themeConfig/permissions/${themeId}.json`

            const permissionFiles = import.meta.glob(`../themeConfig/permissions/*.json`, {
                as: 'raw',
            })

            const rawPermissions = await permissionFiles[translationPath]()
            const permissions = JSON.parse(rawPermissions)

            dispatch(fetchProfilePermissionsSuccess(permissions))

            // Migrated these from setTheme() in the user actions due to its dependency on the languageDictionary
        } catch (error) {
            dispatch(
                addNotification({
                    text: `Failed to fetch language for ${themeId}`,
                    type: 'error',
                }),
            )

            dispatch(fetchProfilePermissionsFailure(error))
        }
    }
}
