import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    fetchSavedSearches,
    fetchDeleteSavedSearches,
    fetchSearchUsers,
    fetchSavedSearchesForUsername,
} from '../../../actions/user'

import { handleOnLoadSearch } from '../../../actions/criteria'

import { onCloseSaveSearchModal } from '../../../actions/modals'

import { addNotification } from '../../../actions/notifications'

import SavedSearches from './SavedSearches'

const mapStateToProps = (state) => ({
    user: state.user,
    saveSearchModal: state.modals.saveSearch,
    pagination: state.pagination,
})

const mapDispatchToProps = {
    fetchSavedSearches,
    fetchSearchUsers,
    fetchSavedSearchesForUsername,
    fetchDeleteSavedSearches,
    handleOnLoadSearch,
    onCloseSaveSearchModal,
    addNotification,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SavedSearches))
