import React, { Component } from 'react'
import { FaFileExcel } from '@react-icons/all-files/fa/FaFileExcel'
import { Tooltip } from 'reactstrap'

import { BuildSearchQuery } from '../../utilities/SearchQuery'
import { API_BASE } from '../../constants'
import { lang } from '../../themeConfig'

class ExcelDownloadIcon extends Component {
    constructor(props) {
        super(props)
        this.dataUrl = `${API_BASE}data/vehicles`
        this.watchListUrl = `${API_BASE}data/auth/watchlist/vehicles`
        this.toggle = this.toggle.bind(this)
        this.id = 'tooltip-download-icon-excel'
        this.state = {
            tooltipOpen: false,
        }
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        })
    }

    render() {
        const params = BuildSearchQuery(this.props.criteria)
        let href = this.dataUrl + '/xlsx' + params
        if (this.props.isWatchList) {
            href = this.watchListUrl + '/xlsx'
        }

        return (
            <React.Fragment>
                <a href={href} id={this.id} style={{ marginLeft: '8px' }}>
                    <FaFileExcel size="2em" color="#333333" />
                </a>
                <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target={this.id} toggle={this.toggle}>
                    {lang('DOWNLOAD_EXCEL_FILE', this.props.theme)}
                </Tooltip>
            </React.Fragment>
        )
    }
}

export default ExcelDownloadIcon
