import { messageTypes, WEBSOCKET_CONNECTED, WEBSOCKET_DISCONNECTED } from '../constants/websocket'
import { addNotification } from './notifications'

import { Client } from '@stomp/stompjs'

let client = new Client({
    reconnectDelay: 1000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,
})

export const init = (store) => {
    const subscribe = () => {
        Object.keys(messageTypes).forEach((type) => {
            if (client && client._stompHandler && client._stompHandler._subscriptions.hasOwnProperty(type)) {
                return
            }
            client.subscribe(
                type,
                function (message) {
                    var body = JSON.parse(message.body)
                    store.dispatch({
                        type: messageTypes[type],
                        payload: body,
                    })
                },
                { id: type },
            )
        })

        client.subscribe('/user/queue/errors', function (message) {
            var body = JSON.parse(message.body)

            store.dispatch(
                addNotification({
                    text: `Request Failed, ${body.errors[0].message}`,
                    type: 'error',
                }),
            )
        })
    }

    client.onConnect = function (frame) {
        subscribe()
        store.dispatch({
            type: WEBSOCKET_CONNECTED,
        })
    }

    client.onWebSocketClose = function (frame) {
        store.dispatch({
            type: WEBSOCKET_DISCONNECTED,
        })
    }

    client.brokerURL = `${store.getState().profileConfig.data.wsHost}/secured/auction`

    client.activate()
}

export const emit = (type, payload) => client.publish({ destination: type, body: JSON.stringify(payload) })
