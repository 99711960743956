import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Header from './Header'

const mapStateToProps = (state) => ({
    user: state.user,
    navigation: state.navigation,
    vehicleStats: state.navigation.vehicleStats,
    global: state.profileConfig && state.profileConfig.data,
})

const mapDispatchToProps = {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
