export function ISODateString(iso, includeSeconds, excludeTime, excludeDate) {
    if (!iso || iso === 'null' || iso === null) {
        return ''
    }
    function pad(n) {
        return n < 10 ? '0' + n : n
    }
    const date = new Date(iso)
    if (excludeTime) {
        return `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`
    }

    if (excludeDate) {
        if (includeSeconds) {
            return `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`
        }
        return `${pad(date.getHours())}:${pad(date.getMinutes())}`
    }
    return `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()} at ${pad(date.getHours())}:${pad(
        date.getMinutes(),
    )}`
}
