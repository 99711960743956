import { SET_PREV_NEXT_ORDER } from '../constants'

export const setPrevNextOrder = (payload) => ({
    type: SET_PREV_NEXT_ORDER,
    payload,
})

export function setPrevAndNextOrder(ids) {
    return (dispatch) => {
        dispatch(setPrevNextOrder(ids))
    }
}
