import React, { Component } from 'react'
import { Input } from 'reactstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { fetchSearchUsers } from '../../../actions/user'

class UserSearch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            searchValue: '',
        }
    }

    render() {
        return (
            <>
                <h3>Search Users</h3>
                <div className="saved-search-input-group">
                    <Input
                        type="text"
                        className="search-user-input"
                        value={this.state.searchValue}
                        aria-label={'Enter account username'}
                        placeholder={'Enter account username'}
                        onChange={(e) => this.setState({ searchValue: e.target.value })}
                    />
                    <div>
                        <button
                            className="btn btn-primary btn-block ab-btn-padding"
                            type="button"
                            disabled={this.state.searchValue === ''}
                            onClick={() => this.props.fetchSearchUsers(this.state.searchValue)}
                        >
                            SEARCH
                        </button>
                    </div>
                </div>
                <table className="m-0 p-0 search-table">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Email</th>
                            <th className="text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.results.length > 0 ? (
                            this.props.results.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.username}</td>
                                        <td>{item.email}</td>
                                        <td>
                                            <a
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.props.onSelection(item.username, item.email)
                                                }}
                                            >
                                                Select
                                            </a>
                                        </td>
                                    </tr>
                                )
                            })
                        ) : (
                            <tr>
                                <td colSpan={3}>
                                    <p>No results.</p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    results: state.user.searchUsers.results,
})

const mapDispatchToProps = {
    fetchSearchUsers,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserSearch))
