// src/actions/notifications.js

import createNotification from '../factories/createNotification'
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../constants'

export const setRemoveNotification = (id) => ({
    payload: id,
    type: REMOVE_NOTIFICATION,
})

export const setAddNotification = (options) => ({
    payload: createNotification(options),
    type: ADD_NOTIFICATION,
})

export function removeNotification(id) {
    return (dispatch) => {
        dispatch(setRemoveNotification(id))
    }
}

export function addNotification(options) {
    return (dispatch) => {
        dispatch(setAddNotification(options))
    }
}
