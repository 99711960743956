import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { ScaleLoader } from 'react-spinners'
import { css } from '@emotion/react'
import AppraisalSearchResults from './AppraisalSearchesResults'

const override = css`
    display: block;
    margin: 0 auto;
`

class VehicleByVrm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            appraisalSearches: [],
        }
    }

    goTo(link) {
        this.props.history.push(link)
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0)
        const vrm = this.props.match.params.vrm
        document.title = 'E-Hub | Vehicle Remarketing and Vehicle Auction | Aston Barclay'

        await this.setState({ currentVrm: vrm, appraisalSearches: [] })

        this.props.onAllSearchVrm(vrm)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
       
        this.setState({
            appraisalSearches: nextProps.ehub.appraisalSearch,
        })
    }

    render() {
        const { appraisalSearches } = this.state

        return (
            <React.Fragment>
                <Container fluid={true}>
                    <Container className="p-0">
                        <Row>
                            <Col xs="6" md="8" lg="9" className="d-flex">
                                <div className="e-hub__title">{this.props.match.params.vrm}</div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container className="e-hub__container p-0">
                    <div className="row mx-0">
                        {/* <!-- AB RIGHT COLUMN --> */}
                        <div className="col-lg-12 col-xs-12 m-0 p-0 p-md-2">
                            {/* <!-- AB CARD RESULTS GRID --> */}
                            <div className="row mx-0 my-0">
                                {this.props.ehub.loading ? (
                                    <div className="col px-2">
                                        <div className="loading-wrapper">
                                            <ScaleLoader
                                                className={override}
                                                size={10}
                                                color={'#F67419'}
                                                loading={true}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <AppraisalSearchResults
                                        data={appraisalSearches}
                                        vrm={this.props.match.params.vrm}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        )
    }
}

export default VehicleByVrm
