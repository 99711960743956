import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { lang } from '../../themeConfig'

class NotFound extends Component {
    render() {
        return (
            <div className="container-fluid">
                <Container>
                    <Row>
                        <Col xs={{ size: 12 }} lg={{ size: 8 }}>
                            <div className="not-found">
                                <h1>{lang('404_TITLE')}</h1>
                                <hr />
                                <p>{lang('404_DESCRIPTION')}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default NotFound
