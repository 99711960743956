import React, { Component } from 'react'
import { css } from '@emotion/react'
import { ScaleLoader } from 'react-spinners'
import { Col, Row } from 'reactstrap'
import Pagination from '../../Pagination'
import SearchResults from '../../SearchResults'

import Sorting from '../../../UiKit/Sorting'
import PageSize from '../../../UiKit/PageSize'
import { getFundResults, getFundTotals } from '../../../utilities/FundingFunctions'
import SideNavFilter from '../../../UiKit/Filter/SideNavFilter'
import { CT_SECURE_BANK_TRUST_FUNDING } from '../../../constants'
import FundingCredit from './FundingCredit'

const override = css`
    display: block;
    margin: 0 auto;
`
class Funding extends Component {
    constructor(props) {
        super(props)

        this.state = {
            viewType: 'grid',
            activeFilter: 'Available',
            fundvehicles: [],
            vehicles: [],
            availableAndIneligibleCount: 0,
            pendingCount: 0,
            acceptedCount: 0,
            declinedCount: 0,
            totalVehicles: 0,
            primaryVendorCode: null,
            fundcredit: {},
        }
        this.handleClick = this.handleClick.bind(this)
        this.toggleView = this.toggleView.bind(this)
        this.getButtonClass = this.getButtonClass.bind(this)
        this.handleRefresh = this.handleRefresh.bind(this)
        this.selectedFilter = this.selectedFilter.bind(this)
    }

    selectedFilter = (activeFilter) => {
        this.setState({
            vehicles: getFundResults(activeFilter, this.state.fundvehicles),
        })
        this.setState({ activeFilter })
        this.props.setPaginationState({
            currentPage: 1,
            offsetStart: 0,
            offsetFinish: this.props.pagination.resultsPerPage.value,
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        document.title = 'Aston Barclay Stock Funding | Vehicle Remarketing and Vehicle Auction | Aston Barclay'

        this.props.getFundingVehicles()
    }

    static getDerivedStateFromProps(props, state) {
        const selectedVehicles = getFundResults(state.activeFilter, props.funding.fundingvehicles)
        const fundTotals = getFundTotals(props.funding.fundingvehicles)
        if (props && props.user.primaryVendorCode !== state.primaryVendorCode) {
            props.getFundingCredit(props.user.primaryVendorCode)
        }

        return {
            primaryVendorCode: props.user.primaryVendorCode,
            fundvehicles: props.funding.fundingvehicles,
            vehicles: selectedVehicles,
            availableAndIneligibleCount: fundTotals.availableAndIneligible,
            pendingCount: fundTotals.pending,
            acceptedCount: fundTotals.accepted,
            declinedCount: fundTotals.declined,
            fundcredit: props.funding.fundingcredit,
        }
    }

    handleClick = (activeButtonValue) => {
        this.setState(() => ({
            activeButton: activeButtonValue,
        }))
    }

    handleRefresh = () => {
        this.props.getFundingVehicles()
        this.props.getFundingCredit(this.state.primaryVendorCode)
    }

    getButtonClass(activeFilter) {
        if (this.state.activeFilter === activeFilter) {
            return 'btn-filter__on'
        } else {
            return 'btn-filter'
        }
    }

    toggleView(viewType) {
        return this.setState({ viewType })
    }

    getResults() {
        return getFundResults(this.state.activeFilter, this.props.funding.fundingvehicles)
    }

    updateCount = (count) => {
        this.setState({ totalVehicles: count })
    }

    render() {
        const fundData = this.state.vehicles
        const filters = [
            { id: 'Available', displayText: 'Available', count: this.state.availableAndIneligibleCount },
            { id: 'Pending', displayText: 'Pending', count: this.state.pendingCount },
            { id: 'Accepted', displayText: 'Accepted', count: this.state.acceptedCount },
            { id: 'Declined', displayText: 'Declined', count: this.state.declinedCount },
        ]
        const fundCredit = this.state.fundcredit

        return (
            <Row className="m-0 px-0 pt-3 pb-2">
                <Col md="3" lg="2" className="p-3 pl-md-0 pt-md-2 pr-md-0 pb-md-3 ab-results-left">
                    <Row className="mx-0 my-2 d-block d-md-none">
                        <Col className="p-sm-0 p-md-1 ab-results-matches">
                            <p className="p-0 m-0 mb-2 ab-results-number text-uppercase">
                                {this.state.activeFilter} VEHICLES
                            </p>

                            {!this.props.loading ? (
                                <p className="p-0 m-0 ab-results-number">{`${fundData.count > 0 ? fundData.count : '0'} Matches`}</p>
                            ) : (
                                <p className="p-0 m-0 ab-results-number">&nbsp;</p>
                            )}
                        </Col>
                    </Row>
                    <SideNavFilter
                        filters={filters}
                        title="Display"
                        selectedFilter={this.selectedFilter}
                        handleRefresh={this.handleRefresh}
                    />
                    <FundingCredit data={fundCredit} />
                </Col>
                <Col md="9" lg="10">
                    <Row className="m-0 p-3 pb-0 pt-4 d-none d-md-flex">
                        <Col className="x-0 p-0">
                            <h2 className="p-0 m-0 text-uppercase">
                                {this.state.activeFilter} VEHICLES
                                {!this.props.funding.loading && (
                                    <span>{` - ${fundData.count > 0 ? fundData.count : '0'} Matches`}</span>
                                )}
                            </h2>
                        </Col>
                    </Row>
                    {!this.props.funding.loading && (
                        <Row className="ab-results-right">
                            <Col xs="12" md="3" className="ab-results-sort">
                                <Sorting
                                    sorting={this.props.sorting}
                                    options="funding"
                                    onChangeSortOrder={this.props.onChangeSortOrder}
                                    offsetFinish={this.props.pagination.resultsPerPage.value}
                                />
                            </Col>
                            <Col xs="12" md="6" className="d-flex justify-content-center">
                                <Pagination vehicles={fundData.data} />
                            </Col>
                            <Col xs="12" md="3">
                                <PageSize
                                    vehicles={fundData.data}
                                    onToggleView={this.toggleView}
                                    viewType={this.state.viewType}
                                    pagination={this.props.pagination}
                                    setPageSize={this.props.setPageSize}
                                    theme={this.props.user.theme}
                                />
                            </Col>
                        </Row>
                    )}
                    <Row className="mx-0 my-0">
                        {this.props.funding.loading ? (
                            <Col className="px-2">
                                <div className="loading-wrapper">
                                    <ScaleLoader className={override} size={10} loading={true} />
                                </div>
                            </Col>
                        ) : (
                            <SearchResults
                                type={CT_SECURE_BANK_TRUST_FUNDING}
                                viewType={'grid'}
                                selectedFilter={this.state.activeFilter}
                                updateCount={this.updateCount}
                            />
                        )}
                    </Row>
                    {!this.props.vehicles.loading && (
                        <Row className="mx-0 mb-0 my-md-3 px-3 px-md-1 py-0">
                            <Col md="3" className="mb-3 mb-md-0 pl-md-0 px-0 ab-results-sort order-0 order-md-0" />
                            <Col md="6" className="mb-0 mb-md-0 px-0 d-flex justify-content-center order-2 order-md-1">
                                <Pagination vehicles={fundData.data} />
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        )
    }
}

export default Funding
