import React, { PureComponent } from 'react'
import uniqueId from 'lodash/uniqueId'

export default class EhubCheckbox extends PureComponent {
    render() {
        const id = uniqueId(
            ((this.props.label && this.props.label.replace(/\s/g, '-')) || 'checkbox').toLowerCase() + '-id_',
        )
        return (
            <div className="e-hub-orange custom-checkbox custom-control">
                <input
                    className="custom-control-input"
                    type="checkbox"
                    id={id}
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                    defaultValue={this.props.defaultValue}
                />
                <label className="custom-control-label" htmlFor={id}>
                    {this.props.label}
                </label>
            </div>
        )
    }
}
