import React, { Component } from 'react'

import InputTwo from './InputTwo'

import { ISODateString } from '../../utilities/DateFunctions'

class SubItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            criteria: [],
        }
    }

    componentDidMount() {
        const data = this.props.data
        const criteria = this.props.criteria
        this.setState({
            data,
            criteria,
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            data: nextProps.data,
            criteria: nextProps.criteria,
        })
    }
    render() {
        if (Object.keys(this.state.data).length) {
            return Object.keys(this.state.data[this.props.item])
                .sort((a, b) => (a !== b ? (a < b ? -1 : 1) : 0))
                .map((subItem, index) => {
                    if (this.props.dataType === 'sales') {
                        var title =
                            this.state.data[this.props.item][subItem].id !== 99
                                ? `${this.state.data[this.props.item][subItem].description} - ${
                                      this.state.data[this.props.item][subItem].id
                                  } - ${ISODateString(this.state.data[this.props.item][subItem].start, false, false)}`
                                : this.state.data[this.props.item][subItem].description
                        return (
                            <InputTwo
                                className="custom-checkbox"
                                checked={this.state.criteria.indexOf(this.state.data[this.props.item][subItem].id) >= 0}
                                inputType={this.props.inputType}
                                dataType={this.props.dataType}
                                title={title}
                                value={this.state.data[this.props.item][subItem].id}
                                id={this.props.dataType + '-' + subItem}
                                description={this.state.data[this.props.item][subItem].vehicles}
                                handleOnSetCriteria={this.props.handleOnSetCriteria}
                                parentItem={this.props.item}
                                key={index}
                                offsetFinish={this.props.offsetFinish}
                            />
                        )
                    } else {
                        return (
                            <InputTwo
                                className="custom-checkbox"
                                checked={this.state.criteria.indexOf(subItem) >= 0}
                                inputType={this.props.inputType}
                                dataType={this.props.dataType}
                                title={subItem}
                                value={subItem}
                                id={this.props.dataType + '-' + subItem}
                                description={this.state.data[this.props.item][subItem]}
                                handleOnSetCriteria={this.props.handleOnSetCriteria}
                                parentItem={this.props.item}
                                key={index}
                                offsetFinish={this.props.offsetFinish}
                            />
                        )
                    }
                })
        }
        return <div>Loading...</div>
    }
}

export default SubItem
