import {
    FETCH_FUNDING_VEHICLES,
    FETCH_FUNDING_VEHICLES_ERROR,
    FETCH_FUNDING_VEHICLES_SUCCESS,
    FUND_VEHICLE,
    FUND_VEHICLE_ERROR,
    FUND_VEHICLE_SUCCESS,
    FETCH_FUNDING_CREDIT_LIMIT,
    FETCH_FUNDING_CREDIT_LIMIT_ERROR,
    FETCH_FUNDING_CREDIT_LIMIT_SUCCESS,
} from '../constants'

const initialState = {
    loading: false,
    error: null,
    fundingvehicles: [],
    fundingcredit: {},
}

const FundingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FUNDING_CREDIT_LIMIT:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case FETCH_FUNDING_CREDIT_LIMIT_SUCCESS:
            return {
                ...state,
                loading: false,
                fundingcredit: action.payload,
            }
        case FETCH_FUNDING_CREDIT_LIMIT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case FETCH_FUNDING_VEHICLES:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case FETCH_FUNDING_VEHICLES_SUCCESS:
            return {
                ...state,
                loading: false,
                fundingvehicles: action.payload,
            }
        case FETCH_FUNDING_VEHICLES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case FUND_VEHICLE:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case FUND_VEHICLE_SUCCESS:
            return {
                ...state,
                fundingvehicles: state.fundingvehicles.map((fundingvehicle, i) => {
                    return fundingvehicle.id === action.payload.id
                        ? { ...fundingvehicle, fundingStatus: 'Pending' }
                        : fundingvehicle
                }),
                loading: false,
                error: null,
            }
        case FUND_VEHICLE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }

        default:
            return state
    }
}

export default FundingReducer
