const initialState = {}

const ErrorReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state
    }
}

export default ErrorReducer
