import React, { Component } from 'react'
import { lang, permissions } from '../../themeConfig'
import confirm from '../../utilities/Confirm'
import moment from 'moment'

import {
    alertVehicle,
    displayButtonForLeaseplan,
    formatCurrency,
    getMessage,
    hasBuyNowAccess,
    hasProxyBidAccess,
} from '../../utilities/Functions'

import {
    getLocation,
    isBidAndBuyNowAllowedForVehicle,
    isBidAndBuyNowRejected,
    isBidAndBuyOnVehicle,
    isProxyBidAllowedForVehicle,
    isProxyBidOnVehicle,
} from '../../components/CatalogueVehicleDetails/VehicleHelper'

import { CT_EVALUATE } from '../../constants'

import { getExchangeBtnText } from '../../utilities/AuctionFunctions'
import { FaTimesCircle } from '@react-icons/all-files/fa/FaTimesCircle'
import Countdown from 'react-countdown'

/**
 * Usage:
 *
 *
 */
class BidActionButtons extends Component {
    constructor(props) {
        super(props)
        this.dataUrl = props.dataUrl

        this.state = {
            proxyBid: null,
        }
        this.onClickChangeStatus = this.onClickChangeStatus.bind(this)
        this.startTimeRenderer = this.startTimeRenderer.bind(this)
        this.getButtonState = this.getButtonState.bind(this)
    }

    onClickChangeStatus() {
        this.props.toggleStatus(this.props.vehicle.status, this.props.vehicle.id)
    }

    startTimeRenderer({ days, hours, minutes, seconds, completed }) {
        if (completed) {
            return this.getButtonState()
        }
        return (
            <div className="exchange-start-countdown">
                Bidding starts in {days}d {hours}h {minutes}m {seconds}s
            </div>
        )
    }

    render = () => {
        const { vehicle } = this.props

        if (
            !(
                vehicle &&
                (vehicle.biddingDetails ||
                    vehicle.actions ||
                    vehicle.bidBuyNowPrice ||
                    vehicle.currentBidAndBuyNow ||
                    vehicle.proxyBid ||
                    vehicle.currentProxyBid ||
                    vehicle.location ||
                    vehicle.cardType ||
                    vehicle.listAndCollect)
            )
        ) {
            return null
        }

        if (
            vehicle.biddingDetails &&
            vehicle.biddingDetails.startDate &&
            !(vehicle.biddingDetails.exchangeStarted || new Date(vehicle.biddingDetails.startDate) < new Date())
        ) {
            return (
                <div className="col-12 m-0 p-2">
                    <Countdown date={new Date(vehicle.biddingDetails.startDate)} renderer={this.startTimeRenderer} />
                </div>
            )
        }

        return this.getButtonState()
    }

    getButtonState() {
        const {
            user,
            vehicle,
            onClickOpenProxyBidModal,
            onClickOpenBuyNowModal,
            onClickSetProxyBid,
            onOpenSendToAuctionModal,
            onClickBiddingModal,
            websocketConnected,
            vehicleDetails,
            accountCode,
            activeTab,
            checkDirtyForm,
            saveVehicleDetails,
        } = this.props
        if (permissions('CLOSED_GROUP_24H', this.props.user.theme) && vehicle.biddingDetails) {
            const userPostcode = (user && user.postcode) || ''
            const sellerPostcode = (vehicle && vehicle.location && vehicle.location.postcode) || ''
            if (userPostcode.replace(/\s/g, '').toUpperCase() !== sellerPostcode.replace(/\s/g, '').toUpperCase()) {
                const lowerBounds = moment(vehicle.biddingDetails.startDate)
                const upperBounds = moment(vehicle.biddingDetails.startDate).add(1, 'days')
                if (moment().isBetween(lowerBounds, upperBounds)) {
                    // Handle the case of the "returning dealer" - i.e. a dealer with a matching postcode has first dibs on the
                    // car before anyone else
                    return null
                }
            }
        } else if (vehicle.accountCode === user.primaryVendorCode) {
            // We don't want users to bid on their own cars. The server rejects this, but make it obvious in the UI
            return null
        }

        if (hasBuyNowAccess(user) && (isBidAndBuyNowAllowedForVehicle(vehicle) || isBidAndBuyOnVehicle(vehicle))) {
            if (isBidAndBuyNowAllowedForVehicle(vehicle)) {
                if (isBidAndBuyNowRejected(vehicle)) {
                    return (
                        <div className="col-12 m-0 p-2">
                            <button
                                className="btn btn-primary btn-block ab-btn-padding ab-btn-buy-now"
                                type="button"
                                onClick={(event) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    onClickOpenBuyNowModal(vehicle)
                                }}
                            >
                                {vehicle.currentBidAndBuyNow && vehicle.currentBidAndBuyNow.amount
                                    ? `${formatCurrency(vehicle.currentBidAndBuyNow.amount)} `
                                    : ''}
                                {lang('REJECTED_NEW_OFFER', this.props.user.theme)}
                            </button>
                        </div>
                    )
                } else if (vehicle.currentBidAndBuyNow && vehicle.currentBidAndBuyNow.status === 'pending') {
                    return (
                        <div className="col-12 m-0 p-2">
                            <button
                                className="btn btn-primary btn-block ab-btn-padding ab-btn-buy-now"
                                type="button"
                                disabled
                            >
                                {vehicle.currentBidAndBuyNow && vehicle.currentBidAndBuyNow.amount
                                    ? `${formatCurrency(vehicle.currentBidAndBuyNow.amount)} `
                                    : ''}
                                {lang('OFFER_DECISION_PENDING', this.props.user.theme)}
                            </button>
                        </div>
                    )
                } else {
                    return (
                        <div className="col-12 m-0 p-2">
                            <button
                                className="btn btn-primary btn-block ab-btn-padding ab-btn-buy-now"
                                type="button"
                                onClick={(event) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    onClickOpenBuyNowModal(vehicle)
                                }}
                            >
                                {lang('BUY_AT')} {formatCurrency(vehicle.bidBuyNowPrice)} /{' '}
                                {lang('MAKE_AN_OFFER', this.props.user.theme)}
                            </button>
                        </div>
                    )
                }
            } else {
                return (
                    <div className="col-12 m-0 p-2">
                        <button
                            className="btn btn-primary btn-block ab-btn-padding ab-btn-buy-now"
                            type="button"
                            onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                            }}
                        >
                            {lang('YOUR_OFFER', this.props.user.theme).toUpperCase()}:{' '}
                            <span id="proxyBidAmount">{formatCurrency(vehicle.currentBidAndBuyNow.amount)}</span>{' '}
                        </button>
                    </div>
                )
            }
        } else if (hasProxyBidAccess(user) && isProxyBidAllowedForVehicle(vehicle)) {
            // User has Proxy Bid access
            if (isProxyBidOnVehicle(vehicle)) {
                // Proxy bid already placed on vehicle

                return (
                    <div className="col-12 m-0 p-2 e-hub">
                        <button
                            className={`btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid-active`}
                            type="button"
                            onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                onClickSetProxyBid(
                                    Object.assign(
                                        {},
                                        {
                                            saleId: vehicle.saleId,
                                            vehicleId: vehicle.id,
                                            account: vehicle.account,
                                            amount: 0,
                                        },
                                    ),
                                    vehicle,
                                )
                            }}
                            title="Remove this proxy bid"
                        >
                            {lang('LABEL_PROXY_BID', this.props.user.theme).toUpperCase()}:{' '}
                            <span id="proxyBidAmount">{formatCurrency(vehicle.currentProxyBid.amount)}</span>{' '}
                            <FaTimesCircle />
                        </button>
                    </div>
                )
            } else {
                // Add a proxy bid
                return (
                    <div className="col-12 m-0 p-2">
                        <button
                            className={`btn btn-primary btn-block ab-btn-padding ${getLocation(vehicle) === 'e-Live' ? 'ab-btn-proxy-bid-e-live' : 'ab-btn-proxy-bid'}`}
                            type="button"
                            onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                onClickOpenProxyBidModal(
                                    {
                                        saleId: vehicle.saleId,
                                        vehicleId: vehicle.id,
                                        account: vehicle.account,
                                        virtual: vehicle.virtual,
                                        amount: 25,
                                    },
                                    vehicle,
                                )
                            }}
                        >
                            {lang('ADD_A_PROXY_BID', this.props.user.theme)}
                        </button>
                    </div>
                )
            }
        } else if (vehicle.status) {
            if (vehicle.status === `Aston Barclay` && vehicle.vehicleStatus && vehicle.vehicleStatus === 'Unsold') {
                return (
                    <div
                        className={`${vehicleDetails || (user.accountsAccess[accountCode] && !user.accountsAccess[accountCode].eXchangeSeller) ? 'col-12' : 'col-6'} card-footer m-0 p-2`}
                    >
                        <button
                            className="btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid disabled"
                            type="button"
                        >
                            SENT TO AUCTION
                        </button>
                    </div>
                )
            }

            if (vehicle.actions && vehicle.actions.sendToAuction) {
                return (
                    <div
                        className={`${
                            user.accountsAccess &&
                            user.accountsAccess[accountCode] &&
                            user.accountsAccess[accountCode].eXchangeSeller &&
                            vehicle.cardType === CT_EVALUATE &&
                            vehicle.status &&
                            vehicle.status === 'In stock' &&
                            (vehicle.actions.addToExchange || vehicle.actions.relistOnExchange)
                                ? 'col-6'
                                : 'col-12'
                        } m-0 p-2`}
                    >
                        <button
                            className="btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid"
                            type="button"
                            onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                if (!vehicle.vatStatus || !vehicle.mileage || vehicle.mileage === 0) {
                                    return confirm('Missing required data', {
                                        buttonDismiss: lang('BUTTON_CLOSE', this.props.user.theme),
                                        description: getMessage(vehicle.vatStatus, vehicle.mileage),
                                    }).then(
                                        () => {
                                            return false
                                        },
                                        () => {
                                            return false
                                        },
                                    )
                                }
                                if (activeTab === '0' && !checkDirtyForm()) {
                                    return confirm('Unsaved changes', {
                                        buttonDismiss: lang('BUTTON_CANCEL', this.props.user.theme),
                                        buttonConfirm: lang('BUTTON_SAVE_AND_CONTINUE', this.props.user.theme),
                                        description: lang('UNSAVED_CHANGES_CHECK', this.props.user.theme),
                                    }).then(
                                        () => {
                                            saveVehicleDetails()
                                            onOpenSendToAuctionModal({
                                                collectionSite: vehicle.collectionSite,
                                                appraisalId: vehicle.id,
                                                sivPrice: vehicle.sivPrice,
                                                accountCode: vehicle.vendorCode,
                                                eXchangeType: user.accountsAccess[vehicle.vendorCode].eXchangeType,
                                                vehicleId: vehicle.vehicleId,
                                            })
                                        },
                                        () => {
                                            return false
                                        },
                                    )
                                } else {
                                    onOpenSendToAuctionModal({
                                        collectionSite: vehicle.collectionSite,
                                        appraisalId: vehicle.id,
                                        sivPrice: vehicle.sivPrice,
                                        accountCode: vehicle.vendorCode,
                                        eXchangeType: user.accountsAccess[vehicle.vendorCode].eXchangeType,
                                        vehicleId: vehicle.vehicleId,
                                    })
                                }
                            }}
                        >
                            {lang('SEND_TO_AUCTION_BUTTON_TEXT', this.props.user.theme)}
                        </button>
                    </div>
                )
            } else if (vehicle.actions.addToStock) {
                return (
                    <div className="col-12 m-0 p-2">
                        <button
                            className="btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid"
                            type="button"
                            onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                this.onClickChangeStatus()
                            }}
                        >
                            {lang('EHUB_ADD_TO_STOCK_BUTTON_TEXT', this.props.user.theme)}
                        </button>
                    </div>
                )
            } else {
                return null
            }
        } else if (
            user.tradeAccess &&
            user.eXchangeBuyer &&
            user.primaryVendorCode &&
            vehicle.cardType === 'e-xchange' &&
            !alertVehicle(vehicle) &&
            displayButtonForLeaseplan(vehicle, user)
        ) {
            if (websocketConnected) {
                return (
                    <React.Fragment>
                        {vehicle.biddingDetails ? (
                            <div className="col-12 m-0 p-2">
                                {user.accountName && vehicle.account !== user.accountName.value ? (
                                    <button
                                        className={`btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid ${
                                            vehicle.biddingDetails && !vehicle.biddingDetails.ended ? '' : 'disabled'
                                        }`}
                                        type="button"
                                        onClick={(event) => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            onClickBiddingModal({
                                                id: vehicle.id,
                                                listAndCollect: vehicle.listAndCollect,
                                            })
                                        }}
                                    >
                                        {getExchangeBtnText(vehicle, user.theme)}
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid e-hub disabled"
                                        type="button"
                                    >
                                        {user.accountName && user.accountName.value
                                            ? 'Your vehicle'
                                            : "Can't locate user to vehicle"}
                                    </button>
                                )}
                            </div>
                        ) : (
                            <React.Fragment>
                                {!user.tradeAccess && (
                                    <div className="col-12 card-footer m-0 p-2">User missing trade access</div>
                                )}
                                {!user.liveBidAccess && (
                                    <div className="col-12 card-footer m-0 p-2">User missing live bid access.</div>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )
            } else {
                return (
                    <div className="col-12 card-footer m-0 p-2">
                        <button
                            className="btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid e-hub disabled"
                            type="button"
                        >
                            {lang('CONNECTION_LOST_RECONNECTING', this.props.user.theme)}
                        </button>
                    </div>
                )
            }
        } else {
            return null
        }
    }
}

export default BidActionButtons
