import {
    API_BASE,
    API_GET_HEADERS,
    API_POST_HEADERS,
    API_DELETE_HEADERS,
    SAVE_SEARCH_BEGIN,
    SAVE_SEARCH_SUCCESS,
    SAVE_SEARCH_ERROR,
    FETCH_SAVED_SEARCHES,
    FETCH_SAVED_SEARCHES_SUCCESS,
    FETCH_SAVED_SEARCHES_ERROR,
    FETCH_DELETE_SAVED_SEARCHES,
    FETCH_DELETE_SAVED_SEARCHES_SUCCESS,
    FETCH_DELETE_SAVED_SEARCHES_ERROR,
    FETCH_USER,
    FETCH_USER_SUCCESS,
    FETCH_USER_ERROR,
    USER_REDIRECT,
    FETCH_EXCHANGE_VEHICLES,
    FETCH_EXCHANGE_VEHICLES_SUCCESS,
    FETCH_EXCHANGE_VEHICLES_ERROR,
    SET_THEME,
    SET_LANGUAGE,
    LOGON,
    LOGON_SUCCESS,
    LOGON_ERROR,
    UPDATE_SEARCH,
    UPDATE_SEARCH_SUCCESS,
    UPDATE_SEARCH_FAILURE,
    ON_UNSUBSCRIBE,
    ON_UNSUBSCRIBE_SUCCESS,
    ON_UNSUBSCRIBE_FAILURE,
    FETCH_SEARCH_USERS,
    FETCH_SEARCH_USERS_SUCCESS,
    FETCH_SEARCH_USERS_ERROR,
    FETCH_SEARCHES_FOR_USER,
    FETCH_SEARCHES_FOR_USER_SUCCESS,
    FETCH_SEARCHES_FOR_USER_ERROR,
} from '../constants'
import store from '../store'

import { init as websocketInit } from './websocket'

import { addNotification } from './notifications'

import { BuildSearchQueryForItem } from '../utilities/SearchQuery'

import { setSortingOrder, changeSortOrder } from './sorting'

import { changePageSize } from './pagination'

import { handleError } from '../utilities/Functions'

import { lang } from '../themeConfig'

const isLanguageValid = ['eng']

export const userRedirect = (status) => ({
    type: USER_REDIRECT,
    payload: status,
})

export const saveSearchBegin = () => ({
    type: SAVE_SEARCH_BEGIN,
})

export const saveSearchSuccess = (savedSearches) => ({
    type: SAVE_SEARCH_SUCCESS,
    payload: savedSearches,
})

export const saveSearchFailure = (error) => ({
    type: SAVE_SEARCH_ERROR,
    payload: { error },
})

export const fetchSavedSearchBegin = () => ({
    type: FETCH_SAVED_SEARCHES,
})

export const fetchSavedSearchSuccess = (savedSearches) => ({
    type: FETCH_SAVED_SEARCHES_SUCCESS,
    payload: savedSearches,
})

export const fetchSavedSearchFailure = (error) => ({
    type: FETCH_SAVED_SEARCHES_ERROR,
    payload: { error },
})

export const updateSearchBegin = () => ({
    type: UPDATE_SEARCH,
})

export const updateSearchSuccess = () => ({
    type: UPDATE_SEARCH_SUCCESS,
})

export const updateSearchFailure = (error) => ({
    type: UPDATE_SEARCH_FAILURE,
    payload: { error },
})

export const sendUnsubscribeBegin = () => ({
    type: ON_UNSUBSCRIBE,
})

export const sendUnsubscribeSuccess = (payload) => ({
    type: ON_UNSUBSCRIBE_SUCCESS,
    payload,
})

export const sendUnsubscribeFailure = (error) => ({
    type: ON_UNSUBSCRIBE_FAILURE,
    payload: { error },
})

export const fetchUserBegin = () => ({
    type: FETCH_USER,
})

export const fetchUserSuccess = (payload) => ({
    type: FETCH_USER_SUCCESS,
    payload,
})

export const fetchUserFailure = (error) => ({
    type: FETCH_USER_ERROR,
    payload: { error },
})

export const logon = () => ({
    type: LOGON,
})

export const logonSuccess = (payload) => ({
    type: LOGON_SUCCESS,
    payload,
})

export const logonFailure = (error) => ({
    type: LOGON_ERROR,
    payload: { error },
})

export const fetchDeleteSavedSearchBegin = () => ({
    type: FETCH_DELETE_SAVED_SEARCHES,
})

export const fetchDeleteSavedSearchSuccess = (savedSearches) => ({
    type: FETCH_DELETE_SAVED_SEARCHES_SUCCESS,
    payload: savedSearches,
})

export const fetchDeleteSavedSearchFailure = (error) => ({
    type: FETCH_DELETE_SAVED_SEARCHES_ERROR,
    payload: { error },
})

export const fetchExchangeVehicles = () => ({
    type: FETCH_EXCHANGE_VEHICLES,
})

export const fetchExchangeVehiclesSuccess = (payload) => ({
    type: FETCH_EXCHANGE_VEHICLES_SUCCESS,
    payload,
})

export const fetchExchangeVehiclesFailure = (payload) => ({
    type: FETCH_EXCHANGE_VEHICLES_ERROR,
    payload,
})

export const setAppTheme = (theme) => ({
    type: SET_THEME,
    theme,
})

export const setAppLang = (language) => ({
    type: SET_LANGUAGE,
    language,
})

export const fetchSearchUsersBegin = () => ({
    type: FETCH_SEARCH_USERS,
})

export const fetchSearchUsersSuccess = (searchUsers) => ({
    type: FETCH_SEARCH_USERS_SUCCESS,
    payload: searchUsers,
})

export const fetchSearchUsersFailure = (error) => ({
    type: FETCH_SEARCH_USERS_ERROR,
    payload: { error },
})

export const fetchSearchesForUserBegin = () => ({
    type: FETCH_SEARCHES_FOR_USER,
})

export const fetchSearchesForUserSuccess = (searches) => ({
    type: FETCH_SEARCHES_FOR_USER_SUCCESS,
    payload: searches,
})

export const fetchSearchesForUserFailure = (error) => ({
    type: FETCH_SEARCHES_FOR_USER_ERROR,
    payload: { error },
})

export function setTheme(theme) {
    return (dispatch) => {
        dispatch(setAppTheme(theme))
    }
}

export function setLanguage(language) {
    const validLang = isLanguageValid.indexOf(language) > -1 ? language : 'eng'

    return (dispatch) => {
        dispatch(setAppLang(validLang))
        dispatch(
            changeSortOrder({
                dataField: {
                    value: 'saleStart',
                    label: lang('SORT_OPTIONS_SALE_DATE'),
                },
                reverse: false,
            }),
        )
        dispatch(
            changePageSize({
                resultsPerPage: {
                    value: 12,
                    label: `12 ${lang('CATALOGUE_PER_PAGE')}`, // Note: This definition is the same in each language dictionary
                },
                currentPage: 1,
                offsetStart: 0,
                offsetFinish: 12,
            }),
        )
    }
}

export function fetchUser() {
    const url = `${API_BASE}api/user`
    return async (dispatch) => {
        dispatch(fetchUserBegin())
        return fetch(url, API_GET_HEADERS())
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    if (data.loggedIn && data.eXchangeBuyer) {
                        websocketInit(store)
                    }
                    dispatch(fetchUserSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchUserFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,
                        type: 'error',
                    }),
                )
                dispatch(fetchUserFailure(error))
            })
    }
}

export function login(history, username, password) {
    const url = `${API_BASE}api/logon`
    const newHeaders = Object.assign(API_POST_HEADERS('1.0'), {
        body: JSON.stringify({
            username: username,
            password: password,
        }),
    })
    return (dispatch) => {
        dispatch(logon())
        return fetch(url, newHeaders)
            .then((response) => {
                response.json().then((data) => {
                    if (response.ok) {
                        if (data.loggedIn && data.eXchangeBuyer) {
                            websocketInit(store)
                        }
                        dispatch(logonSuccess(data))
                        history.push('/vehicle-search')
                    } else {
                        handleError(response.status, dispatch, data, logonFailure)
                    }
                })
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,
                        type: 'error',
                    }),
                )
                dispatch(logonFailure(error))
            })
    }
}

export function fetchSavedSearches(isMarketing) {
    let url = `${API_BASE}api/search/auth`
    if (isMarketing) {
        url = `${API_BASE}api/search/public`
    }
    return async (dispatch) => {
        dispatch(fetchSavedSearchBegin())
        return fetch(url, API_GET_HEADERS())
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(fetchSavedSearchSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchSavedSearchFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,
                        type: 'error',
                    }),
                )
                dispatch(fetchSavedSearchFailure(error))
            })
    }
}

export function onSendUnsubscribe(token) {
    let url = `${API_BASE}api/unsubscribe/${token}`

    return async (dispatch) => {
        dispatch(sendUnsubscribeBegin())
        return fetch(url, API_POST_HEADERS())
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                dispatch(sendUnsubscribeSuccess(data))
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,
                        type: 'error',
                    }),
                )
                dispatch(sendUnsubscribeFailure(error))
            })
    }
}

export function onSaveSearchEdit(search, username) {
    let url = `${API_BASE}api/search/auth/${search.id}`
    if (username.length > 0) {
        url = `${API_BASE}api/search/users/${username}/searches/${search.id}`
    }

    const newHeaders = Object.assign(API_POST_HEADERS(), {
        body: JSON.stringify(search),
    })

    return async (dispatch) => {
        dispatch(updateSearchBegin)
        return new Promise((resolve) => {
            fetch(url, newHeaders)
                .then((response) => Promise.all([response.status, response.ok, response.json()]))
                .then(([status, responseOk, data]) => {
                    if (responseOk) {
                        dispatch(
                            addNotification({
                                text: 'Updated search successfully.',
                                type: 'success',
                                delay: 5000,
                            }),
                        )
                        dispatch(updateSearchSuccess())
                        resolve(data)
                    } else {
                        handleError(status, dispatch, data, updateSearchFailure)
                    }
                })
                .catch((error) => {
                    dispatch(
                        addNotification({
                            text: `Failed api ${url}`,
                            type: 'error',
                        }),
                    )
                    dispatch(updateSearchFailure(error))
                })
        })
    }
}

export function fetchDeleteSavedSearches(id, isMarketing, username = '') {
    let url = `${API_BASE}api/search/auth/${id}`
    if (username.length > 0) {
        url = `${API_BASE}api/search/users/${username}/searches/${id}`
    } else if (isMarketing) {
        url = `${API_BASE}api/search/marketing/auth/${id}`
    }
    return async (dispatch) => {
        dispatch(fetchDeleteSavedSearchBegin())
        return fetch(url, API_DELETE_HEADERS())
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(fetchDeleteSavedSearchSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchDeleteSavedSearchFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,
                        type: 'error',
                    }),
                )
                dispatch(fetchDeleteSavedSearchFailure(error))
            })
    }
}

export function fetchInteractedExchangeVehicles() {
    const url = `${API_BASE}api/vehicles/${BuildSearchQueryForItem('', {})}`
    return async (dispatch) => {
        dispatch(fetchExchangeVehicles())
        return fetch(url, API_GET_HEADERS())
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    const sortedIds = data.map((vehicle) => vehicle.id)
                    dispatch(setSortingOrder(sortedIds))
                    dispatch(fetchExchangeVehiclesSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchExchangeVehiclesFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,
                        type: 'error',
                    }),
                )
                dispatch(fetchExchangeVehiclesFailure(error))
            })
    }
}

export function onSaveSearchCriteria(criteria, isMarketing, selectedUsername, selectedEmail) {
    let url = `${API_BASE}api/search/auth`
    if (selectedUsername && selectedUsername.length > 0) {
        url = `${API_BASE}api/search/users/${selectedUsername}/save`
        criteria.email = selectedEmail
    } else if (isMarketing) {
        url = `${API_BASE}api/search/marketing/auth`
    }
    const newHeaders = Object.assign(API_POST_HEADERS(), {
        body: JSON.stringify(criteria),
    })

    return async (dispatch) => {
        dispatch(saveSearchBegin())
        return fetch(url, newHeaders)
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(
                        addNotification({
                            text: 'Search saved successfully. You can view your saved searches in the My Account section.',
                            type: 'success',
                            delay: 5000,
                        }),
                    )
                    dispatch(saveSearchSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, saveSearchFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,
                        type: 'error',
                    }),
                )
                dispatch(saveSearchFailure(error))
            })
    }
}

export function fetchSearchUsers(query) {
    let url = `${API_BASE}api/search/users?username=${query}`
    return async (dispatch) => {
        dispatch(fetchSearchUsersBegin())
        return fetch(url, API_GET_HEADERS())
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(fetchSearchUsersSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchSearchUsersFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,
                        type: 'error',
                    }),
                )
                dispatch(fetchSearchUsersFailure(error))
            })
    }
}

export function fetchSavedSearchesForUsername(username) {
    let url = `${API_BASE}api/search/users/${username}/searches`
    return async (dispatch) => {
        dispatch(fetchSearchesForUserBegin())
        return fetch(url, API_GET_HEADERS())
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(fetchSearchesForUserSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchSearchesForUserFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,
                        type: 'error',
                    }),
                )
                dispatch(fetchSearchesForUserFailure(error))
            })
    }
}
