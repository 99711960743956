import {
    API_BASE,
    API_GET_HEADERS,
    API_POST_HEADERS,
    FETCH_FUNDING_VEHICLES,
    FETCH_FUNDING_VEHICLES_ERROR,
    FETCH_FUNDING_VEHICLES_SUCCESS,
    FUND_VEHICLE,
    FUND_VEHICLE_ERROR,
    FUND_VEHICLE_SUCCESS,
    SET_PAGINATION_PAGES,
    SET_SORTING_ORDER,
    USER_REDIRECT,
    FETCH_FUNDING_CREDIT_LIMIT,
    FETCH_FUNDING_CREDIT_LIMIT_ERROR,
    FETCH_FUNDING_CREDIT_LIMIT_SUCCESS,
} from '../constants'

import { addNotification } from './notifications'

import { handleError } from '../utilities/Functions'

export const userRedirect = (status) => ({
    type: USER_REDIRECT,
    payload: status,
})

export const fetchFundingCreditInfoBegin = () => ({
    type: FETCH_FUNDING_CREDIT_LIMIT,
})

export const fetchFundingCreditInfoSuccess = (payload) => ({
    type: FETCH_FUNDING_CREDIT_LIMIT_SUCCESS,
    payload: payload,
})

export const fetchFundingCreditInfoFailure = (error) => ({
    type: FETCH_FUNDING_CREDIT_LIMIT_ERROR,
    payload: { error },
})

export const fetchFundingVehiclesBegin = () => ({
    type: FETCH_FUNDING_VEHICLES,
})

export const fetchFundingVehiclesSuccess = (payload) => ({
    type: FETCH_FUNDING_VEHICLES_SUCCESS,
    payload: payload,
})

export const fetchFundingVehiclesFailure = (error) => ({
    type: FETCH_FUNDING_VEHICLES_ERROR,
    payload: { error },
})

export const fundVehicleBegin = () => ({
    type: FUND_VEHICLE,
})

export const fundVehicleSuccess = (payload) => ({
    type: FUND_VEHICLE_SUCCESS,
    payload: payload,
})

export const fundVehicleFailure = (error) => ({
    type: FUND_VEHICLE_ERROR,
    payload: { error },
})

export const setTotalPages = (length) => ({
    type: SET_PAGINATION_PAGES,
    payload: length,
})

export const setSortingOrder = (payload) => ({
    type: SET_SORTING_ORDER,
    payload,
})

export function getFundingVehicles() {
    return (dispatch) => {
        dispatch(fetchFundingVehiclesBegin())
        fetch(`${API_BASE}api/funding`, API_GET_HEADERS('1.0'))
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    const sortedIds = data.map((vehicle) => vehicle.id)
                    dispatch(setSortingOrder(sortedIds))
                    dispatch(fetchFundingVehiclesSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchFundingVehiclesFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `API failed to fetch funding vehicles`,
                        type: 'error',
                    }),
                )
                dispatch(fetchFundingVehiclesFailure(error))
            })
    }
}

export function onClickFundVehicle(payload) {
    const newHeaders = Object.assign(API_POST_HEADERS('1.0'), {
        body: JSON.stringify(payload),
    })
    return (dispatch) => {
        dispatch(fundVehicleBegin())
        fetch(`${API_BASE}api/funding`, newHeaders)
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(fundVehicleSuccess(payload))
                    dispatch(
                        addNotification({
                            text: 'Vehicle is in Pending status awaiting a decision from the lender',
                            delay: 5000,
                            type: 'info',
                        }),
                    )
                    return data
                } else {
                    handleError(status, dispatch, data, fundVehicleFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `API failed to send fund request`,
                        type: 'error',
                    }),
                )
                dispatch(fundVehicleFailure(error))
            })
    }
}

export function getFundingCredit(accountCode) {
    return (dispatch) => {
        dispatch(fetchFundingCreditInfoBegin())
        fetch(`${API_BASE}api/funding/credit/${accountCode}`, API_GET_HEADERS('1.0'))
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(fetchFundingCreditInfoSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchFundingCreditInfoFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `API failed to fetch funding credit`,
                        type: 'error',
                    }),
                )
                dispatch(fetchFundingCreditInfoFailure(error))
            })
    }
}
