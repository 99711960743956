import React from 'react'
import PropTypes from 'prop-types'
import { confirmable } from 'react-confirm'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { CT_EXCHANGE } from '../../constants'

const Confirm = ({ show, proceed, dismiss, cancel, confirmation, options }) => {
    options = Object.keys(options).length
        ? options
        : {
              buttonConfirm: 'Confirm',
              buttonDismiss: 'Cancel',
              description: '',
          }

    return (
        <Modal isOpen={show} fade={false} className="modal-dialog-centered modal-sm ">
            <ModalHeader>{confirmation}</ModalHeader>
            <ModalBody>{options.description}</ModalBody>
            <ModalFooter>
                <div className={`row m-0 ${options.cardType === CT_EXCHANGE ? 'e-hub' : ''}`} style={{ width: '100%' }}>
                    <div className={`col-12 py-1 ${!options.buttonConfirm ? 'pb-3' : ''}`}>
                        <button
                            type="button"
                            className="btn btn-primary btn-block cancel-button-modal"
                            onClick={() => cancel('arguments will be passed to the callback')}
                        >
                            {options.buttonDismiss.toUpperCase()}
                        </button>
                    </div>
                    {options.buttonConfirm && (
                        <div className="col-12 py-1 pb-3'">
                            <button
                                type="button"
                                className="btn btn-primary-alt btn-block confirm-button-modal"
                                onClick={() => proceed('same as cancel')}
                            >
                                {options.buttonConfirm.toUpperCase()}
                            </button>
                        </div>
                    )}
                </div>
            </ModalFooter>
        </Modal>
    )
}

Confirm.propTypes = {
    show: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
    proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
    cancel: PropTypes.func, // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func, // from confirmable. call to only close the dialog.
    confirmation: PropTypes.string, // arguments of your confirm function
    options: PropTypes.object, // arguments of your confirm function
}

export default confirmable(Confirm)
