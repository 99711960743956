export function getFundResults(fundStatus, vehicles) {
    const fundingResults = {
        data: [],
        count: 0,
    }
    switch (fundStatus) {
        case 'Available':
            fundingResults.data = vehicles.filter(function (x) {
                return x.fundingStatus === 'Ineligible' || x.fundingStatus === 'Available'
            })
            fundingResults.count = fundingResults.data.length
            break
        case 'Declined':
            fundingResults.data = vehicles.filter(function (x) {
                return x.fundingStatus === 'Declined'
            })
            fundingResults.count = fundingResults.data.length
            break
        case 'Accepted':
            fundingResults.data = vehicles.filter(function (x) {
                return x.fundingStatus === 'Accepted'
            })
            fundingResults.count = fundingResults.data.length
            break
        case 'Pending':
            fundingResults.data = vehicles.filter(function (x) {
                return x.fundingStatus === 'Pending'
            })
            fundingResults.count = fundingResults.data.length
            break
        default:
            fundingResults.count = 0
            break
    }
    return fundingResults
}

export function getFundTotals(vehicles) {
    let fundingResults = {
        availableAndIneligible: 0,
        declined: 0,
        accepted: 0,
        pending: 0,
    }
    fundingResults.availableAndIneligible = getFundResults('Available', vehicles).count
    fundingResults.declined = getFundResults('Declined', vehicles).count
    fundingResults.accepted = getFundResults('Accepted', vehicles).count
    fundingResults.pending = getFundResults('Pending', vehicles).count

    return fundingResults
}
