import {
    UPDATE_EXCHANGE_START_SUCCESS,
    UPDATE_WATCHERS_DETAILS_SUCCESS,
    UPDATE_BIDDING_DETAILS_SUCCESS,
    UPDATE_BIDDING_DETAILS_ALL_SUCCESS,
    UPDATE_BIDDING_DETAILS_END_DATE_SUCCESS,
} from '.'

const messageTypes = {
    '/user/queue/watchers': UPDATE_WATCHERS_DETAILS_SUCCESS,
    '/user/queue/bid': UPDATE_BIDDING_DETAILS_SUCCESS,
    '/user/queue/auctions': UPDATE_BIDDING_DETAILS_ALL_SUCCESS,
    '/user/queue/enddate': UPDATE_BIDDING_DETAILS_END_DATE_SUCCESS,
    '/user/queue/exchangeStart': UPDATE_EXCHANGE_START_SUCCESS,
}

const WEBSOCKET_CONNECTED = 'WEBSOCKET_CONNECTED'
const WEBSOCKET_DISCONNECTED = 'WEBSOCKET_DISCONNECTED'

export { messageTypes, WEBSOCKET_CONNECTED, WEBSOCKET_DISCONNECTED }
