import { AB_GREEN, TRIUMPH_PRIMARY } from '../constants'

// This was moved here from Functions due to an odd bug encountered when when running tests where it decided it didn't actually exist.
// I think this was due to a circular dependency.

export function getPrimaryColor(theme) {
    switch (theme) {
        case 'triumph':
            return TRIUMPH_PRIMARY
        default:
            return AB_GREEN
    }
}
