import { WEBSOCKET_CONNECTED, WEBSOCKET_DISCONNECTED } from '../constants/websocket'

const initialState = {
    connected: false,
}

const WebSocketReducer = (state = initialState, action) => {
    switch (action.type) {
        case WEBSOCKET_CONNECTED:
            return {
                connected: true,
            }
        case WEBSOCKET_DISCONNECTED:
            return {
                connected: false,
            }
        default:
            return state
    }
}

export default WebSocketReducer
