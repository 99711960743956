import {
    CurrentBid,
    EndDate,
    ExchangeNamaGradeCombinedIcon,
    getEngineText,
    getVehicleTypeIconImageKey,
    MileageIcon,
    MOTIcon,
    NamaGradeCombinedIcon,
    SellerIcon,
    StartDate,
    statFifty,
    statTwentyFive,
    transmissionIcon,
    V5Icon,
    VehicleStatsItem,
    VehicleStatsRow,
} from '../../components/CatalogueVehicleDetails/VehicleHelper'
import { CURRENCY } from '../../constants'

import { convertFuelText, hasTradeAccess, isVehicleAttributeIneligible } from '../../utilities/Functions'

import { ISODateString } from '../../utilities/DateFunctions'
import { lang } from '../../themeConfig'

export function cardConfig(vehicle, user) {
    return {
        'e-hub2': [
            {
                component: VehicleStatsRow,
                label: 'Collection site',
                enabled: vehicle.status === 'Aston Barclay',
                value: vehicle.collectionSite,
                user,
            },
            {
                component: VehicleStatsRow,
                label:
                    vehicle.status === 'Aston Barclay' && vehicle.sendToAuctionDate
                        ? 'Requested date'
                        : lang('LABEL_APPRAISAL_DATE', user.theme),
                enabled: true,
                value:
                    vehicle.status === 'Aston Barclay' && vehicle.sendToAuctionDate
                        ? ISODateString(vehicle.sendToAuctionDate, true, false)
                        : ISODateString(vehicle.appraisalDate, true, false),
                user,
            },
            {
                component: VehicleStatsRow,
                label: lang('LABEL_CAP_CLEAN', user.theme),
                enabled: true,
                value: vehicle.capCleanValuation && `${CURRENCY}${vehicle.capCleanValuation.toLocaleString()}`,
                user,
            },
            {
                component: VehicleStatsRow,
                label: lang('LABEL_CAP_AVERAGE', user.theme),
                enabled: true,
                value: vehicle.capAverageValuation && `${CURRENCY}${vehicle.capAverageValuation.toLocaleString()}`,
                user,
            },
            {
                component: VehicleStatsRow,
                label: lang('LABEL_CAP_BELOW', user.theme),
                enabled: true,
                value: vehicle.capBelowValuation && `${CURRENCY}${vehicle.capBelowValuation.toLocaleString()}`,
                user,
            },
            {
                component: VehicleStatsRow,
                label: lang('LABEL_SIV', user.theme),
                enabled: true,
                value: vehicle.sivPrice && `${CURRENCY}${vehicle.sivPrice.toLocaleString()}`,
                user,
            },
            {
                component: VehicleStatsRow,
                label: 'Reserve',
                enabled: vehicle.status === 'Aston Barclay',
                user,
                value: vehicle.reservePrice && `${CURRENCY}${vehicle.reservePrice.toLocaleString()}`,
            },
            {
                component: VehicleStatsRow,
                label: lang('LABEL_STATUS_WHEN_ARCHIVED', user.theme),
                enabled: vehicle.status === 'Archived',
                value: vehicle.previousStatus,
                user,
            },
        ],
        catalogue: [
            {
                component: VehicleStatsItem,
                icon: 'fuel',
                enabled: true,
                value: convertFuelText(vehicle.fuel),
                user,
            },
            {
                component: transmissionIcon,
                icon: 'transmission',
                enabled: true,
                value: vehicle.transmission ? vehicle.transmission : '-',
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'engine',
                enabled: true,
                value: getEngineText(vehicle),
                user,
            },
            {
                component: MileageIcon,
                icon: 'mileage',
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'firstReg',
                enabled: true,
                value: vehicle.firstRegistration ? ISODateString(vehicle.firstRegistration, false, true) : '-',
                user,
            },
            {
                component: V5Icon,
                icon: 'v5',
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: MOTIcon,
                icon: 'mot',
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'vat',
                enabled: true,
                value: vehicle.vatStatus ? vehicle.vatStatus.label : '-',
                user,
            },
            {
                component: SellerIcon,
                enabled: true,
                value: vehicle.seller ? vehicle.seller : '-',
                user,
            },
            {
                component: NamaGradeCombinedIcon,
                enabled: hasTradeAccess(user),
                value: vehicle,
                user,
            },
        ],
        'e-xchange': [
            {
                component: VehicleStatsItem,
                icon: 'fuel',
                enabled: true,
                value: convertFuelText(vehicle.fuel),
                user,
            },
            {
                component: transmissionIcon,
                icon: 'transmission',
                enabled: true,
                value: vehicle.transmission ? vehicle.transmission : '-',
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'engine',
                enabled: true,
                value: getEngineText(vehicle),
                user,
            },
            {
                component: MileageIcon,
                icon: 'mileage',
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'firstReg',
                enabled: true,
                value: vehicle.firstRegistration ? ISODateString(vehicle.firstRegistration, false, true) : '-',
                user,
            },
            {
                component: V5Icon,
                icon: 'v5',
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: MOTIcon,
                icon: 'mot',
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'vat',
                enabled: true,
                value: vehicle.vatStatus ? vehicle.vatStatus.label : '-',
                user,
            },
            {
                component: SellerIcon,
                enabled: true,
                value: vehicle.seller ? vehicle.seller : '-',
                user,
            },
            {
                component: ExchangeNamaGradeCombinedIcon,
                enabled: hasTradeAccess(user),
                value: vehicle,
                user,
            },
        ],
        'e-xchange-ehub': [
            {
                component: VehicleStatsItem,
                icon: 'fuel',
                enabled: true,
                value: convertFuelText(vehicle.fuel),
                user,
            },
            {
                component: transmissionIcon,
                icon: 'transmission',
                enabled: true,
                value: vehicle.transmission ? vehicle.transmission : '-',
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'engine',
                enabled: true,
                value: getEngineText(vehicle),
                user,
            },
            {
                component: MileageIcon,
                icon: 'mileage',
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'noOfBids',
                enabled: true,
                value:
                    vehicle.biddingDetails && vehicle.biddingDetails.noOfBids ? vehicle.biddingDetails.noOfBids : '-',
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'noOfWatchers',
                enabled: true,
                value:
                    vehicle.biddingDetails && vehicle.biddingDetails.noOfWatchers
                        ? vehicle.biddingDetails.noOfWatchers
                        : '-',
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'noOfViews',
                enabled: true,
                value: vehicle.uniqueWebViews ? vehicle.uniqueWebViews : '-',
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'sivPrice',
                enabled: true,
                value: vehicle.sivPrice ? vehicle.sivPrice : '-',
                user,
            },
            {
                component: CurrentBid,
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: StartDate,
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: EndDate,
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: statFifty,
                class: 'small',
                enabled:
                    vehicle.collectionAddress &&
                    (vehicle.collectionAddress.company || vehicle.collectionAddress.postcode),
                value: vehicle.collectionAddress
                    ? [vehicle.collectionAddress.company, vehicle.collectionAddress.postcode]
                          .filter((i) => !!i)
                          .join(', ')
                    : '',
                user,
            },
        ],
        'e-hub': [
            {
                component: VehicleStatsItem,
                icon: 'fuel',
                enabled: true,
                value: convertFuelText(vehicle.fuel),
                user,
            },
            {
                component: transmissionIcon,
                icon: 'transmission',
                enabled: true,
                value: vehicle.transmission ? vehicle.transmission : '-',
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'engine',
                enabled: true,
                value: getEngineText(vehicle),
                user,
            },
            {
                component: MileageIcon,
                icon: 'mileage',
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'noOfBids',
                enabled: true,
                value:
                    vehicle.biddingDetails && vehicle.biddingDetails.noOfBids ? vehicle.biddingDetails.noOfBids : '-',
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'noOfWatchers',
                enabled: true,
                value:
                    vehicle.biddingDetails && vehicle.biddingDetails.noOfWatchers
                        ? vehicle.biddingDetails.noOfWatchers
                        : '-',
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'noOfViews',
                enabled: true,
                value:
                    vehicle.biddingDetails && vehicle.biddingDetails.noOfViews ? vehicle.biddingDetails.noOfViews : '-',
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'sivPrice',
                enabled: true,
                value: vehicle.sivPrice ? vehicle.sivPrice : '-',
                user,
            },
            {
                component: CurrentBid,
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: StartDate,
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: EndDate,
                enabled: true,
                vehicle: vehicle,
                user,
            },
        ],
        'e-valuate': [
            {
                component: VehicleStatsItem,
                icon: 'fuel',
                enabled: true,
                value: convertFuelText(vehicle.fuel),
                user,
            },
            {
                component: transmissionIcon,
                icon: 'transmission',
                enabled: true,
                value: vehicle.transmission ? vehicle.transmission : '-',
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'engine',
                enabled: true,
                value: getEngineText(vehicle),
                user,
            },
            {
                component: MileageIcon,
                icon: 'mileage',
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: statFifty,
                label: lang('LABEL_SIV', user.theme),
                enabled: true,
                value: vehicle.sivPrice ? `${CURRENCY}${vehicle.sivPrice.toLocaleString()}` : '-',
                user,
            },
            {
                component: statFifty,
                label: lang('LABEL_APPRAISAL_DATE', user.theme),
                enabled: true,
                class: 'small',
                value: vehicle.appraisalDate ? ISODateString(vehicle.appraisalDate, true, true, false) : '-',
                user,
            },
            {
                component: statFifty,
                class: 'small',
                enabled:
                    vehicle.collectionAddress &&
                    (vehicle.collectionAddress.company || vehicle.collectionAddress.postcode),
                value: vehicle.collectionAddress
                    ? [vehicle.collectionAddress.company, vehicle.collectionAddress.postcode]
                          .filter((i) => !!i)
                          .join(', ')
                    : '',
                user,
            },
        ],
        archived: [
            {
                component: VehicleStatsItem,
                icon: 'fuel',
                enabled: true,
                value: convertFuelText(vehicle.fuel),
                user,
            },
            {
                component: transmissionIcon,
                icon: 'transmission',
                enabled: true,
                value: vehicle.transmission ? vehicle.transmission : '-',
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'engine',
                enabled: true,
                value: getEngineText(vehicle),
                user,
            },
            {
                component: MileageIcon,
                icon: 'mileage',
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: statFifty,
                label: lang('LABEL_STATUS_WHEN_ARCHIVED', user.theme),
                enabled: true,
                value: vehicle.previousStatus,
                user,
            },
            {
                component: statTwentyFive,
                label: lang('LABEL_ARCHIVED_DATE', user.theme),
                enabled: true,
                value: vehicle.archivedDate,
                user,
            },
            {
                component: statTwentyFive,
                label: lang('LABEL_APPRAISAL_DATE', user.theme),
                enabled: true,
                value: vehicle.appraisalDate ? ISODateString(vehicle.appraisalDate, true, true, false) : '-',
                user,
            },
        ],
        collections: [
            {
                component: VehicleStatsItem,
                icon: 'fuel',
                enabled: true,
                value: convertFuelText(vehicle.fuel),
                user,
            },
            {
                component: transmissionIcon,
                icon: 'transmission',
                enabled: true,
                value: vehicle.transmission ? vehicle.transmission : '-',
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'engine',
                enabled: true,
                value: getEngineText(vehicle),
                user,
            },
            {
                component: MileageIcon,
                icon: 'mileage',
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: statFifty,
                label: 'Reserve',
                enabled: true,
                value: vehicle.reservePrice ? `${CURRENCY}${vehicle.reservePrice.toLocaleString()}` : '-',
                user,
            },
            {
                component: statTwentyFive,
                label: lang('LABEL_SIV', user.theme),
                enabled: true,
                value: vehicle.sivPrice ? `${CURRENCY}${vehicle.sivPrice.toLocaleString()}` : '-',
                user,
            },
            {
                component: statTwentyFive,
                label: 'Requested date',
                enabled: true,
                value: vehicle.sendToAuctionDate ? ISODateString(vehicle.sendToAuctionDate, true, true, false) : '-',
                user,
            },
            {
                component: statFifty,
                class: 'small',
                enabled:
                    vehicle.collectionAddress &&
                    (vehicle.collectionAddress.company || vehicle.collectionAddress.postcode),
                value: vehicle.collectionAddress
                    ? [vehicle.collectionAddress.company, vehicle.collectionAddress.postcode]
                          .filter((i) => !!i)
                          .join(', ')
                    : '',
                user,
            },
        ],
        secureBankTrust: [
            {
                component: VehicleStatsItem,
                icon: 'fuel',
                enabled: true,
                value: vehicle.fuel ? vehicle.fuel : '-',
                user,
            },
            {
                component: transmissionIcon,
                icon: 'transmission',
                enabled: true,
                value: vehicle.transmission ? vehicle.transmission : '-',
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'engine',
                enabled: true,
                value: getEngineText(vehicle),
                user,
            },
            {
                component: MileageIcon,
                icon: 'milaege',
                enabled: true,
                vehicle: vehicle,
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'firstReg',
                enabled: true,
                value: vehicle.firstRegistration ? ISODateString(vehicle.firstRegistration, false, true) : '-',
                class: isVehicleAttributeIneligible(vehicle.vehicleIneligibilitys, 'firstregistration')
                    ? 'text-danger'
                    : '',
                user,
            },
            {
                component: VehicleStatsItem,
                icon: 'import',
                enabled: true,
                value: vehicle.imported ? 'Import' : 'Not Import',
                class: isVehicleAttributeIneligible(vehicle.vehicleIneligibilitys, 'imported') ? 'text-danger' : '',
                user,
            },
            {
                component: MOTIcon,
                icon: 'mot',
                enabled: true,
                vehicle: vehicle,
                disableValidation: true,
                user,
            },
            {
                component: VehicleStatsItem,
                icon: getVehicleTypeIconImageKey(vehicle.type),
                enabled: true,
                value: vehicle.type ? vehicle.type : '-',
                class: isVehicleAttributeIneligible(vehicle.vehicleIneligibilitys, 'vehicletype')
                    ? 'text-danger capitalize'
                    : 'capitalize',
                user,
            },
            {
                component: VehicleStatsItem,
                enabled: true,
                value: vehicle.hammerPrice ? `${CURRENCY}${vehicle.hammerPrice.toLocaleString()}` : '-',
                class: isVehicleAttributeIneligible(vehicle.vehicleIneligibilitys, 'price')
                    ? 'card-item-h-40 text-danger'
                    : 'card-item-h-40',
                user,
            },
            {
                component: VehicleStatsItem,
                class: 'card-item-h-40',
                enabled: true,
                value: vehicle.registration,
                user,
            },
        ],
    }
}
