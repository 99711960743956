import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { handleOnLoadSearch, onClickBuyNowOnly, onClickExchangeOnly, resetSearch } from '../../actions/criteria'

import { onOpenBuyNowModal, onOpenProxyBidModal, onOpenSaveSearchModal } from '../../actions/modals'

import { fetchSavedSearches } from '../../actions/user'

import {
    getAges,
    getBodyTypes,
    getDoors,
    getEngineSizes,
    getFuelTypes,
    getMakes,
    getMileages,
    getNama,
    getSales,
    getSellers,
    getSites,
    getTransmissions,
    getVehicleTypes,
    getWriteOff,
    getNonRunner,
    getEuroStatus,
} from '../../actions/criteriaData'

import { getVehicles } from '../../actions/vehicles'

import Search from './Search'

const mapStateToProps = (state) => ({
    user: state.user,
    criteria: state.criteria,
    criteriaData: state.criteriaData,
    sorting: state.sorting,
    pagination: state.pagination,
})

const mapDispatchToProps = {
    resetSearch,
    onOpenSaveSearchModal,
    onOpenProxyBidModal,
    onOpenBuyNowModal,
    onClickBuyNowOnly,
    onClickExchangeOnly,
    getVehicles,
    getSites,
    getSales,
    getMakes,
    getEngineSizes,
    getTransmissions,
    getDoors,
    getAges,
    getMileages,
    getBodyTypes,
    getVehicleTypes,
    getFuelTypes,
    getSellers,
    getNama,
    getWriteOff,
    getNonRunner,
    getEuroStatus,
    fetchSavedSearches,
    handleOnLoadSearch,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))
