import { CHANGE_PAGINATION, CHANGE_PAGE_SIZE } from '../constants'

export const changePagination = (value) => ({
    type: CHANGE_PAGINATION,
    payload: value,
})

export const changePageSize = (value) => ({
    type: CHANGE_PAGE_SIZE,
    payload: value,
})

export function setPaginationState(value) {
    return (dispatch) => {
        dispatch(changePagination(value))
    }
}

export function setPageSize(value) {
    return (dispatch) => {
        dispatch(changePageSize(value))
    }
}
