import React, { Component } from 'react'
import { FaAngleDoubleLeft } from '@react-icons/all-files/fa/FaAngleDoubleLeft'
import { FaAngleLeft } from '@react-icons/all-files/fa/FaAngleLeft'
import { FaAngleRight } from '@react-icons/all-files/fa/FaAngleRight'
import { FaAngleDoubleRight } from '@react-icons/all-files/fa/FaAngleDoubleRight'

/**
 * Usage:
 *   <Pagination pagination={this.props.pagination} data={this.props.data} setState={this.setPaginationState} />
 */
class Pagination extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            resultsPerPage: 12,
            totalPages: 0,
            vehicles: [],
        }
    }

    componentDidMount() {
        this.setState({
            currentPage: this.props.pagination.currentPage,
            resultsPerPage: this.props.pagination.resultsPerPage,
            vehicles: this.props.vehicles,
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            currentPage: nextProps.pagination.currentPage,
            resultsPerPage: nextProps.pagination.resultsPerPage,
            vehicles: nextProps.vehicles,
        })
    }

    isFirstPageEnabled = () => {
        return this.state.currentPage > 1
    }

    isPrevPageEnabled = () => {
        return this.state.currentPage > 1
    }

    isNextPageEnabled = () => {
        const resultsPerPage = this.state.resultsPerPage.value
        const totalPages = Math.ceil(this.state.vehicles.length / resultsPerPage)
        return this.state.currentPage < totalPages
    }

    isLastPageEnabled = () => {
        const resultsPerPage = this.state.resultsPerPage.value
        const totalPages = Math.ceil(this.state.vehicles.length / resultsPerPage)
        return this.state.currentPage < totalPages
    }

    moveToPage = (event, action) => {
        event.preventDefault()
        window.scrollTo(0, 0)

        const currentPage = this.state.currentPage
        const resultsPerPage = this.state.resultsPerPage.value

        const totalPages = Math.ceil(this.state.vehicles.length / resultsPerPage)

        let nextPage
        if (action === 'first') {
            nextPage = 1
        } else if (action === 'prev') {
            if (currentPage > 1) {
                nextPage = currentPage - 1
            }
        } else if (action === 'next') {
            if (currentPage < totalPages) {
                nextPage = currentPage + 1
            }
        } else if (action === 'last') {
            nextPage = totalPages
        } else {
            nextPage = Number.parseInt(action)
        }

        const offsetStart = nextPage * resultsPerPage - resultsPerPage
        const offsetFinish = nextPage === totalPages ? this.state.vehicles.length : nextPage * resultsPerPage

        this.props.setPaginationState({
            currentPage: nextPage,
            offsetStart: offsetStart,
            offsetFinish: offsetFinish,
        })
    }

    render() {
        const currentPage = this.state.currentPage
        const resultsPerPage = this.state.resultsPerPage.value

        const totalPages = Math.ceil(this.state.vehicles.length / resultsPerPage)

        const boxes = totalPages > 5 ? 5 : totalPages
        const middleBox = Math.ceil(boxes / 2)

        let paginationPagseStart = 1

        if (currentPage <= middleBox) {
            paginationPagseStart = 1
        } else if (currentPage + (middleBox - 1) >= totalPages) {
            paginationPagseStart = totalPages - (boxes - 1)
        } else {
            paginationPagseStart = currentPage - (middleBox - 1)
        }

        let paginationPages = 1

        if (totalPages <= boxes) {
            paginationPages = totalPages
        } else if (currentPage + (middleBox - 1) >= totalPages) {
            paginationPages = totalPages
        } else {
            paginationPages = paginationPagseStart + 4
        }

        const paginiationPageLinks = []

        for (let i = paginationPagseStart; i < paginationPages + 1; i++) {
            paginiationPageLinks.push(
                <li
                    key={'paginiation-list-item-' + i}
                    id={'pagination-page-link-' + i}
                    className={currentPage === i ? 'page-item active' : 'page-item'}
                >
                    <a className="page-link" href="/" onClick={(event) => this.moveToPage(event, i)}>
                        {i}
                    </a>
                </li>,
            )
        }

        return (
            <nav aria-label="Page navigation">
                <ul className="pagination">
                    <li
                        id={'pagination-page-link-first'}
                        className={this.isFirstPageEnabled() ? 'page-item' : 'page-item disabled'}
                    >
                        <a
                            className="page-link"
                            href="/"
                            aria-label="First"
                            onClick={(event) => this.moveToPage(event, 'first')}
                        >
                            <span aria-hidden="true">
                                <FaAngleDoubleLeft />
                            </span>
                            <span className="sr-only">First</span>
                        </a>
                    </li>
                    <li
                        key={'paginiation-list-item-prev'}
                        id={'pagination-page-link-prev'}
                        className={this.isPrevPageEnabled() ? 'page-item' : 'page-item disabled'}
                    >
                        <a
                            className="page-link"
                            href="/"
                            aria-label="Previous"
                            onClick={(event) => this.moveToPage(event, 'prev')}
                        >
                            <span aria-hidden="true">
                                <FaAngleLeft />
                            </span>
                            <span className="sr-only">Previous</span>
                        </a>
                    </li>
                    {paginiationPageLinks}
                    <li
                        key={'paginiation-list-item-next'}
                        id={'pagination-page-link-next'}
                        className={this.isNextPageEnabled() ? 'page-item' : 'page-item disabled'}
                    >
                        <a
                            className="page-link"
                            href="/"
                            aria-label="Next"
                            onClick={(event) => this.moveToPage(event, 'next')}
                        >
                            <span aria-hidden="true">
                                <FaAngleRight />
                            </span>
                            <span className="sr-only">Next</span>
                        </a>
                    </li>
                    <li
                        key={'paginiation-list-item-last'}
                        id={'pagination-page-link-last'}
                        className={this.isLastPageEnabled() ? 'page-item' : 'page-item disabled'}
                    >
                        <a
                            className="page-link"
                            href="/"
                            aria-label="Last"
                            onClick={(event) => this.moveToPage(event, 'last')}
                        >
                            <span aria-hidden="true">
                                <FaAngleDoubleRight />
                            </span>
                            <span className="sr-only">Last</span>
                        </a>
                    </li>
                </ul>
            </nav>
        )
    }
}

export default Pagination
