import { thunk as reduxThunk, withExtraArgument } from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import { emit } from './actions/websocket'
import { createLogger } from 'redux-logger'
import AppReducers from './reducers'

const logger = createLogger({
    collapsed: true,
    colors: {
        title: () => 'inherit',
        prevState: () => '#f4c713',
        action: () => '#03A9F4',
        nextState: () => '#4CAF50',
        error: () => '#F20404',
    },
})

const middleware = [withExtraArgument({ emit })]
let store = createStore(AppReducers, compose(applyMiddleware(...middleware, reduxThunk)))

if (import.meta.env.VITE_NODE_ENV === 'development') {
    store = createStore(
        AppReducers,
        compose(
            applyMiddleware(...middleware, reduxThunk, logger),
            window.__REDUX_DEVTOOLS_EXTENSION__
                ? window.__REDUX_DEVTOOLS_EXTENSION__({
                      trace: true,
                      traceLimit: 25,
                  })
                : (f) => f,
        ),
    )
}

export default store
