import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Login from './Login'

import { login } from '../../actions/user'

const mapStateToProps = (state) => ({
    user: state.user,
    global: state.profileConfig && state.profileConfig.data,
})

const mapDispatchToProps = {
    login,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
