import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Vehicles from './Vehicles'
import {
    getEHubAppraisals,
    onChangeAppraisalStatus,
    setAppraisalLocationSearch,
    toggleAppraisalAllGroupsFilter,
    toggleAppraisalClosedGroupsFilter,
    resetGroupsFilter,
    changeAccounts,
} from '../../../actions/ehub'
import { changePagination, setPageSize } from '../../../actions/pagination'
import { onChangeSortOrder, setSortOrderProperty } from '../../../actions/sorting'
import { onOpenAddToEXchangeModal, onOpenSendToAuctionModal, onOpenVehiclePhotoModal } from '../../../actions/modals'

const mapStateToProps = (state) => ({
    sorting: state.sorting,
    ehub: state.ehub,
    pagination: state.pagination,
    user: state.user,
    locationSearch: state.ehub.locationSearch,
    allGroupsFilter: state.ehub.allGroupsFilter,
    closedGroupFilter: state.ehub.closedGroupFilter,
})

const mapDispatchToProps = {
    getEHubAppraisals,
    setPageSize,
    onChangeSortOrder,
    onOpenSendToAuctionModal,
    onOpenVehiclePhotoModal,
    onChangeAppraisalStatus,
    onOpenAddToEXchangeModal,
    setAppraisalLocationSearch,
    changePagination,
    setSortOrderProperty,
    toggleAppraisalAllGroupsFilter,
    toggleAppraisalClosedGroupsFilter,
    resetGroupsFilter,
    changeAccounts,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Vehicles))
