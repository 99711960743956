import React from 'react'
import {
    APP_EHUB,
    CT_ARCHIVED,
    CT_COLLECTIONS,
    CT_EHUB,
    CT_EHUB_EXCHANGE,
    CT_EVALUATE,
    CT_EXCHANGE,
    CT_SECURE_BANK_TRUST_FUNDING,
    CURRENCY,
} from '../constants'
import { ISODateString } from './DateFunctions'
import { addNotification } from '../actions/notifications' // !This breaks things
import { userRedirect } from '../actions/user' // !This also breaks things

import {
    catalogueAuthOptions,
    catalogueOptions,
    eXchangeEhubOptions,
    fundingOptions,
    standardEhubOptions,
    standardOptions,
} from './config'
import confirm from './Confirm'

import { isEmpty, isObject } from 'lodash'

export function isCatalogueNavType(location) {
    return location.pathname.indexOf(APP_EHUB) === -1
}

export function isEhubApplication(location) {
    return location && location.pathname.indexOf(APP_EHUB) >= 0
}

export function isNumber(n) {
    return !isNaN(n) && Number.isFinite(n)
}

/**
 * String.toLowerCase()
 */
export function lowerCase(str) {
    return str.toLowerCase()
}

/**
 * String.toUpperCase()
 */
export function upperCase(str) {
    return str.toUpperCase()
}

/**
 * String.toUpperCase()
 */
export function alertVehicle(vehicle) {
    return vehicle.alert
}

export function displayButtonForLeaseplan(vehicle, user) {
    if (vehicle.biddingDetails.leaseplanVehicle && !hasLeaseplanBiddingAccess(user)) {
        return false
    } else {
        return true
    }
}

/**
 * String.toUpperCase()
 */
export function insuranceWriteOffCategory(vehicle) {
    if (vehicle.writeoffcode != null) {
        if (vehicle.writeoffcode.indexOf('Cat A Insurance Loss') !== -1) return 'a'
        if (vehicle.writeoffcode.indexOf('Cat B Insurance Loss') !== -1) return 'b'
        if (vehicle.writeoffcode.indexOf('Cat C Insurance Loss') !== -1) return 'c'
        if (vehicle.writeoffcode.indexOf('Cat D Insurance Loss') !== -1) return 'd'
        if (vehicle.writeoffcode.indexOf('Cat N Insurance Loss') !== -1) return 'n'
        if (vehicle.writeoffcode.indexOf('Cat S Insurance Loss') !== -1) return 's'
    }
}

/**
 * Remove non-word chars.
 */
function removeNonWord(str) {
    return str.replace(/[^0-9a-zA-Z\xC0-\xFF -]/g, '')
}

export function getSortingOptions(user, type, theme) {
    let options = standardOptions
    switch (type) {
        case 'funding':
            options = [...options, ...fundingOptions]
            break
        case 'catalogue':
            let catOptions = catalogueOptions
            if (hasTradeAccess(user)) {
                catOptions = catalogueAuthOptions
            }
            options = [...options, ...catOptions]
            break
        default:
            options = type
            break
    }
    return options
}

export function getSortingOptionsForEhub(selectedOption) {
    let options = standardEhubOptions
    switch (selectedOption) {
        case 'e-xchange':
            options = eXchangeEhubOptions
            break

        default:
            break
    }
    return options
}

/**
 * Convert string to camelCase text.
 */
export function camelCase(str) {
    return removeNonWord(str)
        .replace(/-/g, ' ') //convert all hyphens to spaces
        .replace(/\s[a-z]/g, upperCase) //convert first char of each word to UPPERCASE
        .replace(/\s+/g, '') //remove spaces
        .replace(/^[A-Z]/g, lowerCase) //convert first char to lowercase
}

export function pad(n, width) {
    const z = '0'
    n = n + ''
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export function formatCurrency(value, returnZero) {
    if (value) return CURRENCY + value.toLocaleString('en-GB')
    else {
        if (returnZero) {
            return `${CURRENCY}0.00`
        } else {
            return '-'
        }
    }
}

const getInternalMessage = (mileageWarranty, vatStatus, mileage) => {
    return (
        <ul>
            {!vatStatus && <li>VAT status</li>}
            {!mileage && <li>an up to date mileage</li>}
            {mileageWarranty == null && <li>mileage warranted Yes/No</li>}
        </ul>
    )
}

export const getMessage = (vatStatus, mileage) => {
    if (!vatStatus && !mileage) {
        return 'You must provide an up to date mileage and a VAT status'
    }
    if (!vatStatus && mileage) {
        return 'You must provide a VAT status'
    }

    if (vatStatus && !mileage) {
        return 'You must provide an up to date mileage'
    }
}

export function openAddToExchangeModal(openFunction, user, appraisal) {
    let payload = {
        appraisalId: appraisal.id,
        collectionSite: appraisal.collectionSite,
        accountCode: appraisal.vendorCode,
        eXchangeType: user.accountsAccess[appraisal.vendorCode].eXchangeType,
        listAndCollect: appraisal.listAndCollect,
        allowClosed: appraisal.actions.allowClosed,
        appraisal: appraisal,
    }

    if (appraisal.actions.relistOnExchange) {
        payload = Object.assign({}, payload, {
            vehicleId: appraisal.vehicleId,
        })
    }

    openFunction(payload)
}

export function checkDirtyForm(initialFormValues, formValues) {
    return JSON.stringify(initialFormValues) === JSON.stringify(formValues)
}

export function checkFormEligibility(vehicle) {
    if (vehicle.mileageWarranty == null || !vehicle.vatStatus || !vehicle.mileage || vehicle.mileage === 0) {
        confirm('You must enter:', {
            buttonDismiss: 'Close',
            description: getInternalMessage(vehicle.mileageWarranty, vehicle.vatStatus, vehicle.mileage),
        }).then(
            () => {
                return false
            },
            () => {
                return false
            },
        )
        return false
    }

    return true
}

export const eHubOptions = {
    'e-valuate': [
        { path: 'new', value: 'new', label: 'New' },
        { path: 'in-stock', value: 'in-stock', label: 'In stock' },
    ],
    'e-xchange': [
        { path: 'will-sell', value: 'will-sell', label: 'Will sell' },
        { path: 'yet-to-sell', value: 'yet-to-sell', label: 'Yet to sell' },
        { path: 'unsold', value: 'unsold', label: 'Unsold' },
        { path: 'sold', value: 'sold', label: 'Sold' },
    ],
    collections: [
        { path: 'collected-by-aston-barclay', value: 'collected-by-aston-barclay', label: 'Aston Barclay' },
        // {path: "collected-by-tcbg", value: "collected-by-tcbg", label: "TCBG"}
    ],
    archive: [{ path: 'archive', value: 'archived', label: 'Archived' }],
}

export const eHubOptionsListAndCollect = {
    'e-valuate': [
        { path: 'new', value: 'new', label: 'New' },
        { path: 'in-stock', value: 'in-stock', label: 'In stock' },
    ],
    'e-xchange': [
        { path: 'will-sell', value: 'will-sell', label: 'Will sell' },
        { path: 'yet-to-sell', value: 'yet-to-sell', label: 'Yet to sell' },
        { path: 'sold-lac', value: 'sold-lac', label: 'Sold (List and Collect)' },
    ],
    collections: [
        { path: 'collected-by-aston-barclay', value: 'collected-by-aston-barclay', label: 'Aston Barclay' },
        // {path: "collected-by-tcbg", value: "collected-by-tcbg", label: "TCBG"}
    ],
    archive: [{ path: 'archive', value: 'archived', label: 'Archived' }],
}

export const eHubConversions = {
    'e-valuate': 'e-Valuate',
    'e-xchange': 'e-Xchange',
    archive: 'Archived',
    collections: 'Collections',
    'collected-by-aston-barclay': 'Aston Barclay',
    'ab-sites': 'Aston Barclay sites',
    new: 'New',
    'in-stock': 'In stock',
    'will-sell': 'Will sell',
    'yet-to-sell': 'Yet to sell',
    unsold: 'Unsold',
    sold: 'Sold',
    collected_ab: 'Aston Barclay',
    collected_tcbg: 'TCBG',
    archived: 'Archived',
    'sold-lac': 'Sold (List and Collect)',
}

export const eHubTranslationLookup = {
    'e-valuate': 'e-Valuate',
    'e-xchange': 'e-Xchange',
    archive: 'Archived',
    collections: 'Collections',
    'collected-by-aston-barclay': 'Aston Barclay',
    'ab-sites': 'Aston Barclay sites',
    new: 'New',
    'in-stock': 'IN_STOCK_TEXT',
    'will-sell': 'Will sell',
    'yet-to-sell': 'Yet to sell',
    unsold: 'Unsold',
    sold: 'Sold',
    collected_ab: 'Aston Barclay',
    collected_tcbg: 'TCBG',
}

export function gotoVehicleDetails(vehicle, props, path, subPath) {
    if (
        vehicle.cardType === CT_EVALUATE ||
        vehicle.cardType === CT_EHUB_EXCHANGE ||
        vehicle.cardType === CT_COLLECTIONS ||
        vehicle.cardType === CT_EHUB ||
        vehicle.cardType === CT_ARCHIVED
    ) {
        let id = vehicle.id

        if (path === CT_EXCHANGE) {
            id = vehicle.vehicleId
        }
        let url = `/e-hub/${vehicle.vendorCode || vehicle.account}/${path}/${subPath}/${id}`

        if (!subPath) {
            url = `/e-hub/${vehicle.vendorCode || vehicle.account}/${path}/${id}`
        }
        return {
            pathname: url,
            state: {
                ...props.location.state,
                prevPath: props.type,
                subsection: 'vehicle-details',
            },
        }
    }
    return {
        pathname: `/details/${vehicle.cardType}/${vehicle.id}`,
        state: { prevPath: props.type },
    }
}

export function hasProxyBidAccess(user) {
    return user.liveBidAccess
}

export function hasBuyNowAccess(user) {
    return user.buyItNowAccess
}

export function isEHubUser(user) {
    return user.eHubUser
}

export function hasTradeAccess(user) {
    return user.tradeAccess
}

export function hasLeaseplanBiddingAccess(user) {
    return !user.blockLeaseplanBidAccess
}

export function isUserLoggedIn(user) {
    return user.loggedIn
}

const ValuationPriceRow = (props) => {
    return (
        <tr style={{ backgroundColor: props.background }}>
            <td>{props.name}</td>
            {props.price.map((price, i) => (
                <React.Fragment key={i}>
                    {price.status === 'ACCEPTED' && (
                        <td className={i !== 0 ? 'old-price' : ''}>
                            {price && price.price ? `£${price.price.toLocaleString()}` : 'Not known'}
                        </td>
                    )}
                    {price.status === 'QUOTED' && (
                        <td className={i !== 0 ? 'old-price' : ''}>
                            {price && price.price ? `£${price.price.toLocaleString()}` : 'Not known'}
                        </td>
                    )}
                    {price.status === 'ERROR' && (
                        <td className={`price-error ${i !== 0 ? 'old-price' : ''}`}>No valuation</td>
                    )}
                    {price.status === 'IN_PROGRESS' && (
                        <td className={`price-in-progress ${i !== 0 ? 'old-price' : ''}`}>In Progress</td>
                    )}
                    {price.status === 'CREATED' && !price.price && (
                        <td className={`price-in-progress ${i !== 0 ? 'old-price' : ''}`}>In Progress</td>
                    )}
                    {!price.status && <td className={`price-error ${i !== 0 ? 'old-price' : ''}`}>No valuation</td>}
                </React.Fragment>
            ))}
        </tr>
    )
}

export function renderHistoricalPrices(appraisal, prices, allowCap, allowShowTCBG) {
    const dates = prices.map((prices) => Object.assign({}, { date: prices.date }))
    const mileages = prices.map((prices) => Object.assign({}, { mileage: prices.mileage }))
    const cleans = prices.map((prices) =>
        Object.assign(
            {},
            {
                price: prices.capValuationPriceResponse && prices.capValuationPriceResponse.cleanValuation,
                status: prices.capValuationStatus,
            },
        ),
    )
    const averages = prices.map((prices) =>
        Object.assign(
            {},
            {
                price: prices.capValuationPriceResponse && prices.capValuationPriceResponse.averageValuation,
                status: prices.capValuationStatus,
            },
        ),
    )
    const belows = prices.map((prices) =>
        Object.assign(
            {},
            {
                price: prices.capValuationPriceResponse && prices.capValuationPriceResponse.belowAverageValuation,
                status: prices.capValuationStatus,
            },
        ),
    )

    const tcbgPrices = prices.map((prices) =>
        Object.assign(
            {},
            {
                price: prices.tcbgValuationPriceResponse && prices.tcbgValuationPriceResponse.tcbgBasePrice,
                status: prices.tcgbValuationStatus,
            },
        ),
    )

    return (
        <React.Fragment>
            <tr style={{ backgroundColor: '#fff' }}>
                <td>Date</td>
                {dates.map((date, i) => (
                    <td key={i} className={i !== 0 ? 'old-price' : ''}>
                        {date && date.date ? date.date : '-'}
                    </td>
                ))}
            </tr>
            <tr style={{ backgroundColor: '#eeeeee' }}>
                <td>Mileage</td>
                {mileages.map((mileage, i) => (
                    <td key={i} className={i !== 0 ? 'old-price' : ''}>
                        {mileage.mileage}
                    </td>
                ))}
            </tr>

            {allowCap && (
                <React.Fragment>
                    <tr style={{ backgroundColor: '#fff' }}>
                        <th colSpan={cleans.length + 1} style={{ paddingBottom: '0px' }}>
                            <span style={{ color: 'black', backgroundColor: '#fff', fontSize: '1rem' }}>CAP</span>
                        </th>
                    </tr>
                    <ValuationPriceRow price={cleans} name={`Clean`} background={`#eeeeee`} />
                    <ValuationPriceRow price={averages} name={`Average`} background={`#fff`} />
                    <ValuationPriceRow price={belows} name={`Below`} background={`#eeeeee`} />
                </React.Fragment>
            )}

            {allowShowTCBG && (
                <React.Fragment>
                    <tr style={{ backgroundColor: '#fff' }}>
                        <th colSpan={tcbgPrices.length + 1} style={{ paddingBottom: '0px' }}>
                            <span style={{ color: 'black', fontSize: '1rem' }}>TCBG</span>
                        </th>
                    </tr>
                    <ValuationPriceRow price={tcbgPrices} name={`Base price`} background={`#eeeeee`} />
                </React.Fragment>
            )}

            {!allowShowTCBG && (
                <tr style={{ backgroundColor: '#fff' }}>
                    <th colSpan={cleans.length + 1}>
                        <span style={{ color: 'black', background: 'white' }}>
                            No TCBG valuation services are currently available to this account. Please contact your
                            Account Manager to discuss your requirements.
                        </span>
                    </th>
                </tr>
            )}

            {!allowCap && (
                <tr style={{ backgroundColor: '#fff' }}>
                    <th colSpan={cleans.length + 1}>
                        <span style={{ color: 'black', background: 'white' }}>
                            No CAP valuation services are currently available to this account. Please contact your
                            Account Manager to discuss your requirements.
                        </span>
                    </th>
                </tr>
            )}
        </React.Fragment>
    )
}

export function renderHistoricExchangeAuctionDetails(auctions) {
    return (
        <React.Fragment>
            <tr>
                <td>Channel</td>
                {auctions.map((auction, i) => (
                    <React.Fragment key={i}>
                        <td className={i !== 0 ? 'old-price' : ''}>{auction.closedGroup ? 'Closed' : 'All Buyers'}</td>
                    </React.Fragment>
                ))}
            </tr>
            <tr>
                <td>Started</td>
                {auctions.map((auction, i) => (
                    <React.Fragment key={i}>
                        <td className={i !== 0 ? 'old-price' : ''}>
                            {ISODateString(auction.startDate, false, false, false)}
                        </td>
                    </React.Fragment>
                ))}
            </tr>
            <tr>
                <td>Ended</td>
                {auctions.map((auction, i) => (
                    <React.Fragment key={i}>
                        <td className={i !== 0 ? 'old-price' : ''}>
                            {ISODateString(auction.endDate, false, false, false)}
                        </td>
                    </React.Fragment>
                ))}
            </tr>
            <tr>
                <td>Start Price</td>
                {auctions.map((auction, i) => (
                    <React.Fragment key={i}>
                        <td className={i !== 0 ? 'old-price' : ''}>
                            {auction.startingPrice ? `£${auction.startingPrice.toLocaleString()}` : '-'}
                        </td>
                    </React.Fragment>
                ))}
            </tr>
            <tr>
                <td>Reserve</td>
                {auctions.map((auction, i) => (
                    <React.Fragment key={i}>
                        <td className={i !== 0 ? 'old-price' : ''}>
                            {auction.reserve ? `£${auction.reserve.toLocaleString()}` : '-'}
                        </td>
                    </React.Fragment>
                ))}
            </tr>
            <tr>
                <td>Buy Now</td>
                {auctions.map((auction, i) => (
                    <React.Fragment key={i}>
                        <td className={i !== 0 ? 'old-price' : ''}>
                            {auction.buyNowPrice ? `£${auction.buyNowPrice.toLocaleString()}` : '-'}
                        </td>
                    </React.Fragment>
                ))}
            </tr>
            <tr>
                <td>Watchers</td>
                {auctions.map((auction, i) => (
                    <React.Fragment key={i}>
                        <td className={i !== 0 ? 'old-price' : ''}>{auction.noOfWatchers}</td>
                    </React.Fragment>
                ))}
            </tr>
            <tr>
                <td>Bids</td>
                {auctions.map((auction, i) => (
                    <React.Fragment key={i}>
                        <td className={i !== 0 ? 'old-price' : ''}>{auction.noOfBids}</td>
                    </React.Fragment>
                ))}
            </tr>
            <tr>
                <td>Closing Bid</td>
                {auctions.map((auction, i) => (
                    <React.Fragment key={i}>
                        <td className={i !== 0 ? 'old-price' : ''}>
                            {auction.currentBid ? `£${auction.currentBid.toLocaleString()}` : '-'}
                        </td>
                    </React.Fragment>
                ))}
            </tr>
        </React.Fragment>
    )
}

// function renderTableRow(header, value) {
//   return (
//     <tr>
//       <td>{header}</td>
//       {auctions.map((auction, i) => (
//         <React.Fragment key={i}>
//           <td className={i !== 0 ? "old-price" : ""}>{ISODateString(auction[dataItem], false, false, false)}</td>
//         </React.Fragment>
//       ))}
//     </tr>
//   );
// }

export function renderButtonContent(props) {
    let selectionMarkup = ''
    let itemName

    switch (props.dataType) {
        case 'sales':
            itemName = 'description'
            break
        default:
            itemName = 'name'
            break
    }
    if (props.criteria.length > 0 || Object.keys(props.criteria).length > 0) {
        let filteredData

        // this is for arrays of Option classes
        if (props.jsonType === 'option-list') {
            return props.data
                .filter((item) => props.criteria.indexOf(item.value) !== -1)
                .map((item, index) => {
                    return item.label
                })
                .join(', ')
        }

        if (props.inputType === 'rangeSelect') {
            if (props.criteria.min) {
                selectionMarkup = <div>Min: {props.criteria.min.label}</div>
            }

            if (props.criteria.max) {
                selectionMarkup = <div>Max: {props.criteria.max.label}</div>
            }

            if (props.criteria.min && props.criteria.min.label && props.criteria.max && props.criteria.max.label) {
                selectionMarkup = (
                    <div>
                        <div>Min: {props.criteria.min.label}</div>
                        <div>Max: {props.criteria.max.label}</div>
                    </div>
                )
            }
        } else {
            if (Array.isArray(props.data)) {
                filteredData = props.data
                    .filter((item) => props.criteria.indexOf(item.id) !== -1)
                    .map((item, index) => {
                        return item[itemName]
                    })
                    .join(', ')
            } else {
                if (props.dataType === 'models') {
                    filteredData = Object.keys(props.data)
                        .map((item, itemIndex) => {
                            return Object.keys(props.data[item])
                                .filter((subItem) => {
                                    return props.criteria.indexOf(subItem) >= 0
                                })
                                .map((item, index) => {
                                    return item
                                })
                        })
                        .filter(function (sub) {
                            return sub.length
                        })
                        .join(', ')
                } else if (props.dataType === 'modelVariants') {
                    const lowercaseCriteria = props.criteria.map((criteria) => criteria.toLowerCase())
                    filteredData = Object.keys(props.data)
                        .map((topTtem, itemIndex) => {
                            return Object.keys(props.data[topTtem])
                                .map((item) => {
                                    return Object.keys(props.data[topTtem][item])
                                        .filter((subItem) => {
                                            return lowercaseCriteria.indexOf(subItem.toLowerCase()) >= 0
                                        })
                                        .map((item, index) => {
                                            return item
                                        })
                                })
                                .filter(function (sub) {
                                    return sub.length
                                })
                        })
                        .filter(function (sub) {
                            return sub.length
                        })
                        .join(', ')
                } else if (props.dataType === 'sales') {
                    filteredData = Object.keys(props.data)
                        .map((item, itemIndex) => {
                            return Object.keys(props.data[item])
                                .filter((subItem) => {
                                    return props.criteria.indexOf(props.data[item][subItem].id) >= 0
                                })
                                .map((subCatItem, index) => {
                                    return (
                                        <span key={index} style={{ display: 'block', marginBottom: '5px' }}>
                                            {props.data[item][subCatItem].id === 99
                                                ? `${props.data[item][subCatItem].description}`
                                                : `${props.data[item][subCatItem].description} -
                      ${props.data[item][subCatItem].id} -
                      ${ISODateString(props.data[item][subCatItem].start, false, true)}`}
                                        </span>
                                    )
                                })
                        })
                        .filter(function (sub) {
                            return sub.length
                        })
                } else {
                    filteredData = Object.keys(props.data)
                        .filter((item) => props.criteria.indexOf(item) >= 0)
                        .map((item, index) => {
                            return item
                        })
                        .join(', ')
                }
            }
            selectionMarkup = filteredData.length > 0 ? filteredData : filteredData
        }
        return selectionMarkup
    }
}

export const Hover = ({ onHover, children }) => (
    <div className="c_hover">
        <div className="c_hover__no-hover">{children}</div>
        <div className="c_hover__hover">{onHover}</div>
    </div>
)

export function cardTypeLookup(cardType, virtual) {
    switch (cardType) {
        case 'e-hub':
            return 'e-Hub'
        case 'e-valuate':
            return 'e-Valuate'
        case 'e-xchange':
            return 'e-Xchange'
        case 'e-xchange-ehub':
            return 'e-Xchange'
        case 'archived':
            return 'Archived'
        case 'collections':
            return 'Collections'
        case 'catalogue':
            if (virtual) {
                return 'e-Live'
            }
            return 'Catalogue'
        case 'ab-sites':
            return 'Aston Barclay Sites'
        default:
            return ''
    }
}

export function isSecureBankTrust(cardType) {
    return CT_SECURE_BANK_TRUST_FUNDING === cardType
}

export function isVehicleFundingDeclined(fundingStatus) {
    return 'Declined' === fundingStatus
}

export function isVehicleFundingPended(fundingStatus) {
    return 'Pending' === fundingStatus
}

export function isVehicleFundingAvailable(fundingStatus) {
    return 'Available' === fundingStatus
}

export function isVehicleFundingIneligible(fundingStatus) {
    return 'Ineligible' === fundingStatus
}

export function isVehicleFundingAccepted(fundingStatus) {
    return 'Accepted' === fundingStatus
}

export function isVehicleAttributeIneligible(array, fieldName) {
    if (!array) return false

    const filterResult = array !== null && array.filter((data) => data.ineligibleField === fieldName).length > 0
    return filterResult
}

export function convertFuelText(fuel) {
    if (fuel === 'Diesel/Electric Hybrid') {
        return 'Hybrid D/E'
    }
    if (fuel === 'Petrol/Electric Hybrid') {
        return 'Hybrid P/E'
    }
    return fuel ? fuel : '-'
}

function notifyError(dispatch, failure, errorCode, errorMessage, data) {
    dispatch(
        addNotification({
            text: errorCode ? `Code: ${errorCode} - ${errorMessage}` : errorMessage,
            type: 'error',
        }),
    )
    dispatch(failure(errorMessage))
}

export function handleError(status, dispatch, data, failure) {
    let errorMessage = 'API failed'
    let errorCode = ''

    let source = data.errors ? (data.errors[0] ? ' - ' + data.errors[0].source : '') : ''
    switch (status) {
        case 400:
            if (data.errors && data.errors[0].detail.fieldErrors) {
                errorMessage = data.errors[0].detail.fieldErrors[0].message || 'Error message unavailable'
            } else {
                errorMessage = data.errors[0].detail || 'Error message unavailable'
            }

            errorCode = source

            notifyError(dispatch, failure, errorCode, errorMessage)
            break

        case 401:
            errorMessage =
                (data.errors && data.errors[0].detail) ||
                'Your session has expired. You are being redirected to the login page'

            notifyError(dispatch, failure, errorCode, errorMessage)

            // Redirect the user to the login page after 3 seconds.
            setTimeout(() => {
                dispatch(userRedirect(true))
            }, 3000)

            break

        case 403:
            errorMessage = 'You are unauthorised to make this request'
            errorCode = source

            notifyError(dispatch, failure, errorCode, errorMessage)
            break

        case 404:
            if (data.errors) {
                errorMessage = data.errors[0].detail || 'This api does not exist'
                errorCode = source

                notifyError(dispatch, failure, errorCode, errorMessage)
            }

            notifyError(dispatch, failure, errorCode, errorMessage)
            break

        case 500:
            errorMessage = data.errors[0].message || 'Error message unavailable'
            errorCode = source

            notifyError(dispatch, failure, errorCode, errorMessage)
            break

        default:
            if (data.errors) {
                errorMessage = data.errors[0].detail || 'Error message unavailable'
                errorCode = source

                notifyError(dispatch, failure, errorCode, errorMessage)
            }
    }
}

export const dispatchCall = (dispatch, fetchDetails, begin, success, failure) => {
    dispatch(begin)
    return fetch(fetchDetails.url, fetchDetails.headers)
        .then((response) => Promise.all([response.status, response.ok, response.json()]))
        .then(([status, responseOk, data]) => {
            if (responseOk) {
                dispatch(success(data))
                return data
            } else {
                handleError(status, dispatch, data, failure)
            }
        })
        .catch((error) => {
            dispatchNotification(dispatch, fetchDetails.url, 'error', failure(error))
        })
}

export const dispatchNotification = (dispatch, url, type, failure) => {
    dispatch(
        addNotification({
            text: `Failed api ${url}`,
            type: type,
        }),
    )
    dispatch(failure)
}

export function convertCommaSeparatedListToNumberArray(values) {
    let result = []

    values.split(',').forEach(function (value) {
        let parsedValue = parseInt(value)
        if (isNumber(parsedValue)) {
            result.push(parsedValue)
        }
    })
    return result
}

export function getFilteredAppraisals(appraisals, chosenLocation) {
    if (collectionAddressValid(chosenLocation)) {
        return appraisals.filter((appraisal) => {
            const { collectionAddress } = appraisal

            if (!collectionAddressValid(collectionAddress)) return false
            return (
                collectionAddress.company === chosenLocation.company &&
                collectionAddress.postcode === chosenLocation.postcode
            )
        })
    } else {
        return [...appraisals]
    }
}

export function getFilteredAppraisalGroup(appraisals, chosenLocation, allGroupsChecked, closedGroupsChecked) {
    const groupCheckFilter = (appraisal) => {
        const isAllGroup = appraisal.biddingDetails && !appraisal.biddingDetails.closedGroup
        const isClosedGroup = appraisal.biddingDetails && appraisal.biddingDetails.closedGroup
        return (allGroupsChecked && isAllGroup) || (closedGroupsChecked && isClosedGroup)
    }
    if (collectionAddressValid(chosenLocation)) {
        return appraisals
            .filter((appraisal) => {
                const { collectionAddress } = appraisal

                if (!collectionAddressValid(collectionAddress)) return false
                return (
                    collectionAddress.company === chosenLocation.company &&
                    collectionAddress.postcode === chosenLocation.postcode
                )
            })
            .filter(groupCheckFilter)
    } else {
        return [...appraisals].filter(groupCheckFilter)
    }
}

export function collectionAddressValid(collectionAddress) {
    return (
        collectionAddress &&
        !isEmpty(collectionAddress) &&
        isObject(collectionAddress) &&
        collectionAddress.company &&
        collectionAddress.postcode
    )
}

export function collectionAddressString(collectionAddress) {
    return [collectionAddress.company, collectionAddress.postcode]
        .filter((s) => !!s)
        .map((s) => s.trim())
        .join(', ')
}

export function accountCodeToLabel(accountCode, allAccountCodes) {
    if (!accountCode) return null

    let match = allAccountCodes.find((a) => a.value === accountCode)
    if (match) return match.label

    return null
}

export function accountCodeListToString(accountCodes) {
    if (!accountCodes) return ''
    return accountCodes.map((code) => code.value).join(',')
}

export function accountLabelListToString(accounts) {
    if (!accounts && accounts.length > 0) return ''
    return accounts.map((account) => account.label).join(', ')
}

export function accountValueListToString(accounts) {
    if (!accounts && accounts.length > 0) return ''
    return accounts.map((account) => account.value).join(',')
}

export function getAccountCodes(accountCodes, allAccountCodes) {
    if (!accountCodes || accountCodes.length === 0) {
        accountCodes = allAccountCodes
    }
    return accountCodes
}

export function addRequestParamAccountList(url, name, list) {
    if (!list || list.length === 0) return url
    if (!url.includes('?')) url += '?'
    else url += '&'
    list.forEach((e) => (url += `${name}=${e.value}&`))
    return url.replace(/&\s*$/, '')
}

export function addRequestParamAccount(url, name, account) {
    if (!account) return url
    url += `?${name}=${account}`
    return url
}

export function addRequestParamLabel(url, name, list) {
    if (!list || list.length === 0) return url
    if (!url.includes('?')) url += '?'
    else url += '&'
    list.forEach((e) => (url += `${name}=${e.label};${e.value}&`))
    url.replace(/&\s*$/, '')
    return encodeURI(url)
}

export function anyAccountsAreEhub(user, accounts) {
    if (!user || !accounts || accounts.length === 0) return true
    return accounts.some((account) => !user.accountsAccess[account.value].standalone)
}

export function setAppraisalFullAccount(appraisal, accounts) {
    if (!accounts) return appraisal
    let matchingName
    let comparitor = appraisal.vendorCode ? appraisal.vendorCode : appraisal.account ? appraisal.account : null
    accounts.forEach((account) => (account.value === comparitor ? (matchingName = account.label) : null))
    return {
        ...appraisal,
        fullAccountName: matchingName,
    }
}

export function setupAccounts(changeAccounts, accountCode, accountCodes, otherAccounts) {
    accountCodes = []
    accountCode.split(',').forEach((code) => {
        let account = otherAccounts.find((acc) => acc.value === code.trim())
        if (account) accountCodes.push(account)
    })
    if (accountCodes.length > 0) changeAccounts(accountCodes)
    return accountCodes
}

export function checkEnteredAccountCode(selectedAccountCodes, allAccountCodes) {
    return selectedAccountCodes.filter((sac) => allAccountCodes.includes(sac))
}
