import React, { Component } from 'react'
class MapContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contentVisible: null,
        }
    }

    onToggleOpen(value) {
        this.setState({
            contentVisible: this.state.contentVisible && this.state.contentVisible === value ? null : value,
        })
    }

    render() {
        return (
            <div className="ab__map-wrapper">
                <div className="ab__map-container">
                    <div className="map-marker map-marker__westbury map-marker__left">
                        <div className="map-box" datacount="2" onClick={() => this.onToggleOpen('west')}>
                            Westbury
                        </div>
                        <div className="map-shield"></div>
                        <div className="map-arrow"></div>
                        <div className={`map-more_content ${this.state.contentVisible === 'west' ? 'show' : ''}`}>
                            <div className="content-item">
                                Dealer sale - Lane 1<br />
                                09:00
                            </div>
                            <div className="content-item">
                                Dealer sale - Lane 1<br />
                                10:00
                            </div>
                        </div>
                        <div className="map-box" />
                    </div>
                    <div className="map-marker map-marker__chelmsford map-marker__right">
                        <div className="map-box" />
                        <div className="map-shield"></div>
                        <div className="map-arrow"></div>
                        <div className={`map-more_content ${this.state.contentVisible === 'chelm' ? 'show' : ''}`}>
                            <div className="content-item">
                                Dealer sale - Lane 1<br />
                                08:00
                            </div>
                            <div className="content-item">
                                Dealer sale - Lane 1<br />
                                09:00
                            </div>
                            <div className="content-item">
                                Dealer sale - Lane 1<br />
                                10:00
                            </div>
                            <div className="content-item">
                                Dealer sale - Lane 1<br />
                                11:00
                            </div>
                            <div className="content-item">
                                Dealer sale - Lane 1<br />
                                17:00
                            </div>
                        </div>
                        <div className="map-box" datacount="5" onClick={() => this.onToggleOpen('chelm')}>
                            Chelmsford
                        </div>
                    </div>

                    <div className="map-marker map-marker__leeds map-marker__left disabled">
                        <div className="map-box" datacount="0" onClick={() => this.onToggleOpen('leeds')}>
                            Leeds
                        </div>
                        <div className="map-shield"></div>
                        <div className="map-arrow"></div>
                        <div className="map-box" />
                    </div>
                    <div className="map-marker map-marker__donnington-park map-marker__right live">
                        <div className="map-box" />
                        <div className="map-shield"></div>
                        <div className="map-arrow"></div>
                        <div className={`map-more_content ${this.state.contentVisible === 'dp' ? 'show' : ''}`}>
                            <div className="content-item live">
                                Dealer sale - Lane 1<br />
                                09:00 - Live Now
                            </div>
                            <div className="content-item">
                                Dealer sale - Lane 1<br />
                                12:00
                            </div>
                            <div className="content-item">
                                Dealer sale - Lane 1<br />
                                14:00
                            </div>
                        </div>
                        <div className="map-box" datacount="3" onClick={() => this.onToggleOpen('dp')}>
                            Donnington Park
                        </div>
                    </div>
                    <div className="map-marker map-marker__press-heath map-marker__left">
                        <div className="map-box" datacount="1" onClick={() => this.onToggleOpen('ph')}>
                            Press Heath
                        </div>
                        <div className="map-shield"></div>
                        <div className="map-arrow"></div>
                        <div className={`map-more_content ${this.state.contentVisible === 'ph' ? 'show' : ''}`}>
                            <div className="content-item">
                                Dealer sale - Lane 1<br />
                                09:00
                            </div>
                        </div>
                        <div className="map-box" />
                    </div>
                    <div className="map-marker map-marker__wakefield map-marker__right live">
                        <div className="map-box">Wakefield</div>
                        <div className="map-shield"></div>
                        <div className="map-arrow"></div>
                        <div className={`map-more_content ${this.state.contentVisible === 'wf' ? 'show' : ''}`}>
                            <div className="content-item">
                                Dealer sale - Lane 1<br />
                                10:00
                            </div>
                            <div className="content-item live">
                                Dealer sale - Lane 1<br />
                                12:00 - Live Now
                            </div>
                        </div>
                        <div className="map-box" datacount="2" onClick={() => this.onToggleOpen('wf')}>
                            Wakefield
                        </div>
                    </div>
                    <div className="map-marker map-marker__digital map-marker__right">
                        <div className="map-box" />
                        <div className="map-shield"></div>
                        <div className="map-arrow"></div>
                        <div className={`map-more_content ${this.state.contentVisible === 'digital' ? 'show' : ''}`}>
                            <div className="content-item">
                                e-Xchange
                                <br />
                                View vehicles
                            </div>
                            <div className="content-item">
                                e-Live
                                <br />
                                View vehicles
                            </div>
                        </div>
                        <div className="map-box" datacount="2" onClick={() => this.onToggleOpen('digital')}>
                            Digital
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MapContainer
