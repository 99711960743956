import React, { Component } from 'react'
import { css } from '@emotion/react'
import { Col, Row } from 'reactstrap'
import { ScaleLoader } from 'react-spinners'
import SearchResults from '../../SearchResults'
import SideNavFilter from '../../../UiKit/Filter/SideNavFilter'
import Sorting from '../../../UiKit/Sorting'
import PageSize from '../../../UiKit/PageSize'
import Pagination from '../../Pagination'
import { APP_EHUB, CT_EXCHANGE } from '../../../constants'
import { setTitle } from '../../../themeConfig'

const override = css`
    display: block;
    margin: 0 auto;
`
class EXChangeList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            originalVehicles: [],
            activeFilter: 'active',
            activeCount: 0,
            endedCount: 0,
            totalVehicles: 0,
            primaryVendorCode: null,
            activeVehicles: [],
            endedVehicles: [],
        }
        this.selectedFilter = this.selectedFilter.bind(this)
        this.getVehicles = this.getVehicles.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        setTitle('e-Xchange List', this.props.user.theme)
        this.props.getExchangeVehicles({})
    }

    updateCount = (count) => {
        this.setState({ totalVehicles: count })
    }

    selectedFilter = (activeFilter) => {
        if (activeFilter === 'active') {
            this.setState({
                vehicles: this.state.originalVehicles.filter(
                    (item) =>
                        item.cardType === CT_EXCHANGE &&
                        ((item.biddingDetails && item.biddingDetails.userMaxBid > 0 && !item.biddingDetails.ended) ||
                            (item.watching && !item.biddingDetails.ended)),
                ),
            })
        }

        if (activeFilter === 'ended') {
            this.setState({
                vehicles: this.state.originalVehicles.filter(
                    (item) => item.cardType === CT_EXCHANGE && item.biddingDetails && item.biddingDetails.ended,
                ),
            })
        }
        this.setState({ activeFilter })
        this.props.setPaginationState({
            currentPage: 1,
            offsetStart: 0,
            offsetFinish: this.props.pagination.resultsPerPage.value,
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const activeVehicles = nextProps.vehicles.data.filter(
            (item) =>
                item.cardType === CT_EXCHANGE &&
                ((item.biddingDetails && item.biddingDetails.userMaxBid > 0 && !item.biddingDetails.ended) ||
                    (item.watching && !item.biddingDetails.ended)),
        )
        const endedVehicles = nextProps.vehicles.data.filter(
            (item) => item.cardType === CT_EXCHANGE && item.biddingDetails && item.biddingDetails.ended,
        )

        return {
            primaryVendorCode: nextProps.user.primaryVendorCode,
            originalVehicles: nextProps.vehicles.data,
            activeCount: activeVehicles.length,
            endedCount: endedVehicles.length,
            activeVehicles,
            endedVehicles,
        }
    }

    getVehicles() {
        const { activeVehicles, endedVehicles, activeFilter } = this.state
        switch (activeFilter) {
            case 'active':
                return activeVehicles
            case 'ended':
                return endedVehicles
            default:
                return []
        }
    }

    render() {
        const filters = [
            {
                id: 'active',
                displayText: 'Active',
                count: this.state.activeCount,
            },
            {
                id: 'ended',
                displayText: 'Ended (last 7 days)',
                count: this.state.endedCount,
            },
        ]
        const vehicles = this.getVehicles()
        return (
            <Row className="m-0 px-0 pt-3 pb-2">
                <Col md="3" lg="2" className="p-3 pl-md-0 pt-md-2 pr-md-0 pb-md-3 ab-results-left">
                    <SideNavFilter filters={filters} title="View" selectedFilter={this.selectedFilter} />
                </Col>
                <Col md="9" lg="10">
                    {!this.props.vehicles.loading && (
                        <Row className="ab-results-right">
                            <Col xs="12" md="3" className="ab-results-sort">
                                <Sorting
                                    sorting={this.props.sorting}
                                    options="catalogue"
                                    onChangeSortOrder={this.props.onChangeSortOrder}
                                    offsetFinish={this.props.pagination.resultsPerPage.value}
                                />
                            </Col>
                            <Col xs="12" md="6" className="d-flex justify-content-center">
                                <Pagination vehicles={vehicles} sortType={APP_EHUB} />
                            </Col>
                            <Col xs="12" md="3">
                                <PageSize
                                    vehicles={vehicles}
                                    pagination={this.props.pagination}
                                    setPageSize={this.props.setPageSize}
                                    theme={this.props.user.theme}
                                />
                            </Col>
                        </Row>
                    )}
                    <Row className="mx-0 my-0">
                        {this.props.vehicles.loading ? (
                            <Col className="px-2">
                                <div className="loading-wrapper">
                                    <ScaleLoader className={override} size={10} loading={true} />
                                </div>
                            </Col>
                        ) : (
                            <SearchResults
                                type={CT_EXCHANGE}
                                viewType={'grid'}
                                selectedFilter={this.state.activeFilter}
                                updateCount={this.updateCount}
                            />
                        )}
                    </Row>
                    {!this.props.vehicles.loading && (
                        <Row className="mx-0 mb-0 my-md-3 px-3 px-md-1 py-0">
                            <Col md="3" className="mb-3 mb-md-0 pl-md-0 px-0 ab-results-sort order-0 order-md-0" />
                            <Col md="6" className="mb-0 mb-md-0 px-0 d-flex justify-content-center order-2 order-md-1">
                                <Pagination vehicles={vehicles} />
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        )
    }
}

export default EXChangeList
