import React, { Component } from 'react'
import VehicleIcons from '../icons/VehicleIcons'
/**
 * Usage:
 *   <VehicleHeader />
 */
class VehicleHeader extends Component {
    render = () => {
        const { vehicle, theme } = this.props
        return (
            <React.Fragment>
                <div className="col-12 m-0 p-0 pt-1">
                    <span className="vehicle-card-header">
                        {vehicle.registration} - {vehicle.make.toUpperCase()}
                    </span>
                    {vehicle.model && (
                        <React.Fragment>
                            <br />
                            <span className="vc-model">{vehicle.model.toUpperCase()}</span>
                        </React.Fragment>
                    )}
                    {vehicle && <VehicleIcons vehicle={vehicle} vehicleCard={true} theme={theme} />}
                </div>
            </React.Fragment>
        )
    }
}

export default VehicleHeader
