import React, { Component } from 'react'
import ProgressBar from '../../../UiKit/ProgressBar'

class FundingCredit extends Component {
    dataPercentage(val, total) {
        return !val > 0 && !total > 0 ? 0.0 : val / total
    }

    render() {
        const { data } = this.props

        return (
            <React.Fragment>
                <div className="row mx-0 my-0 mx-md-1">
                    <div className="col text-left p-md-1">Funds</div>
                </div>
                <div className="row mx-0 my-0 mx-md-1">
                    {Array.isArray(data.items) &&
                        data.items.map((item, i) => {
                            const fillPercent = `${this.dataPercentage(item.amount, data.amount) * 100}%`
                            return (
                                <ProgressBar
                                    key={i}
                                    options={{
                                        class: item.colour,
                                        name: item.label,
                                        count: item.amount,
                                        hasIcon: false,
                                        enabled: true,
                                        fillPercent,
                                        format: 'currency',
                                        unclickable: true,
                                    }}
                                    theme={this.props.user.theme}
                                />
                            )
                        })}

                    {Array.isArray(data.items) && (
                        <ProgressBar
                            options={{
                                name: data.label,
                                count: data.amount,
                                hasIcon: false,
                                enabled: true,
                                fillPercent: false,
                                isTotal: true,
                                format: 'currency',
                            }}
                            theme={this.props.user.theme}
                        />
                    )}
                </div>
            </React.Fragment>
        )
    }
}

export default FundingCredit
