import {
    FETCH_PROFILE_LANGUAGE_DICTIONARY,
    FETCH_PROFILE_LANGUAGE_DICTIONARY_ERROR,
    FETCH_PROFILE_LANGUAGE_DICTIONARY_SUCCESS,
} from '../constants'
import { changeSortOrder } from './sorting'
import { addNotification } from './notifications'
import { changePageSize } from './pagination'

export const fetchProfileLanguageDictionaryBegin = () => ({
    type: FETCH_PROFILE_LANGUAGE_DICTIONARY,
})

export const fetchProfileLanguageDictionarySuccess = (language) => ({
    type: FETCH_PROFILE_LANGUAGE_DICTIONARY_SUCCESS,
    payload: language,
})

export const fetchProfileLanguageDictionaryFailure = (error) => ({
    type: FETCH_PROFILE_LANGUAGE_DICTIONARY_ERROR,
    payload: { error },
})

export function fetchProfileLanguage(themeId) {
    return async (dispatch) => {
        dispatch(fetchProfileLanguageDictionaryBegin())

        try {
            const translationPath = `../themeConfig/translations/${themeId}.json`

            const translationFiles = import.meta.glob(`../themeConfig/translations/*.json`, {
                as: 'raw',
            })

            const rawDictionary = await translationFiles[translationPath]()
            const languageDictionary = JSON.parse(rawDictionary)

            dispatch(fetchProfileLanguageDictionarySuccess(languageDictionary))

            // Migrated these from setTheme() in the user actions due to its dependency on the languageDictionary
            dispatch(
                changeSortOrder({
                    dataField: {
                        value: 'saleStart',
                        label: languageDictionary['SORT_OPTIONS_SALE_DATE'],
                    },
                    reverse: false,
                }),
            )

            dispatch(
                changePageSize({
                    resultsPerPage: {
                        value: 12,
                        label: `12 ${languageDictionary['CATALOGUE_PER_PAGE']}`,
                    },
                    currentPage: 1,
                    offsetStart: 0,
                    offsetFinish: 12,
                }),
            )
        } catch (error) {
            dispatch(
                addNotification({
                    text: `Failed to fetch language for ${themeId}`,
                    type: 'error',
                }),
            )

            dispatch(fetchProfileLanguageDictionaryFailure(error))
        }
    }
}
