import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Dashboard from './Dashboard'
import { fetchStats, onSearchVRM, changeAccounts } from '../../../actions/ehub'

const mapStateToProps = (state) => ({
    ehub: state.ehub,
    user: state.user,
})

const mapDispatchToProps = {
    fetchStats,
    onSearchVRM,
    changeAccounts,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard))
