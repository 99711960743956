import React, { Component } from 'react'
import { Col, Container, Input, Row, Tooltip } from 'reactstrap'
import { css } from '@emotion/react'
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle'
import { FaSearch } from '@react-icons/all-files/fa/FaSearch'
import { ScaleLoader } from 'react-spinners'

import { convertNameToLookup, lang, permissions } from '../../../themeConfig'

import ProgressBar from '../../../UiKit/ProgressBar'
import DealerSelector from '../../../UiKit/DealerSelector'
import {
    anyAccountsAreEhub,
    accountValueListToString,
    getAccountCodes,
    checkEnteredAccountCode,
} from '../../../utilities/Functions'

const override = css`
    display: block;
    margin: 0 auto;
`
const letters = /^[a-zA-Z0-9]*$/

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.dataType = 'keywords'
        this.defaultText = 'Search VRM'
        this.toggle = this.toggle.bind(this)
        this.keyPressed = this.keyPressed.bind(this)
        this.changeAccounts = this.changeAccounts.bind(this)
        this.state = {
            value: '',
            radius: '15.91549430918954',
            cx: '21',
            cy: '21',
            tooltipOpen: false,
        }
    }

    componentDidMount() {
        this.props.fetchStats(this.props.ehub.accounts, this.props.user.otherAccounts)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.user.accountName.value !== nextProps.user.accountName.value) {
            this.props.fetchStats(this.props.ehub.accounts, this.props.user.otherAccounts)
        }
    }

    dataPercentage(val, total) {
        return val / total
    }

    goTo(link) {
        this.props.history.push(link)
    }

    circumference() {
        return 2 * Math.PI * this.state.radius
    }

    toggle = (targetName) => {
        if (!this.state[targetName]) {
            this.setState({
                ...this.state,
                [targetName]: {
                    tooltipOpen: true,
                },
            })
        } else {
            this.setState({
                ...this.state,
                [targetName]: {
                    tooltipOpen: !this.state[targetName].tooltipOpen,
                },
            })
        }
    }

    isToolTipOpen = (targetName) => {
        return this.state[targetName] ? this.state[targetName].tooltipOpen : false
    }

    adjustedCircumference() {
        return this.circumference() - 0.5
    }

    calculateStrokeDashOffset(percentage) {
        const circumference = 2 * Math.PI * this.state.radius
        const strokeDiff = percentage * circumference
        return circumference - strokeDiff
    }

    returnCircleTransformValue(degrees) {
        return `rotate(${degrees}, ${this.state.cx}, ${this.state.cy})`
    }

    keyPressed = (event) => {
        if (event.key === 'Enter' && this.state.value !== '' && this.state.value.match(letters)) {
            this.props
                .onSearchVRM(this.state.value, getAccountCodes(this.props.ehub.accounts, this.props.user.otherAccounts))
                .then((res) => {
                    if (res) {
                        this.props.history.push(`/e-hub/${res}`)
                    }
                })
                .catch((err) => {})
        }
    }

    changeAccounts(accounts) {
        accounts = checkEnteredAccountCode(accounts, this.props.user.otherAccounts)
        this.props.changeAccounts(accounts)
        this.props.fetchStats(accounts, this.props.user.otherAccounts)
    }

    render() {
        const imgIconLookUp = {
            'e-valuate': '/img/e-valuate.png',
            'e-xchange': '/img/e-xchange.png',
            'aston barclay sites': '/img/auction.png',
            collections: '/img/collections.png',
        }

        let angleOffset = -90

        const accounts = getAccountCodes(
            this.props.ehub && this.props.ehub.accounts ? this.props.ehub.accounts : null,
            this.props.user && this.props.user.otherAccounts ? this.props.user.otherAccounts : null,
        )
        return (
            <React.Fragment>
                <Container fluid={true}>
                    <Container className="p-0">
                        <Row>
                            <Col>
                                <div className="e-hub__title">
                                    {lang('EHUB_DASHBOARD_TITLE', this.props.user.theme)}
                                </div>
                            </Col>
                            <Col xs="12" sm="6" md="4" lg="3">
                                <div className="e-hub__search">
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={this.state.value}
                                            aria-label={this.defaultText}
                                            placeholder={this.defaultText}
                                            onChange={(e) =>
                                                this.setState({
                                                    value: e.target.value,
                                                })
                                            }
                                            onKeyPress={(e) => this.keyPressed(e)}
                                        />
                                        <div className="input-group-append">
                                            <button
                                                className={`btn btn-outline ${
                                                    this.state.value !== '' ? '' : 'disabled'
                                                }`}
                                                type="button"
                                                id="button-addon2"
                                                onClick={() => {
                                                    if (this.state.value.match(letters)) {
                                                        this.props
                                                            .onSearchVRM(
                                                                this.state.value,
                                                                getAccountCodes(
                                                                    this.props.ehub.accounts,
                                                                    this.props.user.otherAccounts,
                                                                ),
                                                            )
                                                            .then((res) => {
                                                                if (res) {
                                                                    this.props.history.push(`/e-hub/${res}`)
                                                                }
                                                            })
                                                            .catch((err) => {})
                                                    }
                                                }}
                                            >
                                                <FaSearch color="#666" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {this.props.user.otherAccounts && this.props.user.otherAccounts.length > 1 && (
                                <Col xs="12" sm="6" md="4" lg="3">
                                    <DealerSelector
                                        dropdownOptions={this.props.user.otherAccounts}
                                        defaultValue={this.props.ehub.accounts}
                                        onChange={this.changeAccounts}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Container>
                </Container>
                {!Object.keys(this.props.ehub.stats).length || this.props.ehub.loading ? (
                    <Container fluid={true} className="e-hub__dashboard">
                        <Container className="e-hub__container p-0 pt-2">
                            {this.props.ehub.error ? (
                                <Row>
                                    <Col xs="12" lg={{ size: 10 }}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.ehub.error,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <Row>
                                    <Col xs="12" lg={{ size: 10, offset: 1 }}>
                                        <div className="loading-wrapper">
                                            <ScaleLoader
                                                className={override}
                                                size={10}
                                                color={'#F67419'}
                                                loading={this.props.ehub.loading}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Container>
                    </Container>
                ) : (
                    !(
                        !this.props.user.loading &&
                        this.props.user.loggedIn &&
                        (!this.props.user.eHubUser || !anyAccountsAreEhub(this.props.user, this.props.ehub.accounts))
                    ) && (
                        <Container fluid={true} className="e-hub__dashboard">
                            <Container className="e-hub__container p-0 pt-2">
                                <Row>
                                    <Col xs="12" lg={{ size: 10, offset: 1 }}>
                                        <h1>Active Vehicles</h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" lg={{ size: 10, offset: 1 }}>
                                        <div className="row">
                                            <div className="col-xs-12 col-lg-4">
                                                <div className="e-hub__section">
                                                    <h2>
                                                        {lang('EHUB_TOTAL_TEXT', this.props.user.theme)}
                                                        <FaInfoCircle data-toggle="tooltip" id="total-tooltip" />
                                                        <Tooltip
                                                            placement="top"
                                                            isOpen={this.isToolTipOpen('total-tooltip')}
                                                            target="total-tooltip"
                                                            toggle={() => this.toggle('total-tooltip')}
                                                        >
                                                            {lang('EHUB_TOTAL_TOOTLTIP_TEXT', this.props.user.theme)}
                                                        </Tooltip>
                                                    </h2>
                                                    <svg
                                                        width="100%"
                                                        height="100%"
                                                        viewBox="0 0 42 42"
                                                        className="circular-chart"
                                                    >
                                                        <circle
                                                            className="donut-hole"
                                                            cx={this.state.cx}
                                                            cy={this.state.cy}
                                                            r={this.state.radius}
                                                            fill="#fff"
                                                        />
                                                        <circle
                                                            className="donut-ring"
                                                            cx={this.state.cx}
                                                            cy={this.state.cy}
                                                            r={this.state.radius}
                                                            fill="transparent"
                                                            stroke="#fff"
                                                            strokeWidth="3"
                                                        />
                                                        {this.props.ehub.stats.total.items.map((item, i) => {
                                                            if (item.enabled) {
                                                                const percentage = this.dataPercentage(
                                                                    item.count,
                                                                    this.props.ehub.stats.total.count,
                                                                )
                                                                const circle = (
                                                                    <circle
                                                                        key={`chart-${i}`}
                                                                        className={` circle ${
                                                                            item.enabled ? item.class : 'disabled'
                                                                        }`}
                                                                        cx={this.state.cx}
                                                                        cy={this.state.cy}
                                                                        r={this.state.radius}
                                                                        fill="transparent"
                                                                        stroke="#ce4b99"
                                                                        strokeWidth="3"
                                                                        strokeDasharray={this.adjustedCircumference()}
                                                                        strokeDashoffset={this.calculateStrokeDashOffset(
                                                                            percentage,
                                                                        )}
                                                                        transform={this.returnCircleTransformValue(
                                                                            angleOffset,
                                                                        )}
                                                                    />
                                                                )
                                                                angleOffset = percentage * 360 + angleOffset
                                                                return circle
                                                            }
                                                            return null
                                                        })}
                                                        <text x="21" y="20" className="percentage">
                                                            {this.props.ehub.stats.total.count}
                                                        </text>
                                                        <text x="21" y="24" className="text">
                                                            {lang(`EHUB_CHART_VEHICLE_TEXT`, this.props.user.theme)}
                                                        </text>
                                                    </svg>
                                                    {this.props.ehub.stats.total.items.map((item, i) => {
                                                        if (
                                                            permissions(
                                                                `EHUB_HAS_${
                                                                    convertNameToLookup[item.name.toUpperCase()]
                                                                }`,
                                                                this.props.user.theme,
                                                            )
                                                        ) {
                                                            return (
                                                                <ProgressBar
                                                                    key={i}
                                                                    options={{
                                                                        class: item.class,
                                                                        name: item.name,
                                                                        count: item.count,
                                                                        hasIcon: true,
                                                                        enabled: item.enabled,
                                                                        fillPercent: false,
                                                                        format: 'number',
                                                                    }}
                                                                    theme={this.props.user.theme}
                                                                />
                                                            )
                                                        } else return null
                                                    })}
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-lg-8">
                                                <div className="row e-hub__row">
                                                    {this.props.ehub.stats.modules.map((module, i) => {
                                                        if (
                                                            permissions(
                                                                `EHUB_HAS_${
                                                                    convertNameToLookup[module.name.toUpperCase()]
                                                                }`,
                                                                this.props.user.theme,
                                                            )
                                                        ) {
                                                            return (
                                                                <div
                                                                    key={`panel-${i}`}
                                                                    className={`e-hub__panel col-xs-12 col-lg-6 ${
                                                                        !module.enabled ? ' disabled' : ''
                                                                    }`}
                                                                >
                                                                    <div className="e-hub__section e-hub__section--half">
                                                                        <h2
                                                                            className={
                                                                                permissions(
                                                                                    'EHUB_ICONS_ENABLED',
                                                                                    this.props.user.theme,
                                                                                )
                                                                                    ? 'has-image'
                                                                                    : ''
                                                                            }
                                                                        >
                                                                            {permissions(
                                                                                'EHUB_ICONS_ENABLED',
                                                                                this.props.user.theme,
                                                                            ) && (
                                                                                <img
                                                                                    src={
                                                                                        imgIconLookUp[
                                                                                            module.name.toLowerCase()
                                                                                        ]
                                                                                    }
                                                                                    alt={'E-Hub'}
                                                                                />
                                                                            )}
                                                                            {module.name}
                                                                            <FaInfoCircle
                                                                                id={`${module.name.replace(
                                                                                    / /g,
                                                                                    '_',
                                                                                )}-tooltip`}
                                                                            />
                                                                            <Tooltip
                                                                                placement="top"
                                                                                isOpen={this.isToolTipOpen(
                                                                                    `${module.name.replace(
                                                                                        / /g,
                                                                                        '_',
                                                                                    )}-tooltip`,
                                                                                )}
                                                                                target={`${module.name.replace(
                                                                                    / /g,
                                                                                    '_',
                                                                                )}-tooltip`}
                                                                                toggle={() =>
                                                                                    this.toggle(
                                                                                        `${module.name.replace(
                                                                                            / /g,
                                                                                            '_',
                                                                                        )}-tooltip`,
                                                                                    )
                                                                                }
                                                                            >
                                                                                {module.info}
                                                                            </Tooltip>
                                                                        </h2>
                                                                        {module.items.map((section, j) => {
                                                                            const fillPercent = module.enabled
                                                                                ? `${
                                                                                      this.dataPercentage(
                                                                                          section.count,
                                                                                          module.count,
                                                                                      ) * 100
                                                                                  }%`
                                                                                : false

                                                                            return (
                                                                                <div
                                                                                    key={`section-${j}`}
                                                                                    className={
                                                                                        section.count === 0
                                                                                            ? 'disabled'
                                                                                            : ''
                                                                                    }
                                                                                    onClick={() => {
                                                                                        this.goTo({
                                                                                            pathname: `e-hub/${accountValueListToString(
                                                                                                accounts,
                                                                                            )}/${module.path}/${
                                                                                                section.path
                                                                                            }`,
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    <ProgressBar
                                                                                        key={j}
                                                                                        options={{
                                                                                            class: module.class,
                                                                                            name: lang(
                                                                                                `EHUB_${
                                                                                                    convertNameToLookup[
                                                                                                        section.name.toUpperCase()
                                                                                                    ]
                                                                                                }_TEXT`,
                                                                                                this.props.user.theme,
                                                                                            ),
                                                                                            count: section.count,
                                                                                            enabled: module.enabled,
                                                                                            fillPercent,
                                                                                            format: 'number',
                                                                                            excludedFromTotalText:
                                                                                                section.excludedFromTotal,
                                                                                        }}
                                                                                        theme={this.props.user.theme}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        })}
                                                                        <ProgressBar
                                                                            options={{
                                                                                name: lang(
                                                                                    'EHUB_TOTAL_TEXT',
                                                                                    this.props.user.theme,
                                                                                ),
                                                                                count: module.count,
                                                                                enabled: true,
                                                                                fillPercent: false,
                                                                                format: 'number',
                                                                                isTotal: true,
                                                                            }}
                                                                            theme={this.props.user.theme}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        } else return null
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" lg={{ size: 10, offset: 1 }} className="text-right py-3">
                                        <div
                                            className="link"
                                            onClick={() => {
                                                this.goTo({
                                                    pathname: `/e-hub/${accountValueListToString(accounts)}/archive`,
                                                })
                                            }}
                                        >
                                            {lang('EHUB_ARCHIVE_BUTTON_TEXT', this.props.user.theme)}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    )
                )}
            </React.Fragment>
        )
    }
}

export default Dashboard
