import React from 'react'
import { TabPane } from 'reactstrap'
import SubItem from './SubItem'

export default function TabItems(props) {
    if (props.dataType === 'modelVariants') {
        return Object.keys(props.data).map((item, itemIndex) => {
            return Object.keys(props.data[item]).map((subItem, subItemIndex) => {
                return (
                    <TabPane
                        tabId={itemIndex + '-' + subItemIndex}
                        className={props.activeTab === itemIndex + '-' + subItemIndex ? 'fade show active' : 'fade'}
                        key={itemIndex + '-' + subItemIndex}
                    >
                        <div className="row px-3">
                            <SubItem
                                criteria={props.criteria}
                                data={props.data[item]}
                                dataType={props.dataType}
                                item={subItem}
                                handleOnSetCriteria={props.handleOnSetCriteria}
                                key={subItemIndex}
                                inputType={props.inputType}
                                offsetFinish={props.offsetFinish}
                            />
                        </div>
                    </TabPane>
                )
            })
        })
    }
    return Object.keys(props.data).map((item, index, arr) => {
        return (
            <TabPane
                key={index}
                tabId={index}
                className={props.activeTab === index + '-' + index ? 'fade show active' : 'fade'}
            >
                <div className="row px-3" key={index}>
                    <SubItem
                        criteria={props.criteria}
                        data={props.data}
                        dataType={props.dataType}
                        item={item}
                        handleOnSetCriteria={props.handleOnSetCriteria}
                        inputType={props.inputType}
                        offsetFinish={props.offsetFinish}
                    />
                </div>
            </TabPane>
        )
    })
}
