import React, { PureComponent } from 'react'
import { hasTradeAccess } from '../../utilities/Functions'
import NotSignedInBanner from './NotSignedInBanner'
import { getReportDownloadLink } from './VehicleDetailsHelper'
import { lang } from '../../themeConfig'
import {
    getInspectionBodyPlan,
    getInspectionCN,
    getInspectionColour,
    getInspectionDate,
    getInspectionDateOfFirstRegistration,
    getInspectionEngineNumber,
    getInspectionEngineSize,
    getInspectionExteriorGrade,
    getInspectionFuel,
    getInspectionGearBox,
    getInspectionInternalColourTrim,
    getInspectionInternalGrade,
    getInspectionKeys,
    getInspectionMake,
    getInspectionMileage,
    getInspectionModel,
    getInspectionPaintType,
    getInspectionRegistration,
    getInspectionSeats,
    getInspectionTyresNSF,
    getInspectionTyresNSR,
    getInspectionTyresOSF,
    getInspectionTyresOSR,
    getInspectionTyresSpare,
    VehicleStatsRow,
} from './VehicleHelper'

export default class InspectionReport extends PureComponent {
    render() {
        const { vehicle, user } = this.props

        if (!hasTradeAccess(user)) {
            return <NotSignedInBanner />
        }

        return (
            <>
                {getReportDownloadLink(vehicle, user.theme)}
                <div className="row m-0 p-0">
                    <div className="col-12 col-md-6 m-0 p-0 pr-md-2">
                        <h3 className="m-0 pt-3 pr-1 pb-0 pl-1">{lang('LABEL_GENERAL', user.theme)}</h3>
                        <table className="table m-0 p-0">
                            <tbody>
                                <VehicleStatsRow
                                    label={lang('LABEL_INSPECTION_DATE', user.theme)}
                                    value={getInspectionDate(vehicle)}
                                />
                                <VehicleStatsRow
                                    label={lang('LABEL_REG', user.theme)}
                                    value={getInspectionRegistration(vehicle)}
                                />
                                <VehicleStatsRow
                                    label={lang('LABEL_CN', user.theme)}
                                    value={getInspectionCN(vehicle)}
                                />
                                <VehicleStatsRow label="Exterior grade" value={getInspectionExteriorGrade(vehicle)} />
                                <VehicleStatsRow label="Internal grade" value={getInspectionInternalGrade(vehicle)} />
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row m-0 p-0">
                    <div className="col-12 col-md-6 m-0 p-0 pr-md-2">
                        <h3 className="m-0 pt-3 pt-md-4 pr-1 pb-0 pl-1">
                            {lang('LABEL_VEHICLE_OVERVIEW', user.theme)}
                        </h3>
                        <table className="table m-0 p-0">
                            <tbody>
                                <VehicleStatsRow
                                    label={lang('LABEL_MAKE', user.theme)}
                                    value={getInspectionMake(vehicle)}
                                />
                                <VehicleStatsRow
                                    label={lang('LABEL_MODEL', user.theme)}
                                    value={getInspectionModel(vehicle)}
                                />
                                <VehicleStatsRow
                                    label={lang('LABEL_ENGINE_SIZE', user.theme)}
                                    value={getInspectionEngineSize(vehicle)}
                                />
                                <VehicleStatsRow
                                    label={lang('LABEL_ENGINE_NUMBER', user.theme)}
                                    value={getInspectionEngineNumber(vehicle)}
                                />
                                <VehicleStatsRow
                                    label={lang('LABEL_BODY_PLAN', user.theme)}
                                    value={getInspectionBodyPlan(vehicle)}
                                />
                                <VehicleStatsRow
                                    label={lang('LABEL_FUEL', user.theme)}
                                    value={getInspectionFuel(vehicle)}
                                />
                                <VehicleStatsRow
                                    label={lang('LABEL_GEARBOX', user.theme)}
                                    value={getInspectionGearBox(vehicle)}
                                />
                            </tbody>
                        </table>
                    </div>
                    <div className="col-12 col-md-6 m-0 p-0 pl-md-2">
                        <h3 className="m-0 pt-3 pt-md-4 pr-1 pb-0 pl-1">&nbsp;</h3>
                        <table className="table m-0 p-0">
                            <tbody>
                                <VehicleStatsRow
                                    label={lang('LABEL_MILEAGE', user.theme)}
                                    value={getInspectionMileage(vehicle)}
                                />
                                <VehicleStatsRow
                                    label={lang('LABEL_COLOUR', user.theme)}
                                    value={getInspectionColour(vehicle)}
                                />
                                <VehicleStatsRow
                                    label={lang('LABEL_PAINT_TYPE', user.theme)}
                                    value={getInspectionPaintType(vehicle)}
                                />
                                <VehicleStatsRow
                                    label={lang('LABEL_INT_COLOUR_TRIM', user.theme)}
                                    value={getInspectionInternalColourTrim(vehicle)}
                                />
                                <VehicleStatsRow
                                    label={lang('LABEL_SEATS', user.theme)}
                                    value={getInspectionSeats(vehicle)}
                                />
                                <VehicleStatsRow
                                    label={lang('LABEL_DATE_FIRST_REG', user.theme)}
                                    value={getInspectionDateOfFirstRegistration(vehicle)}
                                />
                                <VehicleStatsRow
                                    label={lang('LABEL_KEYS', user.theme)}
                                    value={getInspectionKeys(vehicle)}
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row m-0 p-0">
                    <div className="col-12 col-md-12 m-0 p-0 pr-md-2">
                        <h3 className="m-0 pt-3 pt-md-4 pr-1 pb-0 pl-1">{lang('LABEL_TYRES', user.theme)}</h3>
                        <table className="table m-0 p-0">
                            <thead>
                                <tr>
                                    <th>{lang('LABEL_LOCATION')}</th>
                                    <th>{lang('LABEL_MANUFACTURERS', user.theme)}</th>
                                    <th>{lang('LABEL_DEPTH', user.theme)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{lang('LABEL_NSF', user.theme)}</td>
                                    <td />
                                    <td>{getInspectionTyresNSF(vehicle)}</td>
                                </tr>
                                <tr>
                                    <td>{lang('LABEL_OSF', user.theme)}</td>
                                    <td />
                                    <td>{getInspectionTyresNSR(vehicle)}</td>
                                </tr>
                                <tr>
                                    <td>{lang('LABEL_OSR', user.theme)}</td>
                                    <td />
                                    <td>{getInspectionTyresOSR(vehicle)}</td>
                                </tr>
                                <tr>
                                    <td>{lang('LABEL_NSR', user.theme)}</td>
                                    <td />
                                    <td>{getInspectionTyresOSF(vehicle)}</td>
                                </tr>
                                <tr>
                                    <td>{lang('LABEL_SPARE', user.theme)}</td>
                                    <td />
                                    <td>{getInspectionTyresSpare(vehicle)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}
