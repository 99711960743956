import { eXchangeEhubOptions, standardEhubOptions } from '../../../../utilities/config'

/**
 * For e-Hub, determine which sorting order we choose based on which part of e-Hub the user
 * selects and which category (e.g. new, sold, unsold, etc)
 * For the URL navigated, e.g. http://localhost:3000/e-hub/AST003/e-valuate/new
 * @param path      will be `e-valuate` (or whatever text appears in that part of the URL)
 * @param subpath   will be `new` (or whatever text appears in that part of the URL)
 * @returns {{label: string, value: string, reverse: boolean}}
 *                  label and value are the values that get put inside the selector
 *                  reverse decides the order:
 *                      true -> newest comes first
 *                      false
 */
export function getSortOrderProp(path, subpath) {
    if (path === 'e-valuate') {
        if (subpath === 'new' || subpath === 'in-stock') {
            return standardEhubOptions.find((o) => o.value === 'appraisalDate')
        }
    } else if (path === 'e-xchange') {
        if (subpath === 'sold' || subpath === 'unsold') {
            return { ...eXchangeEhubOptions.find((o) => o.value === 'biddingDetails.endDate'), reverse: true }
        } else {
            return eXchangeEhubOptions.find((o) => o.value === 'biddingDetails.endDate')
        }
    } else if (path === 'collections') {
        return standardEhubOptions.find((o) => o.value === 'appraisalDate')
    }
    return standardEhubOptions[0]
}
