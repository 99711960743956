import { FETCH_NAVIGATION, FETCH_NAVIGATION_SUCCESS, FETCH_NAVIGATION_ERROR } from '../constants'

const initialState = {
    loading: false,
    data: {},
    error: null,
}

const NavigationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NAVIGATION:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case FETCH_NAVIGATION_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
            }
        case FETCH_NAVIGATION_ERROR:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload.error,
            }
        default:
            return state
    }
}

export default NavigationReducer
