import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setTotalPages, getExchangeVehicles } from '../../../actions/vehicles'
import { setPageSize, setPaginationState } from '../../../actions/pagination'
import { onChangeSortOrder } from '../../../actions/sorting'

import EXChangeList from './EXChangeList'

const mapStateToProps = (state) => ({
    user: state.user,
    vehicles: state.vehicles,
    pagination: state.pagination,
    sorting: state.sorting,
})

const mapDispatchToProps = {
    getExchangeVehicles,
    setTotalPages,
    setPageSize,
    onChangeSortOrder,
    setPaginationState,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EXChangeList))
