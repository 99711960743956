import React, { Component } from 'react'
import { Button, Col, Container, Dropdown, DropdownMenu, DropdownToggle, NavItem, NavLink, Row } from 'reactstrap'
import { css } from '@emotion/react'
import { ScaleLoader } from 'react-spinners'
import { LinkContainer } from 'react-router-bootstrap'

import {
    APP_CATALOGUE,
    APP_EHUB,
    MY_ACCOUNT_CHANGE_PASSWORD_PATH,
    MY_ACCOUNT_PATH,
    MY_ACCOUNT_PROFILE_PATH,
} from '../../constants'

import { permissions } from '../../themeConfig'

import { getReactiveMenuHeader } from './HeaderUtils'

import { FaPlus } from '@react-icons/all-files/fa/FaPlus'
import { FaMinus } from '@react-icons/all-files/fa/FaMinus'
import { FaSearch } from '@react-icons/all-files/fa/FaSearch'

const override = css`
    display: block;
    margin: 0 auto;
`
class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            icon: 'sheild-green.png',
            iconurl: `${this.props.global.catalogueHost}/vehicle-search`,
            navType: APP_CATALOGUE,
            dropdownStatus: {},
            menuData: {},
            myAccountStatus: false,
            isMobile: window.matchMedia('only screen and (max-width: 800px)').matches,
            isTouch: window.matchMedia('(pointer:coarse)').matches,
            isDesktop: window.matchMedia('(min-width: 960px)').matches,
        }

        this.toggleNavbar = this.toggleNavbar.bind(this)
        this.closeMenu = this.closeMenu.bind(this)
        this.getExtraCss = this.getExtraCss.bind(this)
        this.getEhubNavItem = this.getEhubNavItem.bind(this)
        this.getMyAccountNavItem = this.getMyAccountNavItem.bind(this)
        this.getMyLogOutNavItem = this.getMyLogOutNavItem.bind(this)
        this.getRegisterNavItem = this.getRegisterNavItem.bind(this)
        this.getLoginNavItem = this.getLoginNavItem.bind(this)
        this.getAuctionNavItem = this.getAuctionNavItem.bind(this)

        this.showDropdown = this.showDropdown.bind(this)
        this.hideDropdown = this.hideDropdown.bind(this)
        this.clickedDropdown = this.clickedDropdown.bind(this)
        this.toggleDropdown = this.toggleDropdown.bind(this)

        this.showMyAccountDropdown = this.showMyAccountDropdown.bind(this)
        this.hideMyAccountDropdown = this.hideMyAccountDropdown.bind(this)

        this.getFullHeader = this.getFullHeader.bind(this)
        this.getHeaderLogo = this.getHeaderLogo.bind(this)
        this.accountOptionsMobile = this.accountOptionsMobile.bind(this)

        this.isClosedGroup = permissions('CLOSED_GROUP')

        this.reactiveHeader = (children) =>
            getReactiveMenuHeader(
                children,
                this.toggleNavbar,
                this.getExtraCss,
                this.getHeaderLogo,
                this.state.isMobile,
                this.state.isOpen,
                this.isClosedGroup,
                this.props.theme,
                this.accountOptionsMobile(), // Calling this function returns a React element which fixes an issue
            )

        this.navigateToMyAccount = this.navigateToMyAccount.bind(this)
    }

    toggleNavbar() {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    isCatalogueNavType() {
        return this.props.location.pathname.indexOf(APP_EHUB) === -1
    }

    isEhubApplication() {
        return this.props.location && this.props.location.pathname.indexOf(APP_EHUB) >= 0
    }

    setFavicon() {
        let link = document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]')

        if (!link) {
            link = document.createElement('link')
            link.id = 'favicon'
            link.rel = 'shortcut icon'
            document.head.appendChild(link)
        }

        if (this.isEhubApplication() && this.props.global.ehubFavicon) {
            link.href = `/img/${this.props.global.ehubFavicon}`
        } else if (this.props.global.favicon) {
            link.href = `/img/${this.props.global.favicon}`
        }
    }

    setHeaderState() {
        if (this.isCatalogueNavType()) {
            this.setState({
                icon: 'sheild-orange.png',
                iconurl: `${this.props.global.eHubHost}/e-hub`,
                navType: APP_CATALOGUE,
            })
        } else {
            this.setState({
                isOpen: false,
                icon: 'sheild-green.png',
                iconurl: `${this.props.global.catalogueHost}${
                    this.props.theme === 'default'
                        ? '/vehicle-search'
                        : this.props.user.loggedIn
                          ? '/vehicle-search'
                          : '/login'
                }`,
                navType: APP_EHUB,
            })
        }
    }

    hasApplicationChanged(nextProps) {
        return this.props.location.pathname.split(/[/]/)[1] !== nextProps.location.pathname.split(/[/]/)[1]
    }

    componentDidMount() {
        this.setFavicon()
        this.setHeaderState()
        window.addEventListener('resize', this.closeMenu)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setFavicon()
        if (this.hasApplicationChanged(nextProps)) {
            this.setHeaderState()
        }
        this.props.updateUrl(window.location.href)
        this.setState({ menuData: this.getMenuData(nextProps) })
    }

    closeMenu() {
        this.setState({
            isMobile: window.matchMedia('only screen and (max-width: 959px)').matches,
            isTouch: window.matchMedia('(pointer:coarse)').matches,
            isDesktop: window.matchMedia('(min-width: 960px)').matches,
            isOpen: false,
        })
    }

    getMenuData(props) {
        const menuData = props.navigation.data.catalogue
        if (menuData && Object.keys(this.state.dropdownStatus).length === 0) {
            let dropdownStatus = {}
            const keys = Object.keys(menuData)
            keys.forEach((m) => {
                if (menuData[m].children) {
                    dropdownStatus[m] = false
                }
            })
            this.setState({ dropdownStatus })
        }
        return menuData || {}
    }

    getVehicleStats() {
        return this.props.navigation.data.vehicleStats || {}
    }

    getHeaderLogoShield() {
        //Assets in /public/img/ dir become available at /img/
        return <img className="logo-shield" src={`/img/${this.props.global.logo}`} alt={this.props.global.logoAlt} />
    }

    getHeaderLogo() {
        let homeLink
        if (this.props.theme === 'default') {
            homeLink = `${this.props.global.frontMediaHost}/`
        } else {
            if (this.isEhubApplication()) {
                homeLink = '/e-hub'
            } else {
                homeLink = '/vehicle-search'
            }
        }

        if (homeLink && homeLink.endsWith('//')) {
            homeLink = homeLink.substring(0, homeLink.length - 1)
        }

        return !(this.state.isMobile || this.isClosedGroup) ? (
            <a href={homeLink} />
        ) : (
            <a href={homeLink}>{this.getHeaderLogoShield()}</a>
        )
    }

    getExtraCss() {
        const devMode = process.env.NODE_ENV === 'development'
        if (devMode) {
            return '__dev-mode'
        }
        return ''
    }

    getEhubNavItem(navigatedToEhub) {
        if (!permissions('EHUB_SHOW_LABEL')) {
            return
        }
        if (navigatedToEhub) {
            return
        }
        return (
            <a
                className="acc_button"
                onClick={() =>
                    window.location.assign(
                        `${
                            this.props.user.loggedIn
                                ? `${this.props.global.eHubHost}/e-hub`
                                : `${this.props.global.userLoginUrl}`
                        }`,
                    )
                }
            >
                e-Hub
            </a>
        )
    }

    getMyAccountNavItem(user) {
        if (!user.loggedIn) {
            return null
        }
        return (
            <a
                className="acc_button_alt"
                // onClick={() => window.location.assign(`${this.props.global.eHubHost}/e-hub`)}
                href={`${this.props.global.frontMediaHost}/my-account`}
            >
                My Account
            </a>
        )
    }

    getMyLogOutNavItem(user) {
        if (!user.loggedIn) {
            return null
        }

        return (
            <a className="acc_button_alt_last" href={this.props.global.userLogoutUrl}>
                Logout
            </a>
        )
    }

    navigateToMyAccount() {
        const { isTouch, isMobile, isDesktop } = this.state
        const my_account_url =
            this.props.global.theme === 'default'
                ? `${this.props.global.frontMediaHost}${MY_ACCOUNT_PATH}`
                : MY_ACCOUNT_PATH
        if (!isMobile && !isTouch) {
            window.location.href = my_account_url
        } else if (isTouch && isDesktop) {
            this.setState({ myAccountStatus: !this.state.myAccountStatus })
        } else {
            window.location.href = my_account_url
        }
    }

    showMyAccountDropdown() {
        this.setState({ myAccountStatus: true })
    }

    hideMyAccountDropdown() {
        this.setState({ myAccountStatus: false })
    }

    getRegisterNavItem(user) {
        if (user.loggedIn) {
            return null
        }
        if (!permissions('SHOW_REGISTER_LINK')) {
            return null
        }
        return (
            <a
                className="acc_button_alt_last"
                onClick={() => window.location.assign(`${this.props.global.frontMediaHost}/trade-application`)}
            >
                Register
            </a>
        )
    }

    accountOptionsMobile = () => {
        const navigatedToEhub = this.props.location.pathname.startsWith('/e-hub')

        const user = this.props.user

        const ehubNavItem = this.getEhubNavItem(navigatedToEhub)
        const myAccountNavItem = this.getMyAccountNavItem(user)
        const registerNavItem = this.getRegisterNavItem(user)
        const loginNavItem = this.getLoginNavItem(user)
        const auctionNavItem = this.getAuctionNavItem(navigatedToEhub)
        const logOutNavItem = this.getMyLogOutNavItem(user)

        return (
            this.state.isMobile && (
                <div className="accountOptions_mv ">
                    {[ehubNavItem, myAccountNavItem, auctionNavItem, loginNavItem, registerNavItem, logOutNavItem]
                        .map((component, index) => <NavItem key={`${index} + ${index + 1}`}>{component}</NavItem>)
                        .sort()}
                </div>
            )
        )
    }

    getLoginNavItem(user) {
        if (user.loggedIn) {
            return null
        }
        if (this.props.location.pathname === '/login') {
            return null
        }
        let loginUrl = this.props.global.userLoginUrl || '/login'

        const onClick = () => {
            window.location.assign(loginUrl)
        }

        return this.isClosedGroup ? (
            <Button className="ab__button-round--small ab__button-round--small__alt" onClick={onClick}>
                LOGIN
            </Button>
        ) : (
            <a className="acc_button_alt" onClick={onClick}>
                Login
            </a>
        )
    }

    getAuctionNavItem(navigatedToEhub) {
        if (!navigatedToEhub) {
            return null
        }
        return (
            <a
                className="acc_button"
                // onClick={() => window.location.assign(`${this.props.global.catalogueHost}/vehicle-search`)}
                href={`${this.props.global.catalogueHost}/vehicle-search`}
            >
                AUCTION
            </a>
        )
    }

    showDropdown(key) {
        let dropdownStatus = this.state.dropdownStatus
        dropdownStatus[key] = true
        this.setState({ dropdownStatus })
    }

    hideDropdown(key) {
        let dropdownStatus = this.state.dropdownStatus
        dropdownStatus[key] = false
        this.setState({ dropdownStatus })
    }

    clickedDropdown(link, forced = false) {
        if (typeof window.screen.orientation !== 'undefined' || forced) {
            window.location.href = link.url
        }
    }

    toggleDropdown(key) {
        let dropdownStatus = this.state.dropdownStatus
        dropdownStatus[key] = !dropdownStatus[key]
        this.setState({ dropdownStatus })
    }

    renderNewPages(loading, menuData, vehicleStats, user, location) {
        const navigatedToEhub = location.pathname.startsWith('/e-hub')

        const ehubNavItem = this.getEhubNavItem(navigatedToEhub)
        const myAccountNavItem = this.getMyAccountNavItem(user)
        const registerNavItem = this.getRegisterNavItem(user)
        const loginNavItem = this.getLoginNavItem(user)
        const auctionNavItem = this.getAuctionNavItem(navigatedToEhub)
        const logOutNavItem = this.getMyLogOutNavItem(user)

        const fmHost = this.props.global.frontMediaHost
        const closedGroupMenuData = {
            'MY ACCOUNT': {
                url: `${fmHost}/my-account`,
                children: {
                    'MY PROFILE': {
                        url: `${this.props.global.frontMediaHost}${MY_ACCOUNT_PROFILE_PATH}`,
                    },
                    'CHANGE PASSWORD': {
                        url: `${this.props.global.frontMediaHost}${MY_ACCOUNT_CHANGE_PASSWORD_PATH}`,
                    },
                    LOGOUT: {
                        url: this.props.global.userLogoutUrl,
                    },
                },
            },
        }
        if (this.isClosedGroup && user.loggedIn) {
            menuData = {
                ...menuData,
                ...closedGroupMenuData,
            }
        }

        const menudataKeys = Object.keys(menuData)

        const { isMobile } = this.state

        const headerType = permissions('HEADER_TYPE')

        switch (headerType) {
            case 'loginonly':
                return this.reactiveHeader([
                    ehubNavItem,
                    auctionNavItem,
                    registerNavItem,
                    loginNavItem,
                    user.loggedIn && this.mapMenuData(closedGroupMenuData, Object.keys(closedGroupMenuData)),
                ])

            default:
                return this.getFullHeader(
                    isMobile,
                    menudataKeys,
                    loading,
                    menuData,
                    navigatedToEhub,
                    ehubNavItem,
                    auctionNavItem,
                    myAccountNavItem,
                    registerNavItem,
                    loginNavItem,
                    logOutNavItem,
                    vehicleStats,
                    user,
                )
        }
    }

    getFullHeader(
        isMobile,
        menudataKeys,
        loading,
        menuData,
        navigatedToEhub,
        ehubNavItem,
        auctionNavItem,
        myAccountNavItem,
        logOutNavItem,
        registerNavItem,
        loginNavItem,
        vehicleStats,
        user,
    ) {
        const extras = (
            <>
                <div className="ab-total-bar">
                    <Container>
                        <Row>
                            <Col xs="12">
                                <div className="ab_vehicle__totals--wrapper">
                                    {permissions('CATALOGUE_VEHICLE_STATS_ENABLED') && (
                                        <React.Fragment>
                                            <div className="ab-vehicle__total">
                                                <div className="ab-vehicle__total--number ab-green">
                                                    {loading ? 'Loading...' : vehicleStats.totalPhysical}
                                                </div>
                                                <div className="ab-vehicle__total--text">Physical vehicles</div>
                                                <div className="ab-vehicle__total--icon ab-green">
                                                    <a
                                                        href={`${this.props.global.catalogueHost}/vehicle-search?sites=${vehicleStats.physicalSiteIds}`}
                                                    >
                                                        <FaSearch />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="ab-vehicle__total">
                                                <div className="ab-vehicle__total--number ab-orange">
                                                    {loading ? 'Loading...' : vehicleStats.totalDigital}
                                                </div>
                                                <div className="ab-vehicle__total--text">Digital vehicles</div>
                                                <div className="ab-vehicle__total--icon ab-orange">
                                                    <a
                                                        href={`${this.props.global.catalogueHost}/vehicle-search?sites=99`}
                                                    >
                                                        <FaSearch />
                                                    </a>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                    {permissions('CATALOGUE_EHUB_ICON_LINK_ENABLED') && (
                                        <div className="ab-vehicle__total right">
                                            <div className="ab-vehicle__total--icon ab-orange">
                                                <a
                                                    className="nav-link nav-link-icon"
                                                    href={this.state.iconurl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img src={`/img/${this.state.icon}`} alt="Catalogue" width="20px" />
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )

        const AccountOptions = () => {
            return (
                !(this.state.isMobile || (this.isClosedGroup && user.loggedIn)) && (
                    <div className="accountOptions">
                        {ehubNavItem && <NavItem>{ehubNavItem}</NavItem>}
                        {auctionNavItem && <NavItem>{auctionNavItem}</NavItem>}
                        {myAccountNavItem && <NavItem>{myAccountNavItem}</NavItem>}
                        {loginNavItem && <NavItem>{loginNavItem}</NavItem>}
                        {registerNavItem && <NavItem>{registerNavItem}</NavItem>}
                        {logOutNavItem && <NavItem>{logOutNavItem}</NavItem>}
                    </div>
                )
            )
        }

        const menuOrLoader =
            menudataKeys.length > 0 && !loading ? (
                this.mapMenuData(menuData, menudataKeys, navigatedToEhub, isMobile)
            ) : (
                <div className="loading-wrapper-small">
                    <ScaleLoader className={override} size={5} loading={true} key="loader" />
                </div>
            )

        const reactiveHeader = this.reactiveHeader([menuOrLoader, <AccountOptions key="accountOptions" />], extras)

        return reactiveHeader
    }

    mapMenuData(menuData, menudataKeys, navigatedToEhub, isMobile) {
        return (
            <React.Fragment key="mapMenuData">
                {menudataKeys.map((key, index) => {
                    const link = menuData[key]
                    if (key === 'E-HUB' && navigatedToEhub) {
                        return null
                    }
                    if (link.children) {
                        let dropdownProps = {}
                        let dropdownToggleProps = {}
                        dropdownProps.isOpen = this.state.dropdownStatus[key]
                        dropdownProps.toggle = () => {}
                        if (!isMobile) {
                            dropdownProps.onMouseOver = () => this.showDropdown(key)
                            dropdownProps.onMouseLeave = () => this.hideDropdown(key)
                        } else {
                            dropdownToggleProps.onClick = () => this.toggleDropdown(key)
                        }
                        return (
                            <Dropdown key={index} nav inNavbar {...dropdownProps}>
                                <DropdownToggle nav caret tag="a" href={link.url} className="dropdown-link">
                                    {modifyNavItemCase(key, this.isClosedGroup)}

                                    <span className="dropdown-line" />
                                    {(isMobile || this.isClosedGroup) && (
                                        <span className="dropdown-icon" {...dropdownToggleProps}>
                                            {this.state.dropdownStatus[key] ? <FaMinus /> : <FaPlus />}
                                        </span>
                                    )}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {Object.keys(link.children).map((key) => {
                                        const child = link.children[key]
                                        return (
                                            <a
                                                key={key}
                                                className={`${child.class ? child.class : ''} dropdown-item`}
                                                href={child.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {modifyNavItemCase(key, this.isClosedGroup)}
                                            </a>
                                        )
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                        )
                    } else {
                        return <MyNavItem key={index} name={key} url={link.url} uppercase={this.isClosedGroup} />
                    }
                })}
            </React.Fragment>
        )
    }

    render() {
        const { user, location, navigation } = this.props
        const loading = navigation.loading
        const menuData = this.state.menuData
        const vehicleStats = this.getVehicleStats()
        return this.renderNewPages(loading, menuData, vehicleStats, user, location)
    }
}

function modifyNavItemCase(navItem, allUpperCase = false) {
    let navItemName = ''

    if (allUpperCase) return navItem.toUpperCase()
    ;[...navItem].forEach((char, index, arr) => {
        if (index === 0) {
            navItemName += char
        } else if (arr[index - 1] === ' ') {
            navItemName += char.toUpperCase()
        } else if (arr[index] === 'V' && arr[index - 1] === 'E') {
            navItemName += char.toUpperCase()
        } else {
            navItemName += char.toLowerCase()
        }
    })

    return navItemName
}

function MyNavItem({ url, name, uppercase }) {
    let currentUrl = window.location.href

    if (url.startsWith('/')) {
        return (
            <NavItem>
                <LinkContainer exact to={url}>
                    <NavLink>{modifyNavItemCase(name, uppercase)}</NavLink>
                </LinkContainer>
            </NavItem>
        )
    } else {
        return (
            <NavItem>
                <a className={`nav-link ${currentUrl === url && 'active'}`} href={url}>
                    {modifyNavItemCase(name, uppercase)}
                </a>
            </NavItem>
        )
    }
}

export default Header
