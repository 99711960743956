import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
class LatestNews extends Component {
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12" className="text-center">
                        <h3>Latest News</h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" md="4" className="text-center mb-3">
                        <div className="ab-news">
                            <img src="/img/img1.png" alt="News" width="100%" />
                            <div className="ab-news__caption">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero perferendis doloribus
                                voluptatem quibusdam dolores consectetur animi.
                            </div>
                        </div>
                    </Col>
                    <Col xs="12" md="4" className="text-center mb-3">
                        <div className="ab-news">
                            <img src="/img/img2.png" alt="News" width="100%" />
                            <div className="ab-news__caption">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero perferendis doloribus
                                voluptatem quibusdam dolores consectetur animi.
                            </div>
                        </div>
                    </Col>
                    <Col xs="12" md="4" className="text-center mb-3">
                        <div className="ab-news">
                            <img src="/img/img3.png" alt="News" width="100%" />
                            <div className="ab-news__caption">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero perferendis doloribus
                                voluptatem quibusdam dolores consectetur animi.
                            </div>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default LatestNews
