import React, { Component } from 'react'
import { Col, Row, Button } from 'reactstrap'
class FiftyFifty extends Component {
    render() {
        return (
            <Row>
                <Col xs="12" md={{ size: 5, offset: 1 }} className="text-center mb-5">
                    <h4>Help to drive the future of remarketing</h4>
                    <p>
                        We have careers in all areas of our business from transport, finance, administration,
                        autioneering or buyer engagement.
                    </p>
                    <p>With a wide range of employee benefits, Aston Barclay is where you can make a difference.</p>
                    <Button className="mt-3 ab__button-round--small" type="submit">
                        View careers
                    </Button>
                </Col>
                <Col xs="12" md={{ size: 5, offset: 0 }} className="text-center">
                    <h4>Another section header</h4>
                    <p>
                        We have careers in all areas of our business from transport, finance, administration,
                        autioneering or buyer engagement.
                    </p>
                    <p>With a wide range of employee benefits, Aston Barclay is where you can make a difference.</p>
                    <Button className="mt-3 ab__button-round--small" type="submit">
                        Button
                    </Button>
                </Col>
            </Row>
        )
    }
}

export default FiftyFifty
