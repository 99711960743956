import React from 'react'
import PropTypes from 'prop-types'

const FilterButton = (props) => {
    return (
        <div className="col px-0 ab-save-filters">
            <button
                className={`btn btn-block position-relative btn-filter ${props.buttonClass}`}
                onClick={() => props.handleClick(props.id, props.callBackId)}
                type="button"
            >
                <p className="p-0 m-0">
                    {props.displayText} <span className="text-right">{props.count}</span>
                </p>
            </button>
        </div>
    )
}

FilterButton.propTypes = {
    id: PropTypes.string,
    displayText: PropTypes.string,
    buttonClass: PropTypes.string,
    handleClick: PropTypes.func,
    count: PropTypes.number,
}

export default FilterButton
