import React, { Component } from 'react'
import { CustomInput } from 'reactstrap'
class Input extends Component {
    constructor(props) {
        super(props)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.state = {
            isChecked: false,
        }
    }

    componentDidMount() {
        this.setState({ isChecked: this.props.checked })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ isChecked: nextProps.checked })
    }

    handleOnChange(evt, id) {
        const isChecked = !this.state.isChecked
        this.setState({ isChecked })
        this.props.handleOnSetCriteria(
            this.props.item.value,
            this.props.dataType,
            isChecked,
            this.props.inputType,
            this.props.offsetFinish,
        )
    }

    render() {
        let customClass = 'col-sm-6 col-md-4 pb-3 px-0'

        switch (this.props.dataType) {
            case 'sales':
                customClass = 'col-12 pb-3'
                break
            case 'sites':
                customClass = 'col-6 pb-3'
                break
            default:
                break
        }
        return (
            <div className={`${customClass} ${this.props.item.label.indexOf('Digital') >= 0 ? 'e-hub-orange' : ''}`}>
                <CustomInput
                    checked={this.state.isChecked}
                    type={this.props.inputType}
                    value={this.props.item.id}
                    id={this.props.dataType + '-' + this.props.index}
                    label={
                        <React.Fragment>
                            {this.props.item.label}
                            <span>({this.props.item.count})</span>
                        </React.Fragment>
                    }
                    onChange={this.handleOnChange}
                />
            </div>
        )
    }
}

export default Input
