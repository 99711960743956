export const standardOptions = [
    { value: 'lot', label: 'Lot' },
    { value: 'saleStart', label: 'Sale Date' },
    { value: 'make', label: 'Make' },
    { value: 'firstRegistration', label: 'Date of Reg' },
    { value: 'registration', label: 'Registration' },
    { value: 'mileage', label: 'Mileage' },
]

export const fundingOptions = [
    { value: 'hammerprice', label: 'Hammer Price' },
    { value: 'fundingStatus', label: 'Funding Status' },
]

export const catalogueOptions = [{ value: 'seller', label: 'Seller' }]

export const catalogueAuthOptions = [
    { value: 'capCleanPrice', label: 'Guide Price (CAP)' },
    { value: 'namaGrade', label: 'NAMA Grade' },
    { value: 'seller', label: 'Seller' },
]

export const standardEhubOptions = [
    { value: 'make', label: 'Make' },
    { value: 'firstRegistration', label: 'Date of Reg' },
    { value: 'registration', label: 'Registration' },
    { value: 'mileage', label: 'Mileage' },
    { value: 'appraisalDate', label: 'Appraisal Date' },
    { value: 'capCleanValuation', label: 'CAP Clean' },
]

export const eXchangeEhubOptions = [
    { value: 'make', label: 'Make' },
    { value: 'firstRegistration', label: 'Date of Reg' },
    { value: 'registration', label: 'Registration' },
    { value: 'mileage', label: 'Mileage' },
    { value: 'capCleanValuation', label: 'CAP Clean' },
    { value: 'biddingDetails.endDate', label: 'Ends' },
]
