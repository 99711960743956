import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { onKeywordChange } from '../../actions/criteria'

import { getVehicles } from '../../actions/vehicles'

import {
    getSites,
    getMakes,
    getEngineSizes,
    getTransmissions,
    getDoors,
    getAges,
    getMileages,
    getBodyTypes,
    getVehicleTypes,
    getFuelTypes,
    getSellers,
    getNama,
    getEuroStatus,
} from '../../actions/criteriaData'

import KeywordSearchInput from './KeywordSearchInput'

const mapStateToProps = (state) => ({
    criteria: state.criteria,
    sorting: state.sorting,
    user: state.user,
})

const mapDispatchToProps = {
    onKeywordChange,
    getVehicles,
    getSites,
    getMakes,
    getEngineSizes,
    getTransmissions,
    getDoors,
    getAges,
    getMileages,
    getBodyTypes,
    getVehicleTypes,
    getFuelTypes,
    getSellers,
    getNama,
    getEuroStatus,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KeywordSearchInput))
