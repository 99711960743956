import {
    ADD_TO_EXCHANGE,
    ADD_TO_EXCHANGE_ERROR,
    ADD_TO_EXCHANGE_SUCCESS,
    CHANGE_APPRAISAL_STATUS,
    CHANGE_APPRAISAL_STATUS_ERROR,
    CHANGE_APPRAISAL_STATUS_SUCCESS,
    CT_EXCHANGE,
    FETCH_ALL_VRM,
    FETCH_ALL_VRM_ERROR,
    FETCH_ALL_VRM_SUCCESS,
    FETCH_EHUB_APPRAISALS,
    FETCH_EHUB_APPRAISALS_ERROR,
    FETCH_EHUB_APPRAISALS_SUCCESS,
    FETCH_STATS,
    FETCH_STATS_ERROR,
    FETCH_STATS_SUCCESS,
    FETCH_VEHICLE_APPRAISAL,
    FETCH_VEHICLE_APPRAISAL_ERROR,
    FETCH_VEHICLE_APPRAISAL_SUCCESS,
    REFRESH_CAP_PRICE,
    REFRESH_CAP_PRICE_ERROR,
    REFRESH_CAP_PRICE_SUCCESS,
    RESET_APPRAISAL_GROUPS_FILTER,
    SAVE_APPRAISAL_DETAILS,
    SAVE_APPRAISAL_DETAILS_ERROR,
    SAVE_APPRAISAL_DETAILS_SUCCESS,
    SEND_TO_AUCTION,
    SEND_TO_AUCTION_ERROR,
    SEND_TO_AUCTION_SUCCESS,
    SET_APPRAISAL_LOCATION_SEARCH,
    SORT_APPRAISALS,
    TOGGLE_APPRAISAL_ALL_GROUPS_FILTER,
    TOGGLE_APPRAISAL_CLOSED_GROUPS_FILTER,
    UPDATE_BIDDING_DETAILS,
    UPDATE_BIDDING_DETAILS_ALL_SUCCESS,
    UPDATE_BIDDING_DETAILS_SUCCESS,
    UPDATE_WATCHERS_DETAILS_SUCCESS,
    CHANGE_ACCOUNTS,
} from '../constants'

const initialState = {
    loading: false,
    error: null,
    stats: {},
    appraisals: [],
    appraisalSearch: [],
    allGroupsFilter: true,
    closedGroupFilter: true,
}

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                stats: action.payload,
                error: null,
            }

        case FETCH_STATS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        case FETCH_ALL_VRM_SUCCESS:
            return {
                ...state,
                loading: false,
                appraisalSearch: action.payload,
                error: null,
            }

        case SET_APPRAISAL_LOCATION_SEARCH:
            return {
                ...state,
                locationSearch: action.payload,
            }

        case TOGGLE_APPRAISAL_ALL_GROUPS_FILTER:
            return {
                ...state,
                allGroupsFilter: !state.allGroupsFilter,
            }

        case TOGGLE_APPRAISAL_CLOSED_GROUPS_FILTER:
            return {
                ...state,
                closedGroupFilter: !state.closedGroupFilter,
            }

        case RESET_APPRAISAL_GROUPS_FILTER:
            return {
                ...state,
                allGroupsFilter: true,
                closedGroupFilter: true,
            }

        case UPDATE_WATCHERS_DETAILS_SUCCESS:
            let appraisals = state.appraisals.map((appraisal, i) => {
                return appraisal.id === action.payload.vehicleId
                    ? {
                          ...appraisal,
                          biddingDetails: {
                              ...appraisal.biddingDetails,
                              noOfWatchers: action.payload.numberOfWatchers,
                          },
                      }
                    : appraisal
            })

            return {
                ...state,
                appraisals,
                loading: false,
                error: null,
            }

        case UPDATE_BIDDING_DETAILS_SUCCESS:
            return {
                ...state,
                appraisals: state.appraisals.map((appraisal, i) => {
                    return appraisal.id === action.payload.id
                        ? {
                              ...appraisal,
                              biddingDetails: {
                                  ...appraisal.biddingDetails,
                                  id: action.payload.id,
                                  reserveMet: action.payload.reserveMet,
                                  startingPrice: action.payload.startingPrice,
                                  currentBid: action.payload.currentBid,
                                  timeRemaining: action.payload.timeRemaining,
                                  endDate: action.payload.endDate,
                                  noOfBids: action.payload.noOfBids,
                                  noOfWatchers: action.payload.noOfWatchers,
                                  startDate: action.payload.startDate,
                                  userWinning: action.payload.userWinning,
                                  userMaxBid: action.payload.userMaxBid,
                                  buyNowPrice: action.payload.buyNowPrice,
                              },
                          }
                        : appraisal
                }),
                loading: false,
                error: null,
            }

        case UPDATE_BIDDING_DETAILS_ALL_SUCCESS:
            return {
                ...state,
                appraisals: state.appraisals.map((b) => {
                    const a1 = action.payload.find((e) => e.id === b.id) || null
                    if (a1) {
                        return Object.assign({}, b, {
                            biddingDetails: a1,
                        })
                    }
                    return Object.assign({}, b)
                }),
            }

        case FETCH_VEHICLE_APPRAISAL_SUCCESS:
            const index = state.appraisals.findIndex(
                (i) =>
                    (action.payload.path === CT_EXCHANGE ? i.vehicleId : i.id) ===
                    (action.payload.path === CT_EXCHANGE ? action.payload.data.vehicleId : action.payload.data.id),
            )
            const newData = state.appraisals.map((data, i) => {
                return (action.payload.path === CT_EXCHANGE ? data.vehicleId : data.id) ===
                    (action.payload.path === CT_EXCHANGE ? action.payload.data.vehicleId : action.payload.data.id)
                    ? (data = action.payload.data)
                    : data
            })

            return {
                ...state,
                appraisals: index === -1 ? [...state.appraisals, action.payload.data] : newData,
                loading: false,
                error: null,
            }

        case CHANGE_APPRAISAL_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                appraisals: state.appraisals.map((appraisal, i) => {
                    // Make copy of object to enforce immutability
                    let newAppraisal = { ...appraisal }
                    if (appraisal.id === action.payload.id) {
                        // Only change the new status and actions as the old way removed the signatures
                        newAppraisal.status = action.payload.status
                        newAppraisal.actions = action.payload.actions
                    }
                    return newAppraisal
                }),
                error: null,
            }

        case REFRESH_CAP_PRICE_SUCCESS:
            return {
                ...state,
                loading: false,
                appraisals: state.appraisals.map((appraisal, i) => {
                    return appraisal.id === action.payload.id
                        ? Object.assign(
                              {},
                              {
                                  ...appraisal,
                                  capCleanPrice: action.payload.capCleanPrice,
                                  capAveragePrice: action.payload.capAveragePrice,
                                  capBelowPrice: action.payload.capBelowPrice,
                              },
                          )
                        : appraisal
                }),
                error: null,
            }

        case SAVE_APPRAISAL_DETAILS:
            return {
                ...state,
                loading: false,
                error: null,
            }

        case CHANGE_ACCOUNTS:
            return {
                ...state,
                accounts: action.accounts,
            }

        case FETCH_EHUB_APPRAISALS_SUCCESS:
        case SORT_APPRAISALS:
            return {
                ...state,
                loading: false,
                appraisals: action.payload,
                error: null,
            }

        case SAVE_APPRAISAL_DETAILS_SUCCESS:
        case SEND_TO_AUCTION_SUCCESS:
        case ADD_TO_EXCHANGE_SUCCESS:
            return {
                ...state,
                loading: false,
                appraisals: state.appraisals.map((appraisal, i) => {
                    return appraisal.id === action.payload.id ? action.payload : appraisal
                }),
                error: null,
            }

        // Loading
        case FETCH_STATS:
        case FETCH_EHUB_APPRAISALS:
        case FETCH_ALL_VRM:
        case FETCH_VEHICLE_APPRAISAL:
        case UPDATE_BIDDING_DETAILS:
        case CHANGE_APPRAISAL_STATUS:
        case SEND_TO_AUCTION:
        case ADD_TO_EXCHANGE:
        case REFRESH_CAP_PRICE:
            return {
                ...state,
                loading: true,
                error: null,
            }

        // Errors
        case REFRESH_CAP_PRICE_ERROR:
        case SAVE_APPRAISAL_DETAILS_ERROR:
        case CHANGE_APPRAISAL_STATUS_ERROR:
        case FETCH_EHUB_APPRAISALS_ERROR:
        case FETCH_ALL_VRM_ERROR:
        case FETCH_VEHICLE_APPRAISAL_ERROR:
        case SEND_TO_AUCTION_ERROR:
        case ADD_TO_EXCHANGE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }

        default:
            return state
    }
}

export default UserReducer
