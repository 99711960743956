import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

// import EhubFooter from "./EhubFooter";
import CatalogueFooter from './CatalogueFooter'

// import { isEhubApplication } from "../../utilities/Functions";

class Footer extends Component {
    render() {
        // if (isEhubApplication(this.props.location)) {
        //   return (
        //       <EhubFooter theme={this.props.theme}
        //                   data={this.props.navigation.data}
        //       />
        //   );
        // }
        return <CatalogueFooter theme={this.props.theme} data={this.props.navigation.data} global={this.props.global} />
    }
}

export default withRouter(Footer)
