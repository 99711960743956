import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getFundingVehicles, setTotalPages, getFundingCredit } from '../../../actions/funding'
import { setPageSize } from '../../../actions/pagination'
import { onChangeSortOrder } from '../../../actions/sorting'
import { setPaginationState } from '../../../actions/pagination'

import Funding from './Funding'

const mapStateToProps = (state) => ({
    user: state.user,
    funding: state.funding,
    pagination: state.pagination,
    sorting: state.sorting,
})

const mapDispatchToProps = {
    getFundingVehicles,
    setTotalPages,
    setPageSize,
    onChangeSortOrder,
    getFundingCredit,
    setPaginationState,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Funding))
