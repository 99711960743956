import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Pagination from './Pagination'
import { setPaginationState } from '../../actions/pagination'
const mapStateToProps = (state) => ({
    user: state.user,
    pagination: state.pagination,
})

const mapDispatchToProps = {
    setPaginationState,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pagination))
