import React, { Component } from 'react'

export default class AuctionExtend extends Component {
    constructor(props) {
        super(props)
        this.state = {
            endDate: props.endDate,
            type: props.type,
            id: props.id,
        }
    }

    componentDidMount() {
        const date = this.props.endDate
        const type = this.props.type
        const id = this.props.id
        this.setState({
            endDate: date,
            type: type,
            id: id,
        })
    }

    shouldComponentUpdate(nextProps) {
        return this.props.endDate !== nextProps.endDate
    }

    render() {
        let hasDate
        if (!this.state.endDate) {
            hasDate = false
        } else {
            hasDate = this.state.endDate !== this.props.endDate
        }
        const type = this.props.type

        if (!hasDate) {
            return null
        } else if (type === 'bidding') {
            return (
                <div key={this.props.endDate + this.props.id} className="col-6 m-0 p-0 p-md-2">
                    <div className="ab-enddate-animation float-left">
                        <div className="ab-modal-date-extended">Auto Extended</div>
                    </div>
                </div>
            )
        } else if (type === 'vehicle-details') {
            return (
                <div key={this.props.endDate + this.props.id} className="ab-enddate-animation float-right">
                    Auto Extended
                </div>
            )
        } else if (type === 'vehicle-card') {
            return (
                <div
                    key={this.props.endDate + this.props.id}
                    className="ab-card-enddate-position ab-enddate-animation position-absolute"
                >
                    <div className="ab-card-date-extended">
                        <span>Auto Extended</span>
                    </div>
                </div>
            )
        }
    }
}
