import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Modals from './Modals'

import { onCloseVehiclePhotoModal } from '../../actions/modals'

const mapStateToProps = (state) => ({
    vehiclePhotoModal: state.modals.vehiclePhoto,
})

const mapDispatchToProps = {
    onCloseVehiclePhotoModal,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Modals))
