import {
    API_BASE,
    API_GET_HEADERS,
    FETCH_NAVIGATION,
    FETCH_NAVIGATION_SUCCESS,
    FETCH_NAVIGATION_ERROR,
} from '../constants'

import { handleError } from '../utilities/Functions'

import { addNotification } from './notifications'

export const fetchNavigationBegin = () => ({
    type: FETCH_NAVIGATION,
})

export const fetchNavigationSuccess = (navigation) => ({
    type: FETCH_NAVIGATION_SUCCESS,
    payload: navigation,
})

export const fetchNavigationFailure = (error) => ({
    type: FETCH_NAVIGATION_ERROR,
    payload: fetchStaticNavigation(),
})

export function fetchNavigation() {
    let url = `${API_BASE}api/navigation`

    return async (dispatch) => {
        dispatch(fetchNavigationBegin())
        return fetch(url, API_GET_HEADERS())
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(fetchNavigationSuccess({ data: data }))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchNavigationFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,

                        type: 'error',
                    }),
                )
                dispatch(fetchNavigationFailure(error))
            })
    }
}

function fetchStaticNavigation() {
    return {
        catalogue: {
            HOME: {
                url: '/',
            },
            'VEHICLE SEARCH': {
                url: '/vehicle-search/',
            },
            'AUCTION SCHEDULE': {
                url: '/auction-schedule/',
            },
            'LIVE AUCTIONS': {
                url: '/live/',
            },
            'OUR SERVICES': {
                url: '/services/',
                children: {
                    FUNDING: {
                        url: '/services/funding/',
                    },
                    'LOGISTICS & TRANSPORTATION': {
                        url: '/services/logistics-transportation/',
                    },
                    'APPRAISAL & PREPARATION': {
                        url: '/services/vehicle-appraisal-preparation/',
                    },
                    'ASTON BARCLAY ASSURED': {
                        url: '/services/aston-barclay-assured/',
                    },
                    'BID & BUY NOW': {
                        url: '/services/bid-buy-now/',
                    },
                    'REPORTING & MARKET DATA': {
                        url: '/services/reporting-market-data/',
                    },
                    'DEALER FUNDING PARTNERS': {
                        url: '/services/nextgear-capital/',
                    },
                },
            },
            'HELP & ADVICE': {
                url: '/help-advice/',
                children: {
                    'TYPES OF AUCTION': {
                        url: '/help-advice/types-of-auction/',
                    },
                    'SELLING GUIDE': {
                        url: '/help-advice/selling-guide/',
                    },
                    'BUYING GUIDE': {
                        url: '/help-advice/buying-guide/',
                    },
                    'VEHICLE GRADING': {
                        url: '/help-advice/vehicle-grading/',
                    },
                    'DIGITAL AUCTION FAQS': {
                        url: '/help-advice/digital-auction-faqs/',
                    },
                    'AUCTION DICTIONARY': {
                        url: '/help-advice/dictionary/',
                    },
                    MERCEDES: {
                        url: '/help-advice/mercedes/',
                    },
                    LINKS: {
                        url: '/help-advice/links/',
                    },
                    'REMOTE SUPPORT': {
                        url: '/help-advice/remote-support/',
                    },
                },
            },
            NEWS: {
                url: '/news/',
            },
            'ABOUT US': {
                url: '/about/',
                children: {
                    CHELMSFORD: {
                        url: '/about/chelmsford/',
                    },
                    LEEDS: {
                        url: '/about/leeds/',
                    },
                    'PREES HEATH': {
                        url: '/about/prees-heath/',
                    },
                    WESTBURY: {
                        url: '/about/westbury/',
                    },
                    'DONINGTON PARK': {
                        url: '/about/donington-park/',
                    },
                    'OUR CUSTOMERS': {
                        url: '/about/customers/',
                    },
                    WAKEFIELD: {
                        url: '/about/wakefield/',
                    },
                    'ASTON BARCLAY GROUP': {
                        url: 'https://astonbarclayub.fmstage.co.uk/ds',
                        newWindow: true,
                        class: 'group',
                    },
                    'ASTON BARCLAY DIGITAL': {
                        url: 'http://www.vehicleremarketing.co.uk/',
                        newWindow: true,
                        class: 'digital',
                    },
                    'THE CAR BUYING GROUP': {
                        url: 'http://www.digitalautomotivesolutions.co.uk/',
                        newWindow: true,
                        class: 'buying',
                    },
                },
            },
            'CONTACT US': {
                url: '/contact/',
                children: {
                    CHELMSFORD: {
                        url: '/contact/chelmsford/',
                    },
                    LEEDS: {
                        url: '/contact/leeds/',
                    },
                    'PREES HEATH': {
                        url: '/contact/prees-heath/',
                    },
                    WESTBURY: {
                        url: '/contact/westbury/',
                    },
                    'DONINGTON PARK': {
                        url: '/contact/donington-park/',
                    },
                    WAKEFIELD: {
                        url: '/contact/wakefield/',
                    },
                },
            },
        },
        ehub: {
            HOME: {
                url: '/e-hub',
            },
            'CONTACT US': {
                url: '/ds/contact-us/',
                host: 'https://astonbarclayub.fmstage.co.uk',
            },
            'ABOUT US': {
                url: '/ds/about-us/',
                host: 'https://astonbarclayub.fmstage.co.uk',
            },
            NEWS: {
                url: '/ds/news/',
                host: 'https://astonbarclayub.fmstage.co.uk',
            },
            FAQS: {
                url: '/ds/faqs/',
            },
            'GROUP SITES': {
                url: '#',
                children: {
                    'ASTON BARCLAY GROUP': {
                        url: 'http://www.vehicleremarketing.co.uk/',
                        class: 'group',
                    },
                    'ASTON BARCLAY VEHICLE REMARKETING': {
                        url: 'https://astonbarclayub.fmstage.co.uk/',
                    },
                    'THE CAR BUYING GROUP': {
                        url: 'http://www.digitalautomotivesolutions.co.uk/',
                        class: 'buying',
                    },
                },
            },
        },
        footer: {
            'VEHICLE SEARCH': {
                url: '/vehicle-search/',
            },
            'AUCTION SCHEDULE': {
                url: '/auction-schedule/',
            },
            'LIVE AUCTIONS': {
                url: '/live/',
            },
            'OUR SERVICES': {
                url: '/services/',
            },
            NEWS: {
                url: '/news/',
            },
            'ABOUT US': {
                url: '/about/',
            },
            'CONTACT US': {
                url: '/contact/',
            },
            RECRUITMENT: {
                url: '/recruitment/',
            },
            'PRESS ENQUIRIES': {
                url: '/press-enquiries/',
            },
            'TERMS & CONDITIONS': {
                url: '/terms/',
            },
            'PRIVACY POLICY': {
                url: '/privacy/',
            },
            SITEMAP: {
                url: '/sitemap/',
            },
        },
        vehicleStats: {
            totalVehicles: 0,
            totalDigital: 0,
        },
    }
}
