const initialState = 'SEARCH'

const ModeReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state
    }
}

export default ModeReducer
