import React, { Component } from 'react'
import { Container, Row, Col, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import { ISODateString } from '../../../utilities/DateFunctions'

export default class AppraisalSearchesResults extends Component {
    getDate(appraisal) {
        return ISODateString(appraisal.appraisalDate.replace('+0000', 'Z'), false, true, false)
    }

    render() {
        const { data, vrm } = this.props

        if (data && data.length === 0) {
            return (
                <Container fluid={true}>
                    <Container className="p-0">
                        <Row>
                            <Col xs={{ size: 8, offset: 2 }} className="pt-3 text-center">
                                <p>{`No vehicles found with the VRM ${vrm}`}</p>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            )
        }
        return (
            <Table responsive>
                <thead>
                    <tr>
                        <th scope="col">Company</th>
                        <th scope="col">Vehicle</th>
                        <th scope="col">Status</th>
                        <th scope="col">Appraisal Date</th>
                        <th scope="col">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(data) &&
                        data.map((appraisal, index) => (
                            <React.Fragment key={appraisal.id}>
                                <tr>
                                    <td
                                        className="text-truncate"
                                        style={{
                                            maxWidth: '150px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <span>{appraisal.company}</span>
                                    </td>
                                    <td
                                        className="text-truncate"
                                        style={{
                                            maxWidth: '200px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <span>{appraisal.vehicle}</span>
                                    </td>
                                    <td className="text-uppercase">{appraisal.status}</td>
                                    <td className="">
                                        {' '}
                                        {appraisal.appraisalDate
                                            ? ISODateString(appraisal.appraisalDate.replace('+0000', 'Z'), false, true)
                                            : '-'}
                                    </td>
                                    <th scope="row" className="text-uppercase">
                                        <div style={{ position: 'relative' }}>
                                            <div className="archive-link">
                                                <Link to={`${appraisal.path}`} className="archive-link">
                                                    view
                                                </Link>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </React.Fragment>
                        ))}
                </tbody>
            </Table>
        )
    }
}
