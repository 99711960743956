import React, { PureComponent } from 'react'
import Select from 'react-select'
import { collectionAddressString } from '../../utilities/Functions'
import uniqWith from 'lodash/uniqWith'
import isEqual from 'lodash/isEqual'

const defaultPlaceholder = { value: null, label: 'Appraisal location...' }

class LocationSearch extends PureComponent {
    constructor(props) {
        super(props)
        this.onSearchChanged = this.onSearchChanged.bind(this)
    }

    onSearchChanged({ value }) {
        this.props.updateLocationSearch(value)
    }

    render() {
        if (!this.props.collectionAddresses || this.props.collectionAddresses.length === 0) {
            return null
        }
        const addresses = uniqWith(
            this.props.collectionAddresses.map((appraisal) => appraisal.collectionAddress),
            isEqual,
        )
        if (!this.props.collectionAddresses || this.props.collectionAddresses.length === 0) {
            return null
        }
        const options = [
            defaultPlaceholder,
            ...addresses.map((collectionAddress) => {
                return {
                    value: collectionAddress,
                    label: collectionAddressString(collectionAddress),
                }
            }),
        ]
        return (
            <div>
                <Select
                    defaultValue={defaultPlaceholder}
                    onChange={this.onSearchChanged}
                    options={options}
                    className="custom-select-input"
                    classNamePrefix="ab-select"
                />
            </div>
        )
    }
}

export default LocationSearch
