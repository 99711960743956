import React, { Component } from 'react'
import { Col, Container, Row } from 'reactstrap'
class FeaturedProducts extends Component {
    render() {
        return (
            <Container className="ab-hr">
                <Row>
                    <Col xs="12" className="text-center">
                        <h2>Featured Products</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" md="4" className="text-center">
                        <img src="/img/ab-assured-icon.png" alt="Enhanced" width="80%" />
                        <h3>Enhanced</h3>
                        <p>Enhance your forecourt stock</p>
                    </Col>
                    <Col xs="12" md="4" className="text-center">
                        <img src="/img/ab-assured-icon.png" alt="Assured" width="80%" />
                        <h3>Assured</h3>
                        <p>accurate and complete vehicle information</p>
                    </Col>
                    <Col xs="12" md="4" className="text-center">
                        <img src="/img/cascade-icon.png" alt="Cascade" width="80%" />
                        <h3>Cascade</h3>
                        <p>Transport retail, sourcing and disposal</p>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default FeaturedProducts
