import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'

import { getSortingOptions } from '../../utilities/Functions'
class Sorting extends Component {
    sort = (selectedOption, reverse) => {
        this.props.onChangeSortOrder(
            {
                sortType: this.props.sortType,
                sorting: {
                    dataField: selectedOption,
                    reverse: reverse,
                    active: true,
                },
            },
            this.props.offsetFinish,
        )
    }

    onChangeSortOrder = () => {
        this.sort(this.props.sorting.dataField, !this.props.sorting.reverse)
    }

    onChangeSortBy = (selectedOption) => {
        this.sort(selectedOption, false)
    }

    render = () => {
        return (
            <div className="input-group">
                <div className="input-group-prepend">
                    <button
                        className={`btn btn-outline-secondary px-3${!this.props.sorting.reverse && this.props.sorting.dataField.value ? ' active' : ''} sorter`}
                        type="button"
                        data-toggle="button"
                        aria-pressed="true"
                        onClick={this.onChangeSortOrder}
                        disabled={!this.props.sorting.dataField.value}
                    />
                </div>
                <Select
                    className="custom-select-input prepend"
                    value={this.props.sorting.dataField}
                    onChange={this.onChangeSortBy}
                    options={getSortingOptions(this.props.user, this.props.options, this.props.theme)}
                    isSearchable={false}
                    classNamePrefix="ab-select"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Sorting)
