import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Download from './Download'

const mapStateToProps = (state) => ({
    criteria: state.criteria,
    theme: state.user.theme,
})

const mapDispatchToProps = {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Download))
