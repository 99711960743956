import React, { Component } from 'react'

class Prices extends Component {
    render = () => {
        const { prices } = this.props
        return (
            <React.Fragment>
                {prices.priceStart && (
                    <div className="ab-guide-price">
                        <div className="ab-card__title">{prices.priceStart.title}</div>
                        <div className="ab-card__details">{prices.priceStart.value}</div>
                    </div>
                )}
                {prices.priceMiddle && (
                    <div className="ab-guide-price text-center">
                        <div className="ab-card__title">{prices.priceMiddle.title}</div>
                        <div className="ab-card__details">{prices.priceMiddle.value}</div>
                    </div>
                )}
                {prices.priceEnd && (
                    <div className="ab-guide-price text-right">
                        <div className="ab-card__title">{prices.priceEnd.title}</div>
                        <div className="ab-card__details">{prices.priceEnd.value}</div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default Prices
