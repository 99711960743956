import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import SearchResults from './SearchResults'

import { getVehicleDetails, getVehicles, onClickSetProxyBid, onClickWatch } from '../../actions/vehicles'
import { setSortedVehicles } from '../../actions/sorting'
import { setPrevAndNextOrder } from '../../actions/prevAndNext'
import {
    onClickBiddingModal,
    onOpenAddToEXchangeModal,
    onOpenBuyNowModal,
    onOpenProxyBidModal,
    getClosedGroup,
} from '../../actions/modals'

import { getFundingVehicles, onClickFundVehicle } from '../../actions/funding'

const mapStateToProps = (state) => ({
    user: state.user,
    vehicles: state.vehicles,
    pagination: state.pagination,
    criteria: state.criteria,
    ehubAppraisals: state.ehub.appraisals,
    sorting: state.sorting,
    websocket: state.websocket,
    funding: state.funding.fundingvehicles,
    loading: state.ehub.loading || state.vehicles.loading,
    locationSearch: state.ehub.locationSearch,
    allGroupsFilter: state.ehub.allGroupsFilter,
    closedGroupFilter: state.ehub.closedGroupFilter,
})

const mapDispatchToProps = {
    onClickWatch,
    getVehicleDetails,
    onOpenBuyNowModal,
    onOpenProxyBidModal,
    onClickSetProxyBid,
    onClickBiddingModal,
    onClickFundVehicle,
    getVehicles,
    getFundingVehicles,
    setSortedVehicles,
    onOpenAddToEXchangeModal,
    setPrevAndNextOrder,
    getClosedGroup,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResults))
