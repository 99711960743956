import React, { Component } from 'react'
import { connect } from 'react-redux'
import Countdown from 'react-countdown'

import { endAuction, triggerEndsSoon } from '../../actions/vehicles'
import { lang } from '../../themeConfig'

export class AuctionCountdown extends Component {
    defaultFormatter(value, unit, days, hours) {
        if (value === 0 || days >= 1 || hours >= 1) {
            return ''
        }
        return value + '' + unit
    }

    getVehicleData(id) {
        return this.props.vehicles.filter((vehicle) => vehicle.id === id)[0]
    }

    renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            if (
                this.getVehicleData(this.props.vehicleId) &&
                this.getVehicleData(this.props.vehicleId).biddingDetails &&
                !this.getVehicleData(this.props.vehicleId).biddingDetails.ended
            ) {
                this.props.endAuction(this.props.vehicleId)
            }
            return <span>{lang('AUCTION_ENDED_TEXT', this.props.theme)}</span>
        } else {
            if (
                days === 0 &&
                hours < 1 &&
                this.getVehicleData(this.props.vehicleId) &&
                this.getVehicleData(this.props.vehicleId).biddingDetails &&
                !this.getVehicleData(this.props.vehicleId).biddingDetails.endsSoon
            ) {
                this.props.triggerEndsSoon(this.props.vehicleId)
            }
            return (
                <span>
                    {this.props.excludePrependedText ? '' : `${lang('LABEL_ENDS_IN', this.props.theme)}: `}
                    {this.renderTimeFormat(days, hours, minutes, seconds)}
                </span>
            )
        }
    }

    renderTimeFormat(days, hours, minutes, seconds) {
        return `${this.defaultFormatter(days, lang('LABEL_DAY_SHORT', this.props.theme))} ${this.defaultFormatter(hours, lang('LABEL_HOUR_SHORT', this.props.theme))} ${this.defaultFormatter(minutes, lang('LABEL_MINUTE_SHORT', this.props.theme), days)} ${this.defaultFormatter(seconds, lang('LABEL_SECOND_SHORT', this.props.theme), days, hours)}`
    }

    render() {
        return <Countdown date={this.props.date} renderer={this.renderer} />
    }
}

const mapStateToProps = (state) => ({
    vehicles: state.vehicles.data,
    theme: state.user.theme,
})

const mapDispatchToProps = {
    endAuction,
    triggerEndsSoon,
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionCountdown)
