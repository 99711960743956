import React, { Component } from 'react'
import { Tooltip } from 'reactstrap'
import { isUserLoggedIn } from '../../utilities/Functions'

import { isWatching } from '../../components/CatalogueVehicleDetails/VehicleHelper'
import { lang } from '../../themeConfig'
import { FaEye } from '@react-icons/all-files/fa/FaEye'

/**
 * Usage:
 *  <ProxyBidButton user={} vehicle={} proxyBids={} />>
 *
 *
 */
class WatchButton extends Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.id = 'tooltip-vehicle-icon-transmission-'
        this.state = {
            watching: false,
            tooltipOpen: false,
        }
    }

    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        })
    }

    /**
     *
     *
     */

    componentDidMount = () => {
        const { vehicle, watchList } = this.props

        if (watchList) {
            const watching = watchList.find((watching) => {
                if (vehicle.id === watching.vehicleid) return watching
                return null
            })

            if (watching) {
                this.setState({
                    watching: true,
                })
            } else {
                this.setState({
                    watching: false,
                })
            }
        } else {
            this.setState({
                watching: isWatching(vehicle),
            })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { vehicle, watchList } = nextProps

        if (watchList) {
            const watching = watchList.find((watching) => {
                if (vehicle.id === watching.vehicleid) return watching
                return null
            })

            if (watching) {
                this.setState({
                    watching: true,
                })
            } else {
                this.setState({
                    watching: false,
                })
            }
        } else {
            this.setState({
                watching: isWatching(vehicle),
            })
        }
    }

    handlOnClickWatch(watching, id, evt) {
        evt.preventDefault()
        evt.stopPropagation()
        this.setState({
            watching: watching,
        })
        this.props.onClickWatch(watching, id, evt)
    }

    render = () => {
        const { user, vehicle } = this.props

        if (isUserLoggedIn(user)) {
            return (
                <button
                    id={`watching-button-${vehicle.id}`}
                    type="button"
                    className={`btn ab-btn-watch ${this.state.watching ? 'active' : ''} `}
                    onClick={(event) => {
                        this.handlOnClickWatch(!vehicle.watching, vehicle.id, event)
                    }}
                >
                    <FaEye />
                    <Tooltip
                        placement="bottom"
                        isOpen={this.state.tooltipOpen}
                        target={`watching-button-${vehicle.id}`}
                        toggle={this.toggle}
                    >
                        {isWatching(vehicle)
                            ? lang('UNWATCH', this.props.user.theme)
                            : lang('WATCH', this.props.user.theme)}
                    </Tooltip>
                </button>
            )
        }

        return <React.Fragment />
    }
}

export default WatchButton
