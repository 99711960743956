import React, { PureComponent } from 'react'
import { hasTradeAccess } from '../../utilities/Functions'
import { lang } from '../../themeConfig'
import { getCurrentRetailPrice, getCurrentTradePrice, getPrice, VehicleStatsRow } from './VehicleHelper'
import NotSignedInBanner from './NotSignedInBanner'

export default class Valuations extends PureComponent {
    render() {
        const { vehicle, user } = this.props

        if (!hasTradeAccess(user)) {
            return <NotSignedInBanner />
        }
        return (
            <div className="row m-0 p-0">
                <div className="col-12 col-md-6 m-0 p-0 pr-md-2">
                    <h3 className="m-0 pt-3 pr-1 pb-0 pl-1">{lang('LABEL_VALUATIONS', user.theme)}</h3>
                    <table className="table m-0 p-0">
                        <tbody>
                            <VehicleStatsRow
                                label={lang('LABEL_CAP_RETAIL_PRICE', user.theme)}
                                value={getPrice(vehicle.capRetailPrice)}
                            />
                            <VehicleStatsRow
                                label={lang('LABEL_CAP_CLEAN_PRICE', user.theme)}
                                value={getPrice(vehicle.capCleanPrice)}
                            />
                            <VehicleStatsRow
                                label={lang('LABEL_CAP_AVERAGE_PRICE', user.theme)}
                                value={getPrice(vehicle.capAveragePrice)}
                            />
                            <VehicleStatsRow
                                label={lang('LABEL_CAP_BELOW_PRICE', user.theme)}
                                value={getPrice(vehicle.capBelowPrice)}
                            />
                        </tbody>
                    </table>
                </div>
                <div className="col-12 col-md-6 m-0 p-0 pl-md-2">
                    <h3 className="m-0 pt-3 pr-1 pb-0 pl-1">{lang('LABEL_AUTOTRADER_MARKET_DATA', user.theme)}</h3>
                    <table className="table m-0 p-0">
                        <tbody>
                            <VehicleStatsRow
                                label={lang('LABEL_CURRENT_RETAIL_VALUE', user.theme)}
                                value={getCurrentRetailPrice(vehicle)}
                            />
                            <VehicleStatsRow
                                label={lang('LABEL_CURRENT_TRADE_VALUE', user.theme)}
                                value={getCurrentTradePrice(vehicle)}
                            />
                            <tr className="ab-tr-overide">
                                <td>{lang('LABEL_POWERED_BY', user.theme)}</td>
                                <td>
                                    <img
                                        src="/img/logo-autotrader.gif"
                                        className="ab-auto-trader-icon"
                                        alt="auto trader"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
