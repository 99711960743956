import React, { Component } from 'react'
class MockGui extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
        }
        this.checkKeyCombination = this.checkKeyCombination.bind(this)
    }
    componentDidMount() {
        window.addEventListener('keydown', (ev) => {
            return this.checkKeyCombination(ev)
        })
    }

    checkKeyCombination(e) {
        if (e.ctrlKey && e.shiftKey && e.keyCode === 71) {
            this.setState({ open: !this.state.open })
        }
    }

    render() {
        const vehicle =
            this.props.vehicles.data.length > 0 ? this.props.vehicles.data.filter((item) => item.id === 1)[0] : []
        if (this.state.open) {
            return (
                <div className="mock-gui">
                    <p className="text-center m-0">Mock Bidding</p>
                    <button
                        className="btn btn-primary btn-block my-1"
                        onClick={() =>
                            this.props.updateBiddingDetails({
                                id: 1,
                                reserveMet: false,
                                startingPrice: 2000,
                                currentBid: null,
                                timeRemaining: 1000000,
                                endDate: '2019-03-07T12:04:06',
                                noOfBids: 0,
                                noOfWatchers: 4,
                                startDate: '2019-03-05T12:04:06',
                                userWinning: false,
                                userMaxBid: null,
                            })
                        }
                    >
                        Reset
                    </button>
                    <button
                        className="btn btn-primary btn-block my-1"
                        onClick={() =>
                            this.props.updateBiddingDetails({
                                id: 1,
                                reserveMet: vehicle.biddingDetails.currentBid >= 2250 ? true : false,
                                startingPrice: 2000,
                                currentBid: vehicle.biddingDetails.currentBid
                                    ? vehicle.biddingDetails.currentBid + 50
                                    : vehicle.biddingDetails.startingPrice + 50,
                                timeRemaining: 1000000,
                                endDate: '2019-03-07T12:04:06',
                                noOfBids: vehicle.biddingDetails.noOfBids + 1,
                                noOfWatchers: 4,
                                startDate: '2019-03-05T12:04:06',
                                userWinning: true,
                                userMaxBid: vehicle.biddingDetails.currentBid
                                    ? vehicle.biddingDetails.currentBid + 50
                                    : vehicle.biddingDetails.startingPrice + 50,
                            })
                        }
                    >
                        Increment user bid +50
                    </button>
                    <button
                        className="btn btn-primary btn-block my-1"
                        onClick={() =>
                            this.props.updateBiddingDetails({
                                id: 1,
                                reserveMet: vehicle.biddingDetails.currentBid >= 2250 ? true : false,
                                startingPrice: 2000,
                                currentBid: vehicle.biddingDetails.currentBid
                                    ? vehicle.biddingDetails.currentBid + 50
                                    : vehicle.biddingDetails.startingPrice + 50,
                                timeRemaining: 1000000,
                                endDate: '2019-03-07T12:04:06',
                                noOfBids: vehicle.biddingDetails.noOfBids + 1,
                                noOfWatchers: 4,
                                startDate: '2019-03-05T12:04:06',
                                userWinning: false,
                                userMaxBid: vehicle.biddingDetails.currentBid
                                    ? vehicle.biddingDetails.currentBid + 50
                                    : vehicle.biddingDetails.startingPrice + 50,
                            })
                        }
                    >
                        Outbid User +50
                    </button>
                    <button
                        className="btn btn-primary btn-block my-1"
                        onClick={() =>
                            this.props.updateBiddingDetails({
                                id: 1,
                                reserveMet: true,
                                startingPrice: 2000,
                                currentBid: vehicle.biddingDetails.currentBid,
                                timeRemaining: 0,
                                endDate: '2019-03-07T12:04:06',
                                noOfBids: vehicle.biddingDetails.noOfBids + 1,
                                noOfWatchers: 4,
                                startDate: '2019-03-05T12:04:06',
                                userWinning: true,
                                userMaxBid: vehicle.userMaxBid,
                            })
                        }
                    >
                        End Auction with bids reseve met - WIN
                    </button>
                    <button
                        className="btn btn-primary btn-block my-1"
                        onClick={() =>
                            this.props.updateBiddingDetails({
                                id: 1,
                                reserveMet: false,
                                startingPrice: 2000,
                                currentBid: vehicle.biddingDetails.currentBid,
                                timeRemaining: 0,
                                endDate: '2019-03-07T12:04:06',
                                noOfBids: vehicle.biddingDetails.noOfBids + 1,
                                noOfWatchers: 4,
                                startDate: '2019-03-05T12:04:06',
                                userWinning: true,
                                userMaxBid: vehicle.userMaxBid,
                            })
                        }
                    >
                        End Auction with bids reseve not met - WIN
                    </button>
                    <button
                        className="btn btn-primary btn-block my-1"
                        onClick={() =>
                            this.props.updateBiddingDetails({
                                id: 1,
                                reserveMet: vehicle.biddingDetails.currentBid >= 2250 ? true : false,
                                startingPrice: 2000,
                                currentBid: vehicle.biddingDetails.currentBid,
                                timeRemaining: 0,
                                endDate: '2019-03-07T12:04:06',
                                noOfBids: vehicle.biddingDetails.noOfBids + 1,
                                noOfWatchers: 4,
                                startDate: '2019-03-05T12:04:06',
                                userWinning: true,
                                userMaxBid: vehicle.userMaxBid,
                            })
                        }
                    >
                        End Auction with bids - Lose
                    </button>
                    <button
                        className="btn btn-primary btn-block my-1"
                        onClick={() =>
                            this.props.updateBiddingDetails({
                                id: 1,
                                reserveMet: false,
                                startingPrice: 2000,
                                currentBid: vehicle.biddingDetails.currentBid,
                                timeRemaining: 0,
                                endDate: '2019-03-07T12:04:06',
                                noOfBids: 0,
                                noOfWatchers: 4,
                                startDate: '2019-03-05T12:04:06',
                                userWinning: false,
                                userMaxBid: vehicle.userMaxBid,
                            })
                        }
                    >
                        End Auction No bids
                    </button>
                    <button
                        className="btn btn-primary btn-block my-1"
                        onClick={() =>
                            this.props.updateBiddingDetails({
                                id: 1,
                                reserveMet: false,
                                startingPrice: 2000,
                                currentBid: null,
                                timeRemaining: 10000000,
                                endDate: '2019-03-07T12:04:06',
                                noOfBids: 0,
                                noOfWatchers: 4,
                                startDate: '2019-03-05T12:04:06',
                                userWinning: false,
                                userMaxBid: null,
                            })
                        }
                    >
                        Timer over 1 hour
                    </button>
                </div>
            )
        } else {
            return null
        }
    }
}

export default MockGui
