import React, { Component } from 'react'
import AuctionCountdown from '../AuctionCountdown'
import {
    getAuctionDate,
    getAuctionTime,
    getLocation,
    getLot,
} from '../../components/CatalogueVehicleDetails/VehicleHelper'
import { cardTypeLookup, hasTradeAccess } from '../../utilities/Functions'
import { convertNameToLookup, lang, permissions } from '../../themeConfig'
import { showRemaining } from '../../utilities/AuctionFunctions'
import {
    CT_ARCHIVED,
    CT_CATALOGUE,
    CT_COLLECTIONS,
    CT_EHUB_EXCHANGE,
    CT_EVALUATE,
    CT_EXCHANGE,
    CT_SECURE_BANK_TRUST_FUNDING,
    APP_EHUB,
} from '../../constants'

class Banner extends Component {
    getCollectionStatus(vehicle) {
        if (vehicle.status === 'Sent to e-xchange' && vehicle.previousStatus === 'e-xchange') {
            return lang('EHUB_ADDED_TO_EXCHANGE_TEXT', this.props.user.theme)
        }

        if (
            (vehicle.status === 'Aston Barclay' && vehicle.previousStatus === 'In stock') ||
            (vehicle.status === 'Aston Barclay' && vehicle.previousStatus === 'e-xchange')
        ) {
            return vehicle.collectionSite
        }

        return ''
    }

    render = () => {
        const { vehicle, user, websocketConnected, type } = this.props
        if (vehicle.cardType === CT_CATALOGUE || vehicle.cardType === CT_SECURE_BANK_TRUST_FUNDING) {
            return (
                <div
                    className={`col-12 py-1 ab-card-aution-details ${getLocation(vehicle) === 'e-Live' ? 'ab-virtual' : ''}`}
                >
                    {
                        <React.Fragment>
                            <span className="ab-lot">{getLot(vehicle) ? getLot(vehicle) : 'No lot no.'}</span>
                            <span>
                                &nbsp;&nbsp;||&nbsp;&nbsp;
                                {getAuctionTime(vehicle) ? getAuctionTime(vehicle) : 'No start time'}
                            </span>
                            <span>
                                &nbsp;&nbsp;||&nbsp;&nbsp;
                                {getAuctionDate(vehicle) ? getAuctionDate(vehicle) : 'No start date'}
                            </span>
                        </React.Fragment>
                    }
                    {!getLot(vehicle) &&
                        !getAuctionTime(vehicle) &&
                        !getAuctionDate(vehicle) &&
                        lang('NO_LOT_INFORMATION', this.props.user.theme)}
                    <span className="float-right">{getLocation(vehicle)}</span>
                </div>
            )
        }
        if (vehicle.cardType === CT_EXCHANGE || vehicle.cardType === CT_EHUB_EXCHANGE) {
            return (
                <div className="col-12 p-0">
                    <div className="ab-card-auction-status py-1 px-2">
                        {user.eXchangeBuyer && type !== APP_EHUB ? (
                            <React.Fragment>
                                {websocketConnected ? (
                                    <React.Fragment>
                                        <div className="time-left" style={{ width: '40%' }}>
                                            {hasTradeAccess(user) && (
                                                <React.Fragment>
                                                    {vehicle.biddingDetails && !vehicle.biddingDetails.ended ? (
                                                        <AuctionCountdown
                                                            date={
                                                                Date.now() +
                                                                showRemaining(
                                                                    vehicle.biddingDetails.endDate.replace(
                                                                        '+0000',
                                                                        'Z',
                                                                    ),
                                                                )
                                                            }
                                                            vehicleId={vehicle.id}
                                                        />
                                                    ) : (
                                                        lang('AUCTION_ENDED_TEXT', this.props.user.theme)
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <div className="">
                                            {vehicle.biddingDetails &&
                                            vehicle.biddingDetails.provisionalStatus &&
                                            vehicle.biddingDetails.provisionalStatus === 'Seller Accepted' ? (
                                                <img
                                                    src={`/img/icon-trophy.png`}
                                                    style={{ width: '16px' }}
                                                    alt="trophy"
                                                />
                                            ) : (
                                                vehicle.biddingDetails &&
                                                vehicle.biddingDetails.userMaxBid && (
                                                    <img
                                                        src={`/img/icon-trophy${vehicle.biddingDetails.userWinning && vehicle.biddingDetails.reserveMet ? '' : '-cross'}.png`}
                                                        style={{ width: '16px' }}
                                                        alt="trophy"
                                                    />
                                                )
                                            )}
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <div className="time-left" style={{ width: '60%' }}>
                                        {lang('RECONNECTING_TO_SERVER', this.props.user.theme)}
                                    </div>
                                )}
                                {permissions('EHUB_SHOW_LABEL', this.props.user.theme) && (
                                    <div className="" style={{ width: '40%', textAlign: 'right' }}>
                                        {lang('EXCHANGE_TITLE', this.props.user.theme)}
                                    </div>
                                )}
                            </React.Fragment>
                        ) : (
                            permissions('EHUB_SHOW_LABEL', this.props.user.theme) && (
                                <div className="text-right wrapper">
                                    {lang('EXCHANGE_TITLE', this.props.user.theme)}
                                </div>
                            )
                        )}
                    </div>
                </div>
            )
        }
        if (vehicle.cardType === CT_EVALUATE || vehicle.cardType === CT_ARCHIVED) {
            return (
                <div className="col-12 p-0">
                    <div className="ab-card-auction-status py-1 px-2">
                        <div className="time-left" style={{ width: '60%' }}>
                            {lang(
                                `EHUB_${convertNameToLookup[vehicle.status.toUpperCase()]}_TEXT`,
                                this.props.user.theme,
                            )}
                        </div>
                        {
                            <div className="" style={{ width: '40%', textAlign: 'right' }}>
                                {cardTypeLookup(vehicle.cardType)}
                            </div>
                        }
                    </div>
                </div>
            )
        }
        if (vehicle.cardType === CT_COLLECTIONS) {
            return (
                <div className="col-12 p-0">
                    <div className="ab-card-auction-status py-1 px-2">
                        <div className="time-left" style={{ width: '60%' }}>
                            {this.getCollectionStatus(vehicle)}
                        </div>
                        {
                            <div className="" style={{ width: '40%', textAlign: 'right' }}>
                                {cardTypeLookup(vehicle.cardType)}
                            </div>
                        }
                    </div>
                </div>
            )
        }

        return null
    }
}

export default Banner
