import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'

import { APP_EHUB, FALLBACK_CASCADE_LOGIN } from '../../constants'
import { usePrevious } from '../../hooks/usePrevious'

import Dashboard from './Dashboard'
import EhubNavigation from './EhubNavigation'
import VehicleDetails from './EhubVehicleDetails'
import VehicleByVrm from './VehicleByVrm'
import Vehicles from './Vehicles'

import { anyAccountsAreEhub } from '../../utilities/Functions'

function getActiveSectionFromLocation(location) {
    const currentPath = location.pathname
    const result = currentPath.substring(currentPath.lastIndexOf(`/`) + 1)

    return result
}

/**
 * This component was heavily refactored due to an issue with redux believing that the eHub values were always different,
 * refreshing this component on a store update and ultimately caused a form to reset every few seconds.
 *
 * This component receives props/state through both hooks and a react-router-dom connect HOC.
 * The latter couldn't be replaced with a hook due to not being able to upgrade react-router-dom to a new version without causing further issues.
 *
 * The routing is convoluted. The routes are defined in the App.js and this acts as a wrapper to the child components.
 */
export const EHub = (props) => {
    const { match, location, loading } = props

    const previousLoading = usePrevious(loading)
    const user = useSelector((state) => state.user)
    const previousUser = usePrevious(user)
    const vehicles = useSelector((state) => state.vehicles)
    const eHub = useSelector((state) => state.ehub, shallowEqual)
    const global = useSelector((state) => state.profileConfig && state.profileConfig.data)
    const activeSection = getActiveSectionFromLocation(location)
    const selectedOption = null // This doesn't appear to be used, but it's here for the sake of consistency with the previous version of this component
    const [lastUserUpdate, setLastUserUpdate] = useState(false)

    // It's easier for now to build this back up and prop drill down. :'(
    const DEPRECATED_props = {
        ...props,
        user,
        vehicles,
        ehub: eHub,
        global,
    }

    const getSubComponent = () => {
        if (activeSection === match.params.id) {
            return <VehicleDetails {...DEPRECATED_props} />
        }

        if (activeSection === match.params.vrm) {
            return <VehicleByVrm {...DEPRECATED_props} selectedOption={selectedOption} />
        }

        if (
            (activeSection === match.params.path && match.params.path !== 'search') ||
            activeSection === match.params.subPath
        ) {
            return <Vehicles {...DEPRECATED_props} selectedOption={selectedOption} />
        }

        // Fallback to the dashboard
        return <Dashboard selectedOption={selectedOption} />
    }

    useEffect(() => {
        document.title = 'E-Hub | Vehicle Remarketing and Vehicle Auction | Aston Barclay'
        document.body.classList.add(APP_EHUB)

        return () => {
            document.body.classList.remove(APP_EHUB)
        }
    }, [])

    const renderRedirect = () => {
        window.location.href = global.cascadeLogin || FALLBACK_CASCADE_LOGIN // ! Deprecated variable
    }

    // The next couple of useEffects attempt to replicate the convoluted behavior of the class based version of this component.
    // It's not pretty, but it works.
    useEffect(() => {
        if (user.loading !== previousLoading && user.loading) {
            setLastUserUpdate(true)
        }
    }, [user.loading])

    useEffect(() => {
        if (!user.loading && !user.loggedIn && user === previousUser && lastUserUpdate) {
            renderRedirect()
        }
    }, [props.user, previousUser, lastUserUpdate, renderRedirect])

    if (user.loading) {
        return null
    }

    // Not logged in
    if (!user.loggedIn) {
        return (
            <div id="top" className="footer-margin">
                <RedirectingMessage />
            </div>
        )
    }

    // Not authorised
    if (!user.eHubUser || !anyAccountsAreEhub(user, eHub.accounts)) {
        return (
            <div id="top" className="footer-margin">
                <NotAuthorisedMessage />
            </div>
        )
    }

    return (
        <div id="top" className="footer-margin">
            <EhubNavigation {...DEPRECATED_props} selectedOption={selectedOption} />

            {getSubComponent()}
        </div>
    )
}

const NotAuthorisedMessage = () => {
    return (
        <Container fluid={true}>
            <Container className="p-0">
                <Row>
                    <Col xs={{ size: 8, offset: 2 }} className="pt-3 text-center">
                        <p>
                            Sorry, you do not currently have access to Aston Barclay’s digital product suite. Please
                            contact your account manager to discuss your requirements. Thank you
                        </p>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

const RedirectingMessage = () => {
    return (
        <Container fluid={true}>
            <Container className="p-0">
                <Row>
                    <Col xs={{ size: 8, offset: 2 }} className="pt-3 text-center">
                        <p>Redirecting....</p>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
