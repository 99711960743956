// src/factories/createNotification.js
import { getPrimaryColor } from '../utilities/ThemeFunctions'

let id = 0

const defaultOptions = {
    color: getPrimaryColor(),
    delay: 10000,
}

export default function createNotification(options) {
    return {
        ...defaultOptions,
        ...options,
        id: id++,
    }
}
