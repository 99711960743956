import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { FaChevronCircleRight } from '@react-icons/all-files/fa/FaChevronCircleRight'

class RegisterNow extends Component {
    render() {
        return (
            <React.Fragment>
                <h1 className="text-center">Aston Barclay</h1>
                <h4>The leading national independent auction group</h4>
                <p className="mt-4">
                    Driving the future of the auction and remarketing industry through leading results, innovation,
                    investment, excellence in technology and exceptional service.
                </p>
                <div className="text-center">
                    <Button className="mt-3 ab__button-round" type="submit">
                        Register now
                    </Button>

                    <p className="mt-4 ab-forgot__password">
                        <a href="/">
                            FIND OUT MORE
                            <FaChevronCircleRight className="ml-3" />
                        </a>
                    </p>
                </div>
            </React.Fragment>
        )
    }
}

export default RegisterNow
