import React, { Component } from 'react'
import { css } from '@emotion/react'
import { ScaleLoader } from 'react-spinners'
import SearchResults from '../../SearchResults'

import Sorting from '../../../UiKit/Sorting'
import PageSize from '../../../UiKit/PageSize'
import Pagination from '../../Pagination'
import { lang, setTitle } from '../../../themeConfig'

const override = css`
    display: block;
    margin: 0 auto;
`
class BidBuyNowList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            totalVehicles: 0,
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        setTitle('Bid & Buy Now', this.props.user.theme)
        this.onLoadBidBuyNow()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {}

    onLoadBidBuyNow() {
        this.props.fetchBidBuyNowVehicles()
    }

    updateCount = (count) => {
        this.setState({ totalVehicles: count })
    }

    render() {
        const bidBuyNowVehicles = this.props.vehicles.data.filter(
            (vehicle) => vehicle.currentBidAndBuyNow && vehicle.currentBidAndBuyNow.status !== 'accepted',
        )
        return (
            <div className="row mx-0">
                {/* <!-- AB RIGHT COLUMN --> */}
                <div className="col-lg-12 col-md-12 m-0 p-0 p-md-2 ab-results-right">
                    <div className="row m-0 p-3 pb-0 pt-4 d-none d-md-flex">
                        <div className="col x-0 p-0">
                            <h2 className="p-0 m-0">
                                {lang('TOTAL_VEHICLES_TEXT', this.props.user.theme)} -{' '}
                                <span>{`${this.state.totalVehicles}`}</span>
                            </h2>
                        </div>
                        <div className="col p-0 x-0 d-none d-md-block text-right ab-download-icons" />
                        <div className="col ab-h4 ml-2 px-0 text-right ab-results-per-page d-none" />
                    </div>

                    {/* <!-- AB RESULTS CONTROLS --> */}
                    <div className="row mx-0 mt-3 mb-0 my-md-3 px-3 px-md-1 py-0">
                        <div className="col-md-3 mb-3 mb-md-0 pl-md-0 px-0 ab-results-sort order-0 order-md-0">
                            <Sorting
                                sorting={this.props.sorting}
                                options="catalogue"
                                onChangeSortOrder={this.props.onChangeSortOrder}
                                offsetFinish={this.props.pagination.resultsPerPage.value}
                            />
                        </div>
                        <div className="col-md-6 mb-0 mb-md-0 px-0 d-flex justify-content-center order-2 order-md-1">
                            <Pagination vehicles={bidBuyNowVehicles} />
                        </div>
                        <div className="col-md-3 mb-3 mb-md-0 px-0 pr-md-0 text-center text-md-right ab-results-per-page order-1 order-md-2">
                            <PageSize
                                vehicles={bidBuyNowVehicles}
                                pagination={this.props.pagination}
                                setPageSize={this.props.setPageSize}
                                theme={this.props.user.theme}
                            />
                        </div>
                    </div>
                    {/* <!-- AB CARD RESULTS GRID --> */}
                    <div className="row mx-0 my-0">
                        {bidBuyNowVehicles.length === 0 && this.props.vehicles.loading ? (
                            <div className="col px-2">
                                <div className="loading-wrapper">
                                    <ScaleLoader className={override} size={10} loading={true} />
                                </div>
                            </div>
                        ) : (
                            <SearchResults type="bid-buy-now" viewType={'grid'} updateCount={this.updateCount} />
                        )}
                    </div>
                    {!this.props.vehicles.loading && (
                        <div className="row mx-0 mb-0 my-md-3 px-3 px-md-1 py-0">
                            <div className="col-md-3 mb-3 mb-md-0 pl-md-0 px-0 ab-results-sort order-0 order-md-0" />
                            <div className="col-md-6 mb-0 mb-md-0 px-0 d-flex justify-content-center order-2 order-md-1">
                                <Pagination vehicles={bidBuyNowVehicles} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default BidBuyNowList
