import { SET_PREV_NEXT_ORDER } from '../constants'

const initialState = {
    order: [],
}

const PrevNextReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PREV_NEXT_ORDER:
            return {
                ...state,
                order: action.payload,
            }
        default:
            return state
    }
}

export default PrevNextReducer
