import React from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Notification from './Notification'
import { removeNotification } from '../../actions/notifications'

const Notifications = ({ actions, notifications }) => {
    return (
        <div className="toast__container">
            {notifications.map((notification) => {
                const { id } = notification
                return <Notification {...notification} key={id} id={id} onDismissClick={actions.removeNotification} />
            })}
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ removeNotification }, dispatch),
})

const mapStateToProps = (state) => ({
    notifications: state.notifications,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications))
