import React, { Component } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { ScaleLoader } from 'react-spinners'
import { css } from '@emotion/react'
import Select from 'react-select'
import { lang } from '../../../themeConfig'
import SendToAuctionModal from '../../Modals/SendToAuctionModal'
import Pagination from '../../Pagination'
import SearchResults from '../../SearchResults'

import Sorting from '../../../UiKit/Sorting'
import PageSize from '../../../UiKit/PageSize'
import LocationSearch from '../../../UiKit/LocationSearch'
import uniqWith from 'lodash/uniqWith'
import sortBy from 'lodash/sortBy'

import {
    eHubOptions,
    eHubOptionsListAndCollect,
    getFilteredAppraisalGroup,
    getFilteredAppraisals,
    getSortingOptionsForEhub,
    setupAccounts,
} from '../../../utilities/Functions'

import { APP_EHUB } from '../../../constants'
import { getSortOrderProp } from './config/sorting'
import EhubCheckbox from './EhubCheckbox'

const override = css`
    display: block;
    margin: 0 auto;
`

class Vehicles extends Component {
    constructor(props) {
        super(props)
        this.dataType = 'keywords'
        this.defaultText = lang('CATALOGUE_SEARCH_PLACEHOLDER', props.user.theme)
        this.toggleStatus = this.toggleStatus.bind(this)
        this.toggleView = this.toggleView.bind(this)
        this.setAppraisalLocationSearch = this.setAppraisalLocationSearch.bind(this)
        this.getCollectionAddress = this.getCollectionAddress.bind(this)
        this.state = {
            value: '',
            viewType: 'grid',
            appraisals: [],
            totalVehicles: 0,
            selected: {},
        }
        this.getSelectedCheckPath = this.getSelectedCheckPath.bind(this)
    }

    componentDidMount() {
        // For the url e.g. http://localhost:3000/e-hub/AST003/e-valuate/new
        // the path should get `e-valuate` and subPath should get `new`
        const path = this.props.match.params.path
        const subPath = this.props.match.params.subPath
        // Set the sort order of e-Hub search based on which page the user has landed in.
        this.props.setSortOrderProperty(getSortOrderProp(path, subPath))

        let accountCode = this.props.match.params.accountCode
        let accountCodes = this.props.ehub.accounts
        const allAccountCodes = this.props.user.otherAccounts

        let { selected, checkPath } = this.getSelectedCheckPath(this.props)

        if (!this.props.ehub || !this.props.ehub.accounts) {
            accountCodes = setupAccounts(
                this.props.changeAccounts,
                accountCode,
                accountCodes,
                this.props.user.otherAccounts,
            )
        }

        if (checkPath && !selected) {
            // path = eHubOptions[this.props.match.params.path][0].value;
            selected = checkPath[0]
        }
        if (selected) {
            this.props.getEHubAppraisals(
                accountCodes,
                allAccountCodes,
                path,
                selected.value,
                this.props.pagination.resultsPerPage.value,
            )
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            appraisals: nextProps.ehub.appraisals,
        })
        let path = nextProps.match.params.path
        let accountCode = nextProps.match.params.accountCode
        let accountCodes = nextProps.ehub.accounts
        const subPath = nextProps.match.params.subPath

        let { selected, checkPath } = this.getSelectedCheckPath(nextProps)

        if ((!this.props.ehub || !this.props.ehub.accounts) && (!nextProps.ehub || !nextProps.ehub.accounts)) {
            accountCodes = setupAccounts(
                this.props.changeAccounts,
                accountCode,
                accountCodes,
                nextProps.user.otherAccounts,
            )
        }

        if (checkPath && !selected) {
            path = checkPath[0].value
            selected = checkPath[0]
        }
        if (selected && subPath !== this.props.match.params.subPath) {
            const allAccountCodes = this.props.user.otherAccounts
            nextProps.getEHubAppraisals(
                accountCodes,
                allAccountCodes,
                path,
                selected.value,
                nextProps.pagination.offsetFinish,
            )
        }
    }

    toggleStatus(value, id) {
        let status = ''
        switch (value) {
            case 'New':
                status = 'PHYSICAL'
                break
            case 'In stock':
                status = 'NEW'
                break
            case 'Archived':
                status = 'ARCHIVED'
                break
            case 'Restored':
                status = 'RESTORED'
                break
            default:
                break
        }

        this.props.onChangeAppraisalStatus(parseInt(id, 10), status, this.props.match.params.accountCode)
    }

    handleChange = (selectedOption) => {
        this.props.history.push({
            pathname: `/e-hub/${this.props.match.params.accountCode}/${this.props.match.params.path}/${selectedOption.path}`,
        })
    }

    updateCount = (count) => {
        this.setState({ totalVehicles: count })
    }

    toggleView(viewType) {
        return this.setState({ viewType })
    }

    setAppraisalLocationSearch(chosenLocation) {
        this.props.setAppraisalLocationSearch(chosenLocation)
        this.props.changePagination({
            currentPage: 0,
            offsetStart: 0,
            offsetFinish: this.props.pagination.offsetFinish,
        })
    }

    componentWillUnmount() {
        this.props.resetGroupsFilter()
        this.props.setAppraisalLocationSearch(null)
        this.setState({ appraisals: [] })
    }

    getCollectionAddress(appraisals) {
        const filtered = appraisals.filter(
            (a) => a.collectionAddress && (a.collectionAddress.company || a.collectionAddress.postcode),
        )
        const distinct = uniqWith(
            filtered,
            (a, b) =>
                a.collectionAddress.company === b.collectionAddress.company &&
                a.collectionAddress.postcode === b.collectionAddress.postcode,
        )
        return sortBy(distinct, 'collectionAddress.company')
    }

    getSelectedCheckPath(props) {
        const sLO =
            eHubOptions[props.match.params.path] &&
            eHubOptions[props.match.params.path].filter((option) => option.path === props.match.params.subPath)[0]
        const cpLO = eHubOptions[props.match.params.path]
        const sLC =
            eHubOptionsListAndCollect[props.match.params.path] &&
            eHubOptionsListAndCollect[props.match.params.path].filter(
                (option) => option.path === props.match.params.subPath,
            )[0]
        const cpLC = eHubOptionsListAndCollect[props.match.params.path]

        let accountCode = props.match.params.accountCode

        let selected = sLO
        let checkPath = cpLO

        if (
            props.user.accountsAccess &&
            props.user.accountsAccess[accountCode] &&
            props.user.accountsAccess[accountCode].listAndCollect
        ) {
            selected = sLC
            checkPath = cpLC
        }

        if (accountCode.split(',').length > 1) {
            // In this scenario, the user is logged in with an account that allows multiple account codes - in this case the
            // account code will look like e.g.
            // TEST002,TEST010,TEST003,TEST001,TEST005,TEST004
            // We need to split it, iterate over it, and find if any are "list and collect" and "list only" and present two
            // choices:
            // 1. if the list given has *either* lo or l&c ? easy, just present the appropriate selected/checkPath variable
            // 2. if the list contains accounts that have a mixture of both, then combine both worlds and feed it back to the
            // user so that the drop down menu appears the same as the dashboard for best UX experience.
            const ac = accountCode.split(',')

            const listOnlyFound = ac.find(
                (a) =>
                    props.user.accountsAccess && props.user.accountsAccess[a] && props.user.accountsAccess[a].listOnly,
            )
            const listAndCollectFound = ac.find(
                (a) =>
                    props.user.accountsAccess &&
                    props.user.accountsAccess[a] &&
                    props.user.accountsAccess[a].listAndCollect,
            )

            if (listAndCollectFound && listOnlyFound) {
                // Selected is the object element from checkPath that shows what got picked - we let it choose the best choice
                // out of the two given choices
                selected = sLC || sLO
                // CheckPath is the actual list - we want to merge both worlds and present a unified, deduplicated alternative
                checkPath = uniqWith([...cpLO, ...cpLC], (a, b) => a.path === b.path)
            } else if (listAndCollectFound) {
                selected = sLC
                checkPath = cpLC
            } else if (listOnlyFound) {
                selected = sLO
                checkPath = cpLO
            }
        }
        return { selected, checkPath }
    }

    render() {
        const { appraisals } = this.state

        const { onOpenSendToAuctionModal } = this.props
        let { selected, checkPath } = this.getSelectedCheckPath(this.props)

        let showGroupFilter = false
        const pathComponents = this.props.location.pathname.split('/').filter((e) => e)
        if (
            pathComponents.indexOf('e-hub') === 0 &&
            pathComponents.indexOf('e-xchange') === 2 &&
            appraisals.filter((e) => e.biddingDetails && e.biddingDetails.closedGroup).length > 0
        ) {
            showGroupFilter = true
        }

        if (checkPath && !selected) {
            selected = checkPath[0]
        }
        if (!selected) {
            this.props.history.push('/e-hub')
        }

        const collectionAddresses = this.getCollectionAddress(appraisals)

        let filteredAppraisals = getFilteredAppraisals(appraisals, this.props.locationSearch)
        if (showGroupFilter) {
            filteredAppraisals = getFilteredAppraisalGroup(
                appraisals,
                this.props.locationSearch,
                this.props.allGroupsFilter,
                this.props.closedGroupFilter,
            )
        }

        let locationOffset = showGroupFilter ? 0 : 9

        return (
            <React.Fragment>
                <Container fluid={true}>
                    <Container>
                        <Row className="ab-results-right">
                            <Col xs="12" lg="3" className="d-flex pl-0 pm-y-2">
                                <div className="input-group">
                                    <Select
                                        className="custom-select-input large-select"
                                        classNamePrefix="ab-select"
                                        value={selected}
                                        onChange={this.handleChange}
                                        options={checkPath}
                                        isSearchable={false}
                                    />
                                </div>
                            </Col>
                            <div className="col-sm-4 col-lg-2 ab-results-sort pm-y-2">
                                <Sorting
                                    sorting={this.props.sorting}
                                    options={getSortingOptionsForEhub(this.props.match.params.path)}
                                    onChangeSortOrder={this.props.onChangeSortOrder}
                                    offsetFinish={this.props.pagination.resultsPerPage.value}
                                />
                            </div>
                            <div className="col-sm-4 col-lg-5 d-flex justify-content-center pm-y-2">
                                <Pagination vehicles={filteredAppraisals} sortType={APP_EHUB} />
                            </div>
                            <div className="col-sm-4 col-lg-2 pm-y-2">
                                <PageSize
                                    vehicles={filteredAppraisals}
                                    pagination={this.props.pagination}
                                    setPageSize={this.props.setPageSize}
                                    theme={this.props.user.theme}
                                />
                            </div>
                        </Row>
                        <Row className="align-items-end pt-2">
                            {showGroupFilter && (
                                <>
                                    <Col sm={3}>
                                        <EhubCheckbox
                                            label="All Buyers"
                                            checked={this.props.allGroupsFilter}
                                            defaultValue={this.props.allGroupsFilter}
                                            onChange={this.props.toggleAppraisalAllGroupsFilter}
                                        />
                                    </Col>
                                    <Col>
                                        <EhubCheckbox
                                            label="Closed Groups"
                                            checked={this.props.closedGroupFilter}
                                            defaultValue={this.props.closedGroupFilter}
                                            onChange={this.props.toggleAppraisalClosedGroupsFilter}
                                        />
                                    </Col>
                                </>
                            )}
                            {collectionAddresses && collectionAddresses.length > 0 && (
                                <Col sm={{ size: 3, offset: locationOffset }}>
                                    <LocationSearch
                                        collectionAddresses={collectionAddresses}
                                        updateLocationSearch={this.setAppraisalLocationSearch}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Container>
                </Container>
                <Container fluid={true} className="e-hub__dashboard">
                    <Container className="e-hub__container p-0">
                        <div className="row mx-0">
                            {/* <!-- AB RIGHT COLUMN --> */}
                            <div className="col-lg-12 col-md-12 m-0 p-0 p-md-2">
                                {/* <!-- AB CARD RESULTS GRID --> */}
                                <div className="row mx-0 my-0">
                                    {this.props.ehub.loading ? (
                                        <div className="col px-2">
                                            <div className="loading-wrapper">
                                                <ScaleLoader
                                                    className={override}
                                                    size={10}
                                                    color={'#F67419'}
                                                    loading={true}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <SearchResults
                                            type={APP_EHUB}
                                            viewType={'grid'}
                                            selectedOption={selected}
                                            onOpenSendToAuctionModal={onOpenSendToAuctionModal}
                                            toggleStatus={this.toggleStatus}
                                            accountCode={
                                                this.props.ehub && this.props.ehub.accounts
                                                    ? this.props.ehub.accounts
                                                    : this.props.user.otherAccounts
                                            }
                                            updateCount={this.updateCount}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </Container>
                </Container>
                <Container fluid={true}>
                    <Container>
                        <Row className="ab-results-right py-3">
                            <div className="col-12 d-flex justify-content-center pm-y-2">
                                <Pagination vehicles={filteredAppraisals} sortType={APP_EHUB} />
                            </div>
                        </Row>
                    </Container>
                </Container>
                <SendToAuctionModal />
            </React.Fragment>
        )
    }
}

export default Vehicles
