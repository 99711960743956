import React, { Component } from 'react'

import confirm from '../../utilities/Confirm'

import { alertVehicle, checkFormEligibility } from '../../utilities/Functions'

import { getExchangeBtnText } from '../../utilities/AuctionFunctions'

/**
 * Usage:

 *
 *
 */
class EHubActionButtons extends Component {
    constructor(props) {
        super(props)
        this.dataUrl = props.dataUrl

        this.state = {
            proxyBid: null,
        }
        this.onClickChangeStatus = this.onClickChangeStatus.bind(this)
    }

    onClickChangeStatus() {
        this.props.toggleStatus(this.props.vehicle.status, this.props.vehicle.id, this.props.vehicle.vendorCode)
    }

    render = () => {
        const {
            user,
            vehicle,
            onOpenSendToAuctionModal,
            onClickBiddingModal,
            websocketConnected,
            vehicleDetails,
            accountCode,
            activeTab,
            checkDirtyForm,
            saveVehicleDetails,
        } = this.props

        if (vehicle.status) {
            if (vehicle.status === `Aston Barclay` && vehicle.vehicleStatus && vehicle.vehicleStatus === 'Unsold') {
                return (
                    <div
                        className={`${
                            vehicleDetails ||
                            (user.accountsAccess[accountCode] && !user.accountsAccess[accountCode].eXchangeSeller)
                                ? 'col-12'
                                : 'col-6'
                        }  m-0 p-2`}
                    >
                        <button
                            className="btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid disabled"
                            type="button"
                        >
                            SENT TO AUCTION
                        </button>
                    </div>
                )
            }

            if (vehicle.actions && vehicle.actions.sendToAuction) {
                return (
                    <div
                        className={`${
                            vehicleDetails ||
                            (user.accountsAccess[accountCode] && !user.accountsAccess[accountCode].eXchangeSeller)
                                ? 'col-12'
                                : 'col-6'
                        }  m-0 p-2`}
                    >
                        <button
                            className="btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid"
                            type="button"
                            onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                if (checkFormEligibility(vehicle)) {
                                    if (activeTab === '0' && !checkDirtyForm()) {
                                        return confirm('Unsaved changes', {
                                            buttonDismiss: 'Cancel',
                                            buttonConfirm: 'Save and continue',
                                            description:
                                                'You have unsaved changes, would you like to save now and continue adding to e-Xchange?',
                                        }).then(
                                            () => {
                                                saveVehicleDetails()
                                                onOpenSendToAuctionModal({
                                                    collectionSite: vehicle.collectionSite,
                                                    appraisalId: vehicle.id,
                                                    sivPrice: vehicle.sivPrice,
                                                    accountCode: vehicle.vendorCode,
                                                    eXchangeType: user.accountsAccess[vehicle.vendorCode].eXchangeType,
                                                    vehicleId: vehicle.vehicleId,
                                                })
                                            },
                                            () => {
                                                return false
                                            },
                                        )
                                    } else {
                                        onOpenSendToAuctionModal({
                                            collectionSite: vehicle.collectionSite,
                                            appraisalId: vehicle.id,
                                            sivPrice: vehicle.sivPrice,
                                            accountCode: vehicle.vendorCode,
                                            eXchangeType: user.accountsAccess[vehicle.vendorCode].eXchangeType,
                                            vehicleId: vehicle.vehicleId,
                                        })
                                    }
                                }
                            }}
                        >
                            SEND TO AUCTION
                        </button>
                    </div>
                )
            } else if (vehicle.actions.addToStock) {
                return (
                    <div className="col-12  m-0 p-2">
                        <button
                            className="btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid"
                            type="button"
                            onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                this.onClickChangeStatus()
                            }}
                        >
                            ADD TO STOCK
                        </button>
                    </div>
                )
            } else {
                return <div className="ab-button__spacer"></div>
            }
        } else if (
            user.tradeAccess &&
            user.eXchangeBuyer &&
            user.primaryVendorCode &&
            vehicle.cardType === 'e-xchange' &&
            !alertVehicle(vehicle)
        ) {
            if (websocketConnected) {
                return (
                    <React.Fragment>
                        {vehicle.biddingDetails ? (
                            <div className="col-12  m-0 p-2">
                                {user.accountName && vehicle.account !== user.accountName.value ? (
                                    <button
                                        className={`btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid e-hub ${
                                            vehicle.biddingDetails && !vehicle.biddingDetails.ended ? '' : 'disabled'
                                        }`}
                                        type="button"
                                        onClick={(event) => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            onClickBiddingModal({
                                                id: vehicle.id,
                                                listAndCollect: vehicle.listAndCollect,
                                            })
                                        }}
                                    >
                                        {getExchangeBtnText(vehicle, user.theme)}
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid e-hub disabled"
                                        type="button"
                                    >
                                        {user.accountName && user.accountName.value
                                            ? 'Your vehicle'
                                            : "Can't locate user to vehicle"}
                                    </button>
                                )}
                            </div>
                        ) : (
                            <React.Fragment>
                                {!user.tradeAccess && <div className="col-12  m-0 p-2">User missing trade access</div>}
                                {!user.liveBidAccess && (
                                    <div className="col-12  m-0 p-2">User missing live bid access.</div>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )
            } else {
                return (
                    <div className="col-12  m-0 p-2">
                        <button
                            className="btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid e-hub disabled"
                            type="button"
                        >
                            Connection lost - Reconnecting
                        </button>
                    </div>
                )
            }
        } else {
            return null
        }
    }
}

export default EHubActionButtons
