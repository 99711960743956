import React, { Component } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { accountLabelListToString, eHubConversions } from '../../utilities/Functions'
import { convertNameToLookup, lang, permissions } from '../../themeConfig'

class EhubNavigation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: '',
        }
    }

    goTo(link) {
        this.props.history.push(link)
    }

    render() {
        const { user, ehub } = this.props
        let userAccounts, accountName
        userAccounts = ehub && ehub.accounts && ehub.accounts.length > 0 ? ehub.accounts : []
        if (userAccounts && userAccounts.length > 0 && userAccounts.length < user.otherAccounts.length)
            accountName = accountLabelListToString(userAccounts)
        else if (user && user.otherAccounts && user.otherAccounts.length === 1)
            accountName = user.otherAccounts[0].label
        else accountName = 'All accounts selected'
        return (
            <Container fluid={true}>
                <Container className="e-hub__container p-0 pt-2">
                    <Row>
                        <Col xs="12" md={{ size: 4, order: 2, offset: 2 }}>
                            {!accountName ? (
                                <div className="e-hub__site" />
                            ) : (
                                <React.Fragment>
                                    {user.otherAccounts && user.otherAccounts.length > 0 ? (
                                        <div className="e-hub__site pr-3 ellipsis-overflow">{accountName}</div>
                                    ) : (
                                        <div className="e-hub__site pr-3">
                                            {user.accountName ? user.accountName.label : null}
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </Col>
                        <Col xs="12" md={{ size: 6, order: 1 }} className="ab-breadcrumb">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <div className="link" onClick={() => this.goTo('/e-hub')}>
                                            <div className="e-hub__logo">
                                                {permissions('EHUB_ICONS_ENABLED', this.props.user.theme) && (
                                                    <img src="/img/e-Hub.svg" alt="E-Hub" />
                                                )}
                                                {lang('EHUB_TITLE', this.props.user.theme)}
                                            </div>
                                        </div>
                                    </li>
                                    {this.props.match.params.path &&
                                        !this.props.match.params.subPath &&
                                        !this.props.match.params.id && (
                                            <li className="breadcrumb-item active" aria-current="page">
                                                {lang(
                                                    `EHUB_${convertNameToLookup[eHubConversions[this.props.match.params.path].toUpperCase()]}_TEXT`,
                                                    this.props.user.theme,
                                                )}
                                            </li>
                                        )}
                                    {this.props.match.params.path &&
                                        this.props.match.params.subPath &&
                                        !this.props.match.params.id && (
                                            <React.Fragment>
                                                <li className="breadcrumb-item active" aria-current="page">
                                                    {lang(
                                                        `EHUB_${convertNameToLookup[eHubConversions[this.props.match.params.path].toUpperCase()]}_TEXT`,
                                                        this.props.user.theme,
                                                    )}
                                                </li>
                                                <li className="breadcrumb-item active">
                                                    {lang(
                                                        `EHUB_${convertNameToLookup[eHubConversions[this.props.match.params.subPath].toUpperCase()]}_TEXT`,
                                                        this.props.user.theme,
                                                    )}
                                                </li>
                                            </React.Fragment>
                                        )}

                                    {this.props.match.params.vrm && (
                                        <React.Fragment>
                                            <li className="breadcrumb-item active">Search</li>
                                        </React.Fragment>
                                    )}

                                    {this.props.match.params.path &&
                                        this.props.match.params.subPath &&
                                        this.props.match.params.id && (
                                            <React.Fragment>
                                                <li className="breadcrumb-item active" aria-current="page">
                                                    {lang(
                                                        `EHUB_${convertNameToLookup[eHubConversions[this.props.match.params.path].toUpperCase()]}_TEXT`,
                                                        this.props.user.theme,
                                                    )}
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <div
                                                        className="link"
                                                        onClick={() =>
                                                            this.goTo({
                                                                pathname: `/e-hub/${this.props.match.params.accountCode}/${this.props.match.params.path}/${this.props.match.params.subPath}`,
                                                            })
                                                        }
                                                    >
                                                        {lang(
                                                            `EHUB_${convertNameToLookup[eHubConversions[this.props.match.params.subPath].toUpperCase()]}_TEXT`,
                                                            this.props.user.theme,
                                                        )}
                                                    </div>
                                                </li>
                                                <li className="breadcrumb-item active">
                                                    {lang('EHUB_BREADCRUMB_DETAILS', this.props.user.theme)}
                                                </li>
                                            </React.Fragment>
                                        )}
                                    {!this.props.match.params.path &&
                                        !this.props.match.params.subPath &&
                                        this.props.match.params.id && (
                                            <React.Fragment>
                                                <li className="breadcrumb-item active">
                                                    {lang('EHUB_BREADCRUMB_DETAILS', this.props.user.theme)}
                                                </li>
                                            </React.Fragment>
                                        )}
                                </ol>
                            </nav>
                        </Col>
                    </Row>
                </Container>
            </Container>
        )
    }
}

export default EhubNavigation
