import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { BUGSNAG_KEY } from './constants'

let e = React.Fragment
if (BUGSNAG_KEY) {
    Bugsnag.start({
        apiKey: BUGSNAG_KEY,
        plugins: [new BugsnagPluginReact()],
    })

    e = Bugsnag.getPlugin('react').createErrorBoundary(React)
}

export const ErrorBoundary = e
