import React, { PureComponent } from 'react'
import { lang } from '../../themeConfig'

class DefaultClearButton extends PureComponent {
    render() {
        return (
            <div className={this.props.className} onClick={() => this.props.onClick()}>
                {lang('CATALOGUE_RESET', this.props.theme)}{' '}
                {this.props.defaultText
                    ? lang('CATALOGUE_FILTER', this.props.theme)
                    : lang('CATALOGUE_ALL_FILTERS', this.props.theme)}
            </div>
        )
    }
}

export default DefaultClearButton
