import {
    FETCH_DELETE_SAVED_SEARCHES,
    FETCH_DELETE_SAVED_SEARCHES_ERROR,
    FETCH_DELETE_SAVED_SEARCHES_SUCCESS,
    FETCH_EXCHANGE_VEHICLES,
    FETCH_EXCHANGE_VEHICLES_ERROR,
    FETCH_EXCHANGE_VEHICLES_SUCCESS,
    FETCH_SAVED_SEARCHES,
    FETCH_SAVED_SEARCHES_ERROR,
    FETCH_SAVED_SEARCHES_SUCCESS,
    FETCH_USER,
    FETCH_USER_ERROR,
    FETCH_USER_SUCCESS,
    LOGON,
    LOGON_ERROR,
    LOGON_SUCCESS,
    ON_CHANGE_USER_ACCOUNT,
    SET_LANGUAGE,
    SET_THEME,
    USER_REDIRECT,
    ON_UNSUBSCRIBE,
    ON_UNSUBSCRIBE_FAILURE,
    ON_UNSUBSCRIBE_SUCCESS,
    UPDATE_SEARCH,
    UPDATE_SEARCH_FAILURE,
    UPDATE_SEARCH_SUCCESS,
    FETCH_SEARCH_USERS,
    FETCH_SEARCH_USERS_SUCCESS,
    FETCH_SEARCH_USERS_ERROR,
    FETCH_SEARCHES_FOR_USER,
    FETCH_SEARCHES_FOR_USER_SUCCESS,
    FETCH_SEARCHES_FOR_USER_ERROR,
} from '../constants'

const initialState = {
    theme: 'default',
    loading: false,
    name: null,
    username: null,
    accountName: { value: null, label: '' },
    otherAccounts: [],
    destinations: null,
    email: null,
    roles: [],
    savedSearches: [],
    error: null,
    redirect: false, // If true, will redirect to the login page
    loggedIn: false,
    eHubUser: false,
    eXchangeBuyer: false,
    tradeAccess: false,
    liveBidAccess: false,
    buyItNowAccess: false,
    marketing: false,
    secureTrustBankAccess: false,
    accountsAccess: [],
    exchangeVehicles: [],
    primaryVendorCode: null,
    blockLeaseplanBidAccess: false,
    closedGroupSeller: false,
    lang: 'eng',
    unsubscribe: {
        success: false,
        message: '',
    },
    searchUsers: {
        loading: false,
        results: [],
        selectedSearches: [],
    },
}

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_THEME:
            return {
                ...state,
                theme: action.theme,
            }
        case SET_LANGUAGE:
            return {
                ...state,
                lang: action.language,
            }
        case USER_REDIRECT:
            return {
                ...state,
                redirect: action.payload,
            }
        case LOGON:
        case FETCH_USER:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case LOGON_SUCCESS:
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
                error: null,
            }
        case LOGON_ERROR:
        case FETCH_USER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case FETCH_SAVED_SEARCHES:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case FETCH_SAVED_SEARCHES_SUCCESS:
            return {
                ...state,
                loading: false,
                savedSearches: action.payload,
                error: null,
            }
        case FETCH_SAVED_SEARCHES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case FETCH_EXCHANGE_VEHICLES:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case FETCH_EXCHANGE_VEHICLES_SUCCESS:
            return {
                ...state,
                loading: false,
                exchangeVehicles: action.payload,
                error: null,
            }
        case FETCH_EXCHANGE_VEHICLES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case ON_CHANGE_USER_ACCOUNT:
            return {
                ...state,
                accountName: action.payload,
            }
        case FETCH_DELETE_SAVED_SEARCHES:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case FETCH_DELETE_SAVED_SEARCHES_SUCCESS:
            return {
                ...state,
                loading: false,
                savedSearches: action.payload,
                error: null,
            }
        case FETCH_DELETE_SAVED_SEARCHES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case UPDATE_SEARCH:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case UPDATE_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case UPDATE_SEARCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case ON_UNSUBSCRIBE:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ON_UNSUBSCRIBE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                unsubscribe: {
                    success: action.payload.success,
                    message: action.payload.message,
                },
            }
        case ON_UNSUBSCRIBE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        case FETCH_SEARCH_USERS:
            return {
                ...state,
                searchUsers: {
                    loading: true,
                    results: [],
                    selectedSearches: [],
                },
                error: null,
            }
        case FETCH_SEARCH_USERS_SUCCESS:
            return {
                ...state,
                searchUsers: {
                    loading: false,
                    results: action.payload,
                    selectedSearches: [],
                },
                error: null,
            }
        case FETCH_SEARCH_USERS_ERROR:
            return {
                ...state,
                searchUsers: {
                    loading: false,
                    results: [],
                    selectedSearches: [],
                },
                error: action.payload.error,
            }
        case FETCH_SEARCHES_FOR_USER:
            return {
                ...state,
                searchUsers: {
                    loading: true,
                    results: state.searchUsers.results,
                    selectedSearches: [],
                },
                error: null,
            }
        case FETCH_SEARCHES_FOR_USER_SUCCESS:
            return {
                ...state,
                searchUsers: {
                    loading: false,
                    results: state.searchUsers.results,
                    selectedSearches: action.payload,
                },
                error: null,
            }
        case FETCH_SEARCHES_FOR_USER_ERROR:
            return {
                ...state,
                searchUsers: {
                    loading: false,
                    results: state.searchUsers.results,
                    selectedSearches: [],
                },
                error: action.payload.error,
            }
        default:
            return state
    }
}

export default UserReducer
