import React, { Component } from 'react'
class AuctionSchedule extends Component {
    render() {
        return (
            <table className="ab-auction-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Mon 06 May</th>
                        <th>Tues 07 May</th>
                        <th>Wed 08 May</th>
                        <th>Thu 09 May</th>
                        <th>Fri 10 May</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <img src="/img/auction-green.png" alt="auction" />
                            Chelmsford
                        </td>
                        <td className="has-auction">3 auctions</td>
                        <td></td>
                        <td className="has-auction">4 auctions</td>
                        <td className="has-auction">1 auction</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <img src="/img/auction-green.png" alt="auction" />
                            Donnington Park
                        </td>
                        <td className="has-auction selected">2 auctions</td>
                        <td className="has-auction">1 auctions</td>
                        <td></td>
                        <td className="has-auction">1 auction</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <img src="/img/auction-green.png" alt="auction" />
                            Leeds
                        </td>
                        <td className="has-auction">3 auctions</td>
                        <td></td>
                        <td className="has-auction">4 auctions</td>
                        <td></td>
                        <td className="has-auction">1 auction</td>
                    </tr>
                    <tr>
                        <td>
                            <img src="/img/auction-green.png" alt="auction" />
                            Press Heath
                        </td>
                        <td className="has-auction">3 auctions</td>
                        <td></td>
                        <td className="has-auction">4 auctions</td>
                        <td className="has-auction">1 auction</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <img src="/img/auction-green.png" alt="auction" />
                            Wakefield
                        </td>
                        <td className="has-auction">3 auctions</td>
                        <td></td>
                        <td className="has-auction">4 auctions</td>
                        <td className="has-auction">1 auction</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <img src="/img/auction-green.png" alt="auction" />
                            Westbury
                        </td>
                        <td className="has-auction">4 auctions</td>
                        <td></td>
                        <td className="has-auction">1 auction</td>
                        <td></td>
                        <td className="has-auction">3 auctions</td>
                    </tr>
                    <tr className="digital">
                        <td>
                            <img src="/img/auction-elive.png" alt="auction" />
                            e-Live
                        </td>
                        <td className="has-auction">3 auctions</td>
                        <td className="has-auction">2 auctions</td>
                        <td className="has-auction">4 auctions</td>
                        <td className="has-auction">1 auction</td>
                        <td></td>
                    </tr>
                    <tr className="digital">
                        <td>
                            <img src="/img/auction-exchange.png" alt="auction" />
                            e-Xchange
                        </td>
                        <td colSpan="5" className="has-auction text-center">
                            460 active vehicles
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}

export default AuctionSchedule
