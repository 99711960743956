import React, { Component } from 'react'
import { Alert, Button, Col, Container, Input, Row } from 'reactstrap'
import { lang, setTitle } from '../../themeConfig'
import { FORGOTTEN_PASSWORD_PATH } from '../../constants'
import { FaChevronCircleRight } from '@react-icons/all-files/fa/FaChevronCircleRight'

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
        }
        this.login = this.login.bind(this)
    }

    componentDidMount() {
        setTitle('Login', this.props.user.theme)
    }

    async getSplashImage() {
        return await import(/* @vite-ignore */ `../../assets/img/${this.props.global.splash}`)
    }

    login() {
        this.props.login(this.props.history, this.state.username, this.state.password)
    }

    render() {
        const contact_name = this.props.global.contactName
        const contact_email = this.props.global.contactEmail

        let errMessage = null
        if (this.props.user.error === 'User is unauthorised' && contact_name && contact_email) {
            errMessage = (
                <Alert color="danger">
                    Sorry, you are not authorised to access this site. Please contact contact {contact_name} (
                    {contact_email}) to discuss your account
                </Alert>
            )
        }

        return (
            <Container fluid={true} className="px-0 position-relative">
                <Container>
                    <Row>
                        <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 0 }}>
                            {errMessage}
                            <div className="login__section">
                                <h1>{lang('LOGIN', this.props.user.theme)}</h1>
                                <p>{lang('LOGIN_DESCRIPTION_1', this.props.user.theme)}</p>
                                <p>{lang('LOGIN_DESCRIPTION_2', this.props.user.theme)}</p>
                                <Row>
                                    <Col xs="12" md="8">
                                        <form>
                                            <div className="form-group">
                                                <label htmlFor="username">
                                                    {lang('USERNAME', this.props.user.theme)}
                                                </label>
                                                <Input
                                                    type="text"
                                                    id="username"
                                                    className="form-control"
                                                    placeholder="Enter username"
                                                    autoComplete="username"
                                                    value={this.state.username}
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            username: evt.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password">
                                                    {lang('PASSWORD', this.props.user.theme)}
                                                </label>
                                                <Input
                                                    type="password"
                                                    id="password"
                                                    className="form-control"
                                                    placeholder="Enter password"
                                                    autoComplete="current-password"
                                                    value={this.state.password}
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            password: evt.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </form>
                                    </Col>
                                </Row>
                                <p className="mt-3">
                                    <small
                                        dangerouslySetInnerHTML={{
                                            __html: lang('LOGIN_TERMS', this.props.user.theme),
                                        }}
                                    />{' '}
                                    <small>
                                        {this.props.user.theme !== 'default' && (
                                            <a href={`mailto: ${contact_email}`}>{contact_email}</a>
                                        )}
                                    </small>
                                </p>
                                <Button className="ab__button-round" type="submit" onClick={() => this.login()}>
                                    {lang('LOGIN', this.props.user.theme)}
                                </Button>
                                <p className="pt-5 ab-forgot__password">
                                    <a
                                        href={`${this.props.global.frontMediaHost}${FORGOTTEN_PASSWORD_PATH}`}
                                        target={(this.props.user.theme !== 'default' && '_blank') || null}
                                        rel={(this.props.user.theme !== 'default' && 'noreferrer noopener') || null}
                                    >
                                        {lang('FORGOTTEN_PASSWORD', this.props.user.theme)}
                                        <FaChevronCircleRight className="position-absolute ml-3" size="1.5em" />
                                    </a>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div
                    className="d-none d-lg-block splash-image"
                    style={{
                        backgroundImage: `url(/img/${this.props.global.splash})`,
                        position: 'absolute',
                        width: '50%',
                        height: '101%',
                        top: '-9px',
                        left: '50%',
                    }}
                />
            </Container>
        )
    }
}

export default Login
