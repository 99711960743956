import React, { PureComponent } from 'react'

export default class NotSignedInBanner extends PureComponent {
    render() {
        return (
            <div className="not-signed-in not-signed-in-message">
                You are not currently signed in - please sign in to view this data
            </div>
        )
    }
}
