import {
    API_BASE,
    API_GET_HEADERS,
    API_PATCH_HEADERS,
    API_PUT_HEADERS,
    CHANGE_APPRAISAL_STATUS,
    CHANGE_APPRAISAL_STATUS_ERROR,
    CHANGE_APPRAISAL_STATUS_SUCCESS,
    FETCH_EHUB_APPRAISALS,
    FETCH_EHUB_APPRAISALS_ERROR,
    FETCH_EHUB_APPRAISALS_SUCCESS,
    FETCH_STATS,
    FETCH_STATS_ERROR,
    FETCH_STATS_SUCCESS,
    FETCH_VEHICLE_APPRAISAL,
    FETCH_VEHICLE_APPRAISAL_ERROR,
    FETCH_VEHICLE_APPRAISAL_SUCCESS,
    FETCH_VRM,
    FETCH_VRM_ERROR,
    FETCH_VRM_SUCCESS,
    REFRESH_CAP_PRICE_ERROR,
    REFRESH_CAP_PRICE_SUCCESS,
    SAVE_APPRAISAL_DETAILS,
    SAVE_APPRAISAL_DETAILS_ERROR,
    SAVE_APPRAISAL_DETAILS_SUCCESS,
    SEND_TO_AUCTION,
    SEND_TO_AUCTION_ERROR,
    SEND_TO_AUCTION_SUCCESS,
    ADD_TO_EXCHANGE,
    ADD_TO_EXCHANGE_SUCCESS,
    ADD_TO_EXCHANGE_ERROR,
    SORT_APPRAISALS,
    FETCH_ALL_VRM,
    FETCH_ALL_VRM_ERROR,
    FETCH_ALL_VRM_SUCCESS,
    SET_APPRAISAL_LOCATION_SEARCH,
    TOGGLE_APPRAISAL_CLOSED_GROUPS_FILTER,
    TOGGLE_APPRAISAL_ALL_GROUPS_FILTER,
    RESET_APPRAISAL_GROUPS_FILTER,
    CHANGE_ACCOUNTS,
} from '../constants'

import { addNotification } from './notifications'
import { setSortingOrder } from './sorting'

import { changePagination } from './pagination'

import {
    handleError,
    accountCodeListToString,
    getAccountCodes,
    addRequestParamAccountList,
    setAppraisalFullAccount,
} from '../utilities/Functions'

import { lang } from '../themeConfig'
export const fetchStatsBegin = () => ({
    type: FETCH_STATS,
})

export const changeVehicleStatus = () => ({
    type: CHANGE_APPRAISAL_STATUS,
})

export const changeVehicleStatusSuccess = (payload) => ({
    type: CHANGE_APPRAISAL_STATUS_SUCCESS,
    payload,
})

export const changeVehicleStatusFailure = (payload) => ({
    type: CHANGE_APPRAISAL_STATUS_ERROR,
    payload,
})
export const refreshCapPriceSuccess = (payload) => ({
    type: REFRESH_CAP_PRICE_SUCCESS,
    payload,
})

export const refreshCapPriceFailure = (payload) => ({
    type: REFRESH_CAP_PRICE_ERROR,
    payload,
})

export const saveVehicleDetails = () => ({
    type: SAVE_APPRAISAL_DETAILS,
})

export const saveVehicleDetailsSuccess = (payload) => ({
    type: SAVE_APPRAISAL_DETAILS_SUCCESS,
    payload,
})

export const saveVehicleDetailsFailure = (payload) => ({
    type: SAVE_APPRAISAL_DETAILS_ERROR,
    payload,
})

export const fetchStatsSuccess = (payload) => ({
    type: FETCH_STATS_SUCCESS,
    payload,
})

export const fetchStatsFailure = (error) => ({
    type: FETCH_STATS_ERROR,
    payload: error,
})

export const fetchAppraisalsBegin = () => ({
    type: FETCH_EHUB_APPRAISALS,
})

export const fetchAppraisalsSuccess = (payload) => ({
    type: FETCH_EHUB_APPRAISALS_SUCCESS,
    payload,
})

export const fetchAppraisalsFailure = (error) => ({
    type: FETCH_EHUB_APPRAISALS_ERROR,
    payload: error,
})

export const fetchVehicleAppraisalBegin = () => ({
    type: FETCH_VEHICLE_APPRAISAL,
})

export const fetchVehicleAppraisalSuccess = (payload) => ({
    type: FETCH_VEHICLE_APPRAISAL_SUCCESS,
    payload,
})

export const fetchVehicleAppraisalFailure = (error) => ({
    type: FETCH_VEHICLE_APPRAISAL_ERROR,
    payload: error,
})

export const sendToAuction = () => ({
    type: SEND_TO_AUCTION,
})

export const sendToAuctionSuccess = (payload) => ({
    type: SEND_TO_AUCTION_SUCCESS,
    payload,
})

export const sendToAuctionFailure = (error) => ({
    type: SEND_TO_AUCTION_ERROR,
    payload: error,
})

export const addToEXchangeBegin = () => ({
    type: ADD_TO_EXCHANGE,
})

export const addToEXchangeSuccess = (payload) => ({
    type: ADD_TO_EXCHANGE_SUCCESS,
    payload,
})

export const addToEXchangeFailure = (error) => ({
    type: ADD_TO_EXCHANGE_ERROR,
    payload: error,
})

export const fetchVRMBegin = () => ({
    type: FETCH_VRM,
})

export const fetchVRMSuccess = (payload) => ({
    type: FETCH_VRM_SUCCESS,
    payload,
})

export const fetchVRMFailure = (error) => ({
    type: FETCH_VRM_ERROR,
    payload: error,
})

export const fetchAllVRMBegin = () => ({
    type: FETCH_ALL_VRM,
})

export const fetchAllVRMSuccess = (payload) => ({
    type: FETCH_ALL_VRM_SUCCESS,
    payload,
})

export const fetchAllVRMFailure = (error) => ({
    type: FETCH_ALL_VRM_ERROR,
    payload: error,
})

export const sortAppraisals = (value) => ({
    type: SORT_APPRAISALS,
    payload: value,
})

export const setAppraisalLocationSearch = (value) => ({
    type: SET_APPRAISAL_LOCATION_SEARCH,
    payload: value,
})

export const toggleAppraisalAllGroupsFilter = () => ({
    type: TOGGLE_APPRAISAL_ALL_GROUPS_FILTER,
})

export const toggleAppraisalClosedGroupsFilter = () => ({
    type: TOGGLE_APPRAISAL_CLOSED_GROUPS_FILTER,
})

export const resetGroupsFilter = () => ({
    type: RESET_APPRAISAL_GROUPS_FILTER,
})

export const changeAccounts = (accounts) => ({
    type: CHANGE_ACCOUNTS,
    accounts,
})

export function fetchStats(accountCodes, allAccountCodes) {
    accountCodes = getAccountCodes(accountCodes, allAccountCodes)
    let url = `${API_BASE}api/e-hub/dashboard`
    url = addRequestParamAccountList(url, 'code', accountCodes)
    return async (dispatch) => {
        dispatch(fetchStatsBegin())
        return fetch(url, API_GET_HEADERS('1.0'))
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(fetchStatsSuccess(data))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchStatsFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,
                        type: 'error',
                    }),
                )
                dispatch(fetchStatsFailure(error))
            })
    }
}

export function getEHubAppraisals(accountCodes, allAccountCodes, path, subPath, offsetFinish) {
    let urlPath
    if (path === 'collections') {
        urlPath = `collection`
    } else {
        urlPath = `${path}/${subPath}`
    }

    accountCodes = getAccountCodes(accountCodes, allAccountCodes)
    let url = `${API_BASE}api/e-hub/${urlPath}`
    url = addRequestParamAccountList(url, 'code', accountCodes)

    return async (dispatch) => {
        dispatch(fetchAppraisalsBegin())
        return fetch(url, API_GET_HEADERS('1.0'))
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    const sortedIds = data.map((vehicle) => ({
                        id: vehicle.id,
                        vendor: vehicle.vendorCode || vehicle.account,
                    }))
                    const appraisals = data.map((appraisal) => setAppraisalFullAccount(appraisal, allAccountCodes))
                    dispatch(
                        changePagination({
                            currentPage: 1,
                            offsetStart: 0,
                            offsetFinish: offsetFinish,
                        }),
                    )
                    dispatch(setSortingOrder(sortedIds))
                    dispatch(fetchAppraisalsSuccess(appraisals))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchAppraisalsFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,
                        type: 'error',
                    }),
                )
                dispatch(fetchAppraisalsFailure(error))
            })
    }
}

export function geteHubVehicleAppraisal(id, accountCode, allAccountCodes, path) {
    let url = `${API_BASE}api/e-hub/${accountCode}/appraisal/${id}`
    let headers = API_GET_HEADERS('1.0')

    if (path) {
        url = `${API_BASE}api/e-hub/${accountCode}/appraisal/${id}/${path}`
    }
    return async (dispatch) => {
        dispatch(fetchVehicleAppraisalBegin())
        return fetch(url, headers)
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    data = setAppraisalFullAccount(data, allAccountCodes)
                    dispatch(fetchVehicleAppraisalSuccess({ path, data }))
                    return data
                } else {
                    handleError(status, dispatch, data, fetchVehicleAppraisalFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,
                        type: 'error',
                    }),
                )
                dispatch(fetchVehicleAppraisalFailure(error))
            })
    }
}

export function onChangeAppraisalStatus(id, status, accountCode) {
    let newStatus = ''
    switch (status) {
        case 'PHYSICAL':
            newStatus = 'set to in stock'
            break
        case 'NEW':
            newStatus = 'set to new'
            break
        case 'ARCHIVED':
            newStatus = 'archived'
            break
        case 'RESTORED':
            newStatus = 'returned from archive'
            break
        default:
            break
    }
    const url = `${API_BASE}api/e-hub/${accountCode}/appraisal/${id}/status/${status}`
    return async (dispatch) => {
        dispatch(changeVehicleStatus())
        return fetch(url, API_PATCH_HEADERS('1.0'))
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(changeVehicleStatusSuccess(data))
                    dispatch(
                        addNotification({
                            text: `Status of appraisal successfully ${newStatus}`,
                            type: 'success',
                            delay: 3000,
                        }),
                    )
                    return data
                } else {
                    handleError(status, dispatch, data, changeVehicleStatusFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,
                        type: 'error',
                    }),
                )
                dispatch(changeVehicleStatusFailure(error))
            })
    }
}

export function onClickSendToAuction(id, payload, accountCode, exchangeType) {
    let url = `${API_BASE}api/e-hub/${accountCode}/appraisal/${id}/auction`

    const newHeaders = Object.assign(API_PUT_HEADERS('1.1'), {
        body: JSON.stringify(payload),
    })
    return async (dispatch) => {
        dispatch(sendToAuction())
        if (!payload.addressId && exchangeType !== 'ListAndDeliver') {
            dispatch(sendToAuctionFailure({ error: 'No address id' }))
            return dispatch(
                addNotification({
                    text: lang('ERROR_NO_DESTINATION_SITE_SA'),
                    type: 'error',
                }),
            )
        }
        if (!payload.reserve) {
            dispatch(sendToAuctionFailure({ error: 'No reserve' }))
            return dispatch(
                addNotification({
                    text: lang('ERROR_NO_RESERVE_SA'),
                    type: 'error',
                }),
            )
        }
        return fetch(url, newHeaders)
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(sendToAuctionSuccess(data))
                    dispatch(
                        addNotification({
                            text: 'Successfully sent to auction',
                            type: 'success',
                            delay: 3000,
                        }),
                    )
                    return data
                } else {
                    handleError(status, dispatch, data, sendToAuctionFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,
                        type: 'error',
                    }),
                )
                dispatch(sendToAuctionFailure(error))
            })
    }
}

export function onClickAddToEXchange(id, payload, accountCode) {
    let url
    if (payload.vehicleId) {
        url = `${API_BASE}api/e-hub/${accountCode}/appraisal/${id}/e-xchange/relist`
    } else {
        url = `${API_BASE}api/e-hub/${accountCode}/appraisal/${id}/e-xchange`
    }

    const newHeaders = Object.assign(API_PUT_HEADERS('1.1'), {
        body: JSON.stringify(payload),
    })
    return (dispatch) => {
        dispatch(addToEXchangeBegin())
        fetch(url, newHeaders)
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(addToEXchangeSuccess(data))
                    dispatch(
                        addNotification({
                            text: 'Successfully sent to e-xchange',
                            delay: 5000,
                            type: 'success',
                        }),
                    )
                    return data
                } else {
                    handleError(status, dispatch, data, addToEXchangeFailure)
                }
            })
            .catch((error) => {
                dispatch(addToEXchangeFailure(error))
            })
    }
}

export function onAllSearchVrm(value) {
    const url = `${API_BASE}api/e-hub/appraisal/vrm/${value.toUpperCase().replace(' ', '')}`
    return async (dispatch) => {
        if (value !== '') {
            dispatch(fetchAllVRMBegin())
            return fetch(url, API_GET_HEADERS('1.0'))
                .then((response) => Promise.all([response.status, response.ok, response.json()]))
                .then(([status, responseOk, data]) => {
                    if (responseOk) {
                        dispatch(fetchAllVRMSuccess(data))
                        return new Promise((resolve, reject) => {
                            if (data.length > 0) {
                                resolve(data)
                            } else {
                                dispatch(
                                    addNotification({
                                        text: `No vehicles found with the VRM ${value.toUpperCase()}`,
                                        type: 'error',
                                    }),
                                )
                            }
                        })
                    } else {
                        handleError(status, dispatch, data, fetchAllVRMFailure)
                    }
                })
                .catch((error) => {
                    dispatch(
                        addNotification({
                            text: `Failed api ${url}`,
                            type: 'error',
                        }),
                    )
                    dispatch(fetchAllVRMFailure(error))
                })
        } else {
            return new Promise((resolve, reject) => {
                reject({ id: null })
                dispatch(
                    addNotification({
                        text: `Unable to search a blank VRM`,
                        delay: 5000,
                        type: 'error',
                    }),
                )
            })
        }
    }
}

export function onSearchVRM(value, allAccountCodes) {
    const vrm = value.toUpperCase().replace(' ', '')
    const url = `${API_BASE}api/e-hub/${accountCodeListToString(allAccountCodes)}/appraisal/vrm/${vrm}`
    return async (dispatch) => {
        dispatch(fetchVRMBegin())
        if (value !== '') {
            return fetch(url, API_GET_HEADERS('1.0'))
                .then((response) => Promise.all([response.status, response.ok, response.json()]))
                .then(([status, responseOk, data]) => {
                    if (responseOk) {
                        dispatch(fetchVRMSuccess(data))
                        return new Promise((resolve, reject) => {
                            if (Array.isArray(data) && data.length > 0) {
                                if (data.length === 1)
                                    resolve(data[0].path)
                                else
                                    resolve(vrm)
                            } else if (data.path) {
                                resolve(data.path)
                            } else {
                                dispatch(
                                    addNotification({
                                        text: `No vehicle found with the VRM ${value.toUpperCase()}`,
                                        type: 'error',
                                    }),
                                )
                            }
                        })
                    } else {
                        handleError(status, dispatch, data, fetchVRMFailure)
                    }
                })
                .catch((error) => {
                    dispatch(
                        addNotification({
                            text: `Failed api ${url}`,
                            type: 'error',
                        }),
                    )
                    dispatch(fetchVRMFailure(error))
                })
        } else {
            return new Promise((resolve, reject) => {
                reject({ id: null })
                dispatch(
                    addNotification({
                        text: `Unable to search a blank VRM`,
                        delay: 5000,
                        type: 'error',
                    }),
                )
            })
        }
    }
}

export function onSaveVehicleDetails(payload, accountCode) {
    let url
    if (payload.unsold) {
        url = `${API_BASE}api/e-hub/${accountCode}/appraisal/${payload.id}/vehicle/${payload.vehicleId}/price`
    } else {
        url = `${API_BASE}api/e-hub/${accountCode}/appraisal/${payload.id}/price`
    }

    const newHeaders = Object.assign(API_PATCH_HEADERS('1.0'), {
        body: JSON.stringify(payload.data),
    })
    return async (dispatch) => {
        dispatch(saveVehicleDetails())
        return fetch(url, newHeaders)
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(saveVehicleDetailsSuccess(data))
                    dispatch(
                        addNotification({
                            text: 'Appraisal details saved successfully',
                            type: 'success',
                            delay: 3000,
                        }),
                    )
                    return data
                } else {
                    handleError(status, dispatch, data, saveVehicleDetailsFailure)
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,
                        type: 'error',
                    }),
                )
                dispatch(saveVehicleDetailsFailure(error))
            })
    }
}
