import React, { Component } from 'react'
import Select from 'react-select'
import { lang } from '../../themeConfig'

class PageSize extends Component {
    onChangePageSize = (selectedOption) => {
        this.props.setPageSize({
            offsetStart: 0,
            offsetFinish: selectedOption.value,
            resultsPerPage: selectedOption,
        })
    }

    render() {
        const options = [
            { value: 12, label: `12 ${lang('CATALOGUE_PER_PAGE', this.props.theme)}` },
            { value: 30, label: `30 ${lang('CATALOGUE_PER_PAGE', this.props.theme)}` },
            { value: 48, label: `48 ${lang('CATALOGUE_PER_PAGE', this.props.theme)}` },
        ]
        return (
            <div className="input-group">
                <Select
                    value={this.props.pagination.resultsPerPage}
                    className="custom-select-input prepend"
                    onChange={this.onChangePageSize}
                    options={options}
                    isSearchable={false}
                    classNamePrefix="ab-select"
                />
            </div>
        )
    }
}

export default PageSize
