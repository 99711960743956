import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import HomePage from './HomePage'

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage))
