import {
    API_BASE,
    API_GET_HEADERS,
    OPEN_PROXY_BID_MODAL,
    CLOSE_PROXY_BID_MODAL,
    OPEN_BUY_NOW_MODAL,
    CLOSE_BUY_NOW_MODAL,
    OPEN_SAVE_SEARCH_MODAL,
    CLOSE_SAVE_SEARCH_MODAL,
    OPEN_VEHICLE_PHOTO_MODAL,
    CLOSE_VEHICLE_PHOTO_MODAL,
    OPEN_SEND_TO_AUCTION_MODAL_BEGIN,
    OPEN_SEND_TO_AUCTION_MODAL_SUCCESS,
    OPEN_SEND_TO_AUCTION_MODAL_ERROR,
    CLOSE_SEND_TO_AUCTION_MODAL,
    OPEN_ADD_TO_EXCHANGE_MODAL_BEGIN,
    OPEN_ADD_TO_EXCHANGE_MODAL_SUCCESS,
    OPEN_ADD_TO_EXCHANGE_MODAL_ERROR,
    CLOSE_ADD_TO_EXCHANGE_MODAL,
    OPEN_BIDDING_MODAL,
    CLOSE_BIDDING_MODAL,
    GET_CLOSED_GROUP_SUCCESS,
    GET_CLOSED_GROUP_FAILURE,
    OPEN_EDIT_SEARCH_MODAL,
    CLOSE_EDIT_SEARCH_MODAL,
} from '../constants'

import { dispatchCall, addRequestParamAccount } from '../utilities/Functions'

export const openProxyBidModal = (value) => ({
    type: OPEN_PROXY_BID_MODAL,
    payload: value,
})

export const closeProxyBidModal = (value) => ({
    type: CLOSE_PROXY_BID_MODAL,
    payload: value,
})

export const openBuyNowModal = (value) => ({
    type: OPEN_BUY_NOW_MODAL,
    payload: value,
})

export const closeBuyNowModal = (value) => ({
    type: CLOSE_BUY_NOW_MODAL,
    payload: value,
})

export const openSendToAuctionModalBegin = (value) => ({
    type: OPEN_SEND_TO_AUCTION_MODAL_BEGIN,
    payload: value,
})

function openSendToAuctionModalSuccess(data) {
    function action(value) {
        return {
            type: OPEN_SEND_TO_AUCTION_MODAL_SUCCESS,
            payload: {
                ...data,
                collectionSites: value,
            },
        }
    }

    return action
}

export const openSendToAuctionModalFailure = (error) => ({
    type: OPEN_SEND_TO_AUCTION_MODAL_ERROR,
    payload: { error },
})

export const closeSendToAuctionModal = (value) => ({
    type: CLOSE_SEND_TO_AUCTION_MODAL,
    payload: value,
})

export const openAddToEXchangeModalBegin = (value) => ({
    type: OPEN_ADD_TO_EXCHANGE_MODAL_BEGIN,
    payload: value,
})

function openAddToEXchangeModalSuccess(data) {
    function action(value) {
        return {
            type: OPEN_ADD_TO_EXCHANGE_MODAL_SUCCESS,
            payload: {
                ...data,
                collectionSites: value,
            },
        }
    }

    return action
}

export const getClosedGroupSuccess = (value) => ({
    type: GET_CLOSED_GROUP_SUCCESS,
    payload: value,
})

export const getClosedGroupFailure = (error) => ({
    type: GET_CLOSED_GROUP_FAILURE,
})

export const openAddToEXchangeModalFailure = (error) => ({
    type: OPEN_ADD_TO_EXCHANGE_MODAL_ERROR,
    payload: { error },
})

export const closeAddToEXchangeModal = (value) => ({
    type: CLOSE_ADD_TO_EXCHANGE_MODAL,
    payload: value,
})

export const openSaveSearchModal = (value) => ({
    type: OPEN_SAVE_SEARCH_MODAL,
    payload: value,
})

export const closeSaveSearchModal = (value) => ({
    type: CLOSE_SAVE_SEARCH_MODAL,
    payload: value,
})

export const openVehiclePhotohModal = (value) => ({
    type: OPEN_VEHICLE_PHOTO_MODAL,
    payload: value,
})

export const openBiddingModal = (payload) => ({
    type: OPEN_BIDDING_MODAL,
    payload,
})

export const closeBiddingModal = (id) => ({
    type: CLOSE_BIDDING_MODAL,
    payload: id,
})

export const closeVehiclePhotohModal = (value) => ({
    type: CLOSE_VEHICLE_PHOTO_MODAL,
    payload: value,
})

export const openEditSearchModal = (value, useSelectedSearches, selectedUsername) => ({
    type: OPEN_EDIT_SEARCH_MODAL,
    payload: value,
    useSelectedSearches,
    selectedUsername,
})

export const closeEditSearchModal = (value) => ({
    type: CLOSE_EDIT_SEARCH_MODAL,
    payload: value,
})

export function onOpenProxyBidModal(value) {
    return (dispatch) => {
        dispatch(openProxyBidModal(value))
    }
}

export function onCloseProxyBidModal(value) {
    return (dispatch) => {
        dispatch(closeProxyBidModal(value))
    }
}

export function onOpenBuyNowModal(value) {
    return (dispatch) => {
        dispatch(openBuyNowModal(value))
    }
}

export function onCloseBuyNowModal(value) {
    return (dispatch) => {
        dispatch(closeBuyNowModal(value))
    }
}

export function onOpenSaveSearchModal(value) {
    return (dispatch) => {
        dispatch(openSaveSearchModal(value))
    }
}

export function onCloseSaveSearchModal(value) {
    return (dispatch) => {
        dispatch(closeSaveSearchModal(value))
    }
}

export function onOpenVehiclePhotoModal(value) {
    return (dispatch) => {
        dispatch(openVehiclePhotohModal(value))
    }
}

export function onCloseVehiclePhotoModal(value) {
    return (dispatch) => {
        dispatch(closeVehiclePhotohModal(value))
    }
}

export function onOpenSendToAuctionModal(value) {
    var success = openSendToAuctionModalSuccess(value)

    const url = `${API_BASE}api/e-hub/${value.accountCode}/addresses`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS('1.1'),
        }
        dispatchCall(dispatch, fetchDetails, openSendToAuctionModalBegin, success, openSendToAuctionModalFailure)
    }
}

export function onCloseSendToAuctionModal(value) {
    return (dispatch) => {
        dispatch(closeSendToAuctionModal(value))
    }
}

export function getClosedGroup(accountCode) {
    let url = `${API_BASE}api/e-hub/closedgroup`
    url = addRequestParamAccount(url, 'accountCode', accountCode)
    return (dispatch) => {
        return fetch(url, API_GET_HEADERS('1.1'))
            .then((response) => Promise.all([response.ok, response.json()]))
            .then(([responseOk, data]) => {
                if (responseOk && Object.keys(data).length > 0) {
                    dispatch(getClosedGroupSuccess(data))
                }
            })
            .catch((error) => {
                dispatch(getClosedGroupFailure(error))
            })
    }
}

export function onOpenAddToEXchangeModal(value) {
    var success = openAddToEXchangeModalSuccess(value)

    const url = `${API_BASE}api/e-hub/${value.accountCode}/addresses`
    return async (dispatch) => {
        const fetchDetails = {
            url: url,
            headers: API_GET_HEADERS('1.1'),
        }
        dispatchCall(dispatch, fetchDetails, openAddToEXchangeModalBegin, success, openAddToEXchangeModalFailure)
    }
}

export function onCloseAddToEXchangeModal(value) {
    return (dispatch) => {
        dispatch(closeAddToEXchangeModal(value))
    }
}

export function onClickBiddingModal(value) {
    return (dispatch) => {
        dispatch(openBiddingModal(value))
    }
}

export function onCloseBiddingModal(value) {
    return (dispatch) => {
        dispatch(closeBiddingModal(value))
    }
}

export function onOpenEditSearchModal(value, useSelectedSearches = false, selectedUsername) {
    return (dispatch) => {
        dispatch(openEditSearchModal(value, useSelectedSearches, selectedUsername))
    }
}

export function onCloseEditSearchModal(value) {
    return (dispatch) => {
        dispatch(closeEditSearchModal(value))
    }
}