import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { css } from '@emotion/react'
import { Prompt } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'
import { isEqual } from 'lodash'
import SendToAuctionModal from '../../Modals/SendToAuctionModal'
import AddToEXchangeModal from '../../Modals/AddToEXchangeModal'
import AuctionCountdown from '../../../UiKit/AuctionCountdown'
import { getBidLabel, getReserveStatus, showRemaining } from '../../../utilities/AuctionFunctions'
import { ISODateString } from '../../../utilities/DateFunctions'
import { convertNameToLookup, lang } from '../../../themeConfig'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import VehicleBanner from '../../../UiKit/VehicleCard/VehicleBanner'

import { Nav, NavLink, TabContent, TabPane } from 'reactstrap'

import {
    getDdateOfRegistration,
    getRegistration,
    getVehicleImageUrl,
    VehicleStatsRow,
} from '../../CatalogueVehicleDetails/VehicleHelper'

import {
    appraisalNewOrInStock,
    getDamageInformation,
    getDamageQuestions,
    getPricingSignatures,
    getVehicleDescription,
    getVehicleSmallDetails,
    showPriorListings,
} from '../../CatalogueVehicleDetails/VehicleDetailsHelper'

import {
    accountCodeToLabel,
    checkFormEligibility,
    collectionAddressString,
    collectionAddressValid,
    eHubConversions,
    isEHubUser,
    openAddToExchangeModal,
    renderHistoricalPrices,
    renderHistoricExchangeAuctionDetails,
} from '../../../utilities/Functions'

import VehicleIcons from '../../../UiKit/VehicleCard/icons/VehicleIcons'

import confirm from '../../../utilities/Confirm'

import EHubActionButtons from '../../../UiKit/ActionButtons/EHubActionButtons'
import PhotoModal from '../../Modals/PhotoModal'

import { API_BASE, CT_COLLECTIONS, CT_EHUB_EXCHANGE, CT_EXCHANGE, CURRENCY } from '../../../constants'
import { FaTimesCircle } from '@react-icons/all-files/fa/FaTimesCircle'
import { FaChevronLeft } from '@react-icons/all-files/fa/FaChevronLeft'
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight'
import { FaCheck } from '@react-icons/all-files/fa/FaCheck'
import { FaTimes } from '@react-icons/all-files/fa/FaTimes'
import { FaFilePdf } from '@react-icons/all-files/fa/FaFilePdf'

const override = css`
    display: block;
    margin: 0 auto;
`
class EhubVehicleDetails extends Component {
    constructor(props) {
        super(props)
        this.initialActiveTab = '0'
        this.toggleStatus = this.toggleStatus.bind(this)
        this.saveVehicleDetails = this.saveVehicleDetails.bind(this)
        this.onChangeField = this.onChangeField.bind(this)
        this.toggleTab = this.toggleTab.bind(this)
        this.doSomethingBeforeUnload = this.doSomethingBeforeUnload.bind(this)
        this.state = {
            activeTab: '0',
            vehiclePhotoModal: false,
            imagePosition: 0,
            phycial: false,
            editable: true,
            formValues: {},
            initialFormValues: {},
            submitting: false,
            appraisal: null, // Object
            editing: false,
            unsaved: true,
            currentAppraisal: null, // Id
            prevAppraisal: null,
            nextAppraisal: null,
        }

        // Used to make sure the form is only generated once
        this.loadedAppraisalIdRef = React.createRef()
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0)
        document.title = 'E-Hub | Vehicle Remarketing and Vehicle Auction | Aston Barclay'
        const appraisalId = this.props.match.params.id
        await this.setState({ currentAppraisal: appraisalId })
        this.setPrevNext(appraisalId)
        const path = this.props.match.params.path
        this.props.geteHubVehicleAppraisal(
            appraisalId,
            this.props.match.params.accountCode,
            this.props.user.otherAccounts,
            path,
        )
        this.setupBeforeUnloadListener()
    }

    UNSAFE_componentWillReceiveProps = async (nextProps) => {
        const appraisalId = nextProps.match.params.id
        const path = nextProps.match.params.path
        const appraisal = await nextProps.appraisals.filter((appraisal) => {
            let id = appraisal.id
            if (path === CT_EXCHANGE) {
                id = appraisal.vehicleId
            }
            return id === parseInt(appraisalId, 10)
        })[0]

        // Check to make sure the form (and state) is only updated when appropriate)
        // Check that we have an appraisal AND...
        // It's different to what's previously been set. (account for vehicle change)
        // OR
        // If it's the same vehicle but the data has changed (by new values being saved)
        // THEN
        // Rebuild the form and set the internal appraisal state to the new value

        const appraisalIsDifferent = appraisalId !== this.loadedAppraisalIdRef.current
        const appraisalIdIsSameButHasDifferentValues =
            appraisalId === this.loadedAppraisalIdRef.current && !isEqual(appraisal, this.state.appraisal)

        if (appraisal && (appraisalIsDifferent || appraisalIdIsSameButHasDifferentValues)) {
            this.loadedAppraisalIdRef.current = appraisalId

            this.setupForm(appraisal)
        }

        if (appraisal && appraisal.vendorCode) {
            this.props.getClosedGroup(appraisal.vendorCode)
        }

        if (appraisalId !== this.state.currentAppraisal) {
            this.setState({ currentAppraisal: appraisalId })
            const path = nextProps.match.params.path
            const accountCode = nextProps.match.params.accountCode
            this.props.geteHubVehicleAppraisal(appraisalId, accountCode, this.props.user.otherAccounts, path)
            this.setPrevNext(appraisalId)
        }
    }

    setupForm(appraisal) {
        if (!appraisal) {
            return
        }

        this.setState({
            appraisal, // Setting current appraisal here
            formValues: {
                noOfKeys: appraisal.noOfKeys,
                noOfSeats: appraisal.noOfSeats,
                doors: appraisal.doors,
                interiorTrim: appraisal.interiorTrim,
                serviceHistory: appraisal.serviceHistory,
                vatStatus: appraisal.vatStatus ? appraisal.vatStatus : null,
                priorUse: appraisal.priorUse ? appraisal.priorUse : null,
                sivPrice: appraisal.sivPrice,
                interiorColour: appraisal.interiorColour ? appraisal.interiorColour : '',
                mileage: appraisal.mileage ? appraisal.mileage : '',
                formerKeepers: appraisal.formerKeepers ? appraisal.formerKeepers : '',
                mileageWarranty: appraisal.mileageWarranty,
                odoChange: appraisal.odoChange ? appraisal.odoChange : '',
                dashboardWarningLights: appraisal.dashboardWarningLights ? appraisal.dashboardWarningLights : false,
                lastService: appraisal.lastService ? new Date(appraisal.lastService) : null,
                motExpiry: appraisal.motExpiry ? new Date(appraisal.motExpiry) : null,
                remarks: appraisal.remarks ? appraisal.remarks.replace('null', '') : '',
                v5: appraisal.v5 ? appraisal.v5 : false,
                nonRunner: appraisal.nonRunner ? appraisal.nonRunner : false,
                extendedWarranty: appraisal.extendedWarranty ? appraisal.extendedWarranty : false,
                paintProtection: appraisal.paintProtection ? appraisal.paintProtection : false,
                prevRegistration: appraisal.prevRegistration ? appraisal.prevRegistration : '',
                equipment: appraisal.equipment ? appraisal.equipment : '',
                tyreNSF: appraisal.tyreNSF,
                tyreOSF: appraisal.tyreOSF,
                tyreNSR: appraisal.tyreNSR,
                tyreOSR: appraisal.tyreOSR,
                tyreSpare: appraisal.tyreSpare,
                mechanicalFaults: appraisal.mechanicalFaults,
            },
            initialFormValues: {
                noOfKeys: appraisal.noOfKeys,
                noOfSeats: appraisal.noOfSeats,
                doors: appraisal.doors,
                interiorTrim: appraisal.interiorTrim,
                serviceHistory: appraisal.serviceHistory,
                vatStatus: appraisal.vatStatus ? appraisal.vatStatus : null,
                priorUse: appraisal.priorUse ? appraisal.priorUse : null,
                sivPrice: appraisal.sivPrice,
                interiorColour: appraisal.interiorColour ? appraisal.interiorColour : '',
                mileage: appraisal.mileage ? appraisal.mileage : '',
                formerKeepers: appraisal.formerKeepers ? appraisal.formerKeepers : '',
                mileageWarranty: appraisal.mileageWarranty,
                odoChange: appraisal.odoChange ? appraisal.odoChange : '',
                dashboardWarningLights: appraisal.dashboardWarningLights ? appraisal.dashboardWarningLights : false,
                lastService: appraisal.lastService ? new Date(appraisal.lastService) : null,
                motExpiry: appraisal.motExpiry ? new Date(appraisal.motExpiry) : null,
                remarks: appraisal.remarks ? appraisal.remarks.replace('null', '') : '',
                v5: appraisal.v5 ? appraisal.v5 : false,
                nonRunner: appraisal.nonRunner ? appraisal.nonRunner : false,
                extendedWarranty: appraisal.extendedWarranty ? appraisal.extendedWarranty : false,
                paintProtection: appraisal.paintProtection ? appraisal.paintProtection : false,
                prevRegistration: appraisal.prevRegistration ? appraisal.prevRegistration : '',
                equipment: appraisal.equipment ? appraisal.equipment : '',
                tyreNSF: appraisal.tyreNSF,
                tyreOSF: appraisal.tyreOSF,
                tyreNSR: appraisal.tyreNSR,
                tyreOSR: appraisal.tyreOSR,
                tyreSpare: appraisal.tyreSpare,
                mechanicalFaults: appraisal.mechanicalFaults,
            },
            editing: false,
            submitting: false,
        })
    }

    setPrevNext(appraisalId) {
        let currentId = parseInt(appraisalId, 10)
        const currentIndex = this.props.prevAndNextOrder.findIndex((v) => v.id === currentId)
        const nextIndex = (currentIndex + 1) % this.props.prevAndNextOrder.length
        const nextAppraisal = this.props.prevAndNextOrder[nextIndex]
        const prevIndex = (currentIndex - 1) % this.props.prevAndNextOrder.length
        const prevAppraisal = this.props.prevAndNextOrder[prevIndex]
        this.setState({
            prevAppraisal: prevAppraisal && prevIndex !== -1 ? prevAppraisal : null,
        })
        this.setState({
            nextAppraisal: nextAppraisal && nextIndex !== 0 ? nextAppraisal : null,
        })
    }

    doSomethingBeforeUnload = (ev) => {
        if (!this.checkDirtyForm()) {
            return (ev.returnValue = 'Are you sure you want to close?')
        }
    }

    setupBeforeUnloadListener = () => {
        window.addEventListener('beforeunload', (ev) => {
            ev.preventDefault()
            return this.doSomethingBeforeUnload(ev)
        })
    }

    checkDirtyForm() {
        return JSON.stringify(this.state.initialFormValues) === JSON.stringify(this.state.formValues)
    }

    renderTable(vehicle, user) {
        if (vehicle && vehicle.biddingDetails) {
            return (
                <table className="e-xchange__table">
                    <tbody>
                        <tr>
                            <td>{lang('LABEL_SIV', user.theme)}</td>
                            <td>{vehicle.sivPrice ? `${CURRENCY}${vehicle.sivPrice.toLocaleString()}` : '-'}</td>
                        </tr>
                        <tr>
                            <td>{lang('LABEL_STARTING_PRICE', user.theme)}</td>
                            <td>
                                {vehicle.biddingDetails.startingPrice
                                    ? `${CURRENCY}${vehicle.biddingDetails.startingPrice.toLocaleString()}`
                                    : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td>{lang('LABEL_RESERVE_PRICE', user.theme)}</td>
                            <td>
                                {vehicle.reservePrice ? `${CURRENCY}${vehicle.reservePrice.toLocaleString()}` : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td>{lang('LABEL_BUY_IT_NOW_PRICE', user.theme)}</td>
                            <td className="bold">
                                {vehicle.biddingDetails.buyNowPrice
                                    ? `${CURRENCY}${vehicle.biddingDetails.buyNowPrice.toLocaleString()}`
                                    : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td>{getBidLabel(this.props.match.params.subPath)}</td>
                            <td className="bold">
                                {vehicle.biddingDetails.noOfBids !== 0
                                    ? `${CURRENCY}${vehicle.biddingDetails.currentBid.toLocaleString()}`
                                    : '-'}{' '}
                                {getReserveStatus(vehicle)}
                            </td>
                        </tr>
                        <tr>
                            <td>{lang('LABEL_STARTED', user.theme)}</td>
                            <td>
                                {vehicle.biddingDetails.startDate &&
                                typeof vehicle.biddingDetails.startDate === 'string'
                                    ? ISODateString(vehicle.biddingDetails.startDate.replace('+0000', 'Z'), true, false)
                                    : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td>{lang('LABEL_ENDS_IN', user.theme)}</td>
                            <td>
                                {!vehicle.biddingDetails.ended ? (
                                    <AuctionCountdown
                                        date={
                                            Date.now() +
                                            showRemaining(vehicle.biddingDetails.endDate.replace('+0000', 'Z'))
                                        }
                                        excludePrependedText={true}
                                        vehicleId={vehicle.id}
                                    />
                                ) : (
                                    'Auction Ended'
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>{lang('LABEL_ENDS', user.theme)}</td>
                            <td>
                                {vehicle.biddingDetails.endDate && typeof vehicle.biddingDetails.endDate === 'string'
                                    ? ISODateString(vehicle.biddingDetails.endDate.replace('+0000', 'Z'), true, false)
                                    : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td>{lang('LABEL_NO_OF_BIDS', user.theme)}</td>
                            <td>{vehicle.biddingDetails.noOfBids}</td>
                        </tr>
                        <tr>
                            <td>{lang('LABEL_NO_OF_WATCHERS', user.theme)}</td>
                            <td>{vehicle.biddingDetails.noOfWatchers}</td>
                        </tr>
                    </tbody>
                </table>
            )
        }
        return null
    }

    toggleTab = (tab, theme) => {
        if (this.state.activeTab !== tab) {
            if (this.state.activeTab === '0' && !this.checkDirtyForm()) {
                confirm('Are you sure?', {
                    buttonConfirm: lang('YES', theme),
                    buttonDismiss: lang('NO', theme),
                    description: 'You currently have unsaved changes, do you wish to proceed? ',
                }).then(
                    () => {
                        this.setState({
                            formValues: this.state.initialFormValues,
                            activeTab: tab,
                        })
                        return false
                    },
                    () => {
                        return false
                    },
                )
            } else {
                this.setState({
                    activeTab: tab,
                })
            }
        }
    }

    toggleStatus(value, id, accountCode) {
        let status = ''
        switch (value) {
            case 'New':
                status = 'PHYSICAL'
                break
            case 'In work':
                status = 'PHYSICAL'
                break
            case 'In stock':
                status = 'NEW'
                break
            case 'Ready to sell':
                status = 'NEW'
                break
            case 'Archived':
                status = 'ARCHIVED'
                break
            case 'Restored':
                status = 'RESTORED'
                break
            default:
                break
        }

        this.props.onChangeAppraisalStatus(parseInt(id, 10), status, accountCode, this.props.user.theme)
    }

    getAppraisalStatus(status, prevStatus, accountCode, path, subPath) {
        let textStatus = ''

        if (
            (status === 'Aston Barclay' && prevStatus === 'In stock') ||
            (status === 'Aston Barclay' && prevStatus === 'e-xchange')
        ) {
            textStatus = 'Collection'
        }

        if (
            this.props.user.accountsAccess &&
            this.props.user.accountsAccess[accountCode] &&
            this.props.user.accountsAccess[accountCode].listAndCollect &&
            status === 'Sent to e-xchange'
        ) {
            textStatus = 'e-Xchange Collection'
        } else if (status === 'Sent to e-xchange' && prevStatus === 'e-xchange') {
            textStatus = 'Added to e-Xchange'
        }

        if (path === 'e-xchange') {
            textStatus = eHubConversions[subPath]
        }

        if (textStatus) {
            return <div className="text-right vehicle-tag">{textStatus}</div>
        } else {
            return null
        }
    }

    onChangeField = (key, value) => {
        let stateValue = value
        if (key === 'mileage' || key === 'formerKeepers') {
            stateValue = isNaN(parseInt(value, 10)) ? null : parseInt(value, 10)
        }

        if (key === 'lastService' && value > new Date()) {
            stateValue = null
        }

        if (key === 'sivPrice') {
            stateValue = parseFloat(
                value
                    .toString()
                    .split('.')
                    .map((el, i) => (i ? el.split('').slice(0, 2).join('') : el))
                    .join('.'),
            )
        }

        this.setState({
            formValues: {
                ...this.state.formValues,
                [key]: stateValue,
            },
        })
    }

    onChangeSelect = (selectedOption, key) => {
        this.setState({
            editing: true,
            formValues: {
                ...this.state.formValues,
                [key]: selectedOption,
            },
        })
    }

    saveVehicleDetails() {
        this.setState({ submitting: true })
        this.props.onSaveVehicleDetails(
            Object.assign(
                {},
                {
                    id: this.state.appraisal.id,
                    vehicleId: this.state.appraisal.vehicleId,
                    data: this.state.formValues,
                    unsold: this.props.match.params.subPath === 'unsold',
                },
            ),
            this.props.match.params.accountCode,
        )
    }

    getTyyreDepthOptions() {
        return Array(99)
            .fill()
            .map((_, i) => {
                return { value: i, label: `${i}mm` }
            })
    }

    keyAllowed(key) {
        const keys = [8, 9, 13, 16, 17, 18, 19, 20, 27, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 91, 92, 93]
        if (key && keys.indexOf(key) === -1) return false
        else return true
    }

    handleNegative(e) {
        const key = !isNaN(e.charCode) ? e.charCode : e.keyCode
        if (!this.keyAllowed(key)) e.preventDefault()
    }

    render = () => {
        const { user, onOpenSendToAuctionModal, onOpenAddToEXchangeModal, websocketConnected, loading } = this.props
        const { appraisal, submitting, formValues } = this.state
        const saveBtnClass = !this.checkDirtyForm() && !submitting ? 'btn-primary' : 'btn-disabled'
        const editable = appraisal && appraisal.actions.editable
        const accountCode = this.props.match.params.accountCode
        const subPath = this.props.match.params.subPath
        const showArchiveLink = !(subPath === 'yet-to-sell' || subPath === 'will-sell')

        return (
            <React.Fragment>
                <div>
                    <div className="container p-3 p-md-0">
                        {!appraisal || loading ? (
                            <div className="row mx-0">
                                <div className="col px-2">
                                    <div className="loading-wrapper">
                                        <ScaleLoader
                                            className={override}
                                            size={10}
                                            color={'#F67419'}
                                            loading={this.props.loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {/* <!-- AB RESULTS DETAILS HEADER --> */}
                                <div className="row m-0 px-0 pt-2 pb-3 mb-3 ab-catelogue-vehicle-details-header position-relative">
                                    <div className="col m-0 p-0 px-md-2 py-md-0">
                                        <div className="row m-0 px-0 pb-2">
                                            <div className="col col-md-8 x-0 p-0">
                                                <h2
                                                    className="p-0 m-0 vehicle-description-title"
                                                    title={getVehicleDescription(appraisal)}
                                                >
                                                    {getVehicleDescription(appraisal)}
                                                </h2>
                                            </div>
                                            <div className="col col-md-4 x-0 p-0 d-flex d-flex-end">
                                                <VehicleIcons vehicle={appraisal} theme={user.theme} />
                                                <React.Fragment>
                                                    {appraisal.status === 'In stock' ? (
                                                        <div
                                                            className="text-right vehicle-tag vehicle-tag-clickable"
                                                            onClick={() =>
                                                                this.toggleStatus(
                                                                    appraisal.status,
                                                                    appraisal.id,
                                                                    accountCode,
                                                                )
                                                            }
                                                        >
                                                            <span>
                                                                {lang(
                                                                    `EHUB_${
                                                                        convertNameToLookup[
                                                                            appraisal.status.toUpperCase()
                                                                        ]
                                                                    }_TEXT`,
                                                                    user.theme,
                                                                )}
                                                            </span>
                                                            <FaTimesCircle color="#fff" />
                                                        </div>
                                                    ) : (
                                                        <React.Fragment>
                                                            {appraisal.cardType === CT_EHUB_EXCHANGE ||
                                                            appraisal.cardType === CT_COLLECTIONS ||
                                                            appraisal.status === 'New' ? (
                                                                <React.Fragment>
                                                                    {this.getAppraisalStatus(
                                                                        appraisal.status,
                                                                        appraisal.previousStatus,
                                                                        accountCode,
                                                                        this.props.match.params.path,
                                                                        this.props.match.params.subPath,
                                                                    )}
                                                                </React.Fragment>
                                                            ) : null}
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.prevAppraisal &&
                                        this.state.prevAppraisal.id !== appraisal.vehicleId && (
                                            <Link
                                                to={`/e-hub/${this.state.prevAppraisal.vendor}/${this.props.match.params.path}/${this.props.match.params.subPath}/${this.state.prevAppraisal.id}`}
                                                className="vehicle-details-btn-prev"
                                            >
                                                <div className="btn">
                                                    <span aria-hidden="true">
                                                        <FaChevronLeft />
                                                    </span>
                                                    <span className="sr-only">Prev</span>
                                                </div>
                                                <div className="text">Prev</div>
                                            </Link>
                                        )}
                                    {this.state.nextAppraisal && (
                                        <Link
                                            to={`/e-hub/${this.state.nextAppraisal.vendor}/${this.props.match.params.path}/${this.props.match.params.subPath}/${this.state.nextAppraisal.id}`}
                                            className="vehicle-details-btn-next"
                                        >
                                            <div className="text">Next</div>
                                            <div className="btn">
                                                <span aria-hidden="true">
                                                    <FaChevronRight />
                                                </span>
                                                <span className="sr-only">Next</span>
                                            </div>
                                        </Link>
                                    )}
                                </div>
                                {/* <!-- AB RESULTS DETAILS CTA --> */}
                                <div className="row m-0 p-0 ab-catelogue-vehicle-details-cta">
                                    {subPath !== 'sold' &&
                                        (subPath === 'unsold' || appraisal.status !== 'Aston Barclay') && (
                                            <div className="col-11 col-md-4 m-0 p-0 cc">
                                                <EHubActionButtons
                                                    user={this.props.user}
                                                    vehicle={appraisal}
                                                    onOpenSendToAuctionModal={onOpenSendToAuctionModal}
                                                    toggleStatus={this.toggleStatus}
                                                    vehicleDetails={true}
                                                    accountCode={accountCode}
                                                    isInvalid={this.state.requiredFields}
                                                    subPath={subPath}
                                                    activeTab={this.state.activeTab}
                                                    checkDirtyForm={() => this.checkDirtyForm()}
                                                    saveVehicleDetails={() => this.saveVehicleDetails()}
                                                />
                                            </div>
                                        )}
                                    {user.accountsAccess &&
                                        user.accountsAccess[accountCode] &&
                                        user.accountsAccess[accountCode].eXchangeSeller &&
                                        (appraisal.actions.addToExchange || appraisal.actions.relistOnExchange) && (
                                            <div className="col-12 col-md-4 m-0 p-0">
                                                <div className="m-0 p-2">
                                                    <button
                                                        className="btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid"
                                                        type="button"
                                                        onClick={(event) => {
                                                            event.preventDefault()
                                                            event.stopPropagation()
                                                            if (checkFormEligibility(appraisal)) {
                                                                if (
                                                                    this.state.activeTab === '0' &&
                                                                    !this.checkDirtyForm()
                                                                ) {
                                                                    return confirm('Unsaved changes', {
                                                                        buttonDismiss: 'Cancel',
                                                                        buttonConfirm: 'Save and continue',
                                                                        description:
                                                                            'You have unsaved changes, would you like to save now and continue adding to e-Xchange?',
                                                                    }).then(
                                                                        () => {
                                                                            this.saveVehicleDetails()
                                                                            openAddToExchangeModal(
                                                                                onOpenAddToEXchangeModal,
                                                                                user,
                                                                                appraisal,
                                                                            )
                                                                        },
                                                                        () => {
                                                                            return false
                                                                        },
                                                                    )
                                                                } else {
                                                                    openAddToExchangeModal(
                                                                        onOpenAddToEXchangeModal,
                                                                        user,
                                                                        appraisal,
                                                                    )
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        {appraisal.actions.addToExchange &&
                                                            lang('BUTTON_ADD_TO_EXCHANGE', user.theme)}
                                                        {appraisal.actions.relistOnExchange &&
                                                            lang('BUTTON_RELIST_E-XCHANGE', user.theme)}
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    {user.accountsAccess &&
                                        user.accountsAccess[accountCode] &&
                                        appraisal.actions.undoArchive && (
                                            <div className="col-12 m-0 p-2">
                                                <button
                                                    className="btn btn-primary btn-block ab-btn-padding ab-btn-proxy-bid"
                                                    type="button"
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        event.stopPropagation()
                                                        confirm(lang('ARE_YOU_SURE', user.theme), {
                                                            buttonConfirm: lang('YES', user.theme),
                                                            buttonDismiss: lang('NO', user.theme),
                                                            description: lang('PROCEED_UNDO_ARCHIVE', user.theme),
                                                        }).then(
                                                            () => {
                                                                return this.toggleStatus(
                                                                    'Restored',
                                                                    appraisal.id,
                                                                    this.props.match.params.accountCode,
                                                                )
                                                            },
                                                            () => {
                                                                return false
                                                            },
                                                        )
                                                    }}
                                                >
                                                    {lang('UNDO_ARCHIVE', user.theme)}
                                                </button>
                                            </div>
                                        )}

                                    {this.props.match.params.subPath === 'unsold' &&
                                        appraisal.provisional &&
                                        user.accountsAccess &&
                                        user.accountsAccess[accountCode] &&
                                        user.accountsAccess[accountCode].eXchangeSeller && (
                                            <React.Fragment>
                                                <div className="col-12 col-md-8 m-0 p-0 p-md-2">
                                                    <div className="collection-message">
                                                        <strong>Provisional – awaiting outcome</strong>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    {appraisal.status === 'Aston Barclay' &&
                                        appraisal.previousStatus === 'In stock' &&
                                        appraisal.collectionSite && (
                                            <React.Fragment>
                                                <div className="col-12 col-md-4 m-0 p-2">
                                                    {appraisal.collectionSite && 'Sent to Auction at'}{' '}
                                                    <strong>
                                                        {appraisal.collectionSite
                                                            ? appraisal.collectionSite
                                                            : lang('ERROR_NO_COLLECTION_SITE_SA', user.theme)}
                                                    </strong>
                                                </div>
                                                <div className="col-12 col-md-8 m-0 p-0 p-md-2">
                                                    <div className="collection-message">
                                                        If you have any questions about the auction of this vehicle
                                                        please call: <strong>{appraisal.collectionSite}</strong>{' '}
                                                        {appraisal.collectionTelephone && 'on'}{' '}
                                                        {appraisal.collectionTelephone && (
                                                            <strong>{appraisal.collectionTelephone}</strong>
                                                        )}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    {this.props.match.params.subPath === 'sold' &&
                                        this.props.user.accountsAccess &&
                                        this.props.user.accountsAccess[accountCode] &&
                                        !this.props.user.accountsAccess[accountCode].listAndTransact && (
                                            <React.Fragment>
                                                <div className="col-12 col-md-4 m-0 p-2">
                                                    <div className="collection-message">
                                                        {appraisal.collectionSite && 'Vehicle sold to'}{' '}
                                                        <strong>
                                                            {appraisal.collectionSite
                                                                ? appraisal.collectionSite
                                                                : 'No buyer details available'}
                                                        </strong>
                                                    </div>
                                                </div>
                                                {(appraisal.collectionTelephone || appraisal.collectionEmail) && (
                                                    <div className="col-12 col-md-8 m-0 p-0 p-md-2">
                                                        <div className="collection-message">
                                                            You can contact the buyer on:{' '}
                                                            {appraisal.collectionTelephone && 'Tel:'}{' '}
                                                            {appraisal.collectionTelephone && (
                                                                <strong>{appraisal.collectionTelephone}</strong>
                                                            )}
                                                            . {appraisal.collectionEmail && 'Email:'}{' '}
                                                            {appraisal.collectionEmail && (
                                                                <strong>{appraisal.collectionEmail}</strong>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                    {this.props.match.params.subPath === 'sold' &&
                                        this.props.user.accountsAccess &&
                                        this.props.user.accountsAccess[accountCode] &&
                                        this.props.user.accountsAccess[accountCode].listAndTransact && (
                                            <React.Fragment>
                                                <div className="col-12 col-md-4 m-0 p-2">
                                                    <div className="collection-message">
                                                        {appraisal.collectionSite && 'Vehicle sold to'}{' '}
                                                        <strong>
                                                            {appraisal.collectionSite
                                                                ? appraisal.collectionSite
                                                                : 'No buyer details available'}
                                                        </strong>
                                                    </div>
                                                </div>
                                                {(appraisal.collectionTelephone || appraisal.collectionEmail) && (
                                                    <div className="col-12 col-md-8 m-0 p-0 p-md-2">
                                                        <div className="collection-message">
                                                            Please contact the{' '}
                                                            <strong>Aston Barclay Digital team</strong> on{' '}
                                                            <strong>01924 927600</strong>
                                                        </div>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                </div>
                                {/* <!-- AB RESULTS DETAILS CONTENT --> */}
                                <div className="row m-0 px-0 py-0 pb-3 ab-catelogue-vehicle-details-content footer-margin">
                                    <div className="col-12 col-md-4 m-0 py-4 px-2">
                                        <div className="row m-0 p-0 ab-catelogue-vehicle-details-photo-container">
                                            {(!appraisal.images || appraisal.images.length === 0) && (
                                                <div
                                                    className="col m-0 p-0 ab-catelogue-vehicle-details-photo-container embed-responsive"
                                                    style={{
                                                        height: '250px',
                                                        backgroundImage: `url(${getVehicleImageUrl(appraisal.images)})`,
                                                        backgroundSize: 'cover',
                                                    }}
                                                />
                                            )}
                                            {appraisal.images && appraisal.images.length > 0 && (
                                                <Carousel
                                                    swipeable={false}
                                                    className="vehicle-details__modal"
                                                    showThumbs={false}
                                                    showStatus={false}
                                                    selectedItem={this.state.imagePosition}
                                                    transitionTime={0}
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            imagePosition: evt,
                                                        })
                                                    }
                                                >
                                                    {appraisal.images.map((image, i) => {
                                                        return (
                                                            <>
                                                                <img
                                                                    key={i}
                                                                    src={image}
                                                                    alt=""
                                                                    onError={(e) => {
                                                                        e.target.onerror = null
                                                                        e.target.src = '/img/image-unavailable.png'
                                                                    }}
                                                                />
                                                                <VehicleBanner vehicle={appraisal} />
                                                            </>
                                                        )
                                                    })}
                                                </Carousel>
                                            )}
                                            {appraisal.images ? (
                                                <div
                                                    className="ab-catelogue-vehicle-details-photo-mask embed-responsive-item"
                                                    type="button"
                                                    onClick={() => {
                                                        appraisal.images &&
                                                            this.props.onOpenVehiclePhotoModal({
                                                                isEhub: true,
                                                                hasSpinCar: false,
                                                                images: appraisal.images,
                                                                selectedItem: this.state.imagePosition,
                                                            })
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                        {user.eXchangeBuyer && appraisal.cardType === CT_EHUB_EXCHANGE && (
                                            <div className="position-relative">
                                                {this.renderTable(appraisal, user)}
                                                {!websocketConnected && (
                                                    <div className="websocket-overlay">
                                                        <div>
                                                            <ScaleLoader
                                                                className={override}
                                                                size={10}
                                                                loading={true}
                                                            />
                                                            {lang('CONNECTION_LOST', user.theme)}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <form>
                                            <div className="row m-0 pt-3 pb-3">
                                                {user.accountsAccess[this.props.match.params.accountCode].cap && (
                                                    <div className="col-6 pl-0">
                                                        <div className="form-group">
                                                            <label htmlFor="cap-clean-price">
                                                                {lang('SORT_OPTIONS_CAP_CLEAN', user.theme)}
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    id="cap-clean-price"
                                                                    type="email"
                                                                    className="form-control"
                                                                    readOnly={true}
                                                                    value={
                                                                        appraisal.capCleanValuation
                                                                            ? `${CURRENCY}${appraisal.capCleanValuation.toLocaleString()}`
                                                                            : '-'
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div
                                                    className={`${
                                                        user.accountsAccess[this.props.match.params.accountCode].cap
                                                            ? 'col-6'
                                                            : 'col-12'
                                                    } pr-0`}
                                                >
                                                    <div className="form-group">
                                                        <label htmlFor="siv-price">
                                                            {lang('LABEL_SIV', user.theme)}
                                                        </label>
                                                        <div className="input-group ab-prepend">
                                                            <div className="input-group-prepend">
                                                                <span
                                                                    className={`input-group-text ${
                                                                        !editable ? 'disabled' : ''
                                                                    }`}
                                                                    id="basic-addon1"
                                                                >
                                                                    {CURRENCY}
                                                                </span>
                                                            </div>
                                                            <input
                                                                id="siv-price"
                                                                className={`form-control ${
                                                                    !editable ? 'disabled' : ''
                                                                }`}
                                                                type="number"
                                                                placeholder={`${
                                                                    !editable
                                                                        ? '-'
                                                                        : lang('ENTER_SIV_PRICE', user.theme)
                                                                }`}
                                                                readOnly={!editable}
                                                                value={formValues.sivPrice ? formValues.sivPrice : ''}
                                                                onKeyPress={(e) => {
                                                                    if (e.target.value.length >= 9) e.preventDefault()
                                                                    this.handleNegative(e)
                                                                }}
                                                                onChange={(evt) =>
                                                                    this.onChangeField('sivPrice', evt.target.value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <form>
                                            <div className="row m-0 pt-0 pb-3">
                                                <div className="col-6 pl-0">
                                                    <div className="form-group">
                                                        <label htmlFor="damage-total">Total Damage</label>
                                                        <div className="input-group ab-prepend">
                                                            <div className="input-group-prepend">
                                                                <span
                                                                    className="input-group-text disabled"
                                                                    id="basic-addon1"
                                                                >
                                                                    £
                                                                </span>
                                                            </div>
                                                            <input
                                                                id="damage-total"
                                                                className="form-control disabled"
                                                                type="number"
                                                                placeholder="Damage total"
                                                                readOnly={true}
                                                                value={appraisal.totalDamage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-12 col-md-8 m-0 py-4 px-2">
                                        {appraisal.status !== 'Archived' && showArchiveLink && (
                                            <div
                                                style={{
                                                    position: 'relative',
                                                }}
                                            >
                                                <div className="archive-link position-absolute">
                                                    <span
                                                        className="link"
                                                        onClick={() =>
                                                            confirm(lang('ARE_YOU_SURE', user.theme), {
                                                                buttonConfirm: lang('YES', user.theme),
                                                                buttonDismiss: lang('NO', user.theme),
                                                                description: lang('PROCEED_WITH_ARCHIVE', user.theme),
                                                            }).then(
                                                                () => {
                                                                    return this.toggleStatus(
                                                                        'Archived',
                                                                        appraisal.id,
                                                                        accountCode,
                                                                    )
                                                                },
                                                                () => {
                                                                    return false
                                                                },
                                                            )
                                                        }
                                                    >
                                                        {lang('EHUB_ARCHIVE_TEXT', user.theme)}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="row m-0 p-0">
                                            <div className="col m-0 p-0 ab-catelogue-vehicle-details-tabs-container">
                                                {/* <ul className="nav nav-pills ab-nav-pills m-0 mb-2 p-3" id="pills-tab" role="tablist"> */}
                                                <Nav tabs className="b-nav-pills ab-custom-tabs" id="pills-tab">
                                                    <NavLink
                                                        className={this.state.activeTab === '0' ? 'active' : ''}
                                                        onClick={() => {
                                                            this.toggleTab('0', user.theme)
                                                        }}
                                                    >
                                                        {lang('LABEL_TAB_VEHICLE', user.theme)}
                                                    </NavLink>
                                                    {isEHubUser(user) ? (
                                                        <React.Fragment>
                                                            <NavLink
                                                                className={this.state.activeTab === '1' ? 'active' : ''}
                                                                onClick={() => {
                                                                    this.toggleTab('1', user.theme)
                                                                }}
                                                            >
                                                                {lang('LABEL_TAB_VALUATIONS', user.theme)}
                                                            </NavLink>
                                                            <NavLink
                                                                className={this.state.activeTab === '2' ? 'active' : ''}
                                                                onClick={() => {
                                                                    this.toggleTab('2', user.theme)
                                                                }}
                                                            >
                                                                {lang('LABEL_TAB_DAMAGE_REPORT', user.theme)}
                                                            </NavLink>
                                                            {showPriorListings(this.props.match.params.subPath) &&
                                                                !appraisalNewOrInStock(appraisal) && (
                                                                    <NavLink
                                                                        className={
                                                                            this.state.activeTab === '3' ? 'active' : ''
                                                                        }
                                                                        onClick={() => {
                                                                            this.toggleTab('3', user.theme)
                                                                        }}
                                                                    >
                                                                        PRIOR LISTINGS
                                                                    </NavLink>
                                                                )}
                                                        </React.Fragment>
                                                    ) : null}
                                                    {/* </ul> */}
                                                </Nav>
                                                <TabContent activeTab={this.state.activeTab}>
                                                    <Prompt
                                                        when={!this.checkDirtyForm()}
                                                        message={() => lang('UNSAVED_CONTINUE_CHECK', user.theme)}
                                                    />

                                                    <TabPane tabId="0">
                                                        <div className="row m-0 p-0">
                                                            <div className="col-12 col-md-6 m-0 p-0 pr-md-2">
                                                                <h3 className="m-0 pt-3 pr-1 pb-0 pl-1 my-1">
                                                                    {lang('VEHICLE_DETAILS_TITLE', user.theme)}
                                                                </h3>
                                                                <table className="table m-0 p-0">
                                                                    <tbody>
                                                                        <VehicleStatsRow
                                                                            label="Appraiser"
                                                                            value={appraisal.accountName}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label="Seller"
                                                                            value={accountCodeToLabel(
                                                                                appraisal.vendorCode,
                                                                                this.props.user.otherAccounts,
                                                                            )}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label="Make"
                                                                            value={appraisal.make}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label="Model"
                                                                            value={appraisal.model}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label="Derivative"
                                                                            value={appraisal.derivative}
                                                                        />
                                                                        <tr>
                                                                            <td>{lang('LABEL_DOR', user.theme)}</td>
                                                                            <td>{getDdateOfRegistration(appraisal)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{lang('LABEL_REG', user.theme)}</td>
                                                                            <td>{getRegistration(appraisal)}</td>
                                                                        </tr>
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_VIN', user.theme)}
                                                                            value={appraisal.vin}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_FUEL', user.theme)}
                                                                            value={appraisal.fuel}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_COLOUR', user.theme)}
                                                                            value={appraisal.colour}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_BODY_STYLE', user.theme)}
                                                                            value={appraisal.bodyType}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_CO2', user.theme)}
                                                                            value={appraisal.co2}
                                                                        />

                                                                        <VehicleStatsRow
                                                                            label={lang(
                                                                                'LABEL_TRANSMISSION',
                                                                                user.theme,
                                                                            )}
                                                                            value={appraisal.transmission}
                                                                        />
                                                                        <tr>
                                                                            <td>
                                                                                {lang(
                                                                                    'LABEL_INSURANCE_WRITE_OFF',
                                                                                    user.theme,
                                                                                )}
                                                                            </td>
                                                                            <td
                                                                                className={
                                                                                    appraisal.insuranceWriteOff
                                                                                        ? 'ab-v5'
                                                                                        : ''
                                                                                }
                                                                            >
                                                                                {appraisal.insuranceWriteOff ? (
                                                                                    <FaCheck />
                                                                                ) : (
                                                                                    <FaTimes />
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                        {collectionAddressValid(
                                                                            appraisal.collectionAddress,
                                                                        ) && (
                                                                            <VehicleStatsRow
                                                                                label="Location"
                                                                                value={collectionAddressString(
                                                                                    appraisal.collectionAddress,
                                                                                )}
                                                                            />
                                                                        )}
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_NON_RUNNER', user.theme)}
                                                                            editable={editable}
                                                                            value={formValues.nonRunner}
                                                                            type="checkbox"
                                                                            name="nonRunner"
                                                                            onChange={this.onChangeField}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang(
                                                                                'LABEL_FORMER_KEEPERS',
                                                                                user.theme,
                                                                            )}
                                                                            value={
                                                                                formValues.formerKeepers
                                                                                    ? formValues.formerKeepers
                                                                                    : ''
                                                                            }
                                                                            name="formerKeepers"
                                                                            editable={editable}
                                                                            type="number"
                                                                            maxLength={2}
                                                                            max={'99'}
                                                                            onChange={this.onChangeField}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang(
                                                                                'LABEL_INTERIOR_TRIM',
                                                                                user.theme,
                                                                            )}
                                                                            value={formValues.interiorTrim}
                                                                            editable={editable}
                                                                            name="interiorTrim"
                                                                            type="select"
                                                                            options={[
                                                                                {
                                                                                    value: 'LEATHER',
                                                                                    label: 'Leather',
                                                                                },
                                                                                {
                                                                                    value: 'PARTIAL_LEATHER',
                                                                                    label: 'Partial Leather',
                                                                                },
                                                                                {
                                                                                    value: 'FABRIC',
                                                                                    label: 'Fabric',
                                                                                },
                                                                            ]}
                                                                            onChangeSelect={(value) =>
                                                                                this.onChangeSelect(
                                                                                    value,
                                                                                    'interiorTrim',
                                                                                )
                                                                            }
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang(
                                                                                'LABEL_INTERIOR_COLOUR',
                                                                                user.theme,
                                                                            )}
                                                                            value={formValues.interiorColour}
                                                                            name="interiorColour"
                                                                            editable={editable}
                                                                            type="text"
                                                                            onChange={this.onChangeField}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_DOORS', user.theme)}
                                                                            name="doors"
                                                                            value={formValues.doors}
                                                                            editable={editable}
                                                                            type="select"
                                                                            options={[
                                                                                {
                                                                                    value: '1',
                                                                                    label: '1',
                                                                                },
                                                                                {
                                                                                    value: '2',
                                                                                    label: '2',
                                                                                },
                                                                                {
                                                                                    value: '3',
                                                                                    label: '3',
                                                                                },
                                                                                {
                                                                                    value: '4',
                                                                                    label: '4',
                                                                                },
                                                                                {
                                                                                    value: '5',
                                                                                    label: '5',
                                                                                },
                                                                            ]}
                                                                            onChangeSelect={(value) =>
                                                                                this.onChangeSelect(value, 'doors')
                                                                            }
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang(
                                                                                'LABEL_NO_OF_SEATS',
                                                                                user.theme,
                                                                            )}
                                                                            value={formValues.noOfSeats}
                                                                            name="noOfSeats"
                                                                            editable={editable}
                                                                            type="select"
                                                                            options={[
                                                                                {
                                                                                    value: '1',
                                                                                    label: '1',
                                                                                },
                                                                                {
                                                                                    value: '2',
                                                                                    label: '2',
                                                                                },
                                                                                {
                                                                                    value: '3',
                                                                                    label: '3',
                                                                                },
                                                                                {
                                                                                    value: '4',
                                                                                    label: '4',
                                                                                },
                                                                                {
                                                                                    value: '5',
                                                                                    label: '5',
                                                                                },
                                                                                {
                                                                                    value: '6',
                                                                                    label: '6',
                                                                                },
                                                                                {
                                                                                    value: '7',
                                                                                    label: '7',
                                                                                },
                                                                                {
                                                                                    value: '8',
                                                                                    label: '8',
                                                                                },
                                                                                {
                                                                                    value: '9',
                                                                                    label: '9',
                                                                                },
                                                                                {
                                                                                    value: '10',
                                                                                    label: '10',
                                                                                },
                                                                                {
                                                                                    value: '11',
                                                                                    label: '11',
                                                                                },
                                                                                {
                                                                                    value: '12',
                                                                                    label: '12',
                                                                                },
                                                                            ]}
                                                                            onChangeSelect={(value) =>
                                                                                this.onChangeSelect(value, 'noOfSeats')
                                                                            }
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_NO_OF_KEYS', user.theme)}
                                                                            value={formValues.noOfKeys}
                                                                            editable={editable}
                                                                            type="select"
                                                                            name="noOfKeys"
                                                                            options={[
                                                                                {
                                                                                    value: '0',
                                                                                    label: '0',
                                                                                },
                                                                                {
                                                                                    value: '1',
                                                                                    label: '1',
                                                                                },
                                                                                {
                                                                                    value: '2',
                                                                                    label: '2',
                                                                                },
                                                                                {
                                                                                    value: '3',
                                                                                    label: '3',
                                                                                },
                                                                                {
                                                                                    value: '4',
                                                                                    label: '4',
                                                                                },
                                                                                {
                                                                                    value: '5',
                                                                                    label: '5',
                                                                                },
                                                                                {
                                                                                    value: '6',
                                                                                    label: '6',
                                                                                },
                                                                                {
                                                                                    value: '7',
                                                                                    label: '7',
                                                                                },
                                                                                {
                                                                                    value: '8',
                                                                                    label: '8',
                                                                                },
                                                                                {
                                                                                    value: '9',
                                                                                    label: '9',
                                                                                },
                                                                            ]}
                                                                            onChangeSelect={(value) =>
                                                                                this.onChangeSelect(value, 'noOfKeys')
                                                                            }
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang(
                                                                                'LABEL_MOT_EXPIRES',
                                                                                user.theme,
                                                                            )}
                                                                            editable={editable}
                                                                            value={formValues.motExpiry}
                                                                            type="date"
                                                                            onChange={this.onChangeField}
                                                                            name="motExpiry"
                                                                        />
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="col-12 col-md-6 m-0 p-0 pl-md-2">
                                                                {editable ? (
                                                                    <button
                                                                        style={{
                                                                            float: 'right',
                                                                        }}
                                                                        className={`btn my-1 ${saveBtnClass}`}
                                                                        onClick={() => this.saveVehicleDetails()}
                                                                    >
                                                                        {lang('BUTTON_SAVE', user.theme)}
                                                                    </button>
                                                                ) : (
                                                                    <h3 className="m-0 pt-3 pr-1 pb-2 pl-1">&nbsp;</h3>
                                                                )}
                                                                <table className="table m-0 p-0">
                                                                    <tbody>
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_MILEAGE', user.theme)}
                                                                            value={
                                                                                formValues.mileage
                                                                                    ? formValues.mileage
                                                                                    : ''
                                                                            }
                                                                            editable={editable}
                                                                            type="number"
                                                                            name="mileage"
                                                                            maxLength={6}
                                                                            max={'999999'}
                                                                            onChange={this.onChangeField}
                                                                            required={true}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_WARRANTED', user.theme)}
                                                                            editable={editable}
                                                                            value={formValues.mileageWarranty}
                                                                            options={[
                                                                                {
                                                                                    name: 'Yes',
                                                                                    value: true,
                                                                                },
                                                                                {
                                                                                    name: 'No',
                                                                                    value: false,
                                                                                },
                                                                            ]}
                                                                            type="radio"
                                                                            name="mileageWarranty"
                                                                            onChange={this.onChangeField}
                                                                            required={true}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_ODO_CHANGE', user.theme)}
                                                                            editable={editable}
                                                                            value={formValues.odoChange}
                                                                            type="checkbox"
                                                                            name="odoChange"
                                                                            onChange={this.onChangeField}
                                                                        />

                                                                        <VehicleStatsRow
                                                                            label={lang(
                                                                                'LABEL_WARNING_LIGHTS',
                                                                                user.theme,
                                                                            )}
                                                                            editable={editable}
                                                                            value={formValues.dashboardWarningLights}
                                                                            type="checkbox"
                                                                            onChange={this.onChangeField}
                                                                            name="dashboardWarningLights"
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang(
                                                                                'LABEL_SERVICE_HISTORY',
                                                                                user.theme,
                                                                            )}
                                                                            value={formValues.serviceHistory}
                                                                            editable={editable}
                                                                            name="serviceHistory"
                                                                            type="select"
                                                                            options={[
                                                                                {
                                                                                    value: 'NO_SERVICE_HISTORY',
                                                                                    label: 'No Service History',
                                                                                },
                                                                                {
                                                                                    value: 'FULL_SERVICE_HISTORY',
                                                                                    label: 'Full Service History',
                                                                                },
                                                                                {
                                                                                    value: 'PART_SERVICE_HISTORY',
                                                                                    label: 'Part Service History',
                                                                                },
                                                                                {
                                                                                    value: 'FIRST_SERVICE_NOT_DUE',
                                                                                    label: 'First Service Not Due',
                                                                                },
                                                                                {
                                                                                    value: 'FULL_MANUFACTURER_SERVICE_HISTORY',
                                                                                    label: 'Full Manufacturer Service History',
                                                                                },
                                                                            ]}
                                                                            onChangeSelect={(value) =>
                                                                                this.onChangeSelect(
                                                                                    value,
                                                                                    'serviceHistory',
                                                                                )
                                                                            }
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang(
                                                                                'LABEL_LAST_SERVICE_DATE',
                                                                                user.theme,
                                                                            )}
                                                                            editable={editable}
                                                                            value={formValues.lastService}
                                                                            type="date"
                                                                            onChange={this.onChangeField}
                                                                            name="lastService"
                                                                            maxDate={new Date()}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang(
                                                                                'LABEL_SERVICE_NOTES',
                                                                                user.theme,
                                                                            )}
                                                                            value={formValues.remarks}
                                                                            editable={editable}
                                                                            type="textarea"
                                                                            name="remarks"
                                                                            onChange={this.onChangeField}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_VAT_STATUS', user.theme)}
                                                                            value={formValues.vatStatus}
                                                                            editable={editable}
                                                                            type="select"
                                                                            options={[
                                                                                {
                                                                                    value: 'Qualifying',
                                                                                    label: 'Qualifying',
                                                                                },
                                                                                {
                                                                                    value: 'Margin',
                                                                                    label: 'Margin',
                                                                                },
                                                                                {
                                                                                    value: 'Commercial + VAT',
                                                                                    label: 'Commercial subject to VAT',
                                                                                },
                                                                            ]}
                                                                            name="vatStatus"
                                                                            onChangeSelect={(value) =>
                                                                                this.onChangeSelect(value, 'vatStatus')
                                                                            }
                                                                            required={true}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_V5_PRESENT', user.theme)}
                                                                            editable={editable}
                                                                            value={formValues.v5}
                                                                            type="checkbox"
                                                                            name="v5"
                                                                            onChange={this.onChangeField}
                                                                        />

                                                                        <VehicleStatsRow
                                                                            label={lang(
                                                                                'LABEL_EXTENDED_WARRANTY',
                                                                                user.theme,
                                                                            )}
                                                                            editable={editable}
                                                                            value={formValues.extendedWarranty}
                                                                            type="checkbox"
                                                                            name="extendedWarranty"
                                                                            onChange={this.onChangeField}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang(
                                                                                'LABEL_PAINT_PROTECTION',
                                                                                user.theme,
                                                                            )}
                                                                            value={formValues.paintProtection}
                                                                            editable={editable}
                                                                            type="checkbox"
                                                                            name="paintProtection"
                                                                            onChange={this.onChangeField}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang(
                                                                                'LABEL_PREVIOUS_REG',
                                                                                user.theme,
                                                                            )}
                                                                            name="prevRegistration"
                                                                            editable={editable}
                                                                            type="text"
                                                                            onChange={this.onChangeField}
                                                                            value={formValues.prevRegistration}
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_PRIOR_USE', user.theme)}
                                                                            value={formValues.priorUse}
                                                                            editable={editable}
                                                                            type="select"
                                                                            options={[
                                                                                {
                                                                                    value: 'Private',
                                                                                    label: 'Private',
                                                                                },
                                                                                {
                                                                                    value: 'Ex-business',
                                                                                    label: 'Ex-business',
                                                                                },
                                                                                {
                                                                                    value: 'Ex-rental',
                                                                                    label: 'Ex-rental',
                                                                                },
                                                                                {
                                                                                    value: 'Ex-private hire',
                                                                                    label: 'Ex-private hire',
                                                                                },
                                                                                {
                                                                                    value: 'Ex-driving tuition',
                                                                                    label: 'Ex-driving tuition',
                                                                                },
                                                                            ]}
                                                                            name="priorUse"
                                                                            onChangeSelect={(value) =>
                                                                                this.onChangeSelect(value, 'priorUse')
                                                                            }
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_TYRE_NSF', user.theme)}
                                                                            value={formValues.tyreNSF}
                                                                            editable={editable}
                                                                            name="tyreNSF"
                                                                            type="select"
                                                                            isSearchable={true}
                                                                            options={this.getTyyreDepthOptions()}
                                                                            onChangeSelect={(value) =>
                                                                                this.onChangeSelect(value, 'tyreNSF')
                                                                            }
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_TYRE_OSF', user.theme)}
                                                                            value={formValues.tyreOSF}
                                                                            editable={editable}
                                                                            name="tyreOSF"
                                                                            type="select"
                                                                            isSearchable={true}
                                                                            options={this.getTyyreDepthOptions()}
                                                                            onChangeSelect={(value) =>
                                                                                this.onChangeSelect(value, 'tyreOSF')
                                                                            }
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_TYRE_NSR', user.theme)}
                                                                            value={formValues.tyreNSR}
                                                                            editable={editable}
                                                                            name="tyreNSR"
                                                                            type="select"
                                                                            isSearchable={true}
                                                                            options={this.getTyyreDepthOptions()}
                                                                            onChangeSelect={(value) =>
                                                                                this.onChangeSelect(value, 'tyreNSR')
                                                                            }
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_TYRE_OSR', user.theme)}
                                                                            value={formValues.tyreOSR}
                                                                            editable={editable}
                                                                            name="tyreOSR"
                                                                            type="select"
                                                                            isSearchable={true}
                                                                            options={this.getTyyreDepthOptions()}
                                                                            onChangeSelect={(value) =>
                                                                                this.onChangeSelect(value, 'tyreOSR')
                                                                            }
                                                                        />
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_TYRE_SPARE', user.theme)}
                                                                            value={formValues.tyreSpare}
                                                                            editable={editable}
                                                                            name="tyreSpare"
                                                                            type="select"
                                                                            isSearchable={true}
                                                                            options={this.getTyyreDepthOptions()}
                                                                            onChangeSelect={(value) =>
                                                                                this.onChangeSelect(value, 'tyreSpare')
                                                                            }
                                                                        />
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <table className="table m-0 p-0">
                                                                    <tbody>
                                                                        <VehicleStatsRow
                                                                            label={lang('LABEL_EQUIPMENT', user.theme)}
                                                                            value={formValues.equipment}
                                                                            editable={editable}
                                                                            type="textarea"
                                                                            name="equipment"
                                                                            onChange={this.onChangeField}
                                                                            maxLength={250}
                                                                        />
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <table className="table m-0 p-0">
                                                                    <tbody>
                                                                        <VehicleStatsRow
                                                                            label="Mechanical Faults"
                                                                            value={formValues.mechanicalFaults}
                                                                            editable={editable}
                                                                            type="textarea"
                                                                            name="mechanicalFaults"
                                                                            onChange={this.onChangeField}
                                                                            maxLength={250}
                                                                        />
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </TabPane>

                                                    <TabPane tabId="1">
                                                        <div className="row m-0 p-0">
                                                            {user.accountsAccess[accountCode].cap ||
                                                            user.accountsAccess[accountCode].tcbg ? (
                                                                <div className="col-12 m-0 p-0">
                                                                    {appraisal.valuations &&
                                                                    appraisal.valuations.length > 0 ? (
                                                                        <div className="table-wrap">
                                                                            <table className="table m-0 p-0">
                                                                                <tbody>
                                                                                    {renderHistoricalPrices(
                                                                                        appraisal,
                                                                                        appraisal.valuations,
                                                                                        user.accountsAccess[accountCode]
                                                                                            .cap,
                                                                                        user.accountsAccess[accountCode]
                                                                                            .ehubShowTcbg,
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            {lang('LABEL_NO_PRICE_DATA', user.theme)}
                                                                        </div>
                                                                    )}
                                                                    <React.Fragment>
                                                                        <div className="form-group mt-2">
                                                                            <label>
                                                                                {lang(
                                                                                    'LABEL_CAP_DESCRIPTION',
                                                                                    user.theme,
                                                                                )}
                                                                            </label>
                                                                            <div className="input-group">
                                                                                <p>
                                                                                    {getVehicleSmallDetails(appraisal)}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                </div>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <div className="form-group mt-2">
                                                                        <label>
                                                                            {lang('LABEL_CAP_DESCRIPTION', user.theme)}
                                                                        </label>
                                                                        <div className="input-group">
                                                                            <p>{getVehicleSmallDetails(appraisal)}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 m-0 px-0 py-3">
                                                                        <p>
                                                                            No valuation services are currently
                                                                            available to this account. Please contact
                                                                            your Account Manager to discuss your
                                                                            requirements.
                                                                        </p>
                                                                    </div>
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    </TabPane>
                                                    {isEHubUser(user) ? (
                                                        <React.Fragment>
                                                            <TabPane tabId="2">
                                                                <div
                                                                    className="col text-right"
                                                                    style={{
                                                                        marginTop: '10px',
                                                                    }}
                                                                >
                                                                    <a
                                                                        href={`${API_BASE}api/e-hub/${appraisal.vendorCode}/appraisal/${appraisal.id}/pdf?registration=${appraisal.registration}`}
                                                                    >
                                                                        <FaFilePdf
                                                                            size="30"
                                                                            data-toggle="tooltip"
                                                                            data-placement="bottom"
                                                                            title={lang(
                                                                                'DOWNLOAD_PDF_FILE',
                                                                                user.theme,
                                                                            )}
                                                                            style={{
                                                                                marginRight: '10px',
                                                                            }}
                                                                        />
                                                                    </a>
                                                                </div>
                                                                {getDamageInformation(
                                                                    appraisal,
                                                                    this.props.onOpenVehiclePhotoModal,
                                                                    user.theme,
                                                                )}
                                                                {getDamageQuestions(appraisal)}
                                                                {getPricingSignatures(appraisal)}
                                                            </TabPane>
                                                            {showPriorListings(this.props.match.params.subPath) &&
                                                                !appraisalNewOrInStock(appraisal) && (
                                                                    <TabPane tabId="3">
                                                                        <div className="row m-0 p-0 py-3">
                                                                            <div className="col-12 m-0 p-0">
                                                                                <div className="table-wrap">
                                                                                    {appraisal.biddingDetailsHistory &&
                                                                                    appraisal.biddingDetailsHistory
                                                                                        .length > 0 ? (
                                                                                        <table className="table m-0 p-0">
                                                                                            <tbody>
                                                                                                {renderHistoricExchangeAuctionDetails(
                                                                                                    appraisal.biddingDetailsHistory,
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    ) : (
                                                                                        <div>
                                                                                            No prior listings available
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </TabPane>
                                                                )}
                                                        </React.Fragment>
                                                    ) : null}
                                                </TabContent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <SendToAuctionModal />
                <AddToEXchangeModal />
                <PhotoModal />
            </React.Fragment>
        )
    }
}

export default EhubVehicleDetails
