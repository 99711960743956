import React, { Component } from 'react'
import { Input } from 'reactstrap'
import { lang } from '../../themeConfig'
import { FaSearch } from '@react-icons/all-files/fa/FaSearch'

class KeywordSearchInput extends Component {
    constructor(props) {
        super(props)
        this.dataType = 'keywords'
        this.state = {
            value: '',
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const newProps = nextProps.criteria['keywords']
        const oldProps = this.props.criteria['keywords']

        if (newProps !== oldProps) {
            this.setState({ value: nextProps.criteria['keywords'] })
            this.props.getVehicles(nextProps.criteria, this.props.sorting)
            this.props.getSites(nextProps.criteria)
            this.props.getMakes(nextProps.criteria)
            this.props.getEngineSizes(nextProps.criteria)
            this.props.getTransmissions(nextProps.criteria)
            this.props.getDoors(nextProps.criteria)
            this.props.getAges(nextProps.criteria)
            this.props.getMileages(nextProps.criteria)
            this.props.getBodyTypes(nextProps.criteria)
            this.props.getVehicleTypes(nextProps.criteria)
            this.props.getFuelTypes(nextProps.criteria)
            this.props.getSellers(nextProps.criteria)
            this.props.getEuroStatus(nextProps.criteria)
        }
    }

    keyPressed = (event) => {
        if (event.keyCode === 13 || event.which === 13) {
            this.props.onKeywordChange(this.state.value, this.props.offsetFinish)
        }
    }

    render = () => {
        return (
            <div className="input-group">
                <Input
                    type="text"
                    className="form-control"
                    value={this.state.value}
                    aria-label={lang('CATALOGUE_SEARCH_PLACEHOLDER', this.props.user.theme)}
                    placeholder={lang('CATALOGUE_SEARCH_PLACEHOLDER', this.props.user.theme)}
                    onChange={(e) => this.setState({ value: e.target.value })}
                    onKeyPress={(e) => this.keyPressed(e)}
                />
                <div className="input-group-append">
                    <button
                        className="btn btn-outline"
                        type="button"
                        id="button-addon2"
                        onClick={() => this.props.onKeywordChange(this.state.value, this.props.offsetFinish)}
                    >
                        <FaSearch color="#d8d8d8" />
                    </button>
                </div>
            </div>
        )
    }
}

export default KeywordSearchInput
