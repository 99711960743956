import React, { Component } from 'react'
import { setTitle } from '../../../themeConfig'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { onSendUnsubscribe } from '../../../actions/user'
import { AB_GREEN } from '../../../constants'
import { ScaleLoader } from 'react-spinners'
import { css } from '@emotion/react'
import { Container, Row, Col } from 'reactstrap'

const override = css`
    display: block;
    margin: 0 auto;
`

class Unsubscribe extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        setTitle('Unsubscribe')
        this.sendUnsubscribe(this.props.match.params.token)
    }

    sendUnsubscribe(token) {
        this.props.onSendUnsubscribe(token)
    }

    render() {
        if (this.props.user.loading) {
            return (
                <div className="row mx-0">
                    <div className="col px-2">
                        <div className="loading-wrapper">
                            <ScaleLoader
                                className={override}
                                sizeUnit={'px'}
                                size={10}
                                color={AB_GREEN}
                                loading={this.props.user.loading}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="container-fluid">
                <Container>
                    <Row>
                        <Col xs={{ size: 12 }} lg={{ size: 8 }}>
                            <div className="not-found">
                                <h1>{this.props.user.unsubscribe.success ? 'Unsubscribed.' : "That didn't work."}</h1>
                                <hr />
                                <p>{this.props.user.unsubscribe.message}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
})

const mapDispatchToProps = {
    onSendUnsubscribe,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Unsubscribe))
