import { CHANGE_SORT_ORDER, SET_SORTING_ORDER, SET_PREV_NEXT_ORDER } from '../constants'

const initialState = {
    dataField: {
        value: 'saleStart',
        label: 'Sale Date',
    },
    sortingOrder: [],
    prevAndNextOrder: [],
    reverse: false,
}

const SortingReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_SORT_ORDER:
            return {
                ...state,
                dataField: action.payload.dataField,
                reverse: action.payload.reverse,
            }
        case SET_SORTING_ORDER:
            return {
                ...state,
                sortingOrder: action.payload,
            }
        case SET_PREV_NEXT_ORDER:
            return {
                ...state,
                prevAndNextOrder: action.payload,
            }
        default:
            return state
    }
}

export default SortingReducer
