import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import EhubVehicleDetails from './EhubVehicleDetails'
import { geteHubVehicleAppraisal, onChangeAppraisalStatus, onSaveVehicleDetails } from '../../../actions/ehub'
import {
    onOpenSendToAuctionModal,
    onOpenVehiclePhotoModal,
    onOpenAddToEXchangeModal,
    getClosedGroup,
} from '../../../actions/modals'
const mapStateToProps = (state) => ({
    appraisals: state.ehub.appraisals,
    loading: state.ehub.loading,
    prevAndNextOrder: state.prevAndNext.order,
    websocketConnected: state.websocket.connected,
})

const mapDispatchToProps = {
    geteHubVehicleAppraisal,
    onOpenSendToAuctionModal,
    onOpenVehiclePhotoModal,
    onChangeAppraisalStatus,
    onOpenAddToEXchangeModal,
    onSaveVehicleDetails,
    getClosedGroup,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EhubVehicleDetails))
