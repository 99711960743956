import React, { Component } from 'react'
import { css } from '@emotion/react'
import { ScaleLoader } from 'react-spinners'
import { Link, withRouter } from 'react-router-dom'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import AuctionCountdown from '../../UiKit/AuctionCountdown'
import { getBiddingStatusColourWithTrophy, getReserveStatus, showRemaining } from '../../utilities/AuctionFunctions'
import VehicleIcons from '../../UiKit/VehicleCard/icons/VehicleIcons'
import { lang } from '../../themeConfig'

import { Nav, NavLink, TabContent, TabPane } from 'reactstrap'
import PhotoModal from '../Modals/PhotoModal'
import { getPrimaryColor } from '../../utilities/ThemeFunctions'
import { CT_EXCHANGE, CURRENCY } from '../../constants'
import { getAuctionDate, getAuctionTime, getLocation, getLot } from './VehicleHelper'

import {
    AuctionWinnerMessage,
    getAuctionLocation,
    getBreadcrumbText,
    getBreadcrumbUrl,
    getVehicleDescription,
} from './VehicleDetailsHelper'

import WatchButton from '../../UiKit/ActionButtons/WatchButton'
import BidActionButtons from '../../UiKit/ActionButtons/BidActionButtons'

import { ISODateString } from '../../utilities/DateFunctions'
import AuctionExtend from '../../utilities/AuctionExtend'
import { FaChevronLeft } from '@react-icons/all-files/fa/FaChevronLeft'
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight'
import { FaMapMarkerAlt } from '@react-icons/all-files/fa/FaMapMarkerAlt'
import Valuations from './Valuations'
import InspectionReport from './InspectionReport'
import Specification from './Specification'
import DamageReport from './DamageReport'
import VehicleDetailsPanel from './VehicleDetailsPanel'

const override = css`
    display: block;
    margin: 0 auto;
`

class CatalogueVehicleDetails extends Component {
    constructor(props) {
        super(props)
        this.initialActiveTab = '0'
        this.state = {
            activeTab: '0',
            vehiclePhotoModal: false,
            imagePosition: 0,
            nextVehicle: null,
            prevVehicle: null,
            currentVehicle: null,
        }

        this.toggle = this.toggle.bind(this)
        this.toggleTab = this.toggleTab.bind(this)
        this.renderTable = this.renderTable.bind(this)
        this.setPrevNext = this.setPrevNext.bind(this)
    }

    componentDidMount() {
        document.title = 'Vehicle Details | Vehicle Remarketing and Vehicle Auction | Aston Barclay'
        window.scrollTo(0, 0)
        const vehicleId = this.props.match.params.id
        this.setState({ currentVehicle: vehicleId }, () => {
            const view = this.props.match.params.cardType

            this.props.getVehicleDetails(vehicleId, view)
            this.setPrevNext(vehicleId)
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const view = this.props.match.params.cardType
        const vehicleId = nextProps.match.params.id
        if (vehicleId !== this.state.currentVehicle) {
            this.props.getVehicleDetails(vehicleId, view)
            this.setState({ currentVehicle: vehicleId })
            this.setPrevNext(vehicleId)
        }
    }

    setPrevNext(vehicleId) {
        const currentId = parseInt(vehicleId, 10)
        const currentIndex = this.props.prevAndNextOrder.findIndex((v) => v.id === currentId)
        const nextIndex = (currentIndex + 1) % this.props.prevAndNextOrder.length
        const nextVehicle = this.props.prevAndNextOrder[nextIndex]
        const prevIndex = (currentIndex - 1) % this.props.prevAndNextOrder.length
        const prevVehicle = this.props.prevAndNextOrder[prevIndex]
        this.setState({
            prevVehicle: prevVehicle && prevIndex !== -1 ? prevVehicle.id : null,
        })
        this.setState({
            nextVehicle: nextVehicle && nextIndex !== 0 ? nextVehicle.id : null,
        })
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    toggle() {
        this.setState({ vehiclePhotoModal: !this.props.vehicle.dataPhotoModal })
    }

    renderTable(vehicle) {
        if (!(vehicle && vehicle.biddingDetails)) {
            return null
        }

        let currentBid
        let reserveStatus = null
        if (
            vehicle.biddingDetails.ended &&
            vehicle.biddingDetails.timeRemaining === 0 &&
            vehicle.biddingDetails.currentBid
        ) {
            currentBid = CURRENCY + vehicle.biddingDetails.currentBid.toLocaleString()
        } else if (vehicle.biddingDetails.ended && vehicle.biddingDetails.currentBid) {
            currentBid = 'Pending'
        } else if (vehicle.biddingDetails.noOfBids !== 0 && vehicle.biddingDetails.currentBid) {
            currentBid = CURRENCY + vehicle.biddingDetails.currentBid.toLocaleString()
        } else {
            currentBid = '-'
        }

        if (
            !vehicle.biddingDetails.ended ||
            (vehicle.biddingDetails.ended && vehicle.biddingDetails.timeRemaining === 0)
        ) {
            reserveStatus = getReserveStatus(vehicle, this.props.user.theme)
        }

        return (
            <table className="e-xchange__table">
                <tbody>
                    <tr>
                        <td>{lang('LABEL_STARTING_PRICE', this.props.user.theme)}</td>
                        <td>
                            {vehicle.biddingDetails.startingPrice
                                ? `${CURRENCY}${vehicle.biddingDetails.startingPrice.toLocaleString()}`
                                : '-'}
                        </td>
                    </tr>
                    <tr>
                        <td>{lang('LABEL_CURRENT_BID', this.props.user.theme)}</td>
                        <td className="bold">
                            {currentBid} {reserveStatus}
                        </td>
                    </tr>
                    <tr>
                        <td>{lang('LABEL_YOUR_MAX_BID', this.props.user.theme)}</td>
                        <td className="bold">
                            {vehicle.biddingDetails.userMaxBid
                                ? `${CURRENCY}${vehicle.biddingDetails.userMaxBid.toLocaleString()}`
                                : '-'}
                        </td>
                    </tr>
                    <tr>
                        <td>{lang('LABEL_YOUR_BID_STATUS', this.props.user.theme)}</td>
                        <td>{getBiddingStatusColourWithTrophy(vehicle, this.props.user.theme) || '-'}</td>
                    </tr>
                    <tr>
                        <td>{lang('LABEL_BUY_NOW_PRICE', this.props.user.theme)}</td>
                        <td>
                            {vehicle.biddingDetails.buyNowPrice
                                ? `${CURRENCY}${vehicle.biddingDetails.buyNowPrice.toLocaleString()}`
                                : 'N/A'}
                        </td>
                    </tr>
                    <tr>
                        <td>{lang('LABEL_STARTED', this.props.user.theme)}</td>
                        <td>
                            {vehicle.biddingDetails.startDate && typeof vehicle.biddingDetails.startDate === 'string'
                                ? ISODateString(vehicle.biddingDetails.startDate.replace('+0000', 'Z'), true, false)
                                : '-'}
                        </td>
                    </tr>
                    <tr>
                        <td>{lang('LABEL_ENDS_IN', this.props.user.theme)}</td>
                        <td>
                            {!vehicle.biddingDetails.ended ? (
                                <AuctionCountdown
                                    date={
                                        Date.now() + showRemaining(vehicle.biddingDetails.endDate.replace('+0000', 'Z'))
                                    }
                                    excludePrependedText={true}
                                    vehicleId={vehicle.id}
                                />
                            ) : (
                                'Auction Ended'
                            )}
                            {!vehicle.biddingDetails.ended && vehicle.leaseplanVehicle && (
                                <AuctionExtend
                                    type={'vehicle-details'}
                                    endDate={vehicle.biddingDetails.endDate}
                                    id={vehicle.id}
                                />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>{lang('LABEL_ENDS', this.props.user.theme)}</td>
                        <td>
                            {vehicle.biddingDetails.endDate && typeof vehicle.biddingDetails.endDate === 'string'
                                ? ISODateString(vehicle.biddingDetails.endDate.replace('+0000', 'Z'), true, false)
                                : '-'}
                        </td>
                    </tr>
                    {!vehicle.leaseplanVehicle && (
                        <React.Fragment>
                            <tr>
                                <td>{lang('LABEL_NO_OF_BIDS', this.props.user.theme)}</td>
                                <td>{vehicle.biddingDetails.noOfBids}</td>
                            </tr>
                            <tr>
                                <td>{lang('LABEL_NO_OF_WATCHERS', this.props.user.theme)}</td>
                                <td>{vehicle.biddingDetails.noOfWatchers}</td>
                            </tr>{' '}
                        </React.Fragment>
                    )}
                </tbody>
            </table>
        )
    }

    onRenderImages(vehicle) {
        return vehicle.images.map((image, i) => {
            return (
                <img
                    key={i}
                    src={image}
                    alt=""
                    onError={(e) => {
                        e.target.onerror = null
                        e.target.src = '/img/image-unavailable.png'
                    }}
                />
            )
        })
    }

    render() {
        const {
            onOpenProxyBidModal,
            onOpenBuyNowModal,
            onClickBiddingModal,
            user,
            onClickSetProxyBid,
            websocketConnected,
        } = this.props
        const vehicle = this.props.vehicles.data.filter(
            (vehicle) =>
                vehicle.id === parseInt(this.props.match.params.id, 10) ||
                vehicle.registration === this.props.match.params.id,
        )[0]

        const prevPath =
            this.props.location.state && this.props.location.state.prevPath ? this.props.location.state.prevPath : null
        const accountCode = this.props.match.params.accountCode

        return (
            <React.Fragment>
                <div
                    className={`${
                        vehicle && getLocation(vehicle) === 'e-Live' ? 'e-hub' : ''
                    } ${vehicle ? vehicle.cardType : ''}`}
                >
                    <div className="container p-3 p-md-0">
                        {/* <!-- AB RESULTS BREADCRUMB --> */}
                        <div className="row m-0 px-0 pt-3 pb-2 ab-breadcrumb d-md-block">
                            <div className="col m-0 p-0 px-md-2 py-md-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to={getBreadcrumbUrl(prevPath, this.props.user.theme)}>
                                                {getBreadcrumbText(prevPath, this.props.user.theme)}
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {lang('VEHICLE_DETAILS_TITLE', this.props.user.theme).toUpperCase()}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        {!vehicle ? (
                            <div className="row mx-0">
                                <div className="col p-3 text-center">
                                    {this.props.vehicles.loading ? (
                                        <div className="loading-wrapper">
                                            <ScaleLoader
                                                className={override}
                                                size={10}
                                                loading={this.props.vehicles.loading}
                                            />
                                        </div>
                                    ) : (
                                        lang('VEHICLE_REMOVED', this.props.user.theme)
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div>
                                {/* <!-- AB RESULTS DETAILS HEADER --> */}
                                <div className="row m-0 px-0 pt-2 pb-3 mb-3 ab-catelogue-vehicle-details-header position-relative">
                                    <div className="col m-0 p-0 px-md-2 py-md-0">
                                        <div className="row m-0 px-0">
                                            <div className="col-12 col-md-8 x-0 p-0 pb-2">
                                                <h2
                                                    className="p-0 m-0 vehicle-description-title"
                                                    title={getVehicleDescription(vehicle)}
                                                >
                                                    {getVehicleDescription(vehicle)}
                                                </h2>
                                            </div>
                                            <div className="col col-md-4 x-0 p-0 d-flex d-flex-end">
                                                <VehicleIcons
                                                    vehicle={vehicle}
                                                    withText={false}
                                                    detailsScreen={true}
                                                    theme={user.theme}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.prevVehicle && this.state.prevVehicle.id !== vehicle.id && (
                                        <Link
                                            to={{
                                                pathname: `/details/${vehicle.cardType}/${this.state.prevVehicle}`,
                                                state: {
                                                    prevPath: prevPath,
                                                },
                                            }}
                                            className="vehicle-details-btn-prev"
                                        >
                                            <div className="btn">
                                                <span aria-hidden="true">
                                                    <FaChevronLeft />
                                                </span>
                                                <span className="sr-only">{lang('PREV', this.props.user.theme)}</span>
                                            </div>
                                            <div className="text">{lang('PREV', this.props.user.theme)}</div>
                                        </Link>
                                    )}
                                    {this.state.nextVehicle && (
                                        <Link
                                            to={{
                                                pathname: `/details/${vehicle.cardType}/${this.state.nextVehicle}`,
                                                state: { prevPath: prevPath },
                                            }}
                                            className="vehicle-details-btn-next"
                                        >
                                            <div className="text">{lang('NEXT', this.props.user.theme)}</div>
                                            <div className="btn">
                                                <span aria-hidden="true">
                                                    <FaChevronRight />
                                                </span>
                                                <span className="sr-only">{lang('NEXT', this.props.user.theme)}</span>
                                            </div>
                                        </Link>
                                    )}
                                </div>
                                {/* <!-- AB RESULTS DETAILS CTA --> */}
                                <div className="row m-0 px-0 pt-3 ab-catelogue-vehicle-details-cta position-relative">
                                    {vehicle.biddingDetails &&
                                        !vehicle.biddingDetails.ended &&
                                        showRemaining(vehicle.biddingDetails.endDate) < 3600000 && (
                                            <div className="ab-ends-soon position-absolute ab-abs-right abs-top-0">
                                                {' '}
                                                {lang('ENDS_SOON', this.props.user.theme)}
                                            </div>
                                        )}
                                    <div className="col-12 col-md-4 m-0 p-0">
                                        <BidActionButtons
                                            user={this.props.user}
                                            vehicle={vehicle}
                                            onClickOpenProxyBidModal={onOpenProxyBidModal}
                                            onClickSetProxyBid={onClickSetProxyBid}
                                            onClickOpenBuyNowModal={onOpenBuyNowModal}
                                            onClickBiddingModal={onClickBiddingModal}
                                            websocketConnected={websocketConnected}
                                            accountCode={accountCode}
                                        />
                                    </div>
                                    <AuctionWinnerMessage vehicle={vehicle} />
                                </div>
                                {/* <!-- AB RESULTS DETAILS CONTENT --> */}
                                <div className="row m-0 px-0 py-0 pb-3 ab-catelogue-vehicle-details-content footer-margin">
                                    <div className="col-12 col-md-4 m-0 py-4 px-2">
                                        <div className="row m-0 p-0">
                                            <div className="col m-0 p-0 ab-catelogue-vehicle-details-photo-container embed-responsive">
                                                {vehicle.images && vehicle.images.length > 0 && (
                                                    <Carousel
                                                        swipeable={false}
                                                        className="vehicle-details__modal"
                                                        showThumbs={false}
                                                        showStatus={false}
                                                        selectedItem={this.state.imagePosition}
                                                        transitionTime={0}
                                                        onChange={(evt) =>
                                                            this.setState({
                                                                imagePosition: evt,
                                                            })
                                                        }
                                                    >
                                                        {this.onRenderImages(vehicle)}
                                                    </Carousel>
                                                )}
                                                {(!vehicle.images || vehicle.images.length === 0) &&
                                                    !vehicle.spinCar && (
                                                        <img src="/img/no-image.png" alt="" width="100%" />
                                                    )}
                                                {(!vehicle.images || vehicle.images.length === 0) &&
                                                    vehicle.spinCar && (
                                                        <div
                                                            key="spincar"
                                                            style={{
                                                                height: '100%',
                                                            }}
                                                        >
                                                            <img src={'/img/no-image.png'} alt="" width="100%" />
                                                            <iframe
                                                                title="spin car"
                                                                className="embed-responsive-item"
                                                                name="sts_14049883636208338"
                                                                src={vehicle.spinCarUrl}
                                                                scrolling="no"
                                                                allowFullScreen=""
                                                                id="sts_14049883636208338-iframe"
                                                                ref={(el) => {
                                                                    if (el) {
                                                                        el.style.setProperty(
                                                                            'z-index',
                                                                            '0',
                                                                            'important',
                                                                        )
                                                                        el.style.setProperty(
                                                                            'border',
                                                                            'none',
                                                                            'important',
                                                                        )
                                                                        el.style.setProperty('margin', '0', 'important')
                                                                        el.style.setProperty(
                                                                            'padding',
                                                                            '0',
                                                                            'important',
                                                                        )
                                                                        el.style.setProperty(
                                                                            'width',
                                                                            '1px',
                                                                            'important',
                                                                        )
                                                                        el.style.setProperty(
                                                                            'height',
                                                                            '1px',
                                                                            'important',
                                                                        )
                                                                        el.style.setProperty(
                                                                            'min-width',
                                                                            '100%',
                                                                            'important',
                                                                        )
                                                                        el.style.setProperty(
                                                                            'min-height',
                                                                            '100%',
                                                                            'important',
                                                                        )
                                                                        el.style.setProperty(
                                                                            'background-color',
                                                                            'black',
                                                                            'important',
                                                                        )
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                {(vehicle.images && vehicle.images.length > 0) || vehicle.spinCar ? (
                                                    <div
                                                        className="ab-catelogue-vehicle-details-photo-mask embed-responsive-item"
                                                        type="button"
                                                        onClick={() =>
                                                            (vehicle.images || vehicle.spinCar) &&
                                                            this.props.onOpenVehiclePhotoModal({
                                                                hasSpinCar: vehicle.spinCar,
                                                                spinCarUrl: vehicle.spinCarUrl,
                                                                images: vehicle.images,
                                                                selectedItem: this.state.imagePosition,
                                                            })
                                                        }
                                                    />
                                                ) : null}
                                                <div className="ab-catelogue-vehicle-details-watch-toggle">
                                                    <WatchButton
                                                        user={this.props.user}
                                                        vehicle={vehicle}
                                                        onClickWatch={this.props.onClickWatch}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {vehicle.cardType !== CT_EXCHANGE && (
                                            <React.Fragment>
                                                <div className="row m-0 p-0 py-3">
                                                    <div className="col m-0 p-0 ab-catelogue-vehicle-details-auction-details">
                                                        <p>
                                                            {lang('LABEL_AVAILABLE_AT_AUCTION', this.props.user.theme)}
                                                        </p>
                                                        <h3>
                                                            <span>{getLot(vehicle)}</span> |{' '}
                                                            <span>{getAuctionTime(vehicle)}</span> |{' '}
                                                            <span>{getAuctionDate(vehicle)}</span>
                                                        </h3>
                                                        <h3>
                                                            <span>{getAuctionLocation(vehicle)}</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                                {vehicle.virtual && (
                                                    <div className="row m-0 p-0">
                                                        <div className="ab-virtual-location">
                                                            <FaMapMarkerAlt
                                                                color={
                                                                    vehicle.location && vehicle.location.siteId
                                                                        ? getPrimaryColor()
                                                                        : '#F67419'
                                                                }
                                                            />{' '}
                                                            {vehicle.location.description}
                                                        </div>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                        {user.eXchangeBuyer && vehicle.cardType === CT_EXCHANGE && (
                                            <div className="position-relative">
                                                {this.renderTable(vehicle)}
                                                {!websocketConnected && (
                                                    <div className="websocket-overlay">
                                                        <div>
                                                            <ScaleLoader
                                                                className={override}
                                                                size={10}
                                                                color={getPrimaryColor()}
                                                                loading={true}
                                                            />
                                                            {lang('CONNECTION_LOST', this.props.user.theme)}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-8 m-0 py-4 px-2">
                                        <div className="row m-0 p-0">
                                            <div className="col m-0 p-0 ab-catelogue-vehicle-details-tabs-container">
                                                {/* <ul className="nav nav-pills ab-nav-pills m-0 mb-2 p-3" id="pills-tab" role="tablist"> */}
                                                <Nav tabs className="b-nav-pills ab-custom-tabs" id="pills-tab">
                                                    <NavLink
                                                        className={this.state.activeTab === '0' ? 'active' : ''}
                                                        onClick={() => {
                                                            this.toggleTab('0')
                                                        }}
                                                    >
                                                        {lang('LABEL_TAB_VEHICLE', this.props.user.theme)}
                                                    </NavLink>
                                                    <NavLink
                                                        className={this.state.activeTab === '1' ? 'active' : ''}
                                                        onClick={() => {
                                                            this.toggleTab('1')
                                                        }}
                                                    >
                                                        {lang('LABEL_TAB_VALUATIONS', this.props.user.theme)}
                                                    </NavLink>
                                                    <NavLink
                                                        className={this.state.activeTab === '2' ? 'active' : ''}
                                                        onClick={() => {
                                                            this.toggleTab('2')
                                                        }}
                                                    >
                                                        {lang('LABEL_TAB_INSPECTION_REPORT', this.props.user.theme)}
                                                    </NavLink>

                                                    <NavLink
                                                        className={this.state.activeTab === '3' ? 'active' : ''}
                                                        onClick={() => {
                                                            this.toggleTab('3')
                                                        }}
                                                    >
                                                        {lang('LABEL_TAB_SPECIFICATION', this.props.user.theme)}
                                                    </NavLink>
                                                    <NavLink
                                                        className={this.state.activeTab === '4' ? 'active' : ''}
                                                        onClick={() => {
                                                            this.toggleTab('4')
                                                        }}
                                                    >
                                                        {lang('LABEL_TAB_DAMAGE_REPORT', this.props.user.theme)}
                                                    </NavLink>
                                                    {/* </ul> */}
                                                </Nav>
                                                <TabContent activeTab={this.state.activeTab}>
                                                    <TabPane tabId="0">
                                                        <VehicleDetailsPanel user={user} vehicle={vehicle} />
                                                    </TabPane>
                                                    <TabPane tabId="1">
                                                        <Valuations user={user} vehicle={vehicle} />
                                                    </TabPane>
                                                    <TabPane tabId="2">
                                                        <InspectionReport user={user} vehicle={vehicle} />
                                                    </TabPane>
                                                    <TabPane tabId="3">
                                                        <Specification user={user} vehicle={vehicle} />
                                                    </TabPane>
                                                    <TabPane tabId="4">
                                                        <DamageReport
                                                            user={user}
                                                            vehicle={vehicle}
                                                            onOpenVehiclePhotoModal={this.props.onOpenVehiclePhotoModal}
                                                        />
                                                    </TabPane>
                                                </TabContent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <PhotoModal />
            </React.Fragment>
        )
    }
}

export default withRouter(CatalogueVehicleDetails)
