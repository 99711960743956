import React, { PureComponent } from 'react'
import { hasTradeAccess } from '../../utilities/Functions'
import NotSignedInBanner from './NotSignedInBanner'
import { getInspectionOptionsByColumn } from './VehicleDetailsHelper'

export default class Specification extends PureComponent {
    render() {
        const { vehicle, user } = this.props

        if (!hasTradeAccess(user)) {
            return <NotSignedInBanner />
        }

        return (
            <div className="row">
                <div className="col-12 col-md-6 m-0 p-0 pl-md-2">
                    <h3 className="m-0 pt-3 pr-1 pb-0 pl-1">&nbsp;</h3>
                    <table className="table m-0 p-0">
                        <tbody>{getInspectionOptionsByColumn(vehicle, 0, user.theme)}</tbody>
                    </table>
                </div>
                <div className="col-12 col-md-6 m-0 p-0 pl-md-2">
                    <h3 className="m-0 pt-3 pr-1 pb-0 pl-1">&nbsp;</h3>
                    <table className="table m-0 p-0">
                        <tbody>{getInspectionOptionsByColumn(vehicle, 1, user.theme)}</tbody>
                    </table>
                </div>
            </div>
        )
    }
}
