import React, { Component } from 'react'
import { FaReply } from '@react-icons/all-files/fa/FaReply'
import { FaRetweet } from '@react-icons/all-files/fa/FaRetweet'
import { FaHeart } from '@react-icons/all-files/fa/FaHeart'

class TwitterIcons extends Component {
    render() {
        return (
            <div className="twitter-icons">
                <a
                    href="https://twitter.com/intent/tweet?in_reply_to=1140915514727964672"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaReply className="ml-3" />
                </a>
                <a
                    href="https://twitter.com/intent/retweet?tweet_id=1140915514727964672"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaRetweet className="ml-3" />
                </a>
                <a
                    href="https://twitter.com/intent/favorite?tweet_id=1140915514727964672"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaHeart className="ml-3" />
                </a>
            </div>
        )
    }
}

export default TwitterIcons
