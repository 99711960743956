import store from '../store'

/**
 * Returns permission for given name/key.
 * @param key
 * @param providedTheme @deprecated Maintained for backwards compatibility.
 * @returns {boolean} Ideally should return boolean values. Defaults to false if permission doesn't exist.
 */
export function permissions(key, _providedTheme = null) {
    return store.getState()['permissions']['data'][key] ?? false
}

/**
 * Returns translations for given name/key. Use {@link langExists} to determine if string exists or not
 * @param key
 * @param providedTheme @deprecated. Maintained for backwards compatibility.
 * @returns {string|undefined} Ideally should return string values.
 */
export function lang(key, _providedTheme = null) {
    return store.getState()['languageDictionary']['data'][key]
}

export function langExists(key, _providedTheme = null) {
    const themeValue = lang(key)

    return typeof themeValue !== 'undefined'
}

export function setTitle(prefix, theme) {
    if (langExists('TITLE_TEXT', theme)) {
        document.title = prefix + ' | ' + lang('TITLE_TEXT', theme)
    } else {
        document.title = prefix + ' | Vehicle Remarketing and Vehicle Auction | Aston Barclay'
    }
}

export const convertNameToLookup = {
    'ASTON BARCLAY SITES': 'ASTON_BARCLAY_SITES',
    'E-VALUATE': 'EVALUATE',
    'E-XCHANGE': 'EXCHANGE',
    COLLECTIONS: 'COLLECTIONS',
    NEW: 'NEW',
    'IN STOCK': 'IN_STOCK',
    'WILL SELL': 'WILL_SELL',
    'YET TO SELL': 'YET_TO_SELL',
    SOLD: 'SOLD',
    UNSOLD: 'UNSOLD',
    'ASTON BARCLAY': 'ASTON_BARCLAY',
    ARCHIVED: 'ARCHIVED',
    TCBG: 'TCBG',
    'ACTIVE E-EXCHANGE': 'ACTIVE_EXCHANGE',
    UNALLOCATED: 'UNALLOCATED',
    'ALLOCATED PHYSICAL': 'ALLOCATED_PHYSICAL',
    'ALLOCATED E-LIVE': 'ALLOCATED_ELIVE',
    'COLLECTED-BY-ASTON-BARCLAY': 'COLLECTED_BY_ASTON_BARCLAY',
    'SOLD (LIST AND COLLECT)': 'SOLD_LIST_AND_COLLECT',
}
