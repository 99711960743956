import React, { Component } from 'react'

import WatchButton from '../ActionButtons/WatchButton'

import { getVehicleImageUrl, getLocation } from '../../components/CatalogueVehicleDetails/VehicleHelper'
import { hasTradeAccess, isSecureBankTrust } from '../../utilities/Functions'
import { getPrimaryColor } from '../../utilities/ThemeFunctions'
import { showRemaining } from '../../utilities/AuctionFunctions'

import { CT_ARCHIVED, CT_COLLECTIONS, CT_EHUB_EXCHANGE, CT_EVALUATE, CT_EXCHANGE } from '../../constants'
import AuctionExtend from '../../utilities/AuctionExtend'

import VehicleLocationBanner from './VehicleBanner'
import { FaMapMarkerAlt } from '@react-icons/all-files/fa/FaMapMarkerAlt'
import { FaAt } from '@react-icons/all-files/fa/FaAt'

class VehicleImage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            image: null,
        }
    }

    async componentDidMount() {
        const { vehicle } = this.props
        const image = await getVehicleImageUrl(vehicle.images)
        await this.setState({ image })
    }

    render = () => {
        const { user, vehicle, onClickWatch, cardType } = this.props
        return (
            <div
                className={`col-12 ab-card-photo-container ${cardType === CT_EVALUATE || cardType === CT_COLLECTIONS || cardType === CT_EHUB_EXCHANGE || cardType === CT_ARCHIVED ? 'e-hub-image' : ''}`}
                style={{
                    backgroundImage: `url(${this.state.image || '/img/image-unavailable.png'})`,
                }}
            >
                <div className="ab-card-photo ab-card-photo__overlay p-2 position-relative">
                    {cardType !== CT_EVALUATE &&
                        cardType !== CT_COLLECTIONS &&
                        cardType !== CT_ARCHIVED &&
                        cardType !== CT_EHUB_EXCHANGE &&
                        !isSecureBankTrust(cardType) && (
                            <div className="ab-card-watch-toggle position-absolute">
                                <WatchButton user={user} vehicle={vehicle} onClickWatch={onClickWatch} />
                            </div>
                        )}
                    <React.Fragment>
                        {user.eXchangeBuyer &&
                            cardType === CT_EXCHANGE &&
                            !vehicle.biddingDetails.ended &&
                            vehicle.leaseplanVehicle && (
                                <AuctionExtend
                                    type={'vehicle-card'}
                                    endDate={vehicle.biddingDetails.endDate}
                                    id={vehicle.id}
                                />
                            )}
                    </React.Fragment>

                    {hasTradeAccess(user) && (
                        <React.Fragment>
                            {user.eXchangeBuyer &&
                                cardType === CT_EXCHANGE &&
                                !vehicle.biddingDetails.ended &&
                                showRemaining(vehicle.biddingDetails.endDate) < 3600000 && (
                                    <div className="ab-ends-soon position-absolute"> Ends soon</div>
                                )}
                            {(vehicle.cardType === 'e-xchange' || getLocation(vehicle) === 'e-Live') && (
                                <div className="virtual-location">
                                    {((vehicle.location && vehicle.location.description) ||
                                        (vehicle.site && vehicle.site.name)) && (
                                        <FaMapMarkerAlt
                                            color={
                                                vehicle.listAndCollect || vehicle.listAndDeliver
                                                    ? getPrimaryColor()
                                                    : '#F67419'
                                            }
                                        />
                                    )}
                                    {!vehicle.listAndCollect &&
                                        !vehicle.listAndDeliver &&
                                        vehicle.location &&
                                        vehicle.location.description && <FaAt style={{ marginLeft: '5px' }} />}

                                    <div>
                                        {vehicle.location
                                            ? vehicle.location.description
                                            : vehicle.site
                                              ? vehicle.site.name
                                              : 'No location available'}
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </div>
                <VehicleLocationBanner vehicle={vehicle} />
            </div>
        )
    }
}

export default VehicleImage
