import React, { Component } from 'react'
import SavedSearches from './SavedSearches'
import WatchList from './WatchList'
import ProxyBidList from './ProxyBidList'
import BidBuyNowList from './BidBuyNowList'
import Funding from './Funding'
import EXChangeList from './EXChangeList'
import { lang, permissions, setTitle } from '../../themeConfig'

import { APP_EHUB, CT_EXCHANGE, MY_ACCOUNT_CHANGE_PASSWORD_PATH } from '../../constants'
import { Button, Col, Row } from 'reactstrap'
import { FaSignOutAlt } from '@react-icons/all-files/fa/FaSignOutAlt'
import { FaLock } from '@react-icons/all-files/fa/FaLock'
import { FaYoutube } from '@react-icons/all-files/fa/FaYoutube'

class MyAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeSection: '',
            titleLookup: {
                'saved-searches': 'Saved Searches',
                watchlist: 'Watch List',
                'proxy-bids': 'Proxy Bids',
                'bid-buy-now': 'Bid and Buy Now',
                funding: 'Aston Barclay Stock Funding',
                'e-xchange': 'e-Xchange',
            },
        }

        this.gotoSavedSearches = () => this.props.history.push('/my-account/saved-searches')
        this.gotoWatchlist = () => this.props.history.push('/my-account/watchlist')
        this.gotoExchange = () => this.props.history.push('/my-account/e-xchange')
        this.gotoEHub = () => this.props.history.push('/e-hub')
    }

    componentDidMount() {
        setTitle('My Account', this.props.user.theme)
        const currentPath = this.props.location.pathname
        const activeSection = currentPath.substring(currentPath.lastIndexOf('/') + 1)
        this.setState({ activeSection })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const currentPath = nextProps.location.pathname
        const activeSection = currentPath.substring(currentPath.lastIndexOf('/') + 1)
        this.setState({ activeSection })
        this.renderAccountServices = this.renderAccountServices.bind(this)
        this.renderAccountDetails = this.renderAccountDetails.bind(this)
    }

    isEhubApplication() {
        return this.props.location && this.props.location.pathname.indexOf(APP_EHUB) >= 0
    }

    renderAccountServices() {
        return (
            <div className="col-12 col-md-8">
                <h1>Welcome to your account</h1>
                <h2>Your services</h2>
                <div className="my-account-buttons">
                    <Row className="py-2">
                        <Col sm>
                            {permissions('CATALOGUE_LOAD_SAVED_SEARCH_ENABLED') && (
                                <Button
                                    className="ab__button-round ab__button-round--outline"
                                    type="submit"
                                    onClick={this.gotoSavedSearches}
                                    block
                                >
                                    Saved Searches
                                </Button>
                            )}
                        </Col>
                        <Col sm>
                            <Button
                                className="ab__button-round ab__button-round--outline"
                                type="submit"
                                onClick={this.gotoWatchlist}
                                block
                            >
                                {lang('CATALOGUE_WATCH_LIST_TITLE', this.props.user.theme)}
                            </Button>
                        </Col>
                        <Col sm>
                            <Button
                                className="ab__button-round ab__button-round--outline"
                                type="submit"
                                onClick={this.gotoExchange}
                                block
                            >
                                {lang('CATALOGUE_MY_ACCOUNT_EXCHANGE_TITLE', this.props.user.theme)}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm>
                            {permissions('CATALOGUE_EHUB_ICON_LINK_ENABLED') && (
                                <Button
                                    className="ab__button-round ab__button-round--outline"
                                    type="submit"
                                    onClick={this.gotoEHub}
                                    block
                                >
                                    e-hub
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    renderAccountDetails() {
        return (
            <div className="col-12 col-md-4 account-details">
                <h1>{this.props.user.name}</h1>
                <h2>{this.props.user.email}</h2>
                <ul className="account-list">
                    <li>
                        <a href={`${this.props.global.frontMediaHost}${MY_ACCOUNT_CHANGE_PASSWORD_PATH}`}>
                            <FaSignOutAlt className="position-absolute" />
                            Change Password
                        </a>
                    </li>
                    <li>
                        <a href={this.props.global.userLogoutUrl}>
                            <FaLock className="position-absolute" />
                            Logout
                        </a>
                    </li>
                </ul>
                {permissions('ACCOUNT_SHOW_PCP_VIDEO_LINKS') && (
                    <>
                        <h2>HOW TO GUIDES</h2>
                        <ul className="links-list">
                            <li>
                                <a href="https://youtu.be/hz7vwuu0b3w">
                                    <FaYoutube color="red" size="1em" /> How to appraise a PCP returns using e-Valuate
                                </a>
                            </li>
                            <li>
                                <a href="https://youtu.be/IEhFPpLvA9k">
                                    <FaYoutube color="red" size="1em" /> How to purchase PCP return vehicles using this
                                    site
                                </a>
                            </li>
                        </ul>
                    </>
                )}
            </div>
        )
    }

    render() {
        return (
            <div className="container">
                {this.state.activeSection === 'my-account' && (
                    <div className="ab-my-account-services">
                        <Row>
                            {this.renderAccountServices()}
                            {this.renderAccountDetails()}
                        </Row>

                        <Row>
                            <Col>
                                <p>
                                    If you require any assistance with any of our services, please email{' '}
                                    <a href="mailto:websupport@astonbarclay.net">websupport@astonbarclay.net</a>
                                </p>
                            </Col>
                        </Row>
                    </div>
                )}
                {this.state.activeSection === 'saved-searches' && <SavedSearches {...this.props} />}
                {this.state.activeSection === 'watchlist' && <WatchList {...this.props} />}
                {this.state.activeSection === CT_EXCHANGE && <EXChangeList {...this.props} />}
                {this.state.activeSection === 'proxy-bids' && <ProxyBidList {...this.props} />}
                {this.state.activeSection === 'bid-buy-now' && <BidBuyNowList {...this.props} />}
                {this.state.activeSection === 'funding' && <Funding {...this.props} />}
            </div>
        )
    }
}

export default MyAccount
