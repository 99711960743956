import React from 'react'
import PDFDownloadIcon from './PDFDownloadIcon'
import ExcelDownloadIcon from './ExcelDownloadIcon'
import CSVDownloadIcon from './CSVDownloadIcon'

import { permissions } from '../../themeConfig'

class Download extends React.Component {
    render() {
        return (
            <div className="col p-0 x-0 d-none d-md-block text-right ab-download-icons">
                {permissions('CATALOGUE_PDF_DOWNLOAD_ENABLED', this.props.theme) && (
                    <PDFDownloadIcon
                        criteria={this.props.criteria}
                        isWatchList={this.props.isWatchList}
                        theme={this.props.theme}
                    />
                )}
                <ExcelDownloadIcon
                    criteria={this.props.criteria}
                    isWatchList={this.props.isWatchList}
                    theme={this.props.theme}
                />
                <CSVDownloadIcon
                    criteria={this.props.criteria}
                    isWatchList={this.props.isWatchList}
                    theme={this.props.theme}
                />
            </div>
        )
    }
}

export default Download
