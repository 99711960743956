import React from 'react'
import { lang } from '../themeConfig'

export function getBidStatus(vehicle, theme) {
    if (vehicle.biddingDetails.ended) {
        return null
    } else if (vehicle.biddingDetails.userWinning && vehicle.biddingDetails.reserveMet) {
        return lang('LABEL_WINNING', theme)
    } else if (vehicle.biddingDetails.userWinning && !vehicle.biddingDetails.reserveMet) {
        return lang('LABEL_HIGHEST_BIDDER', theme)
    } else if (!vehicle.biddingDetails.userWinning && vehicle.biddingDetails.userMaxBid) {
        return lang('LABEL_OUTBID', theme)
    } else {
        return null
    }
}

export function getLabelForWonStatus(vehicle, theme) {
    if (vehicle.leaseplanVehicle) {
        return lang('LABEL_PROVISIONAL', theme)
    }

    return lang('LABEL_WON', theme)
}

export function getBidLabel(subPath, theme) {
    if (subPath === 'sold') {
        return lang('LABEL_SOLD_FOR', theme)
    }
    if (subPath === 'unsold') {
        return lang('LABEL_FINAL_BID', theme)
    }
    return lang('LABEL_CURRENT_BID', theme)
}

export function getPriceText(vehicle, theme) {
    if (vehicle.biddingDetails.ended && vehicle.biddingDetails.reserveMet && vehicle.biddingDetails.noOfBids > 0) {
        return lang('LABEL_WINNING_BID', theme)
    } else if (
        vehicle.biddingDetails.ended &&
        !vehicle.biddingDetails.reserveMet &&
        vehicle.biddingDetails.noOfBids > 0
    ) {
        return lang('LABEL_LAST_BID', theme)
    } else if (vehicle.biddingDetails.noOfBids === 0) {
        return lang('LABEL_STARTING_PRICE', theme)
    } else {
        return lang('LABEL_CURRENT_BID', theme)
    }
}

export function getDivisorText(vehicle) {
    if (vehicle.leaseplanVehicle) {
        return 'Multiples of £' + vehicle.divisor + '.'
    }
    return ''
}

export function getBiddingStatusColour(vehicle, theme) {
    const { ended, userMaxBid, userWinning, reserveMet, provisionalStatus } = vehicle.biddingDetails
    if (ended) {
        if (userMaxBid) {
            if (userWinning && reserveMet) {
                return (
                    <>
                        <span className="ab-auction-tag ab-green">{lang('LABEL_WON', theme)}</span>
                    </>
                )
            } else if (!userWinning) {
                return (
                    <>
                        <span className="ab-auction-tag ab-red">{lang('LABEL_LOST', theme)}</span>
                    </>
                )
            } else if (userWinning && !reserveMet) {
                if (provisionalStatus === 'Seller Accepted') {
                    return (
                        <>
                            <span className="ab-auction-tag ab-green">{lang('LABEL_WON', theme)}</span>
                        </>
                    )
                } else if (!provisionalStatus || provisionalStatus === 'Await Outcome') {
                    return (
                        <>
                            <span className="ab-auction-tag ab-amber">Provisional</span>
                        </>
                    )
                }
            }
        }
    } else {
        if (userWinning) {
            return <span className="ab-auction-tag ab-green">{getBidStatus(vehicle, theme)}</span>
        } else if (!userWinning && userMaxBid) {
            return <span className="ab-auction-tag ab-red">{getBidStatus(vehicle, theme)}</span>
        }
    }
    return ''
}

export function getBiddingStatusColourWithTrophy(vehicle, theme) {
    if (vehicle.biddingDetails.ended && vehicle.biddingDetails.timeRemaining === 0) {
        if (
            vehicle.biddingDetails.userMaxBid &&
            vehicle.biddingDetails.userWinning &&
            vehicle.biddingDetails.reserveMet
        ) {
            return (
                <React.Fragment>
                    <span className="ab-auction-tag ab-green">{getLabelForWonStatus(vehicle, theme)}</span>
                    <img className="bid-win-icon" src="/img/icon-trophy-green.png" alt="trophy" />
                </React.Fragment>
            )
        } else if (
            vehicle.biddingDetails.userMaxBid &&
            !vehicle.biddingDetails.userWinning &&
            !vehicle.biddingDetails.reserveMet
        ) {
            return (
                <React.Fragment>
                    <span className="ab-auction-tag ab-red">{lang('LABEL_LOST', theme)}</span>
                </React.Fragment>
            )
        } else if (
            vehicle.biddingDetails.userMaxBid &&
            !vehicle.biddingDetails.userWinning &&
            vehicle.biddingDetails.reserveMet
        ) {
            return (
                <React.Fragment>
                    <span className="ab-auction-tag ab-red">{lang('LABEL_LOST', theme)}</span>
                </React.Fragment>
            )
        }
    } else if (vehicle.biddingDetails.ended) {
        return 'Pending'
    } else if (vehicle.biddingDetails.reserveMet && vehicle.biddingDetails.userWinning) {
        return (
            <React.Fragment>
                <span className="ab-auction-tag ab-green">{getBidStatus(vehicle, theme)}</span>
                <img className="bid-win-icon" src="/img/icon-trophy-green.png" alt="trophy" />
            </React.Fragment>
        )
    } else if (!vehicle.biddingDetails.reserveMet && vehicle.biddingDetails.userWinning) {
        return (
            <React.Fragment>
                <span className="ab-auction-tag ab-green">{getBidStatus(vehicle, theme)}</span>
            </React.Fragment>
        )
    } else if (!vehicle.biddingDetails.userWinning && vehicle.biddingDetails.userMaxBid) {
        return (
            <React.Fragment>
                <span className="ab-auction-tag ab-red">{getBidStatus(vehicle, theme)}</span>
                <img className="bid-win-icon" src="/img/icon-trophy-cross-red.png" alt="trophy" />
            </React.Fragment>
        )
    } else {
        return ''
    }
}

export function getReserveStatus(vehicle, theme) {
    if (vehicle.biddingDetails.noOfBids === 0 || vehicle.biddingDetails.ended) {
        return ''
    } else if (vehicle.biddingDetails.reserveMet) {
        return lang('LABEL_RESERVE_MET', theme)
    } else {
        return lang('LABEL_RESERVE_NOT_MET', theme)
    }
}

export function getExchangeBtnText(vehicle, theme, isModal) {
    const { userWinning, reserveMet, userMaxBid, provisionalStatus, noOfBids, buyNowPrice, exchangeType, ended } =
        vehicle.biddingDetails

    let trophyIconVariant = '/img/icon-trophy.png'
    if (!(vehicle.biddingDetails.reserveMet && vehicle.biddingDetails.userWinning)) {
        trophyIconVariant = '/img/icon-trophy-cross.png'
    }

    const tCrossRed = '/img/icon-trophy-cross-red.png'
    const tCrossAmber = '/img/icon-trophy-cross-amber.png'
    const tGreen = '/img/icon-trophy-green.png'

    // Whenever you see userMaxBid, treat that as the user has placed a bid on the vehicle. It is called userMaxBid
    // because the user essentially places a "maximum bid" on a vehicle, and the backend calculates the winner and the
    // lowest bid from all the possible winners.

    if (!ended) {
        if (!userMaxBid && buyNowPrice) {
            let label = ''
            if (!isModal && !reserveMet) {
                label = lang('LABEL_BUY_NOW', theme) + ' ' + lang('LABEL_OR', theme) + ' '
            }
            label += lang('LABEL_PLACE_BID', theme)
            label = label.trim()
            return (
                <>
                    <span>{label}</span>
                    <span>{userMaxBid && <img className="bid-win-icon" src={trophyIconVariant} alt="trophy" />}</span>
                </>
            )
        }
        if (userMaxBid) {
            let label = ''
            if (!isModal && !reserveMet && buyNowPrice) {
                label = lang('LABEL_BUY_NOW', theme) + ' ' + lang('LABEL_OR', theme) + ' '
            }
            label += lang('LABEL_INCREASE_BID', theme)
            label = label.trim()
            return (
                <>
                    <span>{label}</span>
                    <span>{userMaxBid && <img className="bid-win-icon" src={trophyIconVariant} alt={'trophy'} />}</span>
                </>
            )
        } else if (vehicle.leaseplanVehicle && isModal) {
            return `${lang('LABEL_PLACE_BID', theme)} *`
        } else {
            return lang('LABEL_PLACE_BID', theme)
        }
    } else {
        if (noOfBids === 0) {
            return 'ENDED - UNSOLD'
        } else if (provisionalStatus === 'Seller Rejected') {
            return (
                <>
                    <span>ENDED - SORRY YOU LOST</span>
                    <span>
                        <img className="bid-win-icon" src={tCrossRed} alt="trophy" />
                    </span>
                </>
            )
        } else if (provisionalStatus === 'Seller Accepted') {
            return (
                <>
                    <span>ENDED - YOU WON!</span>
                    <span>
                        <img className="bid-win-icon" src={tGreen} alt="trophy" />
                    </span>
                </>
            )
        } else if (userWinning && reserveMet) {
            let label = 'ENDED - YOU WON!'
            if (vehicle.leaseplanVehicle) {
                label = 'ENDED - AWAIT APPROVAL'
            }
            return (
                <>
                    <span>{label}</span>
                    <span>{userMaxBid && <img className="bid-win-icon" src={tGreen} alt="trophy" />}</span>
                </>
            )
        } else if (userWinning && !reserveMet) {
            if (userMaxBid) {
                if (exchangeType === 'ListOnly' && (!provisionalStatus || provisionalStatus === 'Await Outcome')) {
                    return (
                        <>
                            <span>PROVISIONAL - AWAIT OUTCOME</span>
                            <span>
                                <img className="bid-win-icon" src={tCrossAmber} alt="trophy" />
                            </span>
                        </>
                    )
                } else {
                    return (
                        <>
                            <span>ENDED - RESERVE NOT MET</span>
                            <span>
                                <img className="bid-win-icon" src={tCrossRed} alt="trophy" />
                            </span>
                        </>
                    )
                }
            } else {
                return <span>ENDED</span>
            }
        } else if (!userWinning) {
            if (userMaxBid) {
                return (
                    <>
                        <span>ENDED - SORRY YOU LOST</span>
                        <span>
                            <img className="bid-win-icon" src={tCrossRed} alt="trophy" />
                        </span>
                    </>
                )
            }
        }
        return <span>ENDED</span>
    }
}

export function showRemaining(end) {
    const d = new Date(end)
    const now = new Date()
    const distance = d - now
    return distance
}

export function hasEndDateChanged() {}
