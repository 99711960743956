import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchWatchedVehicles, setTotalPages } from '../../../actions/vehicles'
import { setPageSize } from '../../../actions/pagination'
import { onChangeSortOrder } from '../../../actions/sorting'

import WatchList from './WatchList'

const mapStateToProps = (state) => ({
    vehicles: state.vehicles,
    criteria: state.criteria,
    pagination: state.pagination,
    sorting: state.sorting,
    theme: state.user.theme,
})

const mapDispatchToProps = {
    fetchWatchedVehicles,
    setTotalPages,
    setPageSize,
    onChangeSortOrder,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WatchList))
