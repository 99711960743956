import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { onCloseEditSearchModal, onOpenEditSearchModal } from "../../actions/modals";
import { fetchSavedSearches, fetchSavedSearchesForUsername, onSaveSearchEdit } from '../../actions/user'
import Select from "react-select";
import { lang } from "../../themeConfig";

class EditSavedSearchModal extends Component {
    constructor(props) {
        super(props);
        this.handleOnSaveEdit = this.handleOnSaveEdit.bind(this);
        this.state = {
            search: {
                description: "",
                frequency: "DISABLED",
                reportType: "PDF"
            }
        }
        this.selectedUsername = ""
        this.useSelectedSearches = false

        this.options = {
            frequency: [
                { value: "DISABLED", label: "Disabled" },
                { value: "DAILY", label: "Daily" },
                { value: "WEEKLY", label: "Weekly" }
            ],
            reportType: [
                { value: "PDF", label: "PDF" },
                { value: "EXCEL", label: "Excel" },
                { value: "CSV", label: "CSV" }
            ]
        }
    }

    getOption(optionType, value) {
        return this.options[optionType].filter(option => option.value === value);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.saveSearchModal.editIsOpen && nextProps.user.loading === false) {
            if (this.state.search === null || this.state.search.id !== nextProps.selectedSearchId) {
                if (nextProps.user && nextProps.user.marketing) {
                    this.selectedUsername = nextProps.selectedUsername
                    this.useSelectedSearches = nextProps.useSelectedSearches
                    this.onLoadSaveSearchById(nextProps.selectedSearchId, nextProps.useSelectedSearches);
                } else {
                    this.onLoadSaveSearchById(nextProps.selectedSearchId, nextProps.useSelectedSearches);
                } 
            }
            
        }
    }

    onLoadSaveSearchById(id, useSelectedSearches) {
        const search = useSelectedSearches ? this.props.user.searchUsers.selectedSearches.filter(s => s.id === id)[0] : this.props.user.savedSearches.filter(s => s.id === id)[0];
        this.setState({
            search: { 
                ...search, 
                frequency: search.frequency || "DISABLED",
                reportType: search.reportType || "PDF" 
            }
        });
    }

    onEditChange(change) {
        this.setState(prevState => ({
            search: { ...prevState.search, ...change }
        }));
    }

    handleOnSaveEdit() {
        this.props.onSaveSearchEdit({ ...this.state.search, email: this.props.user.email }, this.selectedUsername)
        .then(() => {
            this.props.onCloseEditSearchModal();
            if (this.useSelectedSearches) {
                this.props.fetchSavedSearchesForUsername(this.selectedUsername)
            } else {
                this.props.fetchSavedSearches(this.props.user.marketing);
            }

        });
        
    }

    render() {
        return (
            <Modal
                isOpen={this.props.saveSearchModal.editIsOpen}
                toggle={() => {
                    if (this.props.saveSearchModal.editIsOpen) {
                        return this.props.onCloseEditSearchModal();
                    }
                    return this.props.onOpenEditSearchModal(!this.props.saveSearchModal.editIsOpen);
                }}
                fade={false}
                className="modal-dialog-centered modal-lg ab-proxy-bid-modal"
            >
                <ModalHeader>
                {lang("CATALOGUE_EDIT_SEARCH_TITLE", this.props.user.theme)}
                    <button
                        type="button"
                        className="close float-right"
                        aria-label={lang("BUTTON_CLOSE", this.props.user.theme)}
                        onClick={() => {
                            return this.props.onCloseEditSearchModal(!this.props.saveSearchModal.editIsOpen);
                        }}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody>
                    <form>
                        <div className="container">
                            <div className="row form-group">
                                <div className="col">
                                    <div>
                                        <label style={{width: '100%'}}>
                                        {lang("LABEL_SEARCH_DESCRIPTION_INPUT", this.props.user.theme)}
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={this.state.search.description}
                                                placeholder="Description"
                                                onChange={change => {
                                                    this.onEditChange({ description: change.target.value });
                                                }}
                                            >
                                            </Input>
                                        </label>
                                    </div>
                                </div>  
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="row">
                                        <div className="col">
                                            {this.state.search.frequency === "DISABLED" ?
                                                <label className="callout">
                                                    {lang("EDIT_SEARCH_FREQ_DISABLED")}
                                                </label>
                                                :
                                                <label className="callout callout-green">
                                                    {lang("EDIT_SEARCH_FREQ_ENABLED")}
                                                </label>
                                            }
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-12 col-md-6">
                                            <div>
                                                <label style={{width: '100%'}}>
                                                    {lang("LABEL_SEARCH_FREQUENCY_INPUT", this.props.user.theme)}
                                                    <Select 
                                                        options={this.options.frequency}
                                                        aria-label={lang("LABEL_SEARCH_FREQUENCY_INPUT", this.props.user.theme)}
                                                        value={this.getOption("frequency", this.state.search.frequency)}
                                                        onChange={change => {
                                                            this.onEditChange({ frequency: change.value });
                                                        }}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div>
                                                <label style={{width: '100%'}}>
                                                    {lang("LABEL_SEARCH_REPORT_TYPE_INPUT", this.props.user.theme)}
                                                    <Select 
                                                        options={this.options.reportType}
                                                        aria-label={lang("LABEL_SEARCH_REPORT_TYPE_INPUT", this.props.user.theme)}
                                                        value={this.getOption("reportType", this.state.search.reportType)}
                                                        onChange={change => {
                                                            this.onEditChange({ reportType: change.value });
                                                        }}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <button
                                        className="btn btn-primary btn-block ab-btn-padding"
                                        type="button"
                                        onClick={() => this.handleOnSaveEdit()}
                                        disabled={this.state.search.description === ""}
                                    >
                                        {lang('BUTTON_SAVE_SEARCH_TEXT', this.props.user.theme)}
                                    </button>
                                </div>  
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    saveSearchModal: state.modals.saveSearch,
    selectedSearchId: state.modals.saveSearch.selectedSearchId,
    useSelectedSearches: state.modals.saveSearch.useSelectedSearches,
    selectedUsername: state.modals.saveSearch.selectedUsername,
});

const mapDispatchToProps = {
    onOpenEditSearchModal,
    onCloseEditSearchModal,
    fetchSavedSearches,
    onSaveSearchEdit,
    fetchSavedSearchesForUsername
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EditSavedSearchModal)
);