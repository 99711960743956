import { CHANGE_PAGINATION, SET_PAGINATION_PAGES, CHANGE_PAGE_SIZE } from '../constants'

const initialState = {
    resultsPerPage: { value: 12, label: '12 per page' },
    currentPage: 1,
    offsetStart: 0,
    offsetFinish: 12,
}

const PaginationReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PAGINATION:
            return {
                ...state,
                currentPage: action.payload.currentPage,
                offsetStart: action.payload.offsetStart,
                offsetFinish: action.payload.offsetFinish,
            }
        case CHANGE_PAGE_SIZE:
            return {
                ...state,
                resultsPerPage: action.payload.resultsPerPage,
                offsetStart: action.payload.offsetStart,
                currentPage: 1,
                offsetFinish: action.payload.offsetFinish,
            }
        case SET_PAGINATION_PAGES:
            return {
                ...state,
            }
        default:
            return state
    }
}

export default PaginationReducer
