import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getIneligibleFieldDisplayValue } from '../../components/CatalogueVehicleDetails/VehicleHelper'
import { isVehicleFundingIneligible } from '../../utilities/Functions'
import { lang } from '../../themeConfig'

class VehicleRemarks extends Component {
    static propTypes = {
        vehicle: PropTypes.object.isRequired,
        isSecureBank: PropTypes.bool.isRequired,
    }

    render() {
        const { vehicle, isSecureBank, theme } = this.props
        return (
            <div
                className={`ab-results-description ${vehicle.remarks || vehicle.derivative || (isSecureBank && isVehicleFundingIneligible(vehicle.fundingStatus)) ? '' : 'empty'}`}
            >
                {isSecureBank && isVehicleFundingIneligible(vehicle.fundingStatus) && (
                    <section className="ab-ineligible-list">
                        <p>{lang('REASONS_FOR_INELIGIBILITY', theme)}</p>
                        <ul>
                            {vehicle.vehicleIneligibilitys.map((reason, index) => {
                                return (
                                    <li key={index}>
                                        <span className="ineligible-field">
                                            {getIneligibleFieldDisplayValue(reason.ineligibleField)}
                                        </span>{' '}
                                        - {reason.message}
                                    </li>
                                )
                            })}
                        </ul>
                    </section>
                )}
                {vehicle.derivative ? <p>{vehicle.derivative}</p> : null}
                <p className="ab-remarks">{vehicle.remarks ? vehicle.remarks : 'No notes'}</p>
                {vehicle.serviceHistory && (
                    <p>{vehicle.serviceHistory.label ? vehicle.serviceHistory.label : vehicle.serviceHistory}</p>
                )}
            </div>
        )
    }
}

export default VehicleRemarks
