// src/components/Notification.jsx

import React, { Component } from 'react'
import { FaCheckCircle } from '@react-icons/all-files/fa/FaCheckCircle'
import { FaTimesCircle } from '@react-icons/all-files/fa/FaTimesCircle'
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle'

function Timer(callback, delay) {
    let id,
        started,
        remaining = delay,
        running

    this.start = function () {
        running = true
        started = new Date()
        id = setTimeout(callback, remaining)
    }

    this.pause = function () {
        running = false
        clearTimeout(id)
        remaining -= new Date() - started
    }

    this.getTimeLeft = function () {
        if (running) {
            this.pause()
            this.start()
        }

        return remaining
    }

    this.getStateRunning = function () {
        return running
    }

    this.start()
}

const text = {
    success: 'Success',
    error: 'Error',
    info: 'Info',
}

class Notification extends Component {
    constructor(props) {
        super(props)
        this.timeout = null
        this.dismiss = this.dismiss.bind(this)
    }

    componentDidMount() {
        if (this.props.delay) {
            this.timeout = new Timer(() => {
                this.dismiss()
            }, this.props.delay)
        }
    }

    dismiss() {
        if (this.timeout) {
            this.timeout.pause()
        }
        this.props.onDismissClick(this.props.id)
    }

    render() {
        let component
        switch (this.props.type) {
            case 'success':
                component = <FaCheckCircle className="position-absolute" />
                break
            case 'error':
                component = <FaTimesCircle className="position-absolute" />
                break
            default:
                component = <FaInfoCircle className="position-absolute" />
        }
        return (
            <div className="toast__cell" onClick={this.dismiss}>
                <div className={`toast toast--${this.props.type}`}>
                    <div className="toast__icon">{component}</div>
                    <div className="toast__content">
                        <p className="toast__type">{text[this.props.type]}</p>
                        <p className="toast__message" dangerouslySetInnerHTML={{ __html: this.props.text }} />
                    </div>
                    <div className="toast__close">
                        <button className="notification__dismiss" onClick={this.dismiss}>
                            close
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    shouldComponentUpdate() {
        return false
    }
}

export default Notification
