import {
  OPEN_PROXY_BID_MODAL,
  CLOSE_PROXY_BID_MODAL,
  OPEN_BUY_NOW_MODAL,
  CLOSE_BUY_NOW_MODAL,
  OPEN_SAVE_SEARCH_MODAL,
  CLOSE_SAVE_SEARCH_MODAL,
  OPEN_VEHICLE_PHOTO_MODAL,
  CLOSE_VEHICLE_PHOTO_MODAL,
  OPEN_SEND_TO_AUCTION_MODAL_BEGIN,
  OPEN_SEND_TO_AUCTION_MODAL_SUCCESS,
  OPEN_SEND_TO_AUCTION_MODAL_ERROR,
  CLOSE_SEND_TO_AUCTION_MODAL,
  OPEN_ADD_TO_EXCHANGE_MODAL_BEGIN,
  OPEN_ADD_TO_EXCHANGE_MODAL_SUCCESS,
  OPEN_ADD_TO_EXCHANGE_MODAL_ERROR,
  CLOSE_ADD_TO_EXCHANGE_MODAL,
  OPEN_BIDDING_MODAL,
  CLOSE_BIDDING_MODAL,
  GET_CLOSED_GROUP_SUCCESS,
  GET_CLOSED_GROUP_FAILURE,
  OPEN_EDIT_SEARCH_MODAL,
  CLOSE_EDIT_SEARCH_MODAL
} from '../constants';

const initialState = {
    proxyBid: {
        isOpen: false,
        data: {},
    },
    buyNow: {
        isOpen: false,
        data: {},
    },
    saveSearch: {
        isOpen: false,
        editIsOpen: false,
        selectedSearchId: null,
        useSelectedSearches: false,
        selectedUsername: "",
    },
    vehiclePhoto: {
        isOpen: false,
        data: {
            isEhub: false,
            hasSpinCar: false,
            spinCarUrl: null,
            images: [],
            selectedItem: 0,
        },
    },
    sendToAuction: {
        isOpen: false,
        loading: false,
        data: {
            appraisalId: '',
            sivPrice: null,
        },
    },
    addToEXchange: {
        isOpen: false,
        data: {
            appraisalId: '',
            collectionSite: '',
            listAndCollect: false,
        },
    },
    bidding: {
        isOpen: false,
        id: null,
        listAndCollect: false,
    },
}

const PaginationReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_PROXY_BID_MODAL:
            return {
                ...state,
                proxyBid: {
                    ...state.proxyBid,
                    isOpen: true,
                    data: {
                        ...state.proxyBid.data,
                        saleId: action.payload.saleId,
                        vehicleId: action.payload.vehicleId,
                        virtual: action.payload.virtual,
                        account: action.payload.account,
                    },
                },
            }
        case CLOSE_PROXY_BID_MODAL:
            return {
                ...state,
                proxyBid: {
                    ...state.proxyBid,
                    isOpen: false,
                    data: {},
                },
            }
        case OPEN_BUY_NOW_MODAL:
            return {
                ...state,
                buyNow: {
                    ...state.buyNow,
                    isOpen: true,
                    data: action.payload,
                },
            }
        case CLOSE_BUY_NOW_MODAL:
            return {
                ...state,
                buyNow: {
                    ...state.buyNow,
                    isOpen: false,
                    data: {},
                },
            }
        case OPEN_SEND_TO_AUCTION_MODAL_BEGIN:
            return {
                ...state,
                sendToAuction: {
                    ...state.sendToAuction,
                    loading: true,
                },
            }
        case OPEN_SEND_TO_AUCTION_MODAL_SUCCESS:
            return {
                ...state,
                sendToAuction: {
                    ...state.sendToAuction,
                    isOpen: true,
                    data: action.payload,
                },
            }
        case OPEN_SEND_TO_AUCTION_MODAL_ERROR:
            return {
                ...state,
                sendToAuction: {
                    ...state.sendToAuction,
                    isOpen: false,
                    data: action.payload,
                },
            }
        case CLOSE_SEND_TO_AUCTION_MODAL:
            return {
                ...state,
                sendToAuction: {
                    ...state.sendToAuction,
                    isOpen: false,
                    data: {},
                },
            }
        case OPEN_ADD_TO_EXCHANGE_MODAL_BEGIN:
            return {
                ...state,
                sendToAuction: {
                    ...state.addToEXchange,
                    loading: true,
                },
            }
        case OPEN_ADD_TO_EXCHANGE_MODAL_SUCCESS:
            return {
                ...state,
                addToEXchange: {
                    ...state.addToEXchange,
                    isOpen: true,
                    data: action.payload,
                },
            }
        case OPEN_ADD_TO_EXCHANGE_MODAL_ERROR:
            return {
                ...state,
                sendToAuction: {
                    ...state.addToEXchange,
                    isOpen: false,
                    data: action.payload,
                },
            }
        case GET_CLOSED_GROUP_SUCCESS:
            return {
                ...state,
                addToEXchange: {
                    ...state.addToEXchange,
                    closedGroupName: action.payload,
                },
            }
        case GET_CLOSED_GROUP_FAILURE:
            return {
                ...state,
                addToEXchange: {
                    ...state.addToEXchange,
                    closedGroupName: null,
                },
            }
        case CLOSE_ADD_TO_EXCHANGE_MODAL:
            return {
                ...state,
                addToEXchange: {
                    ...state.addToEXchange,
                    isOpen: false,
                    data: {},
                },
            }
        case OPEN_SAVE_SEARCH_MODAL:
            return {
                ...state,
                saveSearch: {
                    ...state.saveSearch,
                    isOpen: true,
                },
            }
        case CLOSE_SAVE_SEARCH_MODAL:
            return {
                ...state,
                saveSearch: {
                    ...state.saveSearch,
                    isOpen: false,
                },
            }
        case OPEN_EDIT_SEARCH_MODAL:
            return {
                ...state,
                saveSearch: {
                    ...state.saveSearch,
                    editIsOpen: true,
                    selectedSearchId: action.payload,
                    useSelectedSearches: action.useSelectedSearches,
                    selectedUsername: action.selectedUsername,
                },
            }
        case CLOSE_EDIT_SEARCH_MODAL:
            return {
                ...state,
                saveSearch: {
                    ...state.saveSearch,
                    editIsOpen: false,
                    selectedSearchId: null,
                },
            }
        case OPEN_BIDDING_MODAL:
            return {
                ...state,
                bidding: {
                    id: action.payload.id,
                    isOpen: true,
                },
            }
        case CLOSE_BIDDING_MODAL:
            return {
                ...state,
                bidding: {
                    ...state.bidding,
                    isOpen: false,
                },
            }
        case OPEN_VEHICLE_PHOTO_MODAL:
            return {
                ...state,
                vehiclePhoto: {
                    ...state.vehiclePhoto,
                    isOpen: true,
                    data: {
                        ...state.data,
                        isEhub: action.payload.isEhub,
                        hasSpinCar: action.payload.hasSpinCar,
                        spinCarUrl: action.payload.spinCarUrl,
                        images: action.payload.images,
                        selectedItem: action.payload.selectedItem,
                        captions: action.payload.captions,
                    },
                },
            }
        case CLOSE_VEHICLE_PHOTO_MODAL:
            return {
                ...state,
                vehiclePhoto: {
                    ...state.vehiclePhoto,
                    isOpen: false,
                    data: initialState.vehiclePhoto.data,
                },
            }
        default:
            return state
    }
}

export default PaginationReducer
