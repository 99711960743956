import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { onCloseVehiclePhotoModal } from '../../actions/modals'

class PhotoModal extends Component {
    onRenderImages() {
        let fragment
        if (this.props.vehiclePhotoModal.data.hasSpinCar) {
            const SpinningCar = (
                <div key="spincar" style={{ height: '100%' }}>
                    <div className="img-spin">
                        <img src="/img/icon-360-black.svg" alt="Spin Car" />
                    </div>
                    <iframe
                        title="spin car"
                        className="embed-responsive-item"
                        name="sts_14049883636208338"
                        src={this.props.vehiclePhotoModal.data.spinCarUrl}
                        scrolling="no"
                        allowFullScreen=""
                        id="sts_14049883636208338-iframe"
                        ref={(el) => {
                            if (el) {
                                el.style.setProperty('z-index', '0', 'important')
                                el.style.setProperty('border', 'none', 'important')
                                el.style.setProperty('margin', '0', 'important')
                                el.style.setProperty('padding', '0', 'important')
                                el.style.setProperty('width', '1px', 'important')
                                el.style.setProperty('height', '1px', 'important')
                                el.style.setProperty('min-width', '100%', 'important')
                                el.style.setProperty('min-height', '100%', 'important')
                            }
                        }}
                    />
                </div>
            )
            const images = this.props.vehiclePhotoModal.data.images.map((image, i) => {
                return (
                    <img
                        key={i}
                        src={image}
                        alt=""
                        onError={(e) => {
                            e.target.onerror = null
                            e.target.src = '/img/image-unavailable.png'
                        }}
                    />
                )
            })
            return [SpinningCar, images]
        } else {
            if (this.props.vehiclePhotoModal.data.isEhub) {
                fragment = this.props.vehiclePhotoModal.data.images.map((image, i) => {
                    return (
                        <img
                            key={i}
                            src={image}
                            alt=""
                            onError={(e) => {
                                e.target.onerror = null
                                e.target.src = '/img/image-unavailable.png'
                            }}
                        />
                    )
                })
            } else {
                fragment = this.props.vehiclePhotoModal.data.images.map((image, i) => {
                    if (this.props.vehiclePhotoModal.data.captions) {
                        return (
                            <React.Fragment key={i}>
                                <img
                                    src={image}
                                    alt=""
                                    onError={(e) => {
                                        e.target.onerror = null
                                        e.target.src = '/img/image-unavailable.png'
                                    }}
                                />
                                <div className="legend">{this.props.vehiclePhotoModal.data.captions[i]}</div>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <img
                                key={i}
                                src={image}
                                alt=""
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = '/img/image-unavailable.png'
                                }}
                            />
                        )
                    }
                })
            }
        }
        return fragment
    }

    render() {
        return (
            <Modal
                isOpen={this.props.vehiclePhotoModal.isOpen}
                toggle={() => {
                    if (this.props.vehiclePhotoModal.isOpen) {
                        return this.props.onCloseVehiclePhotoModal(!this.props.vehiclePhotoModal.isOpen)
                    }
                    return this.props.onOpenVehiclePhotoModal(!this.props.vehiclePhotoModal.isOpen)
                }}
                fade={false}
                className="modal-dialog-centered modal-lg modal-transparent"
            >
                <ModalHeader
                    toggle={() => {
                        if (this.props.vehiclePhotoModal.isOpen) {
                            return this.props.onCloseVehiclePhotoModal(!this.props.vehiclePhotoModal.isOpen)
                        }
                        return this.props.onOpenVehiclePhotoModal(!this.props.vehiclePhotoModal.isOpen)
                    }}
                />
                <ModalBody
                    className={
                        !this.props.vehiclePhotoModal.data.images && this.props.vehiclePhotoModal.data.hasSpinCar
                            ? 'spincar'
                            : ''
                    }
                >
                    {this.props.vehiclePhotoModal.data.images && (
                        <Carousel
                            swipeable={false}
                            showThumbs={false}
                            showStatus={true}
                            selectedItem={this.props.vehiclePhotoModal.data.selectedItem}
                            className="modal-carousel"
                            transitionTime={0}
                        >
                            {this.onRenderImages()}
                        </Carousel>
                    )}
                    {!this.props.vehiclePhotoModal.data.images && this.props.vehiclePhotoModal.data.hasSpinCar && (
                        <iframe
                            title="spin car"
                            className="embed-responsive-item"
                            name="sts_14049883636208338"
                            src={this.props.vehiclePhotoModal.data.spinCarUrl}
                            scrolling="no"
                            allowFullScreen=""
                            id="sts_14049883636208338-iframe"
                            ref={(el) => {
                                if (el) {
                                    el.style.setProperty('z-index', '0', 'important')
                                    el.style.setProperty('border', 'none', 'important')
                                    el.style.setProperty('margin', '0', 'important')
                                    el.style.setProperty('padding', '0', 'important')
                                    el.style.setProperty('width', '1px', 'important')
                                    el.style.setProperty('height', '1px', 'important')
                                    el.style.setProperty('min-width', '100%', 'important')
                                    el.style.setProperty('min-height', '100%', 'important')
                                }
                            }}
                        />
                    )}
                </ModalBody>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    vehiclePhotoModal: state.modals.vehiclePhoto,
})

const mapDispatchToProps = {
    onCloseVehiclePhotoModal,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PhotoModal))
