import React from 'react'
import VehicleIcons from '../../UiKit/VehicleCard/icons/VehicleIcons'
import Magnifier from 'react-magnifier'
import {
    ABGradeIcon,
    getDamageInfo,
    getDamagePanelResultQuestions,
    getInspection,
    getPrice,
    NamaGradeIcon,
    VehicleStatsRow,
    getDamageImages,
} from './VehicleHelper'

import { getPrimaryColor } from '../../utilities/ThemeFunctions'
import { lang, permissions } from '../../themeConfig'
import { FaFilePdf } from '@react-icons/all-files/fa/FaFilePdf'
import { FaCheck } from '@react-icons/all-files/fa/FaCheck'
import { FaTimes } from '@react-icons/all-files/fa/FaTimes'
import { IoIosLock } from '@react-icons/all-files/io/IoIosLock'

export function AuctionWinnerMessage(props) {
    const { vehicle } = props

    if (
        vehicle.biddingDetails &&
        vehicle.biddingDetails.ended &&
        vehicle.biddingDetails.userWinning &&
        vehicle.biddingDetails.reserveMet
    ) {
        if (vehicle.listAndTransact) {
            return (
                <div className="col-12 col-md-8 m-0 p-0 p-md-2">
                    <div className="collection-message">
                        Please contact <strong>Aston Barclay Digital team</strong> on <strong>07384 467110</strong> to
                        book transport and arrange payment within 48 hours of the sale ending.
                    </div>
                </div>
            )
        }
        if (vehicle.location) {
            let endMessage = 'to discuss completing the sale within 48 hours of the sale ending.'
            if (vehicle.listAndCollect || vehicle.listAndDeliver) {
                endMessage = 'to book transport and arrange payment within 48 hours of the sale ending.'
            }
            return (
                <div className="col-12 col-md-8 m-0 p-0 p-md-2">
                    <div className="collection-message">
                        Please contact <strong>{vehicle.location.description || vehicle.seller}</strong>{' '}
                        {vehicle.location.telephone && (
                            <>
                                on <strong>{vehicle.location.telephone}</strong>
                            </>
                        )}{' '}
                        {endMessage}
                    </div>
                </div>
            )
        } else {
            return null
        }
    } else if (
        vehicle.biddingDetails &&
        vehicle.biddingDetails.ended &&
        vehicle.biddingDetails.userWinning &&
        !vehicle.biddingDetails.reserveMet &&
        vehicle.biddingDetails.provisionalStatus === 'Seller Accepted' &&
        vehicle.biddingDetails.exchangeType === 'ListOnly'
    ) {
        return (
            <div className="col-12 col-md-8 m-0 p-0 p-md-2">
                <div className="collection-message">
                    Please contact <strong>{vehicle.seller}</strong> to discuss completing the sale within 48 hours of
                    the sale ending.
                </div>
            </div>
        )
    } else {
        return null
    }
}

const NOT_LOGGED_IN_ROW = (
    <div className="not-signed-in not-signed-in-row">
        <IoIosLock size="1.5em" color="#333" />
        You are not signed in
    </div>
)

export function ABGrade(props) {
    const { vehicle, user } = props

    if (!user.username) {
        return (
            <tr>
                <td>{lang('LABEL_AB_GRADE', user.theme)}</td>
                <td>{NOT_LOGGED_IN_ROW}</td>
            </tr>
        )
    }

    if (!permissions('CATALOGUE_VEHICLE_DETAILS_AB_ENABLED')) {
        return null
    }

    return (
        <tr>
            <td>{lang('LABEL_AB_GRADE', user.theme)}</td>
            <td>
                <ABGradeIcon vehicle={vehicle} />
            </td>
        </tr>
    )
}

export function NamaGrade(props) {
    const { vehicle, user } = props

    if (!user.username) {
        return (
            <tr>
                <td>{lang('LABEL_NAMA_GRADE', user.theme)}</td>
                <td>{NOT_LOGGED_IN_ROW}</td>
            </tr>
        )
    }

    if (!permissions('CATALOGUE_VEHICLE_DETAILS_NAMA_ENABLED', user.theme)) {
        return null
    }

    return (
        <tr>
            <td>{lang('LABEL_NAMA_GRADE', user.theme)}</td>
            <td>
                <NamaGradeIcon vehicle={vehicle} />
            </td>
        </tr>
    )
}

export function CapCleanPrice(props) {
    const { vehicle, user } = props

    if (!user.username) {
        return (
            <tr>
                <td>{lang('LABEL_CAP_CLEAN_PRICE', user.theme)}</td>
                <td>{NOT_LOGGED_IN_ROW}</td>
            </tr>
        )
    }

    return (
        <tr>
            <td>{lang('LABEL_CAP_CLEAN_PRICE', user.theme)}</td>
            <td>{getPrice(vehicle.capCleanPrice)}</td>
        </tr>
    )
}

export function Warranty(props) {
    const { vehicle, user } = props

    return (
        <tr>
            <td>{lang('LABEL_MILEAGE_WARRANTED', user.theme)}</td>
            <td style={vehicle.mileageWarranty ? { color: getPrimaryColor() } : { color: 'inherit' }}>
                {vehicle.mileageWarranty ? <FaCheck /> : <FaTimes />}
            </td>
        </tr>
    )
}

// - --------------------------------
// - --- HELPER export functionS

export function getBreadcrumbUrl(prevPath) {
    switch (prevPath) {
        case 'watch':
            return '/my-account/watchlist'
        case 'proxy-bid':
            return '/my-account/proxy-bids'
        case 'bid-buy-now':
            return '/my-account/bid-buy-now'
        case 'e-xchange':
            return '/my-account/e-xchange'
        default:
            return '/vehicle-search'
    }
}

export function getBreadcrumbText(prevPath, theme) {
    switch (prevPath) {
        case 'watch':
            return lang('CATALOGUE_WATCH_LIST_TITLE', theme)
        case 'proxy-bid':
            return lang('CATALOGUE_PROXY_BID_LIST_TITLE', theme)
        case 'bid-buy-now':
            return lang('CATALOGUE_BID_BUY_NOW_LIST_TITLE', theme)
        case 'e-xchange':
            return lang('CATALOGUE_MY_ACCOUNT_EXCHANGE_TITLE', theme)
        default:
            return lang('CATALOGUE_VEHICLE_SEARCH_TITLE', theme)
    }
}

export function getVehicleDescription(vehicle) {
    return `${vehicle.registration} - ${vehicle.make} ${
        vehicle.model ? vehicle.model : ''
    } ${vehicle.derivative ? vehicle.derivative : ''} ${
        vehicle.fuel ? vehicle.fuel : ''
    } ${vehicle.colour ? vehicle.colour : ''}`
}

export function getVehicleSmallDetails(vehicle) {
    return `${vehicle.make} ${vehicle.model ? vehicle.model : ''} ${vehicle.derivative ? vehicle.derivative : ''}`
}

export function getAuctionLocation(vehicle) {
    const { virtual, site, saleId } = vehicle

    let siteName = ''
    if (virtual) {
        siteName = 'e-Live'
    } else if (site && site.name) {
        siteName = site.name.substring(14)
    }

    return `${siteName} (${saleId})`
}

export function chunkArray(arr, n) {
    let chunkLength = Math.max(arr.length / n, 1)
    let chunks = []
    for (let i = 0; i < n; i++) {
        if (chunkLength * (i + 1) <= arr.length) chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)))
    }
    return chunks
}

export function getInspectionOptionsByColumn(vehicle, column, theme) {
    if (getInspection(vehicle).options && getInspection(vehicle).options.length > 0) {
        const array = chunkArray(getInspection(vehicle).options, 2)[column]
        if (array) {
            return array.map((key, index) => {
                return <VehicleStatsRow key={index} label={key.description} value={key.notes} custom="specification" />
            })
        } else {
            return null
        }
    } else {
        if (column === 0) {
            return (
                <tr>
                    <td>{lang('NO_DAMAGE_REPORT_DATA', theme)}</td>
                </tr>
            )
        } else {
            return null
        }
    }
}

export function getInspectionDamage(vehicle, openPhotoModal, theme) {
    if (getInspection(vehicle).damage) {
        const images = getInspection(vehicle).damage.map((item, val) => {
            return item.images[0] ? item.images[0] : '/img/no-image.png'
        })
        const captions = getInspection(vehicle).damage.map((item, val) => {
            return `${lang('LABEL_LOCATION', theme)} - ${item.component}, ${lang('LABEL_FAULT', theme)} - ${item.damage}, ${lang('LABEL_ACTION', theme)} - ${item.repair}`
        })
        return getInspection(vehicle).damage.map((key, index) => {
            const image = key.images && key.images.length > 0 ? key.images[0] : '/img/no-image.png'

            return (
                <div key={index} className="row m-0 p-0 py-3 ab-row-border">
                    <div
                        className="col-12 col-md-3 m-0 p-0 pr-2 cursor-pointer"
                        onClick={() =>
                            openPhotoModal({
                                images: images,
                                selectedItem: index,
                                captions: captions,
                            })
                        }
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {image ? (
                            <Magnifier
                                src={image}
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = '/img/image-unavailable.png'
                                }}
                                zoomFactor="1.5"
                                alt="img"
                                width="100%"
                            />
                        ) : (
                            <div>{lang('NO_IMAGE', theme)}</div>
                        )}
                    </div>
                    <div className="col-12 col-md-9 m-0 p-0">
                        <table className="table m-0 p-0">
                            <tbody>
                                <tr>
                                    <td>{lang('LABEL_LOCATION', theme)}</td>
                                    <td>{key.component}</td>
                                </tr>
                                <tr>
                                    <td>{lang('LABEL_FAULT', theme)}</td>
                                    <td>{key.damage}</td>
                                </tr>
                                <tr>
                                    <td>{lang('LABEL_ACTION', theme)}</td>
                                    <td>{key.repair}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        })
    } else {
        return null
    }
}

export function getDamageInformation(vehicle, openPhotoModal, theme) {
    if (getDamageInfo(vehicle)) {
        return getDamageInfo(vehicle).map((keyMaster, indexMaster) => {
            return keyMaster.combinedDamageInfo.map((keyCombinedDamage, indexCombinedDamage) => {
                let damageImagesAndAreas = getDamageImages(getDamageInfo(vehicle))
                return (
                    <div
                        key={indexMaster}
                        className={
                            damageHasNext(keyMaster, indexCombinedDamage)
                                ? 'row m-0 p-0 py-3'
                                : 'row m-0 p-0 py-3 ab-row-border'
                        }
                    >
                        {getDamageImage(keyCombinedDamage, indexMaster, openPhotoModal, damageImagesAndAreas)}
                        {getDamageDetails(keyMaster, keyCombinedDamage, indexCombinedDamage)}
                    </div>
                )
            })
        })
    } else {
        return (
            <div className="row m-0 p-0 py-3">
                <div className="text-center" style={{ width: '100%' }}>
                    {lang('NO_DAMAGE_REPORT_DATA', theme)}
                </div>
            </div>
        )
    }
}

export function getDamageQuestions(vehicle) {
    if (!getDamagePanelResultQuestions(vehicle)) {
        return null
    }
    return getDamagePanelResultQuestions(vehicle).map((key, index) => {
        return (
            <div key={index} className="row m-0 p-0 py-3 ab-row-border">
                <div className="col-12 col-md-12 m-0 p-0">
                    <table className="table m-0 p-0">
                        <tbody>
                            <tr>
                                <td>Damage Type</td>
                                <td>{key.resolution || '-'}</td>
                            </tr>
                            <tr>
                                <td>Fault</td>
                                <td>{key.section || '-'}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="damage-spacer" />
                    <div className="damage-summary">
                        <b>
                            {key.resolution} £{key.cost}
                        </b>
                    </div>
                </div>
            </div>
        )
    })
}

function getDamageImage(keyCombined, index, openPhotoModal, photoModalInfo) {
    let images = []
    let locations = []
    photoModalInfo.forEach((info) => {
        images.push(info.image)
        locations.push(`Location - ${info.label}`)
    })
    return (
        <div
            className="col-12 col-md-3 m-0 p-0 cursor-pointer"
            onClick={() =>
                openPhotoModal({
                    images: images,
                    selectedItem: index,
                    captions: locations,
                })
            }
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {keyCombined.image ? (
                <Magnifier src={keyCombined.image} zoomFactor="1.5" alt="img" width="100%" />
            ) : (
                <div>No image</div>
            )}
        </div>
    )
}

function getDamageDetails(key, keyCombined, index) {
    const area = (key.area || '').toUpperCase()
    const section = key.section && key.section !== key.area ? key.section : ''
    const component = key.component || ''
    const subcomponent = key.subComponent && key.subComponent !== key.component ? key.subComponent : ''
    const location = `${area} ${section} ${component} ${subcomponent ? '-' : ''} ${subcomponent}`
        .replace(/\s+/g, ' ') // Reduce multiple white spaces into one (due to multiple missing values)
        .trim() // Trim off remaining whitespaces at the front and at the back of the text
    return (
        <div className="col-12 col-md-9 m-0 p-0">
            <table className="table m-0 p-0">
                <tbody>
                    <tr>
                        <td>Location</td>
                        <td>{location}</td>
                    </tr>
                    {getDamageList(keyCombined.damages, key)}
                    <tr>
                        <td>Action</td>
                        <td>{keyCombined.resolution}</td>
                    </tr>
                </tbody>
            </table>
            {damageHasNext(key, index) ? (
                ''
            ) : (
                <React.Fragment>
                    <div className="damage-spacer" />
                    <div className="damage-summary">
                        <b>
                            {key.highestAreaResolution} £{key.highestAreaCost}
                        </b>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

function getDamageList(damages, key) {
    const count = function (list, value) {
        return list.filter((x) => x === value).length
    }
    const faultList = damages.map((dmg) => {
        let fault = dmg.damage
        if (dmg.severity) {
            fault += ` (${dmg.severity})`
        }
        return fault
    })
    const faultListReduced = faultList
        .map((fault) => {
            const counted = count(faultList, fault)
            if (counted > 1) {
                return `${fault} x${counted}`
            }
            return fault
        })
        .filter((value, index, self) => self.indexOf(value) === index)

    const fault = faultListReduced.join(', ')
    return (
        <>
            <tr>
                <td>Fault</td>
                <td>{fault}</td>
            </tr>
            <tr>
                <td>Comments</td>
                <td>{key.comments}</td>
            </tr>
        </>
    )
}

function damageHasNext(array, index) {
    return array.combinedDamageInfo.length > index + 1
}

export function getPricingSignatures(vehicle) {
    return (
        <div>
            {vehicle.assessorSignatureImage ? (
                <div style={{ width: '45%', float: 'left', padding: '5px' }}>
                    <b>Assessor</b>
                    <img
                        src={'data:image/png;base64,' + vehicle.assessorSignatureImage}
                        alt="Assessor Signature"
                        style={{ width: '100%', border: '2px solid black' }}
                    />
                </div>
            ) : null}

            {vehicle.customerSignatureImage ? (
                <div style={{ width: '45%', float: 'left', padding: '5px' }}>
                    <b>Customer</b>
                    <img
                        src={'data:image/png;base64,' + vehicle.customerSignatureImage}
                        alt="Assessor Signature"
                        style={{ width: '100%', border: '2px solid black' }}
                    />
                </div>
            ) : null}
        </div>
    )
}

export function getReportDownloadLink(vehicle, theme) {
    if (getInspection(vehicle).uncostedreporturl) {
        return (
            <div className="row pt-2 pr-2">
                <div className="col-12 col-lg-8 d-flex d-flex-a-c">
                    <VehicleIcons vehicle={vehicle} detailsScreen={true} withText={true} theme={theme} />
                </div>
                <div className="col-12 col-lg-4 text-right">
                    <a href={getInspection(vehicle).uncostedreporturl} target="_blank" rel=" noopener noreferrer">
                        <FaFilePdf
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title={lang('DOWNLOAD_PDF_FILE')}
                            style={{ marginRight: '10px' }}
                        />
                    </a>
                    <a href={getInspection(vehicle).uncostedreporturl} target="_blank" rel="noopener noreferrer">
                        {lang('DOWNLOAD_INSPECTION_REPORT', theme)}
                    </a>
                </div>
            </div>
        )
    } else {
        return (
            <div className="row pt-2 pr-2">
                <div className="col-12 d-flex d-flex-a-c">
                    <VehicleIcons vehicle={vehicle} detailsScreen={true} withText={true} theme={theme} />
                </div>
            </div>
        )
    }
}

export function showPriorListings(subPath) {
    return subPath !== 'collected-by-aston-barclay' && subPath !== 'collected-by-tcbg'
}

export function appraisalNewOrInStock(appraisal) {
    return appraisal.status === 'New' || appraisal.status === 'In Stock'
}
