import React, { Component } from 'react'

import {
    isVehicleFundingAccepted,
    isVehicleFundingAvailable,
    isVehicleFundingDeclined,
    isVehicleFundingIneligible,
    isVehicleFundingPended,
} from '../../utilities/Functions'

import { lang } from '../../themeConfig'
import { FaSync } from '@react-icons/all-files/fa/FaSync'
import { FaCheck } from '@react-icons/all-files/fa/FaCheck'

export class SecureBankTrustButton extends Component {
    getBtnClass = (fundingStatus) => {
        switch (fundingStatus) {
            case 'Ineligible':
                return 'btn-ineligible'
            case 'Accepted':
                return 'btn-accepted'
            case 'Pending':
                return 'btn-pending'
            default:
                return 'btn-primary'
        }
    }

    render() {
        const { vehicle, onClickFundVehicle } = this.props

        if (isVehicleFundingIneligible(vehicle.fundingStatus)) {
            return (
                <div className="col-12 m-0 p-2">
                    <button
                        className={
                            'btn btn-funding btn-block ab-btn-padding ab-btn-buy-now ' +
                            this.getBtnClass(vehicle.fundingStatus)
                        }
                        type="button"
                        aria-disabled="true"
                        disabled
                    >
                        {lang('VEHICLE_INELIGABLE', this.props.user.theme)}
                    </button>
                </div>
            )
        } else if (isVehicleFundingDeclined(vehicle.fundingStatus)) {
            return (
                <div className="col-12 m-0 p-2">
                    <button
                        className={'btn btn-primary btn-danger btn-block ab-btn-padding ab-btn-buy-now '}
                        type="button"
                        onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            onClickFundVehicle(
                                Object.assign(
                                    {},
                                    {
                                        id: vehicle.id,
                                    },
                                ),
                            )
                        }}
                    >
                        {lang('FUNDING_DECLINED_RETRY', this.props.user.theme)} <FaSync />
                    </button>
                </div>
            )
        } else if (isVehicleFundingPended(vehicle.fundingStatus)) {
            return (
                <div className="col-12 m-0 p-2">
                    <button
                        className={
                            'btn btn-funding btn-block ab-btn-padding ab-btn-buy-now ' +
                            this.getBtnClass(vehicle.fundingStatus)
                        }
                        type="button"
                        aria-disabled="true"
                        disabled
                    >
                        {lang('FUNDING_PENDING', this.props.user.theme)}
                    </button>
                </div>
            )
        } else if (isVehicleFundingAccepted(vehicle.fundingStatus)) {
            return (
                <div className="col-12 m-0 p-2">
                    <button
                        className={
                            'btn btn-funding btn-block ab-btn-padding ab-btn-buy-now ' +
                            this.getBtnClass(vehicle.fundingStatus)
                        }
                        type="button"
                        aria-disabled="true"
                        disabled
                    >
                        {lang('FUNDING_ACCEPTED', this.props.user.theme)} <FaCheck />
                    </button>
                </div>
            )
        } else if (isVehicleFundingAvailable(vehicle.fundingStatus)) {
            return (
                <div className="col-12 m-0 p-2">
                    <button
                        className={
                            'btn btn-primary btn-block ab-btn-padding ab-btn-buy-now ' +
                            this.getBtnClass(vehicle.fundingStatus)
                        }
                        type="button"
                        onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            onClickFundVehicle(
                                Object.assign(
                                    {},
                                    {
                                        id: vehicle.id,
                                    },
                                ),
                            )
                        }}
                    >
                        {lang('FUND_VEHICLE', this.props.user.theme)}
                    </button>
                </div>
            )
        }
    }
}

export default SecureBankTrustButton
