import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchBidBuyNowVehicles } from '../../../actions/vehicles'
import { setPageSize } from '../../../actions/pagination'
import { onChangeSortOrder } from '../../../actions/sorting'
import BidBuyNowList from './BidBuyNowList'

const mapStateToProps = (state) => ({
    user: state.user,
    vehicles: state.vehicles,
    pagination: state.pagination,
    sorting: state.sorting,
})

const mapDispatchToProps = {
    fetchBidBuyNowVehicles,
    setPageSize,
    onChangeSortOrder,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BidBuyNowList))
