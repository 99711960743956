import React, { Component } from 'react'
import FilterButton from '../ActionButtons/FilterButton'

class SideNavFilter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            viewType: 'grid',
            activeButton: 'filter-0',
        }

        this.handleClick = this.handleClick.bind(this)
        this.getButtonClass = this.getButtonClass.bind(this)
    }
    getButtonClass(buttonValue) {
        if (this.state.activeButton === buttonValue) {
            return 'btn-filter__on'
        } else {
            return 'btn-filter'
        }
    }

    handleClick = (activeButtonValue, callBackId) => {
        this.setState((prevState, props) => ({
            activeButton: activeButtonValue,
        }))
        this.props.selectedFilter(callBackId)
    }

    render() {
        const { handleRefresh, title, filters } = this.props

        return (
            <React.Fragment>
                <div className="row mx-0 my-0 mx-md-1">
                    <div className="col text-left p-md-1">
                        {title}
                        {handleRefresh && (
                            <div className="ab-reset-filters-lg link" onClick={() => handleRefresh()}>
                                Refresh
                            </div>
                        )}
                    </div>
                </div>
                <div className="row mx-0 my-0 mx-md-1">
                    <div className="col m-0 p-0 ab-results-filter-lg">
                        {filters.map((item, i) => {
                            return (
                                <div key={i}>
                                    <FilterButton
                                        id={`filter-${i}`}
                                        callBackId={item.id}
                                        handleClick={this.handleClick}
                                        count={item.count}
                                        displayText={item.displayText}
                                        buttonClass={this.getButtonClass(`filter-${i}`)}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SideNavFilter
