import React, { Component } from 'react'
import Select from 'react-select'

class DealerSelector extends Component {
    render() {
        const selected =
            !this.props.defaultValue || this.props.dropdownOptions.length === this.props.defaultValue.length
                ? []
                : this.props.defaultValue
        return (
            <Select
                placeholder={`Showing all accounts`}
                isMulti={true}
                isSearchable={false}
                onChange={this.props.onChange}
                options={this.props.dropdownOptions}
                defaultValue={selected}
                className="custom-select-input"
                classNamePrefix="ab-select"
            />
        )
    }
}

export default DealerSelector
