import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import TwitterIcons from './Twitter'
class FollowPosts extends Component {
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12" className="mt-5 text-center">
                        <h3>Follow us @AstonAuctions</h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" md="4" className="text-center pb-8">
                        <div className="ab-speech-bubble">
                            We have a fantastic opportunity to join our Buyer Engagement team with the last
                            Telemarketing Executive role available!A great, lively team in a fast paced, growing
                            business, based at our premium site in Wakefield! For more details & to apply, click here{' '}
                            <a href="/">https://bit.ly/2GsarJm</a>
                        </div>
                        <TwitterIcons />
                    </Col>
                    <Col xs="12" md="4" className="text-center pb-8">
                        <div className="ab-speech-bubble">
                            Back with another Centre for a Day sale this Tuesday at Chelmsford! In honour of our sites
                            being closed Monday, we're holding a special <a href="/">@CarBuyingGroup</a> exclusive on
                            Tuesday ft. up to 200 FRESH ENTRIES all online, in the hall & via the App from 10:30am!{' '}
                            <a href="/">https://bit.ly/2V7uPYR</a>
                        </div>
                        <TwitterIcons />
                    </Col>
                    <Col xs="12" md="4" className="text-center pb-8">
                        <div className="ab-speech-bubble">
                            The last of our 3 <a href="/">@MercedesBenz</a> sales this week takes place at Westbury
                            tomorrow morning! Join us for over 160 premium cars, all from our South East site, online &
                            in the App! 10:30am, tomorrow ... see you there <a href="/">https://bit.ly/2VHXRhc</a>
                        </div>
                        <TwitterIcons />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default FollowPosts
