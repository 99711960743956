import React, { Component } from 'react'

import BrandedFooterLinks from './BrandedFooterLinks'
import { langExists, permissions, lang } from '../../themeConfig'
import { Link } from 'react-router-dom'

class CatalogueFooter extends Component {
    constructor(props) {
        super(props)

        this.isClosedGroup = permissions('CLOSED_GROUP')
    }
    render() {
        return (
            <div className={this.isClosedGroup ? 'ab-footer__wrapper' : 'footer_wrapper'}>
                <div className="footer_container">
                    <div className="footer_columns four_columns">
                        {this.props.data && this.props.data.registeredAddress && (
                            <React.Fragment>
                                <h4>Registered Address</h4>
                                <p>
                                    {this.props.data.registeredAddress.line1}
                                    <br />
                                    {this.props.data.registeredAddress.line2}
                                    <br />
                                    {this.props.data.registeredAddress.line3}
                                </p>

                                <p>© {new Date().getFullYear()} Aston Barclay Ltd. All rights reserved.</p>
                            </React.Fragment>
                        )}
                    </div>

                    {permissions('FOOTER_TYPE', this.props.theme) === 'classic' && (
                        <div className="footer_columns three_columns">
                            <ul>
                                <li>
                                    {' '}
                                    <Link to="/vehicle-search">Vehicle Search</Link>{' '}
                                </li>

                                <li>
                                    <a href={`${this.props.global.frontMediaHost}/auction-schedule`}>
                                        Auction Schedule
                                    </a>
                                </li>

                                <li>
                                    {' '}
                                    <a href={`${this.props.global.frontMediaHost}/centres`}>Centres</a>
                                </li>

                                <li>
                                    {' '}
                                    <a href={`${this.props.global.frontMediaHost}/services`}>Services</a>
                                </li>

                                <li>
                                    {' '}
                                    <a href={`${this.props.global.frontMediaHost}/help`}>Help</a>
                                </li>

                                <li>
                                    {' '}
                                    <a href={`${this.props.global.frontMediaHost}/news`}>News</a>
                                </li>
                                <li>
                                    <a href={`${this.props.global.frontMediaHost}/press-enquiries`}>Press Enquiries</a>
                                </li>
                            </ul>
                        </div>
                    )}

                    {!this.isClosedGroup && (
                        <div className="footer_columns three_columns">
                            <ul>
                                <li>
                                    {' '}
                                    <a href={`${this.props.global.frontMediaHost}/privacy`}>Privacy Policy</a>
                                </li>

                                <li>
                                    <a href={`${this.props.global.frontMediaHost}/recruitment`}>Recruitment</a>
                                </li>

                                <li>
                                    {' '}
                                    <a href={`${this.props.global.frontMediaHost}/sitemap`}>Sitemap</a>
                                </li>

                                <li>
                                    {' '}
                                    <a href={`${this.props.global.frontMediaHost}/terms`}>Terms & Conditions</a>
                                </li>

                                <li>
                                    {' '}
                                    <a href={`${this.props.global.frontMediaHost}`}>Home</a>
                                </li>
                            </ul>
                        </div>
                    )}

                    {!this.isClosedGroup && (
                        <div className="footer_columns two_columns">
                            <ul id="social">
                                <li>
                                    <a className="fb" href="https://www.facebook.com/VehicleRemarketing/">
                                        &nbsp;
                                    </a>
                                </li>
                                <li>
                                    <a className="tw" href="https://twitter.com/astonauctions">
                                        &nbsp;
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="go-top"
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                        }}
                                    >
                                        &nbsp;
                                    </a>
                                </li>
                                <li />
                            </ul>
                        </div>
                    )}

                    {permissions('FOOTER_TYPE', this.props.theme) === 'branded' && (
                        <div className="footer_columns three_columns">
                            <BrandedFooterLinks
                                theme={this.props.theme}
                                data={this.props.data}
                                global={this.props.global}
                            />
                        </div>
                    )}
                    {langExists('FOOTER_RIGHT_MESSAGE', this.props.theme) && (
                        <div className="footer_columns four_columns">
                            {
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: lang('FOOTER_RIGHT_MESSAGE', this.props.theme),
                                    }}
                                />
                            }
                        </div>
                    )}
                    {!this.isClosedGroup && (
                        <>
                            <hr />
                            <div className="footer_columns eight_columns">
                                <div className="logo-grid">
                                    <figure className="logo">
                                        <img src="/img/logo-nama.png" alt="NAMA member" />
                                    </figure>
                                    <figure className="logo">
                                        <img src="/img/WV_Awards.png" alt="What Van? Awards 2022 finalist" />
                                    </figure>
                                    <figure className="logo">
                                        <img src="/img/Business_Car_Award.jpg" alt="Business Car Awards 2022" />
                                    </figure>
                                    <figure className="logo">
                                        <img src="/img/MTA_21.jpg" alt="Motor Trader Industry Awards 2021" />
                                    </figure>
                                </div>
                            </div>
                            <div className="footer_columns four_columns last-child">
                                <img src="/img/logo-bsi.png" className="scale" alt="" />
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default CatalogueFooter
