import React from 'react'
import { Col, Collapse, Nav, Navbar, NavbarToggler, Row } from 'reactstrap'
import { lang, langExists } from '../../themeConfig'
import { FaTimes } from '@react-icons/all-files/fa/FaTimes'
import { FaBars } from '@react-icons/all-files/fa/FaBars'
import PropTypes from 'prop-types'

/**
 * Get a menu header that reacts to different screen sizes (desktop/laptop, tablet, mobile)
 * @param children                  an array of JSX.Element items that the menu should render
 * @param toggleNavbarFunction      Function in Header.js that toggles the navbar when collapsed in mobile mode
 * @param getExtraCssFunction       Function in Header.js - styling to distinguish between dev, test, and prod
 * @param getHeaderLogoFunction     Function in Header.js - the logo for the header
 * @param isMobileState             State in Header.js - determines if viewport is of mobile
 * @param isOpenState               State in Header.js - determines if menu is open or not
 * @param isClosedGroup
 * @param theme
 * @param extras                    Optional - JSX.Element items that can be added onwards.
 * @returns {JSX.Element}           The actual reactive menu header.
 */
function getReactiveMenuHeader(
    children,
    toggleNavbarFunction,
    getExtraCssFunction,
    getHeaderLogoFunction,
    isMobileState,
    isOpenState,
    isClosedGroup,
    theme,
    extras,
) {
    return (
        <React.Fragment>
            {/* Mobile or closed group header */}
            {(isMobileState || isClosedGroup) && (
                <div className={'header__wrapper '}>
                    <Row>
                        <Col xs={isMobileState ? '10' : '3'}>{getHeaderLogoFunction()}</Col>
                        {langExists('LABEL_HEADER_MESSAGE', theme) && (
                            <Col
                                xs={7}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {
                                    <span
                                        className="label_header_message"
                                        dangerouslySetInnerHTML={{
                                            __html: lang('LABEL_HEADER_MESSAGE', theme),
                                        }}
                                    />
                                }
                            </Col>
                        )}
                        <Col className="ab-header__main ab-header-nav ab-wl-header">
                            <Navbar light expand="lg">
                                <NavbarToggler onClick={toggleNavbarFunction}>
                                    {isOpenState ? (
                                        <FaTimes className="menu-toggle-icon" />
                                    ) : (
                                        <FaBars className="menu-toggle-icon" />
                                    )}
                                </NavbarToggler>
                                <Collapse isOpen={isOpenState} navbar>
                                    <Nav navbar>{children}</Nav>
                                    {extras}
                                </Collapse>
                            </Navbar>
                        </Col>
                    </Row>
                </div>
            )}

            {/* Standard header */}
            {!(isMobileState || isClosedGroup) && (
                <div
                    id={'headerWrap'}
                    // databuild={`Build Date: ${preval`module.exports = new Date().toLocaleString();`}.`}
                >
                    <div className="header-container">
                        <div id="logo">{getHeaderLogoFunction()}</div>

                        <div className="menu">
                            <ul>{children[0]}</ul>
                            <>{children[1]}</>
                        </div>
                    </div>
                </div>
            )}
            {extras}
        </React.Fragment>
    )
}

getReactiveMenuHeader.propTypes = {
    children: PropTypes.array.isRequired,
    toggleNavbarFunction: PropTypes.func.isRequired,
    getExtraCssFunction: PropTypes.func.isRequired,
    getHeaderLogoFunction: PropTypes.func.isRequired,
    isMobileState: PropTypes.bool.isRequired,
    isOpenState: PropTypes.bool.isRequired,
    extras: PropTypes.node,
}

export { getReactiveMenuHeader }
