import React from 'react'
import Select from 'react-select'

import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import { ISODateString } from '../../utilities/DateFunctions'

import { formatCurrency, isVehicleAttributeIneligible, pad } from '../../utilities/Functions'
import { getPrimaryColor } from '../../utilities/ThemeFunctions'

import { getBidLabel } from '../../utilities/AuctionFunctions'
import { CURRENCY } from '../../constants'

import { lang, permissions } from '../../themeConfig'
import { FaCheck } from '@react-icons/all-files/fa/FaCheck'
import { FaTimes } from '@react-icons/all-files/fa/FaTimes'
import { IoIosLock } from '@react-icons/all-files/io/IoIosLock'

const iconsLookup = {
    mileage: '/img/icon-mileage.svg',
    transmissionAuto: '/img/icon-transmission-auto.svg',
    transmissionManual: '/img/icon-transmission-manual-alt.svg',
    fuel: '/img/icon-fuel.svg',
    firstReg: '/img/icon-reg.svg',
    vat: '/img/icon-vat.svg',
    engine: '/img/icon-engine.svg',
    v5: '/img/icon-v5.svg',
    mot: '/img/icon-mot.svg',
    car_vehicle_type: '/img/icon-vehicle-type-car.svg',
    lcv_vehicle_type: '/img/icon-vehicle-type-lcv.svg',
    plant_vehicle_type: '/img/icon-vehicle-type-plant.svg',
    other_vehicle_type: '/img/icon-vehicle-type-other.svg',
    import: '/img/icon-import-black.svg',
    noOfBids: '/img/icon-bids.svg',
    noOfWatchers: '/img/icon-watchers.svg',
    noOfViews: '/img/icon-views.svg',
    sivPrice: '/img/icon-siv.svg',
}

export function getLocation(vehicle) {
    let siteName = null
    if (vehicle.virtual) {
        siteName = 'e-Live'
    } else if (vehicle.site && vehicle.site.name) {
        siteName = vehicle.site.name.substring(14)
    }

    return siteName
}

export async function getVehicleImageUrl(images) {
    if (!images || images.length === 0) {
        return '/img/no-image.png'
    }
    let imageUrl = images[0]
    const offsidefrontquarter = images.filter((image) => {
        return image.indexOf('offsidefrontquarter') >= 0
    })[0]
    if (offsidefrontquarter) {
        imageUrl = offsidefrontquarter
    }
    const result = await fetch(imageUrl, { method: 'HEAD' })
    if (result.status === 200) {
        return `"${imageUrl}"`
    }
    return null
}

function keyAllowed(key) {
    var keys = [8, 9, 13, 16, 17, 18, 19, 20, 27, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 91, 92, 93]
    return !(key && keys.indexOf(key) === -1)
}

function handleNegative(e) {
    var key = !isNaN(e.charCode) ? e.charCode : e.keyCode

    if (!keyAllowed(key)) e.preventDefault()
}

function renderTableRow(props) {
    if (props.editable) {
        if (props.type === 'radio') {
            return (
                <div className="form-check form-check-inline">
                    {props.options.map((option, index) => {
                        return (
                            <React.Fragment key={index}>
                                <label className="form-check-label mr-3" htmlFor={option.name}>
                                    <input
                                        id={option.name}
                                        type={props.type}
                                        name={props.name}
                                        value={option.value}
                                        checked={props.value === option.value}
                                        className="form-check-input"
                                        onChange={(evt) => props.onChange(props.name, option.value)}
                                    />
                                    {option.name}
                                </label>
                            </React.Fragment>
                        )
                    })}
                </div>
            )
        }

        if (props.type === 'text') {
            return (
                <input
                    type={props.type}
                    value={props.value}
                    className="form-control"
                    onChange={(evt) => props.onChange(props.name, evt.target.value)}
                />
            )
        }

        if (props.type === 'date') {
            return (
                <DatePicker
                    selected={props.value ? new Date(props.value) : null}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => props.onChange(props.name, date)}
                    maxDate={props.maxDate}
                />
            )
        }

        if (props.type === 'number') {
            return (
                <input
                    type={props.type}
                    value={props.value}
                    className="form-control"
                    min="0"
                    max={props.max}
                    onKeyPress={(e) => {
                        if (e.target.value.length >= props.maxLength) e.preventDefault()
                        handleNegative(e)
                    }}
                    onChange={(evt) => props.onChange(props.name, evt.target.value)}
                />
            )
        }
        if (props.type === 'checkbox') {
            return (
                <React.Fragment>
                    <input
                        type={props.type}
                        value={props.value}
                        checked={props.value}
                        className="form-control custom-checkbox"
                        id={props.name}
                        onChange={(evt) => props.onChange(props.name, !props.value)}
                    />
                    <label htmlFor={props.name}>&nbsp;</label>
                </React.Fragment>
            )
        }

        if (props.type === 'textarea') {
            return (
                <textarea
                    value={props.value}
                    className="form-control"
                    onChange={(evt) => props.onChange(props.name, evt.target.value)}
                    rows="5"
                    maxLength={props.maxLength}
                />
            )
        }
        if (props.editable && props.type === 'select') {
            return (
                <Select
                    value={props.value}
                    onChange={props.onChangeSelect}
                    options={props.options}
                    isSearchable={props.isSearchable ? props.isSearchable : false}
                    className="custom-select-input"
                    classNamePrefix="ab-select"
                />
            )
        }
        return null
    } else {
        if (props.type === 'select') {
            return props.value && props.value.label ? props.value.label : '-'
        }
        if (props.type === 'date') {
            return props.value ? ISODateString(props.value, false, true) : '-'
        }
        if (props.type === 'checkbox' || props.type === 'radio') {
            return props.value ? <FaCheck /> : <FaTimes />
        }
        if (props.custom === 'specification') {
            return props.value
        }
        return props.value ? props.value : '-'
    }
}
// TODO put the lookup for these to lowercase yes???
const images = {
    automatic: 'icon_transmission_auto_light.svg',
    manual: 'icon_transmission_manual_light.svg',
    petrol: 'icon_fuel_petrol_light.svg',
    hybrid: 'icon_fuel_hybrid_light.svg',
    electric: 'icon_fuel_hybrid_light.svg',
    diesel: 'icon_fuel_diesel_light.svg',
    'hybrid electric': 'icon_fuel_hybrid_light.svg',
    'diesel/electric hybrid': 'icon_fuel_hybrid_light.svg',
    'petrol/electric hybrid': 'icon_fuel_hybrid_light.svg',
    engine: 'icon_engine_light.svg',
    assured: 'icon-a.png',
    'non-runner': 'icon-non-runner.svg',
}

export function headerImages(key) {
    return images[key.toLowerCase()]
}

const precisionRound = (value, precision) => {
    var factor = Math.pow(10, precision)
    return Math.round(value * factor) / factor
}

export function getEngineText(vehicle) {
    let useLitres = false
    let engineText = ''
    if (vehicle.engineSize && vehicle.engineSize !== 0) {
        engineText = vehicle.engineSize
        if (vehicle.engineSize > 1000) {
            engineText = '' + precisionRound(vehicle.engineSize / 1000, 1)
            if (engineText.indexOf('.') === -1) {
                engineText = engineText += '.0'
            }
            useLitres = true
        }
    }
    if (engineText) {
        if (useLitres) {
            return `${engineText}l`
        } else {
            return `${engineText}cc`
        }
    }
    return '-'
}

export function VehicleStatsItem(props) {
    const value = props.value
    const textStyle = props.textStyle || {}
    return (
        <div className={`card-item ${props.class ? props.class : ''}`}>
            <div className="card-content">
                {props.icon && (
                    <div className="card-icon">
                        <img src={iconsLookup[props.icon]} alt="icon" />
                    </div>
                )}
                <div style={textStyle}>{value}</div>
            </div>
        </div>
    )
}

export function VehicleStatsRow(props) {
    let classes = props.valueClasses

    if (!classes) classes = ''
    return (
        <tr>
            <td className={props.custom && props.custom === 'specification' ? 'no-bold' : ''}>
                {props.label} {props.required && <small style={{ color: 'red' }}>*</small>}
            </td>
            <td className={`${classes} ${props.editable ? 'padding-slim' : ''}`}>{renderTableRow(props)}</td>
        </tr>
    )
}

export function NamaGradeIcon(props) {
    const { vehicle } = props

    let namaGradeClass = ''
    if (vehicle.namaGrade) namaGradeClass = 'nama-grade-' + vehicle.namaGrade.toLowerCase()

    return vehicle.namaGrade ? <span className={`nama-grading ${namaGradeClass}`} /> : '-'
}

export function ABGradeIcon(props) {
    const { vehicle } = props

    let abGradeClass = ''
    if (vehicle.grade) abGradeClass = 'ab-grade-' + vehicle.grade.toLowerCase()

    return vehicle.grade ? <span className={`ab-grading ${abGradeClass}`} /> : '-'
}

export function Mileage(props) {
    const { vehicle } = props

    return (
        <tr>
            <td>Mileage</td>
            <td className={getWarrantyCss(vehicle)}>
                {getMileage(vehicle) ? getMileage(vehicle) : '-'} <span />
            </td>
        </tr>
    )
}

export function MileageIcon(props) {
    const { vehicle } = props

    return (
        <div className="card-item">
            <div className="card-content ">
                <div className="card-icon">
                    <img src={iconsLookup['mileage']} alt="icon" />
                </div>
                <div className={getWarrantyCss(vehicle)}>
                    {getMileage(vehicle) ? getMileage(vehicle) : '-'} <span />
                </div>
            </div>
        </div>
    )
}

export function getClass(vehicle) {
    if (vehicle.biddingDetails) {
        if (
            vehicle.biddingDetails.currentBid &&
            vehicle.biddingDetails.currentBid > 0 &&
            vehicle.biddingDetails.reserveMet
        ) {
            return 'ab-green'
        } else if (
            vehicle.biddingDetails.currentBid &&
            vehicle.biddingDetails.currentBid > 0 &&
            !vehicle.biddingDetails.reserveMet
        ) {
            return 'cb-red'
        } else {
            return ''
        }
    } else {
        return ''
    }
}

export function CurrentBid(props) {
    const { vehicle, subPath, user } = props

    return (
        <div className="card-item card-item-50">
            <div className="card-content">
                <div className="ab-card__title">{getBidLabel(subPath, user.theme)}</div>
                <div className={`ab-card__details-cb ${getClass(vehicle)}`}>
                    {vehicle.biddingDetails && vehicle.biddingDetails.currentBid > 0
                        ? `${CURRENCY}${vehicle.biddingDetails.currentBid.toLocaleString()}`
                        : 'No bids'}
                </div>
            </div>
        </div>
    )
}

export function statFifty(props) {
    const { value, label } = props

    return (
        <div className="card-item card-item-50">
            <div className="card-content">
                <div className="ab-card__title">{label}</div>
                <div className={!props.class ? `ab-card__details-cb` : ''}>{value}</div>
            </div>
        </div>
    )
}

export function StartDate(props) {
    const { vehicle, user } = props

    return (
        <div className="card-item card-item__height-40 card-item-h-40">
            <div className="card-content">
                <div className="ab-card__title">
                    {lang('LABEL_STARTS', user.theme)}:{' '}
                    {vehicle.biddingDetails && vehicle.biddingDetails.startDate
                        ? ISODateString(vehicle.biddingDetails.startDate, false, false, true)
                        : '-'}
                </div>
                <div className="ab-card__details">
                    {vehicle.biddingDetails && vehicle.biddingDetails.startDate
                        ? ISODateString(vehicle.biddingDetails.startDate, true, true, true)
                        : '-'}
                </div>
            </div>
        </div>
    )
}

export function statTwentyFive(props) {
    const { label, value } = props

    return (
        <div className="card-item card-item__height-40 card-item-h-40">
            <div className="card-content">
                <div className="ab-card__title">{label}</div>
                <div className="ab-card__details">{value ? value : '-'}</div>
            </div>
        </div>
    )
}

export function EndDate(props) {
    const { vehicle, user } = props

    return (
        <div className="card-item card-item__height-40 card-item-h-40">
            <div className="card-content">
                <div className="ab-card__title">
                    {lang('LABEL_ENDS', user.theme)}:{' '}
                    {vehicle.biddingDetails && vehicle.biddingDetails.endDate
                        ? ISODateString(vehicle.biddingDetails.endDate, false, false, true)
                        : '-'}
                </div>
                <div className="ab-card__details">
                    {vehicle.biddingDetails && vehicle.biddingDetails.endDate
                        ? ISODateString(vehicle.biddingDetails.endDate, true, true, true)
                        : '-'}
                </div>
            </div>
        </div>
    )
}

export function V5(props) {
    const { vehicle, user } = props
    return (
        <tr>
            <td>{lang('LABEL_V5', user.theme)}</td>
            {vehicle && vehicle.v5details && vehicle.v5details === 'TF' ? (
                <td className="ab-v5">
                    <span>To Follow</span>
                </td>
            ) : (
                <td
                    className={vehicle && vehicle.v5 ? 'ab-v5' : ''}
                    style={vehicle && vehicle.v5 ? { color: getPrimaryColor() } : { color: 'inherit' }}
                >
                    {vehicle && vehicle.v5 ? <FaCheck /> : <FaTimes />}
                </td>
            )}
        </tr>
    )
}

export function V5Icon(props) {
    const { vehicle } = props

    return (
        <div className="card-item">
            <div className="card-content">
                <div className="card-icon">
                    <img src={iconsLookup['v5']} alt="icon" />
                </div>

                {vehicle && vehicle.v5details && vehicle.v5details === 'TF' ? (
                    <div className="ab-v5">
                        <span className={`ab-v5-details ab-v5-tf`}>TF</span>
                    </div>
                ) : (
                    <div
                        className={vehicle && vehicle.v5 ? 'ab-v5' : ''}
                        style={vehicle && vehicle.v5 ? { color: getPrimaryColor() } : { color: 'inherit' }}
                    >
                        {vehicle && vehicle.v5 ? <FaCheck /> : <FaTimes />}
                    </div>
                )}
            </div>
        </div>
    )
}

export function MOT(props) {
    const { vehicle } = props

    let expired = false
    let mot = 'No'

    if (vehicle.motExpiry) {
        let motExpiry = new Date(vehicle.motExpiry)
        mot = ISODateString(motExpiry, false, true)
        expired = new Date().getTime() >= motExpiry.getTime()
    }

    return (
        <tr>
            <td>MOT expires</td>
            <td className={`${expired ? 'text-danger' : ''}`}>{mot}</td>
        </tr>
    )
}

export function MOTIcon(props) {
    const { vehicle, disableValidation } = props
    let showDanger = false
    let mot = 'No'

    if (vehicle && vehicle.motExpiry) {
        let motExpiry = new Date(vehicle.motExpiry)
        mot = ISODateString(motExpiry, false, true)
        showDanger = Date.now() >= motExpiry.getTime()
    }

    if (disableValidation === true) {
        showDanger = false
    }

    return (
        <div className="card-item">
            <div className="card-content">
                <div className="card-icon">
                    <img src={iconsLookup['mot']} alt="icon" />
                </div>
                <div className={`${showDanger ? 'text-danger' : ''}`}>{mot}</div>
            </div>
        </div>
    )
}

export function transmissionIcon(props) {
    let icon = iconsLookup['transmissionManual']
    const dataValue = props.value.toLowerCase()

    if (dataValue === 'automatic') {
        icon = iconsLookup['transmissionAuto']
    }

    let value = dataValue
    if (dataValue === 'manual') {
        value = 'Manual'
    }
    if (dataValue === 'automatic') {
        value = 'Automatic'
    }

    return (
        <div className="card-item">
            <div className="card-content">
                <div className="card-icon">
                    <img src={icon} alt="icon" />
                </div>
                <div>{value}</div>
            </div>
        </div>
    )
}

export function SellerIcon(props) {
    let icon = null
    if (props.user && !props.user.username) {
        icon = (
            <div className="card-lock-icon">
                <IoIosLock size="2em" color="#333" />
            </div>
        )
    }
    return (
        <div className="card-item card-item-75">
            <div className="card-content">
                <div className="catalogue-name">{props.value}</div>
                {icon}
            </div>
        </div>
    )
}

export function NamaGrade(props) {
    return (
        <tr>
            <td>Grade / NAMA</td>
            <td>
                <ABGradeIcon vehicle={props.value} />
                <NamaGradeIcon vehicle={props.value} />
            </td>
        </tr>
    )
}

export function NamaGradeCombinedIcon(props) {
    return (
        <div className="card-item card-item-h-40">
            <div className="card-content">
                <div>
                    <NamaGradeIcon vehicle={props.value} />
                </div>
            </div>
        </div>
    )
}

export function NamaGradeOnlyIcon(props) {
    return (
        <div className="card-item card-item-h-40">
            <div className="card-content">
                <div>
                    <NamaGradeIcon vehicle={props.value} />
                </div>
            </div>
        </div>
    )
}

export function ExchangeNamaGradeCombinedIcon(props) {
    const vehicle = props.value
    const user = props.user

    let textStyle = {}
    if (permissions('SELLER_INSPECTED_FONT_SIZE_SHRUNK')) {
        textStyle = { fontSize: '8pt' }
    }

    if (vehicle && vehicle.inspectionType === 'Express') vehicle.namaGrade = '-'

    if (vehicle && vehicle.namaGrade && vehicle.namaGrade !== 'null') {
        if (permissions('OVERRIDE_NAMA_LABEL', user.theme)) {
            return (
                <VehicleStatsItem
                    class={'card-item-h-40'}
                    textStyle={textStyle}
                    value={lang('LABEL_OVERRIDE_NAMA', user.theme)}
                />
            )
        } else if (vehicle.leaseplanVehicle) {
            return NamaGradeOnlyIcon(props)
        } else {
            return NamaGradeCombinedIcon(props)
        }
    }

    return (
        <VehicleStatsItem
            class={'card-item-h-40'}
            textStyle={textStyle}
            value={lang('LABEL_SELLER_INSPECTED', user.theme)}
        />
    )
}

export function getDoors(vehicle) {
    if (vehicle.doors) return vehicle.doors
    else return ''
}

export function getBodyType(vehicle) {
    if (vehicle.bodyType) return vehicle.bodyType
    else return ''
}

export function getGrade(vehicle) {
    if (vehicle.grade) return vehicle.grade
    else return ''
}

export function getEngineSize(vehicle) {
    if (vehicle.engineSize) return vehicle.engineSize
    else return ''
}

export function getLot(vehicle) {
    if (!vehicle.lot) return null

    return vehicle.lot ? `Lot: ${pad(vehicle.lot, 3)}` : null
}

export function getAuctionTime(vehicle) {
    if (!vehicle.saleStart) return null

    const dateTimeObject = new Date(vehicle.saleStart)
    return ISODateString(dateTimeObject, false, false, true)
}

export function getAuctionDate(vehicle) {
    if (!vehicle.saleStart) return null

    const dateTimeObject = new Date(vehicle.saleStart)
    return ISODateString(dateTimeObject, false, true)
}

export function presentLiveAuctionLink(vehicle) {
    return false
}

export function getAuctionLocation(vehicle) {
    const { virtual, site } = vehicle

    let siteName = null
    if (virtual) {
        siteName = 'e-Live'
    } else if (site && site.name) {
        siteName = site.name.substring(14)
    }

    return siteName
}

export function getDdateOfRegistration(vehicle) {
    if (vehicle.firstRegistration) return ISODateString(vehicle.firstRegistration, false, true)
    else return ''
}

export function getRegistration(vehicle) {
    if (vehicle.registration) return vehicle.registration
    else return ''
}

export function getVin(vehicle) {
    if (vehicle.vin) return vehicle.vin
    else return ''
}

export function getMileage(vehicle) {
    if (!vehicle) return ''

    if (vehicle.mileage) return vehicle.mileage.toLocaleString('en-GB')
    else return ''
}

export function getWarrantyCss(vehicle) {
    if (!vehicle) {
        return
    }

    const ineligibleFieldCss = isVehicleAttributeIneligible(vehicle.vehicleIneligibilitys, 'mileage')
        ? 'text-danger'
        : ''

    if (vehicle.mileageWarranty) {
        return 'ab-mileage-w ' + ineligibleFieldCss
    }

    return ineligibleFieldCss
}

export function getPrice(price) {
    return formatCurrency(price)
}

export function getGlassPrice(vehicle) {
    return formatCurrency(vehicle.glassTradePrice)
}

export function getCurrentRetailPrice(vehicle) {
    return formatCurrency(vehicle.deltapointRetailPrice)
}

export function getCurrentTradePrice(vehicle) {
    return formatCurrency(vehicle.deltapointTradePrice)
}

export function isInspection(vehicle) {
    return vehicle.inspection ? true : false
}

export function getInspection(vehicle) {
    if (vehicle.inspection) return vehicle.inspection
    else return ''
}

export function getCondition(vehicle) {
    if (vehicle.conditions && vehicle.conditions.length > 0) return vehicle.conditions
    else return null
}

export function getDamageInfo(vehicle) {
    if (vehicle.finalDamageResult && vehicle.finalDamageResult.length > 0) return vehicle.finalDamageResult
    else return null
}

export function getDamagePanelResults(vehicle) {
    if (vehicle.damagePanelResults && vehicle.damagePanelResults.length > 0) return vehicle.damagePanelResults
    else return null
}

export function getDamagePanelResultQuestions(vehicle) {
    if (vehicle.damagePanelQuestions && vehicle.damagePanelQuestions.length > 0) return vehicle.damagePanelQuestions
    else return null
}

export function getInspectionDate(vehicle) {
    if (getInspection(vehicle).date && !vehicle.leaseplanVehicle) {
        const dateTimeObject = new Date(getInspection(vehicle).date)
        return ISODateString(dateTimeObject, false, false, false)
    } else {
        return ''
    }
}

export function getInspectionRegistration(vehicle) {
    return getRegistration(vehicle)
}

export function getInspectionCN(vehicle) {
    return getVin(vehicle)
}

export function getInspectionExteriorGrade(vehicle) {
    if (getGrade(vehicle).length > 0) return getGrade(vehicle).substring(0, 1)
}

export function getInspectionInternalGrade(vehicle) {
    if (getGrade(vehicle).length > 1) return getGrade(vehicle).substring(1)
}

export function getInspectionMake(vehicle) {
    if (getInspection(vehicle).make) {
        return getInspection(vehicle).make.toUpperCase()
    } else {
        return ''
    }
}

export function getInspectionModel(vehicle) {
    if (getInspection(vehicle).model) {
        return getInspection(vehicle).model
    } else {
        return ''
    }
}

export function getInspectionEngineSize(vehicle) {
    return getEngineSize(vehicle)
}

export function getInspectionEngineNumber(vehicle) {
    if (getInspection(vehicle).engineNo) {
        return getInspection(vehicle).engineNo
    } else {
        return ''
    }
}

export function getInspectionBodyPlan(vehicle) {
    const doors = getDoors(vehicle)
    const bodyType = getBodyType(vehicle)

    let bodyPlan = ''

    if (doors > 0) bodyPlan = doors + ' Door'

    if (bodyType.length > 0) bodyPlan = bodyPlan + ' ' + bodyType

    return bodyPlan
}

export function getInspectionFuel(vehicle) {
    if (getInspection(vehicle).fuel) {
        return getInspection(vehicle).fuel
    } else {
        return ''
    }
}

export function getInspectionGearBox(vehicle) {
    if (getInspection(vehicle).transmission) {
        return getInspection(vehicle).transmission
    } else {
        return ''
    }
}

export function getInspectionMileage(vehicle) {
    return getMileage(vehicle)
}

export function getInspectionColour(vehicle) {
    if (getInspection(vehicle).colour) {
        return getInspection(vehicle).colour
    } else {
        return ''
    }
}

export function getInspectionPaintType(vehicle) {
    if (getInspection(vehicle).paint) {
        return getInspection(vehicle).paint
    } else {
        return ''
    }
}

export function getInspectionInternalColourTrim(vehicle) {
    if (getInspection(vehicle).engineNo) {
        return getInspection(vehicle).engineNo
    } else {
        return ''
    }
}

export function getInspectionSeats(vehicle) {
    if (getInspection(vehicle).seats) {
        return getInspection(vehicle).seats
    } else {
        return ''
    }
}

export function getInspectionDateOfFirstRegistration(vehicle) {
    return getDdateOfRegistration(vehicle)
}

export function getInspectionKeys(vehicle) {
    if (getInspection(vehicle).keys || getInspection(vehicle).keys === 0) {
        return getInspection(vehicle).keys
    } else {
        return ''
    }
}

export function getInspectionTyres(vehicle) {
    if (getInspection(vehicle).tyres) {
        return getInspection(vehicle).tyres
    } else {
        return ''
    }
}

export function getInspectionTyresNSF(vehicle) {
    if (getInspectionTyres(vehicle).nsf || getInspectionTyres(vehicle).nsf === 0) {
        return getInspectionTyres(vehicle).nsf
    } else {
        return ''
    }
}

export function getInspectionTyresNSR(vehicle) {
    if (getInspectionTyres(vehicle).nsr || getInspectionTyres(vehicle).nsr === 0) {
        return getInspectionTyres(vehicle).nsr
    } else {
        return ''
    }
}
export function getInspectionTyresOSR(vehicle) {
    if (getInspectionTyres(vehicle).osr || getInspectionTyres(vehicle).osr === 0) {
        return getInspectionTyres(vehicle).osr
    } else {
        return ''
    }
}
export function getInspectionTyresOSF(vehicle) {
    if (getInspectionTyres(vehicle).osf || getInspectionTyres(vehicle).osf === 0) {
        return getInspectionTyres(vehicle).osf
    } else {
        return ''
    }
}
export function getInspectionTyresSpare(vehicle) {
    if (getInspectionTyres(vehicle).spare || getInspectionTyres(vehicle).spare === 0) {
        return getInspectionTyres(vehicle).spare
    } else {
        return ''
    }
}

export function isWatching(vehicle) {
    return vehicle.watching
}

export function isProxyBidAllowedForVehicle(vehicle) {
    return vehicle.proxyBid
}

export function isProxyBidOnVehicle(vehicle) {
    return vehicle.currentProxyBid && vehicle.currentProxyBid.amount > 0
}

export function getProxyBidButton(vehicle, toggle, removeBid) {}

export function isBidAndBuyNowAllowedForVehicle(vehicle) {
    return (
        vehicle.bidBuyNowPrice &&
        (!vehicle.currentBidAndBuyNow || (vehicle.currentBidAndBuyNow && isBidAndBuyNowRejected(vehicle)))
    )
}

export function isBidAndBuyOnVehicle(vehicle) {
    return vehicle.currentBidAndBuyNow && vehicle.currentBidAndBuyNow.status === 'pending'
}

export function isBidAndBuyNowRejected(vehicle) {
    return vehicle.currentBidAndBuyNow && vehicle.currentBidAndBuyNow.status === 'rejected'
}

export function getIneligibleFieldDisplayValue(field) {
    switch (field) {
        case 'nonrunner':
            return 'Non Runner'

        case 'price':
            return 'Hammer Price'

        case 'mileage':
            return 'Mileage'

        case 'vehicletype':
            return 'Vehicle Type'

        case 'firstregistration':
            return 'First Reg.'

        case 'imported':
            return 'Imported'

        case 'registration':
            return 'Registration'
        default:
            return 'missing field name! (' + field + ')'
    }
}

export function getVehicleTypeIconImageKey(vehicleType) {
    switch (vehicleType) {
        case 'car':
            return 'car_vehicle_type'

        case 'commercial':
            return 'lcv_vehicle_type'

        case 'plant':
            return 'plant_vehicle_type'

        default:
            return 'other_vehicle_type'
    }
}

export function getDamageImages(damages) {
    if (damages) {
        let images = []
        damages.forEach((damage) => {
            const area = (damage.area || '').toUpperCase()
            const section = damage.section && damage.section !== damage.area ? damage.section : ''
            const component = damage.component || ''
            const subcomponent =
                damage.subComponent && damage.subComponent !== damage.component ? damage.subComponent : ''
            const location = `${area} ${section} ${component} ${subcomponent ? '-' : ''} ${subcomponent}`
            images.push({
                image: damage.combinedDamageInfo[0].image,
                label: `${location}${
                    damage.combinedDamageInfo[0].resolution
                        ? ', Action - ' + damage.combinedDamageInfo[0].resolution
                        : ''
                }`,
            })
        })
        return images
    } else {
        return []
    }
}
