import {
    API_BASE,
    API_GET_HEADERS,
    FETCH_PROFILE_CONFIG,
    FETCH_PROFILE_CONFIG_ERROR,
    FETCH_PROFILE_CONFIG_SUCCESS,
} from '../constants'
import { handleError } from '../utilities/Functions'
import { addNotification } from './notifications'
import { setTheme, fetchUser } from '../actions/user'
import { fetchNavigation } from '../actions/navigation'
import { fetchProfileLanguage } from './language'
import { fetchProfilePermissions } from './permissions'

export const fetchProfileConfigBegin = () => ({
    type: FETCH_PROFILE_CONFIG,
})

export const fetchProfileConfigSuccess = (profileConfig) => ({
    type: FETCH_PROFILE_CONFIG_SUCCESS,
    payload: profileConfig,
})

export const fetchProfileConfigFailure = (error) => ({
    type: FETCH_PROFILE_CONFIG_ERROR,
    payload: { error },
})

export function fetchProfileConfig() {
    let url = `${API_BASE}api/profileconfig`

    return async (dispatch) => {
        dispatch(fetchProfileConfigBegin())

        return fetch(url, API_GET_HEADERS())
            .then((response) => Promise.all([response.status, response.ok, response.json()]))
            .then(([status, responseOk, data]) => {
                if (responseOk) {
                    dispatch(setTheme(data.theme)) // Partially needs to be after profile language
                    dispatch(fetchProfileLanguage(data.theme))
                    dispatch(fetchProfilePermissions(data.theme))
                    dispatch(fetchProfileConfigSuccess({ data: data }))
                    dispatch(fetchUser())
                    dispatch(fetchNavigation())
                    return data
                } else {
                    console.log('handleError')
                    handleError(status, dispatch, data, fetchProfileConfigFailure())
                }
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        text: `Failed api ${url}`,

                        type: 'error',
                    }),
                )
                dispatch(fetchProfileConfigFailure(error))
            })
    }
}
