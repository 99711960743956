import React, { Component } from 'react'
import { ScaleLoader } from 'react-spinners'
import { css } from '@emotion/react'
import VehicleCard from '../../UiKit/VehicleCard'
import AddToEXchangeModal from '../Modals/AddToEXchangeModal'
import { getFundResults } from '../../utilities/FundingFunctions'
import { getFilteredAppraisalGroup, getFilteredAppraisals } from '../../utilities/Functions'
import { APP_EHUB, CT_CATALOGUE, CT_EHUB_EXCHANGE, CT_EXCHANGE, CT_SECURE_BANK_TRUST_FUNDING } from '../../constants'
import sortBy from 'lodash/sortBy'
import reverseFunc from 'lodash/reverse'

const override = css`
    display: block;
    margin: 0 auto;
`
class SearchResults extends Component {
    constructor(props) {
        super(props)
        this.state = {
            vehicles: [],
            removedProxyBid: [],
            currentUrl: 'catalogue',
        }
        this.setProxyBid = this.setProxyBid.bind(this)
        this.openProxyBidModal = this.openProxyBidModal.bind(this)
        this.sort = this.sort.bind(this)
        this.setSortedArray = this.setSortedArray.bind(this)
    }

    componentDidMount() {
        this.setState({ currentUrl: this.props.type })

        this.loadVehicles(this.props, false)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.loadVehicles(nextProps, true)
    }

    loadVehicles(props, checkSortingOrder) {
        const vehicles = this.getVehicles(props)
        const sortedVehicles = this.getSortedVehicles(props.sorting, vehicles)
        const sortedState = this.getSortedIds(sortedVehicles)
        const sortedIds = sortedState.map((s) => s.id)
        this.setState({ vehicles: sortedVehicles })
        this.props.updateCount(vehicles.length)

        if (checkSortingOrder) {
            if (JSON.stringify(props.sorting.sortingOrder) !== JSON.stringify(sortedIds)) {
                this.setSortedArray(sortedVehicles)
                this.props.setPrevAndNextOrder(sortedState)
            }
        } else {
            this.props.setPrevAndNextOrder(sortedState)
        }
    }

    getSortedVehicles(sorting, vehicles) {
        return this.sort(sorting.dataField, sorting.reverse, vehicles)
    }

    getSortedIds(vehicles) {
        return vehicles.map((vehicle) => ({
            id: vehicle.id,
            vendor: vehicle.vendorCode || vehicle.account,
        }))
    }

    getVehicles(props) {
        let vehicles
        switch (props.type) {
            case 'watch':
                vehicles = props.vehicles.data.filter((vehicle) => vehicle.watching)

                break
            case 'e-xchange':
                if (props.selectedFilter === 'active') {
                    vehicles = props.vehicles.data.filter(
                        (item) =>
                            item.cardType === CT_EXCHANGE &&
                            ((item.biddingDetails &&
                                item.biddingDetails.userMaxBid > 0 &&
                                !item.biddingDetails.ended) ||
                                (item.watching && !item.biddingDetails.ended)),
                    )
                } else if (props.selectedFilter === 'ended') {
                    vehicles = props.vehicles.data.filter(
                        (item) => item.cardType === CT_EXCHANGE && item.biddingDetails && item.biddingDetails.ended,
                    )
                } else {
                    vehicles = []
                }
                break
            case 'bid-buy-now':
                vehicles = props.vehicles.data.filter(
                    (vehicle) => vehicle.currentBidAndBuyNow && vehicle.currentBidAndBuyNow.status !== 'accepted',
                )
                break
            case 'proxy-bid':
                const temp = props.vehicles.data.filter(
                    (vehicle) =>
                        vehicle.currentProxyBid && vehicle.currentProxyBid.amount && vehicle.currentProxyBid.amount > 0,
                )
                vehicles = [...temp, ...this.state.removedProxyBid]
                break
            case APP_EHUB:
                const label = props.selectedOption && props.selectedOption.label
                vehicles = props.ehubAppraisals.filter((appraisal) => {
                    if (label === 'Aston Barclay') {
                        return appraisal.cardType === 'collections'
                    } else if (label === 'Sold (List and Collect)') {
                        return true
                    } else {
                        return appraisal.status === label || appraisal.cardType === CT_EHUB_EXCHANGE
                    }
                })
                break
            case CT_SECURE_BANK_TRUST_FUNDING:
                vehicles = getFundResults(props.selectedFilter, props.funding).data
                break
            default:
                vehicles = props.vehicles.data
                if (this.props.user.loggedIn) {
                    vehicles = props.vehicles.data.filter(
                        (vehicle) =>
                            vehicle.cardType === CT_CATALOGUE ||
                            (vehicle.cardType === CT_EXCHANGE &&
                                vehicle.biddingDetails &&
                                !vehicle.biddingDetails.ended),
                    )
                }
        }
        return vehicles
    }

    async setProxyBid(currentProxyBid, vehicle) {
        vehicle.currentProxyBid = currentProxyBid
        if (this.props.type === 'proxy-bid') {
            this.setState({
                removedProxyBid: [...this.state.removedProxyBid, vehicle],
            })
        }
        await this.props.onClickSetProxyBid(currentProxyBid)
    }

    setSortedArray(vehicles) {
        const sortedIds = vehicles.map((vehicle) => vehicle.id)
        this.props.setSortedVehicles(sortedIds)
    }

    async openProxyBidModal(currentProxyBid, proxyVehicle) {
        if (this.props.type === 'proxy-bid') {
            const array = this.state.removedProxyBid.filter((vehicle) => vehicle.id !== proxyVehicle.id)
            this.setState({
                removedProxyBid: array,
            })
        }
        await this.props.onOpenProxyBidModal(currentProxyBid)
    }

    sort(selectedOption, reverse, appraisals) {
        const newList = sortBy(appraisals, selectedOption.value)
        if (reverse) {
            return reverseFunc(newList)
        }
        return newList
    }

    render() {
        const {
            user,
            pagination,
            onClickUnWatch,
            onClickWatch,
            onOpenBuyNowModal,
            onClickBiddingModal,
            onClickFundVehicle,
            onOpenAddToEXchangeModal,
            getClosedGroup,
        } = this.props

        let offsetStart = pagination.offsetStart
        let offsetFinish = pagination.offsetFinish

        if (this.state.vehicles.length > 0) {
            let ogList = this.state.vehicles.slice()
            let list = ogList
            if (this.props.type === APP_EHUB) {
                list = getFilteredAppraisals(ogList, this.props.locationSearch)
                const pathComponents = this.props.location.pathname.split('/').filter((e) => e)
                if (
                    pathComponents.indexOf('e-hub') === 0 &&
                    pathComponents.indexOf('e-xchange') === 2 &&
                    this.props.match.params.subPath !== 'sold-lac'
                ) {
                    list = getFilteredAppraisalGroup(
                        ogList,
                        this.props.locationSearch,
                        this.props.allGroupsFilter,
                        this.props.closedGroupFilter,
                    )
                }
            }

            const currentDataSet = list.slice(offsetStart, offsetFinish)

            return (
                <React.Fragment>
                    {currentDataSet.map((vehicle, index) => (
                        <VehicleCard
                            key={`${vehicle.id}-${index}`}
                            user={user}
                            vehicle={vehicle}
                            type={this.props.type}
                            length={this.state.vehicles.length}
                            onClickOpenProxyBidModal={this.openProxyBidModal}
                            onClickSetProxyBid={this.setProxyBid}
                            onClickOpenBuyNowModal={onOpenBuyNowModal}
                            onClickUnWatch={onClickUnWatch}
                            onClickBiddingModal={onClickBiddingModal}
                            onClickWatch={onClickWatch}
                            currentUrl={this.state.currentUrl}
                            viewType={this.props.viewType}
                            selectedOption={this.props.selectedOption}
                            websocketConnected={this.props.websocket.connected}
                            onOpenSendToAuctionModal={this.props.onOpenSendToAuctionModal}
                            toggleStatus={this.props.toggleStatus}
                            onClickFundVehicle={onClickFundVehicle}
                            onOpenAddToEXchangeModal={onOpenAddToEXchangeModal}
                            accountCode={this.props.accountCode}
                            view={this.props.selectedFilter}
                            path={this.props.match.params.path}
                            subPath={this.props.match.params.subPath}
                            getClosedGroup={getClosedGroup}
                        />
                    ))}
                    <AddToEXchangeModal />
                </React.Fragment>
            )
        } else {
            if (this.props.loading) {
                return (
                    <div className="loading-wrapper">
                        <ScaleLoader className={override} size={10} color={'#F67419'} loading={true} />
                    </div>
                )
            } else {
                return (
                    <div className="no-data-wrapper text-center">
                        <div className="no-data">No results</div>
                    </div>
                )
            }
        }
    }
}

export default SearchResults
