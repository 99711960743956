import React, { Component } from 'react'

import SaveSearchModal from './SaveSearchModal'
import BuyNowModal from './BuyNowModal'
import ProxyBidModal from './ProxyBidModal'
import BiddingModal from './BiddingModal'
import EditSaveSearchModal from './EditSaveSearchModal'

class Modals extends Component {
    render() {
        return (
            <div>
                <SaveSearchModal />
                <BuyNowModal />
                <ProxyBidModal />
                <BiddingModal />
                <EditSaveSearchModal />
            </div>
        )
    }
}

export default Modals
